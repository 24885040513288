/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
// import ToolTip from '../../../shared/ToolTip';
// import Notification from '../../../shared/Notification';
import {
  isNotEmpty,
  isEmpty,
  unFocusForOnboarding,
  shortenedVulnerabilityScannerNameMap,
  getDimensionsAndOffset,
} from '../../../shared/Utilities';
import { OnboardingWizardContext } from '../../../Contexts/OnboardingWizard';
import InlineSVG from '../../../shared/InlineSVG';
import { makeRequest } from '../../../../legacy/io';
import { CurrentUserContext } from '../../../Contexts/CurrentUser';
import { canConfigure, hasFeatureAccess } from '../../App/AccessControl';

const TaskButton = ( {
  taskType,
  recordOptions,
  tasks,
  refreshTasks,
  warnings,
  handleWarningsClick=() => {},
} ) => {

  const [ isWorking, setIsWorking ] = React.useState( false );
  const [ status, setStatus ] = React.useState( '' );
  const [ workItems, setWorkItems ] = React.useState( [] );
  const [ additionalOptions, setAdditionalOptions ] = React.useState( {} );
  const [ showAdditional, setShowAdditional ] = React.useState( false );
  const [ newWorkItems, setNewWorkItems ] = React.useState( [] );

  // settings for task checkboxes
  const [ forceRescan, setForceRescan ] = React.useState( false );
  const [ runNextTask, setRunNextTask ] = React.useState( true );

  const [ additionalOptionsStyle, setAdditionalOptionsStyle ] = React.useState( { top: '3em', right: '0em' } );
  const additionalOptionsRef = React.useRef( null );

  const [ , , refreshWizard, , , , , , , ] = React.useContext( OnboardingWizardContext );
  const [ currentUser, , licenseInfo ] = React.useContext( CurrentUserContext );

  const hasAdditionalOptions = [
    // 'upgrade',
    'feed',
    'authenticated',
    'connectivity',
    'import',
    'process',
  ];

  const buttonLabelMap = {
    feed: {
      start: 'Sync',
      running: 'Syncing...',
    },
    authenticated: {
      start: 'Scan',
      running: 'Scanning...',
    },
    connectivity: {
      start: 'Test',
      running: 'Testing...',
    },
    import: {
      start: 'Import',
      running: 'Importing...',
    },
    analysis: {
      start: 'Analyze',
      running: 'Analyzing...',
    },
    process: {
      start: 'Process',
      running: 'Processing...',
    },
    upgrade: {
      start: 'Update',
      running: 'Updating...',
    },
  };

  React.useEffect( () => {
    if ( isNotEmpty( taskType ) && isNotEmpty( tasks ) ) {
      if ( isNotEmpty( tasks[taskType].current ) ) {
        setStatus( tasks[taskType].current.status );
        setWorkItems( tasks[taskType].current.work_items );

      } else {
        setStatus( '' );
        setWorkItems( [] );
      }

      if ( taskType === 'analysis' ) {
        setNewWorkItems( [ 'base' ] );
        setAdditionalOptions( { base: 'Base Model' } );
      }
    }
  }, [ taskType, tasks ] );

  React.useEffect( () => {
    if ( isEmpty( workItems ) ) {
      if ( status === 'running' ) {
        setIsWorking( true );
      } else {
        setIsWorking( false );
      }
    } else if ( status === 'running' ) {
      setIsWorking( true );
    } else {
      setIsWorking( false );
    }
  }, [ status, workItems, newWorkItems, additionalOptions ] );

  const handleSelection = e => {
    let value = e.target.valueAsNumber || e.target.value;
    value = Array.from( e.target.selectedOptions, option => option.value );

    setNewWorkItems( value );
  };

  // main button action that decides whether to kick off the task,
  // or gather additional info and then kick of the task
  // or stop the task
  const handleTaskButtonClick = () => {
    if ( isWorking && stopAllowed() ) {
      stopTask();
    } else if ( !isWorking && hasAdditionalOptions.includes( taskType ) ) {
      setShowAdditional( true );
    } else if ( taskType === 'analysis' && confirm( 'Are you sure you want to begin Risk Analysis Prioritization?' ) ) {
      startTask( );
    } else if ( taskType === 'upgrade' && confirm( 'Are you sure you want to update the DeepSurface RiskAnalyzer?' ) ) {
      startTask( );
    }
  };

  const startTask = async ( ) => {

    const params = {
      project: 'default',
      task: taskType,
      // eslint-disable-next-line camelcase
      run_next_task: runNextTask,
    };

    if ( taskType === 'process' ) {
      params.task = 'authenticated';
      // eslint-disable-next-line camelcase
      params.force_rescan = true;
    }

    if ( isNotEmpty( newWorkItems ) ) {
      // eslint-disable-next-line camelcase
      params.work_items = newWorkItems;
    }

    if ( taskType === 'authenticated' ) {
      // eslint-disable-next-line camelcase
      params.force_rescan = forceRescan;
    }

    if ( taskType === 'analysis' ) {
      // eslint-disable-next-line camelcase
      params.force_analysis = true;
    }

    await makeRequest( 'START', '/task', params );

    unFocusForOnboarding( document.getElementById( `${taskType}Task` ) );

    refreshTasks();

    if (
      isNotEmpty( currentUser )
      && isNotEmpty( licenseInfo )
      && hasFeatureAccess( currentUser, licenseInfo, 'f_onboarding' )
    ) {
      refreshWizard();
    }
    resetTask();
  };

  const stopAllowed = () => taskType === 'authenticated' || taskType === 'process' || taskType === 'connectivity';

  const stopTask = async () => {
    const params = {
      project: 'default',
      task: taskType,
    };
    if ( confirm( 'Are you sure you want to stop this task?' ) ) {
      await makeRequest( 'STOP', '/task', params );

      refreshTasks();
    }
  };

  const resetTask = () => {
    setShowAdditional( false );
    if ( taskType !== 'analysis' ) {
      setNewWorkItems( [] );
    }
    setForceRescan( false );
    setRunNextTask( true );
  };

  React.useEffect( () => {
    if ( isNotEmpty( recordOptions ) ) {
      const _options = {};

      Object.entries( recordOptions ).map( ( [ value, record ] ) => {
        // importing vuln scanner
        if ( taskType === 'import' ) {
          let instance = '';
          if ( isNotEmpty( record.settings ) ) {
            if ( record.category === 'vulnerability_scanner' ) {
              if ( record.settings.label ) {
                instance =  record.settings.label;
              } else if ( record.settings.server ) {
                instance =  record.settings.server;
              } else if ( record.settings.username ) {
                instance =  record.settings.username;
              } else {
                instance =  'Manual Upload';
              }
              // eslint-disable-next-line max-len
              _options[value] = `${shortenedVulnerabilityScannerNameMap[record?.tool]} ${ isNotEmpty( instance ) ? `(${instance})` : ''}`;
            }
          }
        }
        // running a scanGroup
        if ( taskType === 'authenticated' ) {
          _options[value] = record.label;
        }
        // running an analysis
        if ( taskType === 'analysis' ) {
          _options[value] = record.label;
        }

      } );

      setAdditionalOptions( _options );
      if ( isNotEmpty( _options ) ) {
        setNewWorkItems( [ Object.keys( _options )[0] ] );
      }
    }
  }, [ recordOptions, taskType ] );

  React.useEffect( ( ) => {
    document.addEventListener( 'keydown', escFunction );

    return () => {
      document.removeEventListener( 'keydown', escFunction );
    };
  }, [] );

  const escFunction = React.useCallback( ( e ) => {
    // esc key
    if ( e.keyCode === 27 ) {
      setShowAdditional( false );
    }
  }, [] );

  // position the additionalOptions when they need to be shown so that they are on the screen
  React.useEffect( () => {
    if ( showAdditional && isNotEmpty( additionalOptionsRef ) && isNotEmpty( additionalOptionsRef.current ) ) {
      const offset = getDimensionsAndOffset( additionalOptionsRef.current );
      const padding = 16;

      if ( ( offset.top + offset.height + padding ) > window.innerHeight ) {
        setAdditionalOptionsStyle( {
          top: 48 - 48 - 8 - offset.height,
          left: '0em',
        } );
      } else {
        setAdditionalOptionsStyle( {
          top: '3em',
          left: '0em',
        } );
      }
    } else {
      setAdditionalOptionsStyle( {
        top: '3em',
        left: '0em',
      } );
    }
  }, [ showAdditional, additionalOptionsRef ] );

  return (
    <div className="startButtonWrapper">
      {
        showAdditional &&
        <React.Fragment>
          <div className="additionalShade" onClick={ () => setShowAdditional( false ) } />
          <div className="additionalContainer" ref={additionalOptionsRef} style={ additionalOptionsStyle }>
            <h4>
              Additional Options
              <button
                onClick={ () => setShowAdditional( false ) }
                className="roundGlyphButton"
              >
                <InlineSVG type="close" />
              </button>
            </h4>
            {
              (
                taskType === 'authenticated'
                || taskType === 'analysis'
                || taskType === 'import'
              ) &&
              <select
                value={newWorkItems}
                onChange={handleSelection}
                multiple
                disabled={taskType === 'analysis'}
              >
                {
                  Object.entries( additionalOptions ).map( ( [ value, display ], i ) => {
                    return  <option key={i} value={value}>{ display }</option>;
                  } )
                }
              </select>
            }
            {
              ( taskType === 'authenticated' || taskType === 'analysis' ) &&
              <React.Fragment>
                {
                  taskType === 'authenticated' &&
                  <label>
                    {
                      <div className={
                        `checkboxFieldWrapper ${forceRescan ? 'checked' : ''}`
                      }>
                        <input
                          type="checkbox"
                          onChange={ () => setForceRescan( !forceRescan ) }
                          checked={ forceRescan }
                        />
                      </div>
                    }
                    <span className="labelWrapper">Force re-scan of recently scanned hosts?</span>
                  </label>
                }
              </React.Fragment>
            }
            {
              taskType !== 'analysis' &&
              <label>
                <div className={
                  `checkboxFieldWrapper ${runNextTask ? 'checked' : ''}`
                }>
                  <input
                    type="checkbox"
                    onChange={ () => setRunNextTask( !runNextTask ) }
                    checked={ runNextTask }
                  />
                </div>
                <span className="labelWrapper">
                  Run next task in sequence when finished?
                </span>
              </label>
            }
            <div className="actionsContainer">
              <button
                onClick={ () => setShowAdditional( false ) }
                className="additionalCancelButton"
              >
                Cancel
              </button>
              <button
                disabled={
                  ( taskType === 'authenticated' || taskType === 'analysis' ) ? isEmpty( newWorkItems ) : false
                }
                onClick={ startTask }
                className="additionalButton"
              >
                { buttonLabelMap[taskType].start }
              </button>
            </div>
          </div>
        </React.Fragment>
      }
      <button
        onClick={ handleTaskButtonClick }
        disabled={ !canConfigure( licenseInfo ) }
        // eslint-disable-next-line max-len
        className={ `${!canConfigure( licenseInfo ) ? 'disabled' : ''} taskRunnerButton ${status} ${stopAllowed() ? 'stopAllowed' : ''}` }
      >
        {
          isWorking
            ? <React.Fragment>
              <InlineSVG type="cycle" elementClass="running" />
              {
                stopAllowed() &&
                <InlineSVG type="stop" elementClass="stop" />
              }
            </React.Fragment>
            : <React.Fragment>
              <InlineSVG type="play" elementClass="start" />
              <InlineSVG type="queue" elementClass="queued" />
            </React.Fragment>
        }
        <span className="taskStartLabel">{ buttonLabelMap[taskType][isWorking ? 'running' : 'start'] }</span>
        <span className="queuedLabel">Queued</span>
        {
          ( isNotEmpty( warnings ) && isNotEmpty( warnings[taskType] ) ) &&
          <div className="warningDot" onClick={ e => handleWarningsClick( e, warnings[taskType] ) }/>
        }
      </button>
    </div>

  );
};

export default TaskButton;
