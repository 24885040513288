/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { RiskContext } from '../../../../../Contexts/Risk';
import { formatNumber, getRiskClass, isNotEmpty } from '../../../../../shared/Utilities';

import './RiskScore.scss';
import EmptyState from '../../../../../shared/EmptyState';

const RiskScore = ( { item, settings, data } ) => {

  const [ globalRisk, targetRisk ] = React.useContext( RiskContext );

  const [ riskClass, setRiskClass ] = React.useState( null );

  React.useEffect( () => {
    if ( isNotEmpty( globalRisk ) && isNotEmpty( targetRisk ) ) {
      if ( isNotEmpty( item ) && item.key === 'risk_target' ) {
        setRiskClass( 'default' );
      } else {
        const _riskClass = getRiskClass( globalRisk, targetRisk );
        setRiskClass( _riskClass );
      }
    }
  }, [ globalRisk, targetRisk, settings, data ] );

  return (
    <React.Fragment>
      {
        ( isNotEmpty( globalRisk ) && isNotEmpty( riskClass ) && isNotEmpty( item ) )
          ? <div className={ `riskScoreWidgetWrapper risk--${riskClass}` }>
            <div className="outerRiskCircle">
              <div className="innerRiskCircle">
                <div className="scoreAndLabelWrapper">
                  <strong>
                    {
                      item.key === 'risk_target'
                        ? formatNumber( Math.round( targetRisk ) )
                        : formatNumber( Math.round( globalRisk ) )
                    }
                  </strong>
                  <span>
                    {
                      item.key === 'risk_target'
                        ? 'Target Risk'
                        : 'Risk Score'
                    }
                  </span>
                </div>
              </div>
            </div>
          </div>
          : <EmptyState message="No data available" />
      }
    </React.Fragment>
  );
};

export default RiskScore;