/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';

import './ScanningAgentVersionBreakdown.scss';
import { tagColors, isNotEmpty } from '../../../../../shared/Utilities';
import Donut from '../../../../../shared/Charts/Donut';
import Legend from '../../../../../shared/Charts/Legend';
import EmptyState from '../../../../../shared/EmptyState';

const ScanningAgentVersionBreakdown = ( { data, settings } ) => {
  const [ versionTotal, setVersionTotal ] = React.useState( 1 );
  const [ versionData, setVersionData ] = React.useState( null );
  const [ hoveredSeriesIdentifier, setHoveredSeriesIdentifier ] = React.useState( null );

  const setupData = data => {
    if ( isNotEmpty( data ) ) {
      const availableColors = [ ...tagColors.four ];
      availableColors.shift();

      const _versionData = {};
      const total = Object.values( data ).reduce( ( accum, count ) => ( accum + count ), 0 );

      Object.entries( data ).map( ( [ versionKey, count ], index ) => {
        _versionData[versionKey] = {
          label: versionKey,
          stroke: availableColors[index % 8],
          value: ( count / total ) * 100,
          originalCount: count,
          total: count,
          key: versionKey,
        };
      } );

      setVersionTotal( total );
      setVersionData( _versionData );
    }
  };


  React.useEffect( () => {
    if ( isNotEmpty( data ) ) {
      setupData( data.version );
    }
  }, [ data, settings ] );

  return (
    <React.Fragment>
      {
        ( isNotEmpty( versionTotal ) && isNotEmpty( versionData ) )
          ? <React.Fragment>
            <Donut
              data={versionData}
              total={versionTotal}
              hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
              setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
            />
            <Legend
              legendData={ versionData }
              hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
              setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
            />
          </React.Fragment>
          : <EmptyState message="No scanning data available" />
      }
    </React.Fragment>
  );
};

export default ScanningAgentVersionBreakdown;