/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';

import {
  isNotEmpty,
  recordSorter,
  reportTypeDisplayName,
  uniqueArray,
  isEmpty,
  removeFromURLHash,
  isEqual,
} from '../../../../shared/Utilities';

import Step1 from './RemediationSteps/Step1';
import Step2 from './RemediationSteps/Step2';
import Step3 from './RemediationSteps/Step3';
import Step4 from './RemediationSteps/Step4';

import InlineSVG from '../../../../shared/InlineSVG';
import { getFieldValues } from '../../../../shared/Form/Shared';

import './style.scss';
import { typeForTask } from '../Shared';
import { RemediationContext } from '../../../../Contexts/Remediation';
import RemediationExportMenu from '../RemediationExportMenu';
import Loading from '../../../../shared/Loading';
import { FlashMessageQueueContext } from '../../../../Contexts/FlashMessageQueue';
import { makeRequest } from '../../../../../legacy/io';
import { getRecords } from '../../../../shared/RecordCache';
import { CurrentUserContext } from '../../../../Contexts/CurrentUser';
import { canConfigure } from '../../../App/AccessControl';

// used for className purposes
const stepNameMap = {
  1: 'details',
  2: 'select',
  3: 'draft',
  4: 'review',
};

// needed to initialize an empty plan, and also to track changes so that upserts only happen when necessary
const EMPTY_PLAN = {
  id: '',
  label: '',
  owner: '',
  description: '',
  // eslint-disable-next-line camelcase
  host_ids: [],
  // eslint-disable-next-line camelcase
  patch_ids: [],
  // eslint-disable-next-line camelcase
  vulnerability_ids: [],
  status: 'draft',
};

// easy way to know what tasks have been selected and what are still available
const EMPTY_PLAN_TASKS = {
  selected: [],
  candidates: [],
};

// easy way to know what items have been added to a plan
const EMPTY_PLAN_ITEMS = {
  host: [],
  patch: [],
  vulnerability: [],
};

// the back end returns lots of attributes that are not needed for the plan to be edited,
// these are the only relevant fields
const RELEVANT_PLAN_ATTRIBUTES = [
  'id',
  'label',
  'owner',
  'description',
  'host_ids',
  'patch_ids',
  'vulnerability_ids',
  'status',
];

const RemediationModal = ( {
  activeIntegrations,
  setShowModal,
  showModal,
  selectedRecord,
  users,
} ) => {
  const [
    workingDraftPlan,
    setWorkingDraftPlan,
    ,
    ,
    refreshAllDrafts,
  ] = React.useContext( RemediationContext );

  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  const [ loadingStep, setLoadingStep ] = React.useState( false );
  const [ currentStep, setCurrentStep ] = React.useState( 0 );

  const [ loadingExisting, setLoadingExisting ] = React.useState( false );
  const [ isSaving, setIsSaving ] = React.useState( false );
  const [ updatedForm, setUpdatedForm ]= React.useState( null );

  // source of truth for current state of the plan we are interacting with, needed something a bit more
  // flexible to track changes across all 4 tabs
  const [ plan, setPlan ] = React.useState( EMPTY_PLAN );
  const [ fullPlanDetails, setFullPlanDetails ] = React.useState( null );
  const [ planTasks, setPlanTasks ] = React.useState( EMPTY_PLAN_TASKS );
  const [ planItems, setPlanItems ] = React.useState( {} );

  const [ isActive, setIsActive ] = React.useState( false );
  const [ currentUser, , licenseInfo ] = React.useContext( CurrentUserContext );

  // 1) First thing to happen when opening the modal, if there is a plan already, it will be editing,
  // otherwise it will be a blank slate
  React.useEffect( ( ) => {
    // called when editing an existing record
    const setupExistingRecord = async ( selectedRecord ) => {
      setLoadingExisting( true );
      const _planItems = await getItems( selectedRecord );
      const _planTasks = await getTasks( {}, selectedRecord );
      const _plan = { ...EMPTY_PLAN };

      if ( isNotEmpty( selectedRecord ) ) {
        Object.entries( selectedRecord ).map( ( [ attr, val ] ) => {
          if ( RELEVANT_PLAN_ATTRIBUTES.includes( attr ) ) {
            _plan[attr] = val;
          }
        } );
      }
      setLoadingExisting( false );
      setUpdatedForm( {
        label: selectedRecord.label || '',
        description: selectedRecord.description || '',
        status: selectedRecord.status || 'draft',
        owner: selectedRecord.owner || null,
      } );

      setPlan( _plan );
      setFullPlanDetails( selectedRecord );
      setPlanItems( _planItems );
      setPlanTasks( _planTasks );
      if ( selectedRecord.status === 'active' ) {
        setCurrentStep( 0 );
        transitionToStep( 4, 0 );
      } else {
        setCurrentStep( 0 );
        transitionToStep( 1, 0 );
      }
    };

    // on close, reset
    if ( showModal === false ) {
      setPlan( EMPTY_PLAN );
      setPlanTasks( EMPTY_PLAN_TASKS );
      setPlanItems( EMPTY_PLAN_ITEMS );
      setCurrentStep( 0 );
      removeFromURLHash( 'create_from_saved' );
    // reopening an existing record
    } else if ( isNotEmpty( users ) && isNotEmpty( selectedRecord ) ) {
      setupExistingRecord( selectedRecord );
    // creating a brand new plan
    } else {
      setPlan( EMPTY_PLAN );
      setPlanTasks( EMPTY_PLAN_TASKS );
      setPlanItems( EMPTY_PLAN_ITEMS );
      transitionToStep( 1, 0 );
    }
  }, [ selectedRecord, users, showModal ] );

  // 2) always called when switching steps, needs to make sure the planState is ready for that step, and if
  // it is not, it gets what it needs
  const transitionToStep = async ( toStep=1, fromStep=null ) => {
    let from = fromStep;
    let _planTasks;
    setLoadingStep( true );

    let itemsWereUpdated = false;

    if ( fromStep === null ) {
      from = currentStep;
    }
    // at the end of each step, need to make sure that we do any upserts, saving, etc.
    if ( isNotEmpty( from ) ) {
      switch ( from ) {
      // at the end of step 1, we need to make sure that any of the fields (label, owner, description )
      // have not changed, and if they have, we need to send an upsert to the server
      case 1:
        if ( canConfigure( licenseInfo ) ) {
          itemsWereUpdated = await savePlanIfNecessary();
        }
        break;
      // at the end of step 2, we need to save the items to the plan, if they are different
      // need to be very careful to make sure that we are only sending
      // over ids of items that have actually changed, that way we can avoid unecessary UPSERTS
      case 2:
        if ( canConfigure( licenseInfo ) ) {
          itemsWereUpdated = await saveItemsIfNecessary();
        }
        break;

      default:
        break;
      }
    }
    // need to get necessary info at the beginning of certain steps
    if ( isNotEmpty( toStep ) ) {
      switch ( toStep ) {
      // at the beginning of step 3, need to grab the tasks
      case 3:
        if ( itemsWereUpdated || isEmpty( planTasks?.candidates ) ) {
          _planTasks = await getTasks( {}, selectedRecord );
          setPlanTasks( _planTasks );
        }
        break;

      default:
        break;
      }
    }
    setCurrentStep( toStep );
    setLoadingStep( false );
  };

  const savePlanIfNecessary = async ( additionalParams={} ) => {
    const planValues = getFieldValues( updatedForm?.fieldStates, 'remediation_plan' );

    // a subset of the plan obj that stores the state, with just the attrs from the first page form
    const _planValues = { label: plan.label, description: plan.description, status: plan.status, owner: plan.owner };

    // if any of the values have changed, send an upsert to the server
    if ( isNotEmpty( planValues ) && !isEqual( planValues, _planValues ) ) {
      const params = {
        owner: planValues?.owner || null,
        label: planValues?.label || '',
        description: planValues?.description || '',
        ...additionalParams,
      };

      let recordID;

      if ( isNotEmpty( selectedRecord ) && isNotEmpty( selectedRecord.id ) ) {
        recordID = selectedRecord.id;
      } else if ( isNotEmpty( plan ) && isNotEmpty( plan.id ) ) {
        recordID = plan.id;
      }

      if ( isNotEmpty( recordID ) ) {
        if ( recordID === 'null' ) {
          params.id = null;
        }
        params.id = recordID;
      }

      if ( params.owner === 'null' ) {
        params.owner = null;
      }

      // eslint-disable-next-line max-len, camelcase
      const _planRequest = await makeRequest( 'UPSERT', '/project/default/model/base/remediation_plan', {
        records: [ params ],
      } );

      if ( isNotEmpty( _planRequest ) && isNotEmpty( _planRequest.results ) ) {

        const _updatedPlanAttributes = {};

        if ( isNotEmpty( _planRequest.results[0] ) ) {
          setFullPlanDetails( _planRequest.results[0] );
          Object.entries( _planRequest.results[0] ).map( ( [ attr, val ] ) => {
            if ( RELEVANT_PLAN_ATTRIBUTES.includes( attr ) ) {
              _updatedPlanAttributes[attr] = val;
            }
          } );
        }
        const _plan = { ...plan, ..._updatedPlanAttributes };

        if ( isNotEmpty( currentUser ) ) {
          const accessItem = {
            // eslint-disable-next-line camelcase
            user_id: currentUser.id,
            // eslint-disable-next-line camelcase
            access_level: 'owner',
            // eslint-disable-next-line camelcase
            remediation_plan_id: _plan.id,
          };

          await makeRequest( 'PUT', '/fe/object_access/UPSERT', [ accessItem ] );
        }

        setPlan( _plan );
      }
    }
  };

  const saveItemsIfNecessary = async ( ) => {
    if ( isNotEmpty( plan ) && isNotEmpty( plan.id ) ) {
      const existingHostIDs = plan?.host_ids || [];
      const existingPatchIDs = plan?.patch_ids || [];
      const existingVulnerabilityIDs = plan?.vulnerability_ids || [];

      let newHostIDs = [];
      let newPatchIDs = [];
      let newVulnerabilityIDs = [];

      if ( isNotEmpty( planItems ) ) {
        if ( isNotEmpty( planItems.host ) ) {
          newHostIDs = planItems.host.map( h => h.id );
        }
        if ( isNotEmpty( planItems.patch ) ) {
          newPatchIDs = planItems.patch.map( p => p.id );
        }
        if ( isNotEmpty( planItems.vulnerability ) ) {
          newVulnerabilityIDs = planItems.vulnerability.map( v => v.id );
        }
      }

      const params = {};

      // compare the arrays, still need one more check for empty arrays
      if ( !isEqual( existingHostIDs, newHostIDs ) ) {
        // eslint-disable-next-line camelcase
        params.host_ids = newHostIDs;
      }
      if ( !isEqual( existingPatchIDs, newPatchIDs ) ) {
        // eslint-disable-next-line camelcase
        params.patch_ids = newPatchIDs;
      }
      if ( !isEqual( existingVulnerabilityIDs, newVulnerabilityIDs ) ) {
        // eslint-disable-next-line camelcase
        params.vulnerability_ids = newVulnerabilityIDs;
      }

      // remove any empty arrays
      if ( isEmpty( params.host_ids ) ) {
        delete params.host_ids;
      }
      if ( isEmpty( params.patch_ids ) ) {
        delete params.patch_ids;
      }
      if ( isEmpty( params.vulnerability_ids ) ) {
        delete params.vulnerability_ids;
      }

      // if there are any changes, we will need to update the plan
      if ( isNotEmpty( params ) ) {
        // // need to clear out the candidates because an update to the plan just happened, this way it will trigger
        // // a fresh set of tasks for the newly updated plan and its items
        // setPlanTasks( { ...planTasks, candidates: [] } );
        params.id = plan.id;

        // eslint-disable-next-line max-len, camelcase
        const _planRequest = await makeRequest( 'UPSERT', '/project/default/model/base/remediation_plan', {
          records: [ params ],
        } );

        if ( isNotEmpty( _planRequest ) && isNotEmpty( _planRequest.results ) ) {

          const _updatedPlanAttributes = {};

          if ( isNotEmpty( _planRequest.results[0] ) ) {
            setFullPlanDetails( _planRequest.results[0] );
            Object.entries( _planRequest.results[0] ).map( ( [ attr, val ] ) => {
              if ( RELEVANT_PLAN_ATTRIBUTES.includes( attr ) ) {
                _updatedPlanAttributes[attr] = val;
              }
            } );
          }
          const _plan = { ...plan, ..._updatedPlanAttributes };
          setPlan( _plan );
          return true;
        }
        return false;
      }
      return false;
    }
    return false;
  };

  // 3) does a check whenever the user wants to click "save and exit", needs to do a check against the plan to see if
  // anything needs saving and if it does not, it will just close the modal
  const saveProgress = async ( ) => {
    if ( canConfigure( licenseInfo ) ) {
      await savePlanIfNecessary();
      await saveItemsIfNecessary();
    }
    setShowModal( false );
    setPlan( EMPTY_PLAN );
    setPlanTasks( EMPTY_PLAN_TASKS );
    setPlanItems( EMPTY_PLAN_ITEMS );
    setCurrentStep( 0 );
    removeFromURLHash( 'create_from_saved' );
    removeFromURLHash( 'selected_record' );
    refreshAllDrafts();
  };

  // 4) called on the last step to finalize the plan, sets status to 'active'
  const finish = async ( ) => {
    if ( canConfigure( licenseInfo ) ) {
      // eslint-disable-next-line max-len, camelcase
      if ( confirm( 'Are you sure you want to commit to this remediation plan? Once committed, you can no longer change the underlying risk items (step 2) for this plan. You will still be able to update computed tasks.' ) ) {
        setLoadingStep( true );

        const params = {
          id: plan.id,
          status: 'active',
        };

        const planActivation = await makeRequest( 'UPSERT', '/project/default/model/base/remediation_plan', {
          records: [ params ],
        } );

        if ( isNotEmpty( planActivation ) && isNotEmpty( planActivation.results ) ) {
          addFlashMessage( {
            type: 'success',
            body: 'Successfully saved and activated remediation plan',
          } );
          setShowModal( false );
          removeFromURLHash( 'selected_record' );
          // sync with the remediation "cart" context
          if ( planActivation.results[0].id === workingDraftPlan?.id ) {
            setWorkingDraftPlan( null );
          }
          refreshAllDrafts();
        } else {
          setIsSaving( false );
          addFlashMessage( {
            type: 'alert',
            body: 'error saving and activating remediation plan',
          } );
        }

      }
    }
  };

  // only ever called when re-opening an existing plan
  const getItems = async ( plan ) => {
    let host = [];
    let patch = [];
    let vulnerability = [];

    if ( isNotEmpty( plan.host_ids ) ) {
      const params = {
        rows: [ 0, plan.host_ids.length ],
        filters: {
          // eslint-disable-next-line camelcase
          host_has_sensitive_nodes: null,
          // eslint-disable-next-line camelcase
          accepted_risk: false,
          // eslint-disable-next-line camelcase
          host_ids: plan.host_ids,
        },
        // eslint-disable-next-line camelcase
        risk_type: 'direct_risk',
        // eslint-disable-next-line camelcase
        order_by: [
          [ 'filtered_risk', 'DESC' ],
          [ 'local_name', 'ASC' ],
        ],
      };
      host = await getRecords( 'host', params, true );
      host = uniqueArray( host );
    }
    if ( isNotEmpty( plan.patch_ids ) ) {
      const params = {
        rows: [ 0, plan.patch_ids.length ],
        filters: {
          superseded: 'unsuperseded',
          // eslint-disable-next-line camelcase
          accepted_risk: false,
          // eslint-disable-next-line camelcase
          patch_ids: plan.patch_ids,
        },
        // eslint-disable-next-line camelcase
        risk_type: 'cumulative_risk',

        // eslint-disable-next-line camelcase
        order_by: [
          [ 'filtered_risk', 'DESC' ],
          [ 'vendor', 'ASC' ],
          [ 'identifier', 'DESC' ],
        ],
      };

      patch = await getRecords( 'patch', params, true );
      patch = uniqueArray( patch );
    }
    if ( isNotEmpty( plan.vulnerability_ids ) ) {
      const params = {
        rows: [ 0, plan.vulnerability_ids.length ],
        filters: {
          patchable: null,
          // eslint-disable-next-line camelcase
          vulnerability_ids: plan.vulnerability_ids,
        },
        // eslint-disable-next-line camelcase
        risk_type: 'cumulative_risk',
        // eslint-disable-next-line camelcase
        order_by: [
          [ 'filtered_risk', 'DESC' ],
          [ 'identifier', 'ASC' ],
        ],
      };

      vulnerability = await getRecords( 'vulnerability', params, true );
      vulnerability = uniqueArray( vulnerability );
    }
    return { host, patch, vulnerability };
  };

  // called whenever the items change
  // eslint-disable-next-line camelcase
  const getTasks = async ( additionalFilters={ exclude_no_risk: true, tanium: false }, passedInPlan=null ) => {
    setLoadingExisting( true );
    let selected, candidates, planID;

    if ( isNotEmpty( passedInPlan ) ) {
      planID = passedInPlan.id;
    } else if ( isNotEmpty( plan ) ) {
      planID = plan.id;
    }

    if ( isNotEmpty( planID ) ) {
      const taskCandidatesResponse = await makeRequest( 'COMPUTE', '/project/default/model/base/remediation_plan', {
        // eslint-disable-next-line camelcase
        remediation_plan_id: planID,
        ...additionalFilters,
        rownums: [ 0, 100 ],
      } );

      if ( isNotEmpty( taskCandidatesResponse ) && isNotEmpty( taskCandidatesResponse.results ) ) {
        candidates = taskCandidatesResponse.results;
      } else {
        candidates = [];
      }

      const selectedTasksResponse = await makeRequest( 'FETCH', '/project/default/model/base/remediation_task', {
        // eslint-disable-next-line camelcase
        remediation_plan_id: planID,
      } );

      if ( isNotEmpty( selectedTasksResponse ) && isNotEmpty( selectedTasksResponse.results ) ) {
        selected = selectedTasksResponse.results;
      } else {
        selected = [];
      }

      if ( isNotEmpty( candidates ) ) {
        candidates = await formatTaskItems( candidates );
      }
      if ( isNotEmpty( selected ) ) {
        selected = await formatTaskItems( selected );
      }
      const _planTasks = { selected, candidates };
      setLoadingExisting( false );
      return _planTasks;
    }
    return EMPTY_PLAN_TASKS;
  };

  // helper function to get the next step action in the lower right
  const nextStepAction = ( currentStep, plan ) => {
    switch ( currentStep ) {
    case 1:
      return transitionToStep( 2 );
    case 2:
      return transitionToStep( 3 );
    case 3:
      return transitionToStep( 4 );
    case 4:
      return finish( plan );
    default:
      return saveProgress( plan );
    }
  };

  // helper function to get the text for the next step in the lower right
  const nextStepText = currentStep => {
    switch ( currentStep ) {
    case 1:
      return <React.Fragment>
        <span>NEXT STEP: Select Risk Items</span>
      </React.Fragment>;
    case 2:
      return <React.Fragment>
        <span>NEXT STEP: Select Tasks</span>
        <InlineSVG type="carretRight" version="light" />
      </React.Fragment>;
    case 3:
      return <React.Fragment>
        <span>NEXT STEP: Review / Approve</span>
        <InlineSVG type="carretRight" version="light" />
      </React.Fragment>;
    case 4:
      return <React.Fragment>
        <span>Activate</span>
      </React.Fragment>;
    default:
      return <React.Fragment>
        <span>Activate</span>
      </React.Fragment>;
    }
  };

  // // helper function to get the loading text depending on the step transition
  const loadingStepText = currentStep => {
    switch ( currentStep ) {
    case 1:
      return 'Saving plan details...';
    case 2:
      return 'Saving risk items and creating plan...';
    case 3:
      return 'calculating remaining tasks and risk...';
    case 4:
      return 'Saving plan...';
    default:
      return 'Saving plan...';
    }
  };

  // called whenever the tasks are recomputed, needs to go fetch additional things and format all the data correctly
  const formatTaskItems = async ( tasks ) => {

    const _formatted = await fetchAndFormatTaskRecordData( tasks );
    _formatted.sort( ( a, b ) => recordSorter( 'risk', false, a, b ) );

    return _formatted;
  };

  // gets all necessary records for the tasks and formats it correctly
  const fetchAndFormatTaskRecordData = async ( _tasks ) => {

    const _formatted = [];

    const hostIDs = [];
    const patchIDs = [];
    const vulnerabilityIDs = [];

    let fetchedHosts, fetchedPatches, fetchedVulnerabilities;

    _tasks.map( c => {
      if ( typeForTask( c ) === 'host' ) {
        hostIDs.push( c.task );
      }
      if ( typeForTask( c ) === 'patch' ) {
        patchIDs.push( c.task );
      }
      if ( typeForTask( c ) === 'vulnerability' ) {
        vulnerabilityIDs.push( c.task );
      }
    } );

    if ( isNotEmpty( hostIDs ) ) {
      const params = {
        rows: [ 0, hostIDs.length + 1 ],
        filters: {
          // eslint-disable-next-line camelcase
          host_has_sensitive_nodes: null,
          // eslint-disable-next-line camelcase
          accepted_risk: false,
          // eslint-disable-next-line camelcase
          host_ids: hostIDs,
        },
        // eslint-disable-next-line camelcase
        risk_type: 'direct_risk',

        // eslint-disable-next-line camelcase
        order_by: [
          [ 'filtered_risk', 'DESC' ],
          [ 'local_name', 'ASC' ],
        ],
      };
      // eslint-disable-next-line camelcase
      fetchedHosts = await getRecords( 'host', params, true );
    }
    if ( isNotEmpty( patchIDs ) ) {
      // We only need vendor and identifier for patches, we can get that from patches table
      const params = {
        // eslint-disable-next-line camelcase
        columns: [ 'vendor', 'identifier' ],
        rows: [ 0, patchIDs.length ],
        filters: {
          // eslint-disable-next-line camelcase
          patch_ids: patchIDs,
        },
        // eslint-disable-next-line camelcase
        order_by: [
          [ 'filtered_risk', 'DESC' ],
          [ 'vendor', 'ASC' ],
          [ 'identifier', 'DESC' ],
        ],
      };
      // eslint-disable-next-line max-len, camelcase
      fetchedPatches = await getRecords( 'patch', params, true );
    }
    if ( isNotEmpty( vulnerabilityIDs ) ) {
      const params = {
        rows: [ 0, vulnerabilityIDs.length + 1 ],
        filters: {
          patchable: null,
          // eslint-disable-next-line camelcase
          accepted_risk: false,
          // eslint-disable-next-line camelcase
          vulnerability_ids: vulnerabilityIDs,
        },
        // eslint-disable-next-line camelcase
        order_by: [
          [ 'filtered_risk', 'DESC' ],
          [ 'identifier', 'ASC' ],
        ],
      };
      // eslint-disable-next-line max-len, camelcase
      fetchedVulnerabilities = await getRecords( 'vulnerability', params, true );
    }

    _tasks.map( c => {

      const _type = typeForTask( c );

      let _record;

      if ( _type === 'host' ) {
        _record = fetchedHosts.find( r => r.id === c.task );
      }
      if ( _type === 'vulnerability' ) {
        _record = fetchedVulnerabilities.find( r => r.id === c.task );
      }
      if ( _type === 'patch' ) {
        _record = fetchedPatches.find( r => r.id === c.task );
      }
      const _formattedTask = {
        original: c,
        risk: c.risk,
        id: c.id,
        normalizedType: _type,
        owner: isNotEmpty( c.owner ) ? users[c.owner] : 'N/A',
        label: isNotEmpty( _record ) ? reportTypeDisplayName( _record, _type ) : 'N/A',
      };

      _formatted.push( _formattedTask );
    } );

    return _formatted;
  };

  // convenience checker to make sure users cannot get to step 3 without any items
  const hasNoItems = () => {
    const itemStatus = [];

    if ( isNotEmpty( planItems ) ) {
      Object.values( planItems ).map( itemGroup => {
        itemStatus.push( isEmpty( itemGroup ) );
      } );
    }
    return itemStatus.every( s => s === true );
  };

  // convenience checker to make sure users cannot get to step 4 without any tasks
  const hasNoTasks = () => {
    return isEmpty( planTasks ) || isEmpty( planTasks?.selected );
  };

  // used on the lower right button, and checks the previous ^^^ two methods for specific disabling logic for
  // steps 3 and 4
  const shouldDisable = ( step, plan ) => {
    if ( step === 0 || step === 1 ) {
      return false;
    }
    if ( step === 2 ) {
      return hasNoItems( plan );
    }
    if ( step === 3 ) {
      return hasNoItems( plan ) || hasNoTasks( plan );
    }
    return false;
  };

  // just a small tweak when opening this modal, make sure to remove an unneeded hash param
  React.useEffect( ( ) => {
    if ( showModal === true ) {
      removeFromURLHash( 'export_plan_id' );
    }
  }, [ showModal ] );

  // just sets active or not on a plan as it comes in
  React.useEffect( () => {

    if ( isNotEmpty( plan ) && plan.status === 'active' ) {
      setIsActive( true );
    } else {
      setIsActive( false );
    }
  }, [ plan ] );

  // showing the correct loading message was getting complicated, this checks all the conditions and makes
  // sure it only shows one loading message
  const getLoadingMessage = () => {
    if ( loadingStep || isSaving || loadingExisting ) {
      let text = 'Loading...';

      if ( loadingExisting ) {
        text = 'Loading Remediation Plan...';
      } else if ( loadingStep ) {
        text = loadingStepText( currentStep );
      } else {
        text = 'Saving Remediation Plan...';
      }
      return <Loading text={text} />;
    }
  };

  return (
    <React.Fragment>
      { getLoadingMessage() }
      <div
        // eslint-disable-next-line max-len
        className={ `setupTabsContainer tabCount--4 ${ isNotEmpty( currentStep ) ? `selectedTabIndex--${ currentStep - 1 }`: 'noSelectedTab' }`}
      >
        <div
          className={`setupTab ${currentStep === 1 ? 'isCurrent' : ''}`}
          onClick={ () => transitionToStep( 1 )}
        >
          <div className="setupTabContentWrapper">
            <span className="stepAndLabelWrapper">
              <InlineSVG type="progress_circle_1" />
              <label>Details</label>
            </span>
          </div>
        </div>
        <div
          className={`setupTab ${currentStep === 2 ? 'isCurrent' : ''}`}
          onClick={ () => transitionToStep( 2 )}
        >
          {
            isActive
              ? <div className="setupTabContentWrapper">
                <span className="stepAndLabelWrapper">
                  <InlineSVG type="progress_circle_2" />
                  <label>View Risk Items</label>
                </span>
              </div>
              : <div className="setupTabContentWrapper">
                <span className="stepAndLabelWrapper">
                  <InlineSVG type="progress_circle_2" />
                  <label>Select Risk Items</label>
                </span>
              </div>
          }
        </div>
        <div
          className={`setupTab ${currentStep === 3 ? 'isCurrent' : ''} ${ hasNoItems() ? 'isDisabled' : ''}`}
          onClick={ () => transitionToStep( 3 ) }
        >
          {
            isActive
              ? <div className="setupTabContentWrapper">
                <span className="stepAndLabelWrapper">
                  <InlineSVG type="progress_circle_3" />
                  <label>Edit Selected Tasks</label>
                </span>
              </div>
              : <div className="setupTabContentWrapper">
                <span className="stepAndLabelWrapper">
                  <InlineSVG type="progress_circle_3" />
                  <label>Select Tasks</label>
                </span>
              </div>
          }
        </div>
        <div
          // eslint-disable-next-line max-len
          className={`setupTab ${currentStep === 4 ? 'isCurrent' : ''} ${ ( hasNoItems() || hasNoTasks() ) ? 'isDisabled' : ''}`}
          onClick={ () => transitionToStep( 4 ) }
        >
          {
            isActive
              ? <div className="setupTabContentWrapper">
                <span className="stepAndLabelWrapper">
                  <InlineSVG type="progress_circle_4" />
                  <label>Tasks Summary / Progress</label>
                </span>
              </div>
              : <div className="setupTabContentWrapper">
                <span className="stepAndLabelWrapper">
                  <InlineSVG type="progress_circle_4" />
                  <label>Review / Approve</label>
                </span>
              </div>
          }
        </div>
      </div>

      <div className={`tabWrapper ${stepNameMap[currentStep]} ${isActive ? 'isActive' : ''}`}>
        {
          currentStep === 1 &&
          <React.Fragment>
            <h2>Remediation Plan: Details</h2>
            <div className="stepDirections">
              <span>
                <strong>Step 1:</strong> Details of the remediation plan.
              </span>
            </div>
          </React.Fragment>

        }
        {
          currentStep === 2 &&
          <React.Fragment>
            <h2>Remediation Plan: Select Risk Items</h2>
            <div className="stepDirections">
              {
                isActive
                  ? <span>
                    <strong>Step 2:</strong> The underlying risk items included in this plan
                  </span>
                  : <span>
                    <strong>Step 2:</strong> Filter down and select the risk
                    items (hosts, patches, and/or vulnerabilities) that you wish to have included in the remediation
                    plan.
                  </span>
              }
            </div>
          </React.Fragment>

        }
        {
          currentStep === 3 &&
          <React.Fragment>
            <h2>Remediation Plan: Select Tasks</h2>
            <div className="stepDirections">
              <span>
                <strong>Step 3:</strong> Review the available tasks and add the ones you would like included in this
                remediation plan. <strong>NOTE: </strong> Remediation plans are automatically saved when changes are
                made because tasks need to be computed on the fly whenever you add or remove tasks for a plan.
              </span>
            </div>
          </React.Fragment>
        }
        {
          currentStep === 4 &&
          <React.Fragment>
            <h2>
              {
                isActive
                  ? 'Remediation Plan'
                  : 'Remeidation Plan: Review / Approve'
              }
            </h2>
            <div className="stepDirections">
              {
                isActive
                  ? <React.Fragment>
                    <span>
                      <strong>STEP 4:</strong> Your Remediation Plan.
                    </span>
                  </React.Fragment>
                  : <React.Fragment>
                    <span>
                      <strong>STEP 4:</strong> If you approve of these tasks, click "Activate" to commit to this
                      remediation plan.
                    </span>
                  </React.Fragment>
              }
              <div className="activePlanActions">
                <button onClick={ () => transitionToStep( 3 ) } className="stepChangeButton">
                  Edit tasks
                </button>
                {
                  isActive &&
                  <RemediationExportMenu fullVersion={true} plan={plan} activeIntegrations={activeIntegrations} />
                }
              </div>
            </div>
          </React.Fragment>
        }
        {
          currentStep === 1 &&
          <Step1
            selectedRecord={selectedRecord}
            plan={plan}
            users={users}
            setUpdatedForm={setUpdatedForm}
          />
        }
        {
          currentStep === 2 &&
          <Step2
            plan={plan}
            setPlan={setPlan}
            planItems={planItems}
            setPlanItems={setPlanItems}
            isActive={ isActive }
          />
        }
        {
          currentStep === 3 &&
          <Step3
            setLoadingStep={setLoadingStep}
            plan={plan}
            setPlan={setPlan}
            getTasks={getTasks}
            planTasks={planTasks}
            setPlanTasks={setPlanTasks}
            isActive={ isActive }
            users={users}
            activeIntegrations={activeIntegrations}
          />
        }
        {
          currentStep === 4 &&
          <Step4
            currentStep={currentStep}
            setLoadingStep={setLoadingStep}
            transitionToStep={transitionToStep}
            getTasks={getTasks}
            isActive={ isActive }
            plan={plan}
            planTasks={planTasks}
            setPlanTasks={setPlanTasks}
            fullPlanDetails={ fullPlanDetails }
            setPlan={setPlan}
            users={users}
            activeIntegrations={activeIntegrations}
          />
        }
      </div>
      <div className="modalActions">
        {
          isActive
            ? <button
              disabled={ !canConfigure( licenseInfo ) }
              className={ `${ !canConfigure( licenseInfo ) ? 'disabled' : ''} nextFinishButton` }
              onClick={ () => saveProgress() }
            >
              Save and close
            </button>
            : <button
              disabled={ !canConfigure( licenseInfo ) }
              className={ `${shouldDisable( currentStep ) ? 'disabled' : '' } nextFinishButton` }
              onClick={ () => nextStepAction( currentStep ) }
            >
              { nextStepText( currentStep ) }
            </button>
        }

        <div
          className="stepText"
        >
          { `Step ${currentStep} of 4` }
        </div>
        {
          !isActive &&
          <button
            disabled={!canConfigure( licenseInfo )}
            onClick={ saveProgress }
            className={`${!canConfigure( licenseInfo ) ? 'disabled' : ''} cancelButton`}
          >
            <span>Save draft and close</span>
          </button>
        }
      </div>
    </React.Fragment>
  );
};

export default RemediationModal;