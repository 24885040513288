/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { getNodeIcon, nodeTypeLabelMap } from '../../components/RecordDetails/shared';
import { formatNumber } from '../Utilities';
import './SensitiveAssetItem.scss';

const SensitiveAssetItem = ( { asset, asLink=true } ) => {
  const content = <React.Fragment>
    <div className="left">
      <div className="nodeIconWrapper">
        <svg
          width={ 24 }
          height={ 24 }
          viewBox="0 0 32 32"
          fill="none"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          className="recordTypeHeaderIcon"
        >
          { getNodeIcon( asset, true ) }
        </svg>
        {
          asset.isExposed ? <div className="dot" /> : <></>
        }
      </div>
      <div className="labelAndType">
        <span>
          <strong>{ `${asset.label }` }</strong> - { asset.fullScope?.label }
        </span>
        <span className="type" >{ nodeTypeLabelMap[asset.type] }</span>
      </div>
    </div>
    <div className="right">
      <label>Impact</label>
      <strong>{ formatNumber( asset.combined_impact || asset.impact || 0 ) }</strong>
    </div>
  </React.Fragment>;

  return (
    <React.Fragment>
      {
        asLink
          ? <a
            target="_blank"
            rel="noreferrer noopener"
            href={ `#.=explore&page=explore_model&type=node&explore_node=%5B"${asset?.id}"%5D`}
            className={ `sensitiveAssetItemListWrapper asLink ${asset.isExposed ? 'exposed' : ''}` }
          >
            { content }
          </a>
          : <div className={ `sensitiveAssetItemListWrapper ${asset.isExposed ? 'exposed' : ''}` } >
            { content }
          </div>
      }
    </React.Fragment>
  );
};

export default SensitiveAssetItem;