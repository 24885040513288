/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

/* eslint-disable camelcase */

import { makeRequest } from '../../legacy/io';
import { keyToAttrMap, multipleOrderByOptions } from '../components/Reporting/Dashboards/shared';
import {
  decodeURLHash,
  isEmpty,
  isGroupInequalityType,
  isInequalityType,
  isNotEmpty,
  itemIsArray,
  paramsToFilters,
  uniqueArray,
} from './Utilities';

// --------------------------------------------------------
// Internal Methods, fn, and vars -------------------------
// --------------------------------------------------------
const baseRiskInsightParams = {
  filters: {},
  // group_filters: {},
  risk_type: 'direct_risk',
  order_by: [ [ 'id', 'ASC' ] ],
  rows: [ 0, 100 ],
};

const project ='default';
const model = 'base';

// used for legacy endpoints, will be removed once all endpoints are updated
const _defaultParamsForType = ( type, useInsightEndpoint ) => {
  let _params = {};

  if ( type === 'scope' ) {

    if ( useInsightEndpoint ) {
      _params = {
        // eslint-disable-next-line camelcase
        group_type: 'host',
        filters: {
          // eslint-disable-next-line camelcase
          extra_columns: [
            // 'scope_analysis.sensitive_nodes',
            // 'scope_analysis.patches',
            // 'scope_analysis.vulnerabilities',
            'scope_analysis.risk',
            'scope_analysis.ancestor_labels',
            'modified',
            'label',
          ],
        },
        // eslint-disable-next-line camelcase
        order_by: [
          [ 'scope_analysis.risk', 'DESC' ],
          [ 'label', 'ASC' ],
        ],
        rownums: [ 0, 100 ],
      };
    } else {
      _params = {
        // eslint-disable-next-line camelcase
        extra_columns: [
          // 'scope_analysis.sensitive_nodes',
          // 'scope_analysis.patches',
          // 'scope_analysis.vulnerabilities',
          'scope_analysis.risk',
          'scope_analysis.ancestor_labels',
          'ancestor_labels',
          'modified',
          'label',
        ],
        // eslint-disable-next-line camelcase
        order_by: [
          [ 'scope_analysis.risk', 'DESC' ],
          [ 'label', 'ASC' ],
        ],
      };
    }

  }

  if ( type === 'patch' ) {
    if ( useInsightEndpoint ) {
      _params = {
        // eslint-disable-next-line camelcase
        group_type: 'patch',
        filters: {
          // eslint-disable-next-line camelcase
          extra_columns: [
            'vendor',
            'identifier',
            'description',
            'modified',
            'patch_analysis.vulnerabilities',
            'supersedes',
          ],
        },
        // eslint-disable-next-line camelcase
        order_by: [
          [ 'patch_analysis.risk', 'DESC' ],
          [ 'vendor', 'ASC' ],
          [ 'identifier', 'DESC' ],
        ],
        rownums: [ 0, 100 ],
      };
    } else {
      _params = {
        // eslint-disable-next-line camelcase
        extra_columns: [
          'vendor',
          'identifier',
          'description',
          'modified',
          'patch_analysis.vulnerabilities',
        ],
        // eslint-disable-next-line camelcase
        order_by: [
          [ 'patch_analysis.risk', 'DESC' ],
          [ 'vendor', 'ASC' ],
          [ 'identifier', 'DESC' ],
        ],
      };
    }

  }

  if ( type === 'path' ) {
    _params = {
      // eslint-disable-next-line camelcase
      extra_columns: [
        'keywords',
        'risk',
        'modified',
        'edges',
        'node_labels',
      ],
      // eslint-disable-next-line camelcase
      order_by: [
        [ 'risk', 'DESC' ],
      ],
    };
  }

  if ( type === 'vulnerability' ) {
    if ( useInsightEndpoint ) {
      _params = {
        // eslint-disable-next-line camelcase
        group_type: 'vulnerability',
        // eslint-disable-next-line camelcase
        extra_columns: [
          'vulnerability_analysis.hosts',
          'vulnerability_analysis.patches',
          'vulnerability_analysis.risk',
          'identifier',
          'modified',
        ],
        // eslint-disable-next-line camelcase
        order_by: [
          [ 'vulnerability_analysis.risk', 'DESC' ],
          [ 'identifier', 'DESC' ],
        ],
      };
    } else {
      _params = {
        // eslint-disable-next-line camelcase
        extra_columns: [
          'vulnerability_analysis.hosts',
          'vulnerability_analysis.patches',
          'vulnerability_analysis.risk',
          'identifier',
          'modified',
        ],
        // not_field_map: {
        //   effort: 'nofix',
        // },
        // eslint-disable-next-line camelcase
        order_by: [
          [ 'vulnerability_analysis.risk', 'DESC' ],
          [ 'identifier', 'DESC' ],
        ],
      };
    }

  }

  if ( type === 'node' ) {
    _params = {
      // eslint-disable-next-line camelcase
      extra_columns: [
        'impact',
        'label',
        'scope_id',
        'name',
        'type',
        'node_analysis.risk',
        'node_analysis.combined_impact',
      ],
      // eslint-disable-next-line camelcase
      order_by: [ [ 'node_analysis.risk', 'DESC' ] ],
    };
  }

  if ( type === 'edge' ) {
    _params = {
      // eslint-disable-next-line camelcase
      extra_columns: [ 'from_node', 'to_node', 'edge_analysis.risk' ],
      // eslint-disable-next-line camelcase
      order_by: [ [ 'risk', 'DESC' ] ],
    };
  }

  return _params;
};
// utility function to parse whether an attr has '_analysis' in it, legacy version only
const _isAnalysisAttribute = ( type, attribute ) => attribute?.startsWith( `${type}_analysis` );

const unneededKeys = [
  'current_page',
  'order_by',
  'order_direction',
  'item_count',
  'group_type',
  'risk_type',
  'instances_visual_mode',
  'include_risk',
  'panel_tab',
  'item',
  'creating_report',
  'selected_record',
  'return_to_reports',
  'report_id',
  'creating_report',
];

export const whiteListedRiskInsightParamKeys = [
  'rows',
  'order_by',
  'columns',
  'filters',
  'group_filters',
  'risk_type',
];

export const whiteListedRiskInsightFilterKeys = [
  'accepted_risk',
  'asset_tag_ids',
  'category',
  'exploit_status',
  'exploit_statuses',
  'attack_scenario',
  'has_instances',
  'has_host',
  'host_has_sensitive_nodes',
  'host_product_name',
  'host_vendor',
  'host_os_arch',
  'host_os_type',
  'host_os_family',
  'patchable',
  'superseded',
  'host_ids',
  'vulnerability_ids',
  'patch_ids',
  'signature_ids',
  'third_party_setting_ids',
  'host_keywords',
  'host_globs',
  'vulnerability_keywords',
  'vulnerability_globs',
  'patch_keywords',
  'patch_globs',
  'signature_keywords',
  'signature_globs',
  'gt_map',
  'lt_map',

  // gt/lt types
  'risk_reduction',
  'risk_rating',
  'cvss_base_score',
  'num_hosts',
  'vulnerability_created',
];

export const whiteListedRiskInsightOrderByKeysForRecordType = {
  host: [
    'id',
    'filtered_risk',
    'local_name',
    'product_name',
    'vendor',
    'os_release',
    'os_build',
    'os_revision',
    'os_type',
    'os_family',
    'architecture',
    'num_vulnerabilities',
    'num_patches',
    'num_unsuperseded_patches',
    'last_scanned',
    'tp_last_scanned',
    'last_logged_user',
    'exploitable_vulns_at_risk_count',
  ],
  vulnerability: [
    'id',
    'filtered_risk',
    'identifier',
    'cvss_base_score',
    'num_hosts',
    'exploit_status',
    'created',
  ],
  patch: [
    'id',
    'filtered_risk',
    'vendor',
    'identifier',
    'num_hosts',
    'num_vulnerabilities',
  ],
  signature: [
    'id',
    'filtered_risk',
    'scanner',
    'signature',
    'num_hosts',
    'num_vulnerabilities',
  ],
};

const unNeededFilterKeysForRecordTypeMap = {
  host: [
    'superseded',
    'include_risk',
    'has_instances',
  ],
  patch: [
    'sensitive_assets',
  ],
  patch_cumulative: [
    'sensitive_assets',
  ],
  vulnerability: [
    'sensitive_assets',
    'superseded',
  ],
  signature: [],
  instances: [],
  instance: [],
  configuration_alert: [],
  configuration_alerts: [],
  instance_host: [
    'superseded',
    'include_risk',
    'has_instances',
  ],
  instance_patch: [
    'sensitive_assets',
  ],
  instance_patch_cumulative: [
    'sensitive_assets',
  ],
  instance_vulnerability: [
    'sensitive_assets',
    'superseded',
  ],
  instances_host: [
    'superseded',
    'include_risk',
    'has_instances',
  ],
  instances_patch: [
    'sensitive_assets',
  ],
  instances_patch_cumulative: [
    'sensitive_assets',
  ],
  instances_vulnerability: [
    'sensitive_assets',
    'superseded',
  ],
};

const defaultColumnsForRecordType = {
  host: [
    'id',
    'num_vulnerabilities',
    'num_unsuperseded_patches',
    'num_patches',
    'num_sensitive_nodes',
    'local_name',
    'ip_addresses',
    'product_name',
    'last_scanned',
    'has_host',
    'filtered_risk',
    'risk_rating',
    'asset_tags',
  ],
  patch: [
    'id',
    'identifier',
    'description',
    'vendor',
    'url',
    'num_hosts',
    'num_supersedes',
    'num_vulnerabilities',
    'filtered_risk',
    'risk_rating',
  ],
  vulnerability: [
    'id',
    'identifier',
    'num_hosts',
    'num_patches',
    'num_unsuperseded_patches',
    'exploit_status',
    'cvss_base_score',
    'description',
    'public_notes',
    'created',
    'filtered_risk',
    'risk_rating',
  ],
  signature: [
    'id',
    'scanner',
    'signature',
    'title',
    'scanner_rating',
    'description',
    'recommendation',
    'urls',
    'num_hosts',
    'num_vulnerabilities',
    'filtered_risk',
    'risk_rating',
  ],
};

const minimalColumnsForRecordType = {
  host: [
    'id',
    'local_name',
    'filtered_risk',
  ],
  patch: [
    'id',
    'vendor',
    'identifier',
    'filtered_risk',
  ],
  vulnerability: [
    'id',
    'identifier',
    'filtered_risk',
  ],
  signature: [
    'id',
    'scanner',
    'signature',
    'filtered_risk',
  ],
};

const additionalColumnsForRecordDetailType = {
  host: [
    'vulnerabilities',
    // 'num_direct_patches',
    'direct_patches',
    // 'unsuperseded_patches',
    'patches',
    'paths',
    'sensitive_nodes_at_risk',
    'cvss_histogram',
    'scan_results',
    'sensitive_nodes',
    'os_release',
    'os_build',
    'os_revision',
    'os_type',
    'os_family',
    'architecture',
    'host_names',
    'networks',
    'third_party_hosts',
    'host_id',
    // 'descendants',
    // 'ancestors',
    'vendor',
    'software',
    // 'ancestor_labels',
    'last_scanned_address',
    'third_party_identifiers',
    'last_logged_user',
    'hijackable_users',
    'desktop_users_at_risk',
    'vulnerability_type_counts',
    'exploitable_vulns_at_risk_count',
    'tp_last_scanned',
  ],
  patch: [
    'hosts',
    'hosts',
    'cvss_histogram',
    'additional_actions',
    'products',
    'supersedes',
    'superseded_by',
    'scan_results',
    'vulnerabilities',
  ],
  vulnerability: [
    'attack_scenarios',
    'hosts',
    'patches',
    'unsuperseded_patches',
    'scan_results',
    'cvssv2',
    'cvssv3',
    'urls',
    'nofix',
  ],
  signature: [
    'hosts',
  ],
};

const buildRows = ( filterValues, count=100 ) => {
  let start, end;
  if ( isEmpty( filterValues.current_page ) ) {
    start = 0;
    end = filterValues.item_count ? parseInt( filterValues.item_count ) : count;
  } else {
    // eslint-disable-next-line max-len
    start = ( parseInt( filterValues.current_page ) - 1 ) * ( filterValues.item_count ? parseInt( filterValues.item_count ) : count );
    end = start + ( filterValues.item_count ? parseInt( filterValues.item_count ) : count );
  }

  if ( isEmpty( start ) || isNaN( start ) ) {
    start = 0;
  }
  if ( isEmpty( end ) || isNaN( end ) ) {
    end = 100;
  }
  return [ start, end ];
};

export const buildParamsForRiskInsight = ( filterValues, resourceType, version='table', tallyTypes=[] ) => {
  const params = { ...baseRiskInsightParams };
  const gt_map = {};
  const lt_map = {};
  const group_gt_map = {};
  const group_lt_map = {};

  const _rows = buildRows( filterValues );

  const _orderBy = filterValues?.order_by || 'filtered_risk';
  const _orderDirection = filterValues?.order_direction || 'DESC';

  const isFormNull = [ 'any', 'null', 'undefined', null, undefined ];

  const stringBooleans = [ 'true', 'false', 'True', 'False' ];

  const stringToBoolean = ( val ) => {
    if ( stringBooleans.includes( val ) ) {
      return val === 'true' || val === 'True';
    }
    return val;
  };

  params.filters = {};

  // console.log( resourceType );

  console.log( filterValues );

  Object.entries( filterValues ).map( ( [ key, val ] ) => {
    if ( key === 'item_count' ) {
      if ( version === 'table' ) {
        params.rows = _rows;
      }
    // translating vuln. age to gt/lt from string to unix timestamp
    } else if ( key === 'age_end' || key === 'age_start' ) {
      if ( key === 'age_start' ) {
        const valAsDate = new Date( val );
        const ltVal = valAsDate.getTime() / 1_000;
        lt_map.vulnerability_created = ltVal;
      }
      if ( key === 'age_end' ) {
        const valAsDate = new Date( val );
        const gtVal = valAsDate.getTime() / 1_000;
        gt_map.vulnerability_created = gtVal;
      }
    } else if ( isInequalityType.includes( key ) ) {
      const [ inequality, unit ] = val;

      if ( isNotEmpty( unit ) ) {
        if ( inequality === 'gt_map' ) {
          gt_map[key] = parseFloat( unit );
        } else if ( inequality === 'lt_map' ) {
          lt_map[key] = parseFloat( unit );
        }
      }
    } else if ( isGroupInequalityType.includes( key ) ) {
      const [ inequality, unit ] = val;

      if ( isNotEmpty( unit ) ) {
        if ( inequality === 'gt_map' ) {
          group_gt_map[key] = parseFloat( unit );
        } else if ( inequality === 'lt_map' ) {
          group_lt_map[key] = parseFloat( unit );
        }
      }
    } else if ( stringBooleans.includes( val ) ) {
      params.filters[key] = stringToBoolean( val );
    } else if (
      whiteListedRiskInsightFilterKeys.includes( key )
      && (
        isEmpty( unNeededFilterKeysForRecordTypeMap[resourceType] )
        || !unNeededFilterKeysForRecordTypeMap[resourceType].includes( key )
      )
    ) {
      params.filters[key] = isFormNull.includes( val ) ? null : val;
    } else if ( !whiteListedRiskInsightFilterKeys.includes( key ) ) {
      console.log( `key ${key} is not a valid filter key` );
    }
  } );

  const group_filters = {};

  if ( isNotEmpty( gt_map ) ) {
    params.filters.gt_map = gt_map;
  }
  if ( isNotEmpty( lt_map ) ) {
    params.filters.lt_map = lt_map;
  }

  if ( isNotEmpty( group_gt_map ) ) {
    group_filters.gt_map = group_gt_map;
  }
  if ( isNotEmpty( group_lt_map ) ) {
    group_filters.lt_map = group_lt_map;
  }

  if ( isNotEmpty( group_filters ) ) {
    params.group_filters = group_filters;
  }
  params.risk_type = filterValues.risk_type || 'direct_risk';

  const hash = decodeURLHash();

  if ( hash?.group_type === 'patch_cumulative' ) {
    params.risk_type = 'cumulative_risk';
  } else {
    params.risk_type = 'direct_risk';
  }

  if ( isNotEmpty( hash.risk_type ) ) {
    params.risk_type = hash.risk_type;
  }

  if ( version === 'table' ) {
    // some orderBy options are a concatenation of multiple columns, need to split and send the correct vals
    if ( multipleOrderByOptions.includes( _orderBy ) ) {
      const _orders = [];
      _orderBy.split( '_' ).map( order => {
        _orders.push( [ keyToAttrMap[order], _orderDirection ] );
      } );
      params.order_by = [ ..._orders, [ 'id', 'ASC' ] ];
    } else {
      params.order_by = [ [ _orderBy, _orderDirection ], [ 'id', 'ASC' ] ];
    }
    params.columns = defaultColumnsForRecordType[resourceType];
  } else if ( version === 'tally' ) {
    delete params.rows;
    delete params.order_by;
    delete params.columns;
    if ( isNotEmpty( tallyTypes ) ) {
      params.types = tallyTypes;
    } else {
      params.types = [ 'category' ];
    }
    delete params.group_filters;
  } else if ( version === 'count' ) {
    delete params.rows;
    delete params.order_by;
    delete params.columns;
  }

  // check risk type for bad values
  if ( isNotEmpty( params.risk_type ) ) {
    if ( params.risk_type !== 'direct_risk' && params.risk_type !== 'cumulative_risk' ) {
      params.risk_type = 'direct_risk';
    }
  }

  // check order by for bad values
  if ( isNotEmpty( params.order_by ) ) {

    const _cleansedOrderBy = [];

    params.order_by.map( orderPair => {
      if ( isNotEmpty( orderPair ) && itemIsArray( orderPair ) ) {
        const [ orderKey ] = orderPair;
        if (
          isNotEmpty( whiteListedRiskInsightOrderByKeysForRecordType[resourceType] )
          && whiteListedRiskInsightOrderByKeysForRecordType[resourceType].includes( orderKey )
        ) {
          _cleansedOrderBy.push( orderPair );
        } else if ( orderKey === 'risk' || orderKey === 'filtered_risk' || orderKey === 'direct_risk' ) {
          _cleansedOrderBy.push( [ 'filtered_risk', orderPair[1] ] );
        }
      }
    } );
    params.order_by = _cleansedOrderBy;
  }

  return params;
};

// --------------------------------------------------------
// Internal io Functions ----------------------------------
// --------------------------------------------------------
// main search method (adapted from original search_model_records in io.js)
// if new insight endpoints are desired, it hits the new 'INSIGHT' method, otherwise, it falls back to old behavior
const _search = async ( recordType, params ) => {

  const start = new Date().getTime();

  const _recordType = recordType === 'scope' ? 'host' : recordType;

  const response = await makeRequest(
    'POST',
    `/fe/analysis/${_recordType === 'patch_cumulative' ? 'patch' : _recordType}/SELECT`,
    params,
  );

  const seconds = ( new Date().getTime() - start ) / 1000.0;

  if ( isNotEmpty( response ) ) {
    console.log( 'Search time: '+ seconds, recordType, params, response );
    return response;
  }

  return [];
};

// main add/create method (adapted from add_model_records in io.js)
const _create = async ( type, additions ) => {
  const params = {
    additions,
    project,
    model,
  };

  const response = await makeRequest( 'ADD', `/model/${type}`, params );
  return response;
};

const _searchLegacy = async ( type, filters, useInsightEndpoint, singleRecord=false ) => {

  let response;

  const params = {
    filters,
    project,
    model,
  };

  const start = new Date().getTime();

  if ( useInsightEndpoint ) {
    if ( singleRecord ) {
      const _type = type === 'scope' ? 'host' : type;

      const params = { filters: { [`${_type}_id`]: filters.id_list[0] } };

      if ( _type === 'patch' ) {
        // eslint-disable-next-line camelcase
        params.filters.risk_type = filters.risk_type;
      }

      // eslint-disable-next-line max-len, camelcase
      response = await makeRequest( _type.toUpperCase(), '/model/base/insight_details', params );
    } else {
      response = await makeRequest( type.toUpperCase(), '/model/base/insight', filters );
    }

  } else {
    response = await makeRequest( 'SEARCH', `/model/${type}`, params );
  }

  const seconds = ( new Date().getTime() - start ) / 1000.0;

  if ( isNotEmpty( response ) ) {
    console.log( 'Search time: '+ seconds, type, params, response['results'] );
    return response.results;
  }

  return [];
};

// main update method (adapted from update_model_records in io.js)
const _update = async ( type, changes ) => {
  const params = {
    changes,
    project,
    model,
  };

  const response = await makeRequest( 'UPDATE', `/model/${type}`, params );
  return response;
};

// main delete (named 'remove' because delete is not allowed)
// method (adapted from delete_model_records in io.js)
const _remove = async ( type, ids ) => {
  const params = {
    ids,
    project,
    model,
  };

  const response = await makeRequest( 'DELETE', `/model/${type}`, params );
  return response;
};

// --------------------------------------------------------
// Internal Caching Functions -----------------------------
// --------------------------------------------------------
const _addToCache = ( key, value ) => {
  window.recordCache?.delete( key );

  // check if we have reached the cache limit, if so, remove the last and add, otherwise just add
  if ( window.recordCache && window.recordCache.size >= window.CACHE_CAPACITY ) {
    window.recordCache?.delete( window.recordCache && window.recordCache.keys().next().value );
  }

  window.recordCache?.set( key, value );

};

const _removeFromCache = ( key ) => {
  window.recordCache?.delete( key );
};

const _deleteRecordsAndCache = async ( type, IDs ) => {

  const response = await _remove( type, IDs );

  if ( isNotEmpty( response ) ) {
    const { errors } = response;

    if ( errors ) {
      return { errors };
    }
    // remove each id from the cache
    IDs.map( id => {
      window.recordCache?.delete( id );
    } );
  }
};

const _addRecordsAndCache = async ( type, params ) => {

  const response = await _create( type, params );

  if ( isNotEmpty( response ) ) {
    const { errors, results } = response;

    if ( isNotEmpty( errors ) ) {
      return { errors };
    } else if ( isNotEmpty( results ) ) {
      // store each item in the cache
      results.map( record => {
        _addToCache( record.id, record );
      } );
      return response.results;
    }
  }
};

const _updateRecordsAndCache = async ( type, params ) => {

  const response = await _update( type, params );

  if ( isNotEmpty( response ) ) {
    const { errors, results } = response;

    if ( isNotEmpty( errors ) ) {
      return { errors };
    } else if ( isNotEmpty( results ) ) {
      // store each item in the cache
      results.map( record =>  _addToCache( record.id, record ) );
      return results;
    }
  }
};

const _fetchRecordsAndCache = async ( recordType, params ) => {

  let _params = { ...params };
  const cachedRecords = [];

  // if we are asking for any specific hosts, patches, vulnerabilities, or signatures, we can potentially
  // utilize the cache to get the data, if it exists
  if (
    isNotEmpty( _params )
    && isNotEmpty( _params.filters )
    && isNotEmpty( recordType )
  ) {

    const unCachedRecordIDs = [];
    const filterKeys = Object.keys( _params.filters );

    // we are looking for specific records of the same type as the recordType,
    // ie: we are getting specific hosts by hitting analysis/host and passing in host_ids
    // this is different than returning hosts by hitting analysis/host but filtering on something like patch_ids
    if ( isNotEmpty( filterKeys ) && filterKeys.includes( `${recordType}_ids` ) ) {
      const recordIDs = _params.filters[`${recordType}_ids`];

      const { columns } = _params;

      if ( isNotEmpty( columns ) ) {
        recordIDs.map( id => {
          const cachedRecord = window.recordCache?.get( id );

          // there is a record already cached, but need to check if it has all the columns we need
          if ( isNotEmpty( cachedRecord ) ) {
            const attrs = Object.keys( cachedRecord );
            const allAttrs = columns.every( col => attrs.includes( col ) );

            // all columns present, return the cached record
            if ( allAttrs ) {
              cachedRecords.push( cachedRecord );
            } else {
              // some columns missing, need to fetch the record
              _removeFromCache( id );
              unCachedRecordIDs.push( id );
            }
          } else {
            // there was no cached record, still need to fetch
            unCachedRecordIDs.push( id );
          }
        } );
      }

      if ( isNotEmpty( unCachedRecordIDs ) ) {
        console.log( `fetching ${unCachedRecordIDs.length} records for ${recordType} that are not in the cache` );
        _params = { ..._params, filters: { ..._params.filters, [`${recordType}_ids`]: unCachedRecordIDs } };
      }
    }
  }

  // fetch any remaining records
  let fetchedRecords = await _search( recordType, _params );

  // merge in any cached records
  if ( isNotEmpty( cachedRecords ) ) {
    console.log( `merging ${cachedRecords.length} cached records with ${fetchedRecords.length} fetched records` );
    fetchedRecords = [ ...fetchedRecords, ...cachedRecords ];
  }

  // dedupe the records
  fetchedRecords = uniqueArray( fetchedRecords );

  // store each item in the cache
  if ( isNotEmpty( fetchedRecords ) && itemIsArray( fetchedRecords ) ) {
    fetchedRecords.map( record => {
      _addToCache( record.id, record );
    } );
  }

  // return the records
  return fetchedRecords;
};

const _fetchRecordsAndCacheLegacy = async ( type, additionalParams={}, useInsightEndpoint, singleRecord=false ) => {
  let params = {};

  // need to first see if we are looking for a single record using the new(er) instance_details endpoints, if so,
  // need to make sure we always fetch the record because... otherwise it has been cached and might be missing fields
  // I cannot rely on the params being different anymore
  if ( useInsightEndpoint === true && singleRecord === true ) {
    // eslint-disable-next-line max-len, camelcase
    const fetchedRecords = await _searchLegacy( type, { risk_type: additionalParams.risk_type, id_list: additionalParams.id_list }, useInsightEndpoint, singleRecord );

    if ( isNotEmpty( fetchedRecords ) ) {
      const [ record ] = fetchedRecords;
      _addToCache( record.id, record );
      return fetchedRecords;
    }
    return ( {} );
  // we are asking for specific records with an id_list,
  // this is the real performance gain, if we are asking for any that have
  // already been fetched, we will only ask for the new ones.
  } else if (
    isNotEmpty( additionalParams )
    && isNotEmpty( additionalParams.id_list )
    && isEmpty( additionalParams.id_field )
  ) {

    let newRecordIDsToFetch = [];
    let toReturn = [];

    // eslint-disable-next-line camelcase, max-len
    additionalParams.id_list = additionalParams.id_list.filter( id => id !== undefined || id !== 'undefined' );

    additionalParams.id_list.map( id => {
      const item = window.recordCache && window.recordCache.get( id );
      // record is already in the cache
      if ( isNotEmpty( item ) ) {
        // this checks to make sure the existing record has all the columns that we are asking
        // for, if it does not, we will add the id to the list of records that need fetching,
        // so that it has all the expected data that may be missing from the cache
        if ( isNotEmpty( additionalParams.extra_columns ) ) {
          // loop through each attribute, and see if it is not on the record, if even one is
          // missing we need to refetch
          // eslint-disable-next-line
          for ( const [ index, ec ] of additionalParams.extra_columns.entries() ) {
            let attr = ec;

            if ( _isAnalysisAttribute( type, attr ) ) {
              // eslint-disable-next-line
              attr = attr.split( '.' )[1];
            }
            // pop it to the top of the cache
            if ( item[attr] !== undefined && item[attr] !== null ) {
              // if we are only asking for one thing... we need to replace the ids, otherwise, add to it
              if ( additionalParams.id_list.length === 1 ) {
                toReturn = [ item ];
              } else {
                toReturn.push( item );
              }
              _removeFromCache( id );
              _addToCache( id, item );
            // if this attr is not present, need to refetch
            } else {
              // if we are only asking for one thing... we need to replace the ids, otherwise, add to it
              if ( additionalParams.id_list.length === 1 ) {
                newRecordIDsToFetch = [ id ];
              } else {
                newRecordIDsToFetch.push( id );
              }
              break;
            }
          }
        }
      // record is not in the cache
      } else {
        newRecordIDsToFetch.push( id );
      }
    } );

    // we still have more IDs for uncached records, need to fetch any missing ones
    if ( isNotEmpty( newRecordIDsToFetch ) ) {
      params = {
        ...params,
        ...additionalParams,
        // eslint-disable-next-line camelcase
        id_list: newRecordIDsToFetch,
      };

      // get the additional records and map them
      const fetchedRecords = await _searchLegacy( type, params, useInsightEndpoint );

      if ( additionalParams.id_list?.length === 1 ) {
        // store each item in the cache
        fetchedRecords.map( record => {
          toReturn = [ record ];
          _removeFromCache( record.id );
          _addToCache( record.id, record );
        } );
      } else {
        // store each item in the cache
        fetchedRecords?.map( record => {
          toReturn.push( record );
          _addToCache( record.id, record );
        } );
      }

      return uniqueArray( toReturn );

    // all the records we are asking for are already cached
    }
    additionalParams.id_list.map( id => {
      const item = window.recordCache && window.recordCache.get( id );
      if ( isNotEmpty( item ) ) {
        toReturn.push( item );
      }
    } );

    return uniqueArray( toReturn );

  // we are not asking for specific ids, need to do the default fetch
  }
  params = {
    ...params,
    ...additionalParams,
  };

  const fetchedRecords = await _searchLegacy( type, params, useInsightEndpoint );

  // store each item in the cache
  if ( isNotEmpty( fetchedRecords ) ) {
    fetchedRecords.map( record => {
      _addToCache( record.id, record );
    } );
  }

  return fetchedRecords;
};

// takes the existing params, and merges them with any additional ones, more control than just
// overwriting, need to take into account different data types (arrays, objects, strings)
const _mergeParams = ( existing, additional ) => {

  const combined = { ...existing };

  if ( isNotEmpty( additional ) ) {
    Object.entries( additional ).map( ( [ key, val ] ) => {
      // this filter already exists
      if ( isNotEmpty( combined[key] ) ) {
        // this value is an array, the two need to be merged ( most likely columns )
        if ( Array.isArray( val ) ) {
          // for all array types, need to merge them,
          // rows/order_by need to be overwritten though
          if ( key === 'rows' || key === 'order_by' || key === 'rownums' ) {
            combined[key] = val;
          } else {
            combined[key] = [ ...combined[key], ...val ];
          }
        } else if ( val.constructor === Object ) {
          combined[key] = { ...combined[key], ...val };
        } else if ( typeof val === 'string' || val instanceof String ) {
          combined[key] = val;
        } else {
          combined[key] = val;
        }
      // the filter does not already exist, add it
      } else {
        combined[key] = val;
      }
    } );
  }
  return combined;
};

// --------------------------------------------------------
// Exported Functions -------------------------------------
// --------------------------------------------------------
export const clearCache = () => {
  window.recordCache.clear();
};

// external addition to cache, takes an array of
// records and replaces any existing records with these
export const addRecordsToCache = records => {
  records.map( record => {
    const { id } = record;

    if ( isNotEmpty( id ) && isNotEmpty( record ) ) {
      _addToCache( id, record );
    }
  } );
};

// gets a collection of a particular record type, will automatically figure out the params it needs based on the hash
// if more control is needed, the additionalParams can be passed in
export const getRecords = async ( recordType, additionalParams={}, useFastAPI=false, ignoreParams=false ) => {
  // need to support new and old endpoints, defaults to old endpoints
  if ( useFastAPI ) {
    let _resourceEndpoint = recordType;

    let filterValues = paramsToFilters();


    if ( isNotEmpty( filterValues.group_type ) ) {
      _resourceEndpoint = filterValues.group_type;
    }

    if ( ignoreParams ) {
      filterValues = {};
    }

    const filterParams = buildParamsForRiskInsight( filterValues, _resourceEndpoint, 'table' );

    const params = _mergeParams( filterParams, additionalParams );

    // need to purge any innapropriate filters for record type
    if (
      isNotEmpty( params )
      && isNotEmpty( params.filters )
      && isNotEmpty( unNeededFilterKeysForRecordTypeMap[recordType] )
    ) {
      unNeededFilterKeysForRecordTypeMap[recordType].map( key => {
        delete params.filters[key];
      } );
    }

    // need to purge any FE only params
    if ( isNotEmpty( params ) && isNotEmpty( params.filters ) ) {
      unneededKeys.map( key => {
        delete params.filters[key];
      } );
    }

    const records = await _fetchRecordsAndCache( _resourceEndpoint, params );
    return records;
  }

  let params = {
    rownums: [ 0, 100 ],
  };

  const typeFilters = _defaultParamsForType( recordType, false );

  params = _mergeParams( { ...params, ...typeFilters }, additionalParams );

  const records = await _fetchRecordsAndCacheLegacy( recordType, params, false );
  return records;
};

// gets a single record of a particular type
export const getRecordDetails = async ( recordType, id, additionalParams={}, excludeDefaults=false ) => {
  if ( isNotEmpty( id ) ) {
    let _resourceEndpoint = recordType;
    let filterValues = {};

    if ( !excludeDefaults ) {
      filterValues = paramsToFilters();

      if ( isNotEmpty( filterValues ) ) {

        if ( isNotEmpty( filterValues.group_type ) ) {
          _resourceEndpoint = filterValues.group_type;
        }
      }
    }

    let _params = {};

    // just want a minimal label, risk, etc.
    if ( excludeDefaults ) {
      if ( _resourceEndpoint === 'scope' ) {
        _resourceEndpoint = 'host';
      }

      _params = {
        rows: [ 0, 1 ],
        columns: minimalColumnsForRecordType[recordType],
        filters: {
          [`${_resourceEndpoint}_ids`]: [ id ],
        },
        risk_type: additionalParams.risk_type || 'direct_risk',
      };
    // full record with all the details
    } else {
      const params = {
        rows: [ 0, 1 ],
        columns: [
          ...defaultColumnsForRecordType[recordType],
          ...additionalColumnsForRecordDetailType[recordType],
        ],
        filters: {
          [`${recordType}_ids`]: [ id ],
        },
        risk_type: filterValues.risk_type || 'direct_risk',
      };

      _params = _mergeParams( params, additionalParams );

      // need to purge any innapropriate filters for record type
      if (
        isNotEmpty( _params )
        && isNotEmpty( _params.filters )
        && isNotEmpty( unNeededFilterKeysForRecordTypeMap[recordType] )
      ) {
        unNeededFilterKeysForRecordTypeMap[recordType].map( key => {
          delete _params.filters[key];
        } );
      }

      // need to purge any FE only params
      if ( isNotEmpty( _params ) && isNotEmpty( _params.filters ) ) {
        unneededKeys.map( key => {
          delete _params.filters[key];
        } );
      }
    }
    const records = await _fetchRecordsAndCache( _resourceEndpoint, _params );
    return records[0];
  }
};

// gets a single record of a particular type legacy version of getRecordDetails
export const getRecord = async ( type, id, additionalParams={}, useInsightEndpoint=false ) => {
  if ( isNotEmpty( id ) ) {
    let params = {
      rownums: [ 0, 1 ],
    };

    const typeFilters = _defaultParamsForType( type, useInsightEndpoint );

    params = _mergeParams( { ...params, ...typeFilters }, additionalParams );
    params = {
      ...params,
      // eslint-disable-next-line camelcase
      id_list: [ id ],
    };

    const records = await _fetchRecordsAndCacheLegacy( type, params, useInsightEndpoint, true );

    return records[0];
  }
};

// updates existing records of a particular type
export const updateRecords = async ( type, changes=[] ) => {
  const records = await( _updateRecordsAndCache( type, changes ) );
  return uniqueArray( records );
};

// creates new records of an existing type
export const addRecords = async ( type, additions=[] ) => {
  const records = await( _addRecordsAndCache( type, additions ) );
  return uniqueArray( records );
};

// removes records of a particular type
export const deleteRecords = async ( type, removals=[] ) => {
  const records = await( _deleteRecordsAndCache( type, removals ) );
  return uniqueArray( records );
};

export const getTallies = async ( recordType, additionalParams={} ) => {
  let _resourceEndpoint = recordType;

  const filterValues = paramsToFilters();

  if ( isNotEmpty( filterValues ) ) {

    if ( isNotEmpty( filterValues.group_type ) ) {
      _resourceEndpoint = filterValues.group_type;
    }
  }

  let params = buildParamsForRiskInsight( filterValues, _resourceEndpoint, 'tally' );

  if ( isNotEmpty( additionalParams ) ) {
    params = _mergeParams( params, additionalParams );
  }

  params.types = uniqueArray( params.types );

  // need to purge any innapropriate filters for record type
  if (
    isNotEmpty( params )
    && isNotEmpty( params.filters )
    && isNotEmpty( unNeededFilterKeysForRecordTypeMap[recordType] )
  ) {
    unNeededFilterKeysForRecordTypeMap[recordType].map( key => {
      delete params.filters[key];
    } );
  }

  // need to purge any FE only params
  if ( isNotEmpty( params ) && isNotEmpty( params.filters ) ) {
    unneededKeys.map( key => {
      delete params.filters[key];
    } );
  }

  const records = await makeRequest( 'POST', '/fe/analysis/TALLY', params );

  return records;
};

export const getRecordsCount = async ( recordType ) => {

  let _resourceEndpoint = recordType;

  const filterValues = paramsToFilters();

  if ( isNotEmpty( filterValues ) ) {

    if ( isNotEmpty( filterValues.group_type ) ) {
      _resourceEndpoint = filterValues.group_type;
    }

    const params = buildParamsForRiskInsight( filterValues, _resourceEndpoint, 'count' );

    // need to purge any innapropriate filters for record type
    if (
      isNotEmpty( params )
      && isNotEmpty( params.filters )
      && isNotEmpty( unNeededFilterKeysForRecordTypeMap[recordType] )
    ) {
      unNeededFilterKeysForRecordTypeMap[recordType].map( key => {
        delete params.filters[key];
      } );
    }

    // need to purge any FE only params
    if ( isNotEmpty( params ) && isNotEmpty( params.filters ) ) {
      unneededKeys.map( key => {
        delete params.filters[key];
      } );
    }

    const _count = await makeRequest(
      'POST',
      `/fe/analysis/${_resourceEndpoint === 'patch_cumulative' ? 'patch' : _resourceEndpoint}/COUNT`,
      params,
    );
    return _count;
  }
  return 0;
};