/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { decodeURLHash, isEmpty, isNotEmpty, itemIsArray } from '../../../shared/Utilities';
import { availableWidgetOptions, availableWidgetsV2 } from './shared';
import { v4 as uuidv4 } from 'uuid';

import './DashboardEditorV2.scss';
import InlineSVG from '../../../shared/InlineSVG';
import Form from '../../../shared/Form';
import { getFieldValues } from '../../../shared/Form/Shared';
import { makeRequest } from '../../../../legacy/io';
import { FlashMessageQueueContext } from '../../../Contexts/FlashMessageQueue';
import { CurrentUserContext } from '../../../Contexts/CurrentUser';

const DashboardEditorV2 = ( {
  setEditMode,
  currentLayoutID,
  setCurrentLayoutID,
  contentWidgets,
  layoutWidgets,
  currentDashboard,
  setCurrentDashboard,
  updatedDashboardForm,
  setUpdatedDashboardForm,
  editorRef,
  openWidgetEditorFor,
  setupWidgetState,
} ) => {

  const [ availableWidgetCategories, setAvailableWidgetCategories ] = React.useState( null );

  const [ reportFields, setReportFields ] = React.useState( null );
  const [ isValid, setIsValid ] = React.useState( true );

  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );
  const [ currentUser ] = React.useContext( CurrentUserContext );

  // on init, decide what widgets are available for this page, for now, it is all and this
  // only exists on the "custom reports" page, but in the future, this could be used in many more places
  React.useEffect( ( ) => {
    const hash = decodeURLHash();
    const page = hash.page || hash.report;
    const availableKeys = availableWidgetOptions[page];

    if ( isNotEmpty( availableKeys ) ) {
      const _options = {};
      availableKeys.map( key => {
        _options[key] = availableWidgetsV2[key];
      } );
      setAvailableWidgetCategories( _options );
    }
  }, [] );

  // set up the fields (only 1, the name of the dashboard)
  React.useEffect( ( ) => {
    setReportFields( [
      {
        type: 'text',
        label: 'Dashboard Name',
        attribute: 'label',
        required: true,
        defaultValue: currentDashboard?.label || '',
      },
    ] );
  }, [ currentDashboard ] );

  const cancelChanges = () => {
    window.location.reload();
  };

  const saveChanges = async () => {
    let values = {};
    let newDashboard = false;
    if ( isNotEmpty( updatedDashboardForm ) && isNotEmpty( updatedDashboardForm.fieldStates ) ) {
      values = getFieldValues( updatedDashboardForm.fieldStates, 'reporting_dashboard' );
    }

    const _dashboard = { ...currentDashboard, ...values };

    let dashboardID = _dashboard.id;

    if ( isEmpty( _dashboard.id ) ) {
      dashboardID = uuidv4();
      _dashboard.id = dashboardID;
      newDashboard = true;
    }

    let _layoutWidgets = [];
    const widgets = [];
    if ( isNotEmpty( layoutWidgets ) ) {
      _layoutWidgets = Object.values( layoutWidgets );
    }

    if ( isNotEmpty( _layoutWidgets ) ) {
      _layoutWidgets.map( w => {

        if ( isNotEmpty( w ) ) {
          let _widget = { ...w };
          const contentWidget = contentWidgets[w.i];

          if ( isNotEmpty( contentWidget ) ) {
            _widget = { ..._widget, ...contentWidget };
          }
          widgets.push( _widget );
        }
      } );
    }

    if ( isEmpty( widgets ) ) {
      addFlashMessage( {
        header: 'Unable to save',
        body: 'Add at least one widget to this dashboard before saving',
        type: 'alert',
      } );
    } else if ( isNotEmpty( _dashboard ) ) {
      _dashboard.widgets = { widgets };

      delete _dashboard.created;
      delete _dashboard.modified;
      delete _dashboard.builtin;

      let upsertRequest = null;

      if ( newDashboard ) {
        upsertRequest = await makeRequest( 'PUT', '/fe/dashboard/INSERT', [ _dashboard ] );
      } else {
        upsertRequest = await makeRequest( 'PUT', '/fe/dashboard/UPDATE', [ _dashboard ] );
      }

      const accessItem = {
        // eslint-disable-next-line camelcase
        user_id: currentUser.id,
        // eslint-disable-next-line camelcase
        access_level: 'owner',
        // eslint-disable-next-line camelcase
        dashboard_id: dashboardID,
      };

      await makeRequest( 'PUT', '/fe/object_access/UPSERT', [ accessItem ] );

      // success
      if ( isNotEmpty( upsertRequest ) ) {
        setCurrentLayoutID( dashboardID );

        addFlashMessage( {
          body: 'Successfully saved dashboard changes',
          type: 'success',
        } );
        window.location.reload();
      } else {
        addFlashMessage( {
          body: 'There was an error saving your dashboard',
          type: 'alert',
        } );
      }

      setEditMode( false );
      setupWidgetState();
    }
  };

  const deleteLayout = async () => {
    if ( confirm( 'Are you sure you want to delete this dashboard?' ) ) {

      if ( isNotEmpty( currentDashboard ) && isNotEmpty( currentDashboard.id ) ) {
        const { id } = currentDashboard;

        // if the id is equal to the current layout... which it probably will be, then we need to choose a different one
        if ( id === currentLayoutID ) {
          setCurrentDashboard( null );
        }

        const deleteRequest = await makeRequest( 'PUT', '/fe/dashboard/DELETE', [ id ] );
        // success
        if ( isNotEmpty( deleteRequest ) && itemIsArray( deleteRequest ) ) {
          addFlashMessage( {
            body: 'Successfully deleted dashboard',
            type: 'success',
          } );
        } else {
          addFlashMessage( {
            body: 'There was an error deleting your dashboard',
            type: 'alert',
          } );
        }
        setEditMode( false );
        window.location.reload();
      }
    }
  };

  return (
    <React.Fragment>
      <div
        className="dashboardEditorV2"
        ref={editorRef}
      >
        {
          isNotEmpty( reportFields ) &&
          <Form
            fields={reportFields}
            onChangeCallback={ setUpdatedDashboardForm }
            existingRecord={ currentDashboard }
            recordType="reporting_dashboard"
            setIsValid={setIsValid}
            trackUpdates={false}
          />
        }
        {
          isNotEmpty( availableWidgetCategories ) &&
          <div className="addWidgetsWrapper">
            <h2>Select a widget category:</h2>
            <div className="optionsWrapper">
              {
                Object.values( availableWidgetCategories ).map( ( widgetCategory, index ) => {
                  return <button
                    className="widgetOption"
                    key={index}
                    onClick={ () => openWidgetEditorFor( widgetCategory ) }
                  >
                    <InlineSVG type={widgetCategory.icon} />
                    <label>{ widgetCategory.label }</label>
                  </button>;
                } )
              }
            </div>
          </div>
        }
        <div className="editorActionsWrapper">
          <button
            className="deleteButton"
            onClick={ deleteLayout }
          >
            Delete
          </button>
          <div className="buttonWrapper">
            <button
              className="cancelButton"
              onClick={ cancelChanges }
            >
              Cancel
            </button>
            <button
              className={ `${isValid ? '' : 'disabled'} submitButton`}
              onClick={ saveChanges }
              disabled={ !isValid }
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DashboardEditorV2;