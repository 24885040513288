/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';

import './ScanningTotal.scss';
import { formatNumber, isNotEmpty } from '../../../../../shared/Utilities';
import InlineSVG from '../../../../../shared/InlineSVG';
import EmptyState from '../../../../../shared/EmptyState';

const ScanningTotal = ( { data, settings } ) => {

  const [ credentialedTotal, setCredentialedTotal ] = React.useState( null );
  const [ agentTotal, setAgentTotal ] = React.useState( null );

  React.useEffect( () => {
    if ( isNotEmpty( data ) && 'scanning_type' in settings ) {

      if ( settings.scanning_type === 'both' ) {
        setAgentTotal( data.agentTotal );
        setCredentialedTotal( data.agentlessTotal );
      }
      if ( settings.scanning_type === 'agent' ) {
        setAgentTotal( data.total );
      }
      if ( settings.scanning_type === 'agentless' ) {
        setAgentTotal( data.total );
      }
    }
  }, [ data, settings ] );

  return (
    <React.Fragment>
      {
        ( isNotEmpty( credentialedTotal ) || isNotEmpty( agentTotal ) )
          ? <div
            // eslint-disable-next-line max-len
            className={ `bigNumberWrapper ${ ( isNotEmpty( credentialedTotal ) && isNotEmpty( agentTotal ) ) ? 'twoNumbers' : '' }` }
          >
            {
              isNotEmpty( credentialedTotal ) &&
              <div className="totalWrapper credentialed">
                <h3>Scanned Hosts</h3>
                <h2>
                  <InlineSVG type="hostsAlt" />
                  { formatNumber( credentialedTotal ) }
                </h2>
                <span>scanned via credentialed scans</span>
              </div>
            }
            {
              isNotEmpty( agentTotal ) &&
              <div className="totalWrapper credentialed">
                <h3>Scanned Hosts</h3>
                <h2>
                  <InlineSVG type="hostsAlt" />
                  { formatNumber( agentTotal ) }
                </h2>
                <span>scanned via user managed/agent scans</span>
              </div>
            }
          </div>
          : <EmptyState message="No data available" />
      }
    </React.Fragment>
  );
};

export default ScanningTotal;