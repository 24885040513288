
/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import InlineSVG from '../../../shared/InlineSVG';
import { formatUnixDate, isNotEmpty } from '../../../shared/Utilities';

import './AcceptedRiskPlanItem.scss';
import Loading from '../../../shared/Loading';
import EmptyState from '../../../shared/EmptyState';
import { getRecords } from '../../../shared/RecordCache';
import RecordList from '../../../shared/RecordList';

const AcceptedRiskPlanItem = ( { plan } ) => {

  const [ showItems, setShowItems ] = React.useState( false );
  const [ items, setItems ] = React.useState( [] );
  const [ hasFetchedItems, setHasFetchedItems ] = React.useState( false );
  const [ loadingItems, setLoadingItems ] = React.useState( false );
  const [ highestRisk, setHighestRisk ] = React.useState( null );

  const planReasonMap = {
    // eslint-disable-next-line camelcase
    false_positive: 'False positive',
    mitigated: 'Mitigated',
    decommissioning: 'Decommissioning',
    // eslint-disable-next-line camelcase
    disrupts_functionality: 'Disrupts functionality',
  };

  const planAction = plan => {
    let _text = <React.Fragment></React.Fragment>;

    const [ , planItemsLabel ] = plan.type.split( '_' );

    if ( isNotEmpty( plan ) ) {
      if ( plan.type === 'host_patches' ) {
        _text = <React.Fragment>
          Will not apply <strong> {plan.item_ids.length} {planItemsLabel}</strong>
        </React.Fragment>;
      }
      if ( plan.type === 'patch_hosts' ) {
        _text = <React.Fragment>
          Will not be applied on <strong> {plan.item_ids.length} {planItemsLabel}</strong>
        </React.Fragment>;
      }
      if ( plan.type === 'host_vulnerabilities' ) {
        _text = <React.Fragment>
          Accept risk of <strong> {plan.item_ids.length} {planItemsLabel}</strong>
        </React.Fragment>;
      }
      if ( plan.type === 'vulnerability_hosts' ) {
        _text = <React.Fragment>
          Will not be patched on <strong> {plan.item_ids.length} {planItemsLabel}</strong>
        </React.Fragment>;
      }
    }

    return _text;
  };

  const getPlanIcon = plan => {
    const [ type ] = plan.type.split( '_' );

    if ( type === 'host' ) {
      return <InlineSVG type="hostsAlt" />;
    }

    if ( type === 'vulnerability' ) {
      return <InlineSVG type="vulnerabilitiesAlt" />;
    }

    if ( type === 'patch' ) {
      return <InlineSVG type="patchesAlt" />;
    }
  };

  const fetchAndFormatItems = async () => {
    setHighestRisk( null );
    if ( isNotEmpty( plan ) && isNotEmpty( plan.item_ids ) && isNotEmpty( plan.type ) ) {

      setLoadingItems( true );

      let _items;

      const [ , type ] = plan.type.split( '_' );

      if ( type === 'vulnerabilities' ) {

        const params = {
          rows: [ 0, plan.item_ids.length ],
          filters: {
            patchable: null,
            // eslint-disable-next-line camelcase
            vulnerability_ids: plan.item_ids,
          },
          // eslint-disable-next-line camelcase
          order_by: [
            [ 'filtered_risk', 'DESC' ],
            [ 'identifier', 'ASC' ],
          ],
        };

        _items = await getRecords( 'vulnerability', params, true );

        if ( isNotEmpty( _items ) ) {
          _items = _items.sort( ( a, b ) => b.filtered_risk - a.filtered_risk );
        }
      } else if ( type === 'hosts' ) {
        const params = {
          rows: [ 0, plan.item_ids.length ],
          filters: {
            // eslint-disable-next-line camelcase
            host_has_sensitive_nodes: null,
            // eslint-disable-next-line camelcase
            has_host: true,
            // eslint-disable-next-line camelcase
            accepted_risk: null,
            // eslint-disable-next-line camelcase
            host_ids: plan.item_ids,
          },
          // eslint-disable-next-line camelcase
          risk_type: 'direct_risk',
          // eslint-disable-next-line camelcase
          order_by: [
            [ 'filtered_risk', 'DESC' ],
            [ 'local_name', 'ASC' ],
          ],
        };

        _items = await getRecords( 'host', params, true );

        if ( isNotEmpty( _items ) ) {
          _items = _items.sort( ( a, b ) => b.filtered_risk - a.filtered_risk );
        }
      }

      if ( isNotEmpty( _items ) ) {
        setItems( _items );
        const _highestRisk = Math.max( ..._items.map( i => i.filtered_risk ) );
        setHighestRisk( _highestRisk );
      } else {
        setItems( [] );
      }
      setHasFetchedItems( true );
      setLoadingItems( false );
    }
  };

  React.useEffect( ( ) => {
    if ( showItems && !hasFetchedItems && isNotEmpty( plan ) ) {
      fetchAndFormatItems();
    } else if ( isNotEmpty( items ) && showItems && hasFetchedItems ) {
      const _highestRisk = Math.max( ...items.map( i => i.filtered_risk ) );
      setHighestRisk( _highestRisk );
    }
  }, [ showItems ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( plan ) &&
        <React.Fragment>
          <section className="mainDetailsSection">
            <div className="column">
              <h2>
                { getPlanIcon( plan ) }
                { plan.object_label }
              </h2>
              <span>{ planAction( plan ) }</span>
            </div>
          </section>
          <section>
            <strong className="sectionLabel">
              Reason
            </strong>
            <span className="sectionDetails">
              { planReasonMap[plan.reason] || 'Accepting Risk' }
            </span>
          </section>
          <section>
            <strong className="sectionLabel">
              Approved by
            </strong>
            <span className="sectionDetails">
              { plan.owner }
            </span>
          </section>
          <section>
            <strong className="sectionLabel">
              Effective until
            </strong>
            <span className="sectionDetails">
              { formatUnixDate( plan.expiration * 1_000 ) }
            </span>
          </section>
          <div className={ `expandingItemsContainer ${ showItems ? 'expanded' : '' }` }>
            <button
              className="showItemsButton"
              onClick={ () => setShowItems( !showItems )}
            >
              {
                showItems
                  ? 'Hide Items'
                  : 'Show Items'
              }
              <InlineSVG type="carretDown" />
            </button>

            {
              showItems &&
              <section className="acceptedRiskPlanItemsWrapper">
                <h2>{ `Items (${items.length})` }</h2>
                {
                  loadingItems
                    ? <Loading />
                    : <React.Fragment>
                      {
                        ( isNotEmpty( items ) && isNotEmpty( highestRisk ) )
                          ? <RecordList
                            records={ items }
                            orderBy="filtered_risk"
                            riskType="filtered_risk"
                            // eslint-disable-next-line max-len
                            sectionKey={ plan.type === 'host_vulnerabilities' ? 'accepted_risk_vulnerabilities' : 'accepted_risk_hosts' }
                            // eslint-disable-next-line max-len
                            reportType={ plan.type === 'host_vulnerabilities' ? 'accepted_risk_vulnerabilities' : 'accepted_risk_hosts' }
                          />
                          : <EmptyState message="This plan has no items" />
                      }
                    </React.Fragment>
                }
              </section>
            }
          </div>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default AcceptedRiskPlanItem;