/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { makeRequest } from '../../../../legacy/io';
import { HelpTrigger } from '../../../components/HelpDocumentation/ContextualHelp/index.js';

import FilterForm from '../../../shared/FilterForm';
import {
  isNotEmpty,
  decodeURLHash,
  shortenedVulnerabilityScannerNameMap,
  attackScenarioLabelMap,
  vulnerabilityScannerInstanceName,
} from '../../../shared/Utilities';

import './style.scss';
import { acceptedRiskFilterOptions, supersededFilterOptions } from '../../../shared/FilterForm/shared';
import { BreakpointContext } from '../../../Contexts/Breakpoint';
import InlineSVG from '../../../shared/InlineSVG';
import { TagsContext } from '../../../Contexts/Tags';
import ReportCreator, { openReportCreator } from '../../../shared/ReportCreator/index.js';
import { hasFeatureAccess } from '../../App/AccessControl.js';
import { CurrentUserContext } from '../../../Contexts/CurrentUser.js';

const InsightFilters = ( {
  onRefresh,
  collapsed,
  setCollapsed,
  visualCollapsed=false,
  setVisualCollapsed=() => {},
  reportType,
  tableLoading=false,
  visualLoading=false,
  showAdvancedFilters=false,
} ) => {

  const [ fields, setFields ] = React.useState( null );
  const [ existingReport, setExistingReport ] = React.useState( null );

  const [ tags ] = React.useContext( TagsContext );
  const [ currentUser, , licenseInfo ] = React.useContext( CurrentUserContext );
  const [ breakpointClass ] = React.useContext( BreakpointContext );

  let isMounted = true;
  /* eslint-disable camelcase */
  const EMPTY_FILTERS = {
    host: {
      one: {
        fields: [
          {
            type: 'debouncedText',
            label: 'Keywords',
            attribute: 'host_keywords',
            placeholder: 'Filter Hosts by keyword...',
            helpItem: <HelpTrigger helpKey="keywords" />,
          },
          {
            type: 'select',
            label: 'Tags',
            attribute: 'asset_tag_ids',
            multiple: true,
            value: [],
            allowBlank: true,
            helpItem: <HelpTrigger helpKey="tag" />,
          },
          {
            type: 'hidden',
            attribute: 'item',
          },
          {
            type: 'hidden',
            attribute: 'order_by',
            value: 'filtered_risk',
          },
          {
            type: 'hidden',
            attribute: 'order_direction',
            value: 'DESC',
          },
          {
            type: 'hidden',
            attribute: 'include_risk',
            value: true,
          },
          {
            type: 'hidden',
            attribute: 'item_count',
            value: 100,
          },
        ],
      },
      two: {
        fields: [
          {
            type: 'select',
            label: 'Include accepted risk items?',
            attribute: 'accepted_risk',
            value: 'false',
            options: acceptedRiskFilterOptions,
            helpItem: <HelpTrigger helpKey="include_accepted_risk_items" />,
          },
          {
            type: 'radioGroup',
            label: 'Sensitive Assets',
            attribute: 'host_has_sensitive_nodes',
            value: 'null',
            options: {
              'null': 'Any',
              true: 'Some',
              false: 'None',
            },
            helpItem: <HelpTrigger helpKey="sensitive_assets" />,
          },
          {
            type: 'inequality',
            label: 'Risk Reduction',
            attribute: 'risk_reduction',
            value: [ 'gt_map', '' ],
            htmlProps: { min: '0', max: '100' },
            subLabel: '%',
            helpItem: <HelpTrigger helpKey="risk_reduction" />,
          },
        ],
      },
      three: {
        header: 'Wildcards and Search',
        fields: [
          {
            type: 'debouncedText',
            label: 'Host wildcard',
            attribute: 'host_globs',
            placeholder: 'example: OR-*SQL*',
            helpItem: <HelpTrigger helpKey="host_wildcard" />,
          },
          {
            type: 'searchResults',
            label: 'Specific Host',
            attribute: 'host_ids',
            placeholder: 'Find host by name...',
            recordType: 'host',
            helpItem: <HelpTrigger helpKey="specific_host" />,
          },
          {
            type: 'searchResults',
            label: 'Specific Patch',
            attribute: 'patch_ids',
            placeholder: 'Find patch by name...',
            recordType: 'patch',
            helpItem: <HelpTrigger helpKey="specific_patch" />,
          },
          {
            type: 'searchResults',
            label: 'Specific Vulnerability',
            attribute: 'vulnerability_ids',
            placeholder: 'Find vulnerability by name...',
            recordType: 'vulnerability',
            helpItem: <HelpTrigger helpKey="specific_vulnerability" />,
          },
        ],
      },
      four: {
        header: 'Operating System',
        fields: [
          {
            type: 'select',
            label: 'Host OS Name',
            attribute: 'host_os_label',
            options: {},
            allowBlank: true,
            blankDisplayText: 'Any',
            defaultValue: '',
            helpItem: <HelpTrigger helpKey="host_os_name" />,
          },
          {
            type: 'select',
            label: 'Host OS Vendor',
            attribute: 'host_vendor',
            options: {},
            allowBlank: true,
            blankDisplayText: 'Any',
            defaultValue: '',
            helpItem: <HelpTrigger helpKey="host_os_vendor" />,
          },
          {
            type: 'select',
            label: 'Host OS Type',
            attribute: 'host_os_type',
            defaultValue: '',
            blankDisplayText: 'Any',
            allowBlank: true,
            options: {},
            helpItem: <HelpTrigger helpKey="host_os_type" />,
          },
          {
            type: 'select',
            label: 'Host OS Architecture',
            attribute: 'host_architecture',
            defaultValue: '',
            blankDisplayText: 'Any',
            allowBlank: true,
            options: {},
            helpItem: <HelpTrigger helpKey="host_os_architecture" />,
          },
        ],
      },
    },
    patch: {
      one: {
        fields: [
          {
            type: 'debouncedText',
            label: 'Keywords',
            attribute: 'patch_keywords',
            placeholder: 'Filter Patches by keyword...',
            helpItem: <HelpTrigger helpKey="keywords" />,
          },
          {
            type: 'select',
            label: 'Tags',
            attribute: 'asset_tag_ids',
            multiple: true,
            value: [],
            allowBlank: true,
            helpItem: <HelpTrigger helpKey="tag" />,
          },
          // {
          //   type: 'checkbox',
          //   label: 'Show Only Patches that Apply to Hosts in Environment?',
          //   attribute: 'has_instances',
          //   value: true,
          // },
        ],
      },
      two: {
        fields: [
          {
            type: 'select',
            label: 'Include accepted risk items?',
            attribute: 'accepted_risk',
            value: 'false',
            options: acceptedRiskFilterOptions,
            helpItem: <HelpTrigger helpKey="include_accepted_risk_items" />,
          },
          {
            type: 'radioGroup',
            label: 'Superseded status',
            attribute: 'superseded',
            value: 'false',
            options: supersededFilterOptions,
            helpItem: <HelpTrigger helpKey="superseded" />,
          },
        ],
      },
      three: {
        header: 'Wildcards and Search',
        fields: [
          {
            type: 'debouncedText',
            label: 'Patch wildcard',
            attribute: 'patch_globs',
            placeholder: 'example: 447*',
            helpItem: <HelpTrigger helpKey="patch_wildcard" />,
          },
          {
            type: 'searchResults',
            label: 'Specific Host',
            attribute: 'host_ids',
            placeholder: 'Find host by name...',
            recordType: 'host',
            helpItem: <HelpTrigger helpKey="specific_host" />,
          },
          {
            type: 'searchResults',
            label: 'Specific Patch',
            attribute: 'patch_ids',
            placeholder: 'Find patch by name...',
            recordType: 'patch',
            helpItem: <HelpTrigger helpKey="specific_patch" />,
          },
          {
            type: 'searchResults',
            label: 'Specific Vulnerability',
            attribute: 'vulnerability_ids',
            placeholder: 'Find vulnerability by name...',
            recordType: 'vulnerability',
            helpItem: <HelpTrigger helpKey="specific_vulnerability" />,
          },
        ],
      },
      four: {
        fields: [
          {
            type: 'inequality',
            label: 'Risk Reduction',
            attribute: 'risk_reduction',
            value: [ 'gt_map', '' ],
            htmlProps: { min: '0', max: '100' },
            subLabel: '%',
            helpItem: <HelpTrigger helpKey="risk_reduction" />,
          },
          {
            type: 'inequality',
            label: 'Patch Age',
            attribute: 'age',
            valuesMap: { gt_map: 'Older than', lt_map: 'Newer than' },
            value: [ 'gt_map', '' ],
            htmlProps: { min: '0', max: '120' },
            subLabel: 'day(s)',
            helpItem: <HelpTrigger helpKey="age" />,
          },
          {
            type: 'inequality',
            label: 'Affected Hosts',
            attribute: 'num_hosts',
            value: [ 'gt_map', '' ],
            htmlProps: { min: '0' },
            subLabel: 'hosts',
            helpItem: <HelpTrigger helpKey="affected_hosts" />,
          },
          {
            type: 'hidden',
            attribute: 'item',
          },
          {
            type: 'hidden',
            attribute: 'include_risk',
            value: true,
          },
          {
            type: 'hidden',
            attribute: 'item_count',
            value: 100,
          },
        ],
      },
    },
    vulnerability: {
      one: {
        fields: [
          {
            type: 'debouncedText',
            label: 'Keywords',
            attribute: 'vulnerability_keywords',
            placeholder: 'Filter Vulnerabilities by keyword...',
            helpItem: <HelpTrigger helpKey="keywords" />,
          },
          {
            type: 'select',
            label: 'Tags',
            attribute: 'asset_tag_ids',
            multiple: true,
            value: [],
            allowBlank: true,
            helpItem: <HelpTrigger helpKey="tag" />,
          },
        ],
      },
      two: {
        fields: [
          {
            type: 'select',
            label: 'Include accepted risk items?',
            attribute: 'accepted_risk',
            value: 'false',
            options: acceptedRiskFilterOptions,
            helpItem: <HelpTrigger helpKey="include_accepted_risk_items" />,
          },
          {
            type: 'select',
            label: 'Exploit Status',
            attribute: 'exploit_statuses',
            value: [],
            multiple: true,
            options: {
              private: 'Private',
              // eslint-disable-next-line camelcase
              published_details: 'Details published',
              poc: 'PoC published',
              weaponized: 'Weaponized',
            },
            helpItem: <HelpTrigger helpKey="exploit_status" />,
          },
        ],
      },
      three: {
        header: 'Wildcards and Search',
        fields: [
          {
            type: 'debouncedText',
            label: 'Vulnerability wildcard',
            attribute: 'vulnerability_globs',
            placeholder: 'example: CVE-2022-*',
            helpItem: <HelpTrigger helpKey="vulnerability_wildcard" />,
          },
          {
            type: 'searchResults',
            label: 'Specific Host',
            attribute: 'host_ids',
            placeholder: 'Find host by name...',
            recordType: 'host',
            helpItem: <HelpTrigger helpKey="specific_host" />,
          },
          {
            type: 'searchResults',
            label: 'Specific Patch',
            attribute: 'patch_ids',
            placeholder: 'Find patch by name...',
            recordType: 'patch',
            helpItem: <HelpTrigger helpKey="specific_patch" />,
          },
          {
            type: 'searchResults',
            label: 'Specific Vulnerability',
            attribute: 'vulnerability_ids',
            placeholder: 'Find vulnerability by name...',
            recordType: 'vulnerability',
            helpItem: <HelpTrigger helpKey="specific_vulnerability" />,
          },
        ],
      },
      four: {
        fields: [
          {
            type: 'inequality',
            label: 'Risk Reduction',
            attribute: 'risk_reduction',
            value: [ 'gt_map', '' ],
            htmlProps: { min: '0', max: '100' },
            subLabel: '%',
            helpItem: <HelpTrigger helpKey="risk_reduction" />,
          },
          {
            type: 'inequality',
            label: 'CVSS Score',
            attribute: 'cvss_base_score',
            valuesMap: { gt_map: 'Higher than', lt_map: 'Lower than' },
            value: [ 'gt_map', '' ],
            htmlProps: { min: '0', max: '10' },
            subLabel: '',
            helpItem: <HelpTrigger helpKey="cvss_base_score" />,
          },
          {
            type: 'date',
            label: 'Vulnerability Age Range',
            preLabel: 'between',
            attribute: 'age_start',
            htmlProps: { max: new Date().toISOString().split( 'T' )[0] },
            value: '',
            helpItem: <HelpTrigger helpKey="age" />,
          },
          {
            type: 'date',
            label: '',
            preLabel: 'and',
            attribute: 'age_end',
            htmlProps: { max: new Date().toISOString().split( 'T' )[0] },
            value: '',
          },
          {
            type: 'hidden',
            attribute: 'item',
          },
          {
            type: 'hidden',
            attribute: 'include_risk',
            value: true,
          },
          {
            type: 'hidden',
            attribute: 'item_count',
            value: 100,
          },
        ],
      },
    },
    instance: {
      one: {
        fields: [
          {
            type: 'debouncedText',
            label: 'Host Keywords',
            attribute: 'host_keywords',
            placeholder: 'Filter Instances by keyword...',
            helpItem: <HelpTrigger helpKey="keywords" />,
          },
          {
            type: 'debouncedText',
            label: 'Patch Keywords',
            attribute: 'patch_keywords',
            placeholder: 'Filter Instances by keyword...',
            helpItem: <HelpTrigger helpKey="keywords" />,
          },
          {
            type: 'debouncedText',
            label: 'Vulnerability Keywords',
            attribute: 'vulnerability_keywords',
            placeholder: 'Filter Instances by keyword...',
            helpItem: <HelpTrigger helpKey="keywords" />,
          },
          {
            type: 'debouncedText',
            label: 'Signature Keywords',
            attribute: 'signature_keywords',
            placeholder: 'Filter Instances by keyword...',
            helpItem: <HelpTrigger helpKey="keywords" />,
          },
          {
            type: 'select',
            label: 'Tags',
            attribute: 'asset_tag_ids',
            multiple: true,
            value: [],
            allowBlank: true,
            helpItem: <HelpTrigger helpKey="tag" />,
          },
          {
            type: 'select',
            label: 'Category',
            attribute: 'category',
            value: '',
            allowBlank: true,
            blankDisplayText: 'All',
            helpItem: <HelpTrigger helpKey="category" />,
            options: {
              /* eslint-disable camelcase */
              deprioritized: 'Deprioritized',
              dos_only: 'Purely denial of service',
              not_exploitable: 'Mitigated',
              overridden: 'Overridden',
              for_review: 'For Review',
              unrecognized: 'Unrecognized',
              unsupported: 'Unsupported',
              missing_host: 'Not scanned by DeepSurface',
              cannot_model: 'Cannot Model',
              insufficient_information: 'Insufficient information',
              missing_capability: 'Missing capability',
              prioritized: 'Prioritized',
              unreachable: 'No attack path',
              carries_risk: 'Carries risk',
              /* eslint-enable camelcase */
            },
          },
          {
            type: 'hidden',
            attribute: 'order_by',
            value: 'filtered_risk',
          },
          {
            type: 'hidden',
            attribute: 'order_direction',
            value: 'DESC',
          },
          {
            type: 'hidden',
            attribute: 'include_risk',
            value: true,
          },
          {
            type: 'hidden',
            attribute: 'group_type',
            value: 'host',
          },
          {
            type: 'hidden',
            attribute: 'instances_visual_mode',
            value: 'categories',
          },
          {
            type: 'hidden',
            attribute: 'item_count',
            value: 100,
          },
        ],
      },
      two: {
        fields: [

          {
            type: 'select',
            label: 'Include accepted risk items?',
            attribute: 'accepted_risk',
            value: 'false',
            options: acceptedRiskFilterOptions,
            helpItem: <HelpTrigger helpKey="include_accepted_risk_items" />,
          },
          {
            type: 'select',
            label: 'Exploit Status',
            attribute: 'exploit_statuses',
            value: [],
            multiple: true,
            options: {
              private: 'Private',
              // eslint-disable-next-line camelcase
              published_details: 'Details published',
              poc: 'PoC published',
              weaponized: 'Weaponized',
            },
            helpItem: <HelpTrigger helpKey="exploit_status" />,
          },
          {
            type: 'debouncedText',
            label: 'Host wildcard',
            attribute: 'host_globs',
            placeholder: 'example: OR-*SQL*',
            helpItem: <HelpTrigger helpKey="host_wildcard" />,
          },
          {
            type: 'debouncedText',
            label: 'Patch wildcard',
            attribute: 'patch_globs',
            placeholder: 'example: 447*',
            helpItem: <HelpTrigger helpKey="patch_wildcard" />,
          },
          {
            type: 'debouncedText',
            label: 'Vulnerability wildcard',
            attribute: 'vulnerability_globs',
            placeholder: 'example: CVE-2022-*',
            helpItem: <HelpTrigger helpKey="vulnerability_wildcard" />,
          },
        ],
      },
      three: {
        header: 'Wildcards and Search',
        fields: [
          {
            type: 'searchResults',
            label: 'Specific Host',
            attribute: 'host_ids',
            placeholder: 'Find host by name...',
            recordType: 'host',
            helpItem: <HelpTrigger helpKey="specific_host" />,
          },
          {
            type: 'searchResults',
            label: 'Specific Patch',
            attribute: 'patch_ids',
            placeholder: 'Find patch by name...',
            recordType: 'patch',
            helpItem: <HelpTrigger helpKey="specific_patch" />,
          },
          {
            type: 'searchResults',
            label: 'Specific Vulnerability',
            attribute: 'vulnerability_ids',
            placeholder: 'Find vulnerability by name...',
            recordType: 'vulnerability',
            helpItem: <HelpTrigger helpKey="specific_vulnerability" />,
          },
          {
            type: 'searchResults',
            label: 'Specific Scanner Signature',
            attribute: 'signature_ids',
            placeholder: 'Find scanner signature by name...',
            recordType: 'scanner_signature',
            helpItem: <HelpTrigger helpKey="specific_scanner_signature" />,
          },
          {
            type: 'select',
            label: 'Attack Scenario',
            attribute: 'attack_scenario',
            allowBlank: true,
            blankDisplayText: 'All Scenarios',
            options: attackScenarioLabelMap,
          },
          {
            type: 'inequality',
            label: 'Risk Reduction',
            attribute: 'risk_reduction',
            value: [ 'gt_map', '' ],
            htmlProps: { min: '0', max: '100' },
            subLabel: '%',
            helpItem: <HelpTrigger helpKey="risk_reduction" />,
          },
        ],
      },
      four: {
        header: 'Operating System',
        fields: [
          {
            type: 'select',
            label: 'Host OS Name',
            attribute: 'host_os_label',
            options: {},
            allowBlank: true,
            blankDisplayText: 'Any',
            defaultValue: '',
            helpItem: <HelpTrigger helpKey="host_os_name" />,
          },
          {
            type: 'select',
            label: 'Host OS Vendor',
            attribute: 'host_vendor',
            options: {},
            allowBlank: true,
            blankDisplayText: 'Any',
            defaultValue: '',
            helpItem: <HelpTrigger helpKey="host_os_vendor" />,
          },
          {
            type: 'select',
            label: 'Host OS Type',
            attribute: 'host_os_type',
            defaultValue: '',
            blankDisplayText: 'Any',
            allowBlank: true,
            options: {},
            helpItem: <HelpTrigger helpKey="host_os_type" />,
          },
          {
            type: 'select',
            label: 'Host OS Architecture',
            attribute: 'host_architecture',
            defaultValue: '',
            blankDisplayText: 'Any',
            allowBlank: true,
            options: {},
            helpItem: <HelpTrigger helpKey="host_os_architecture" />,
          },
          {
            type: 'select',
            label: 'Included Vulnerability Source(s)',
            attribute: 'third_party_setting_ids',
            multiple: true,
            options: {},
            disabled: true,
            helpItem: <HelpTrigger helpKey="vulnerability_scanners" />,
          },
          {
            type: 'inequality',
            label: 'CVSS Score',
            attribute: 'cvss_base_score',
            // eslint-disable-next-line camelcase
            valuesMap: { gt_map: 'Higher than', lt_map: 'Lower than' },
            value: [ 'gt_map', '' ],
            htmlProps: { min: '0', max: '10' },
            subLabel: '',
            helpItem: <HelpTrigger helpKey="cvss_base_score" />,
          },
        ],
      },
    },
    user: {
      one: {
        fields: [
          {
            type: 'debouncedText',
            label: 'Keywords',
            attribute: 'keywords',
            placeholder: 'Filter Users by keyword...',

            helpItem: <HelpTrigger helpKey="keywords" />,
          },
          {
            type: 'hidden',
            attribute: 'item',
          },
          {
            type: 'hidden',
            attribute: 'item_count',
            value: 100,

          },
        ],
      },
    },
    path: {
      alwaysVisible: {
        fields: [
          {
            type: 'hidden',
            attribute: 'item',
          },
          {
            type: 'hidden',
            attribute: 'item_count',
            value: 100,
          },
          {
            type: 'hidden',
            attribute: 'order_by',
            value: 'risk',
          },
          {
            type: 'hidden',
            attribute: 'order_direction',
            value: 'DESC',
          },
        ],
      },
    },
  };
  /* eslint-enable camelcase */

  // fetches osversion info and tag info so that the options can be setup
  const fetchFilterOptions = async ( _fields, _flattenedFields, reportType ) => {

    // for the instance/host/patch/vuln report, we need to grab os version info and third_party_instance
    if (
      reportType === 'instance'
      || reportType === 'host'
      || reportType === 'patch'
      || reportType === 'vulnerability'
    ) {
      const _vulnerabilityScannerOptions = {};
      const _availableScanners = {};
      const osResponse = await makeRequest( 'GROUP', '/analysis/osversion', { project: 'default', model: 'base' } );
      if ( hasFeatureAccess( currentUser, licenseInfo, 'third_party_settings' ) ) {
        const vulnResponse = await makeRequest( 'SEARCH', '/project/default/third_party_setting', {
          // eslint-disable-next-line camelcase
          extra_columns: [ 'tool', 'settings', 'credential_id', 'category' ],
        } );

        if ( isNotEmpty( vulnResponse ) && isNotEmpty( vulnResponse.results ) ) {
          vulnResponse.results.map( v => {
            if ( v.category === 'vulnerability_scanner' ) {
              _availableScanners[v.id] = v;
            }
          } );

          // if there are scanners configured
          if ( isNotEmpty( _availableScanners ) ) {

            Object.values( _availableScanners ).map( v => {
              // eslint-disable-next-line max-len
              _vulnerabilityScannerOptions[v.id] = `${shortenedVulnerabilityScannerNameMap[v.tool]} - ${vulnerabilityScannerInstanceName( v )}`;
            } );

            if ( isNotEmpty( _vulnerabilityScannerOptions ) ) {
              const scannerFilter = _flattenedFields.find( i => i.attribute === 'third_party_setting_ids' );

              if ( isNotEmpty( scannerFilter ) ) {
                scannerFilter.options = _vulnerabilityScannerOptions;
              }
            }
          }
        }
      }

      // work through all the osVersion data and set filter options
      if ( isNotEmpty( osResponse ) && isNotEmpty( osResponse.results ) ) {
        const osVersionData = osResponse.results;

        if ( isNotEmpty( osVersionData ) && isNotEmpty( _flattenedFields ) ) {
          const vendorFilter = _flattenedFields.find( i => i.attribute === 'host_vendor' );
          const typeFilter = _flattenedFields.find( i => i.attribute === 'host_os_type' );
          const archFilter = _flattenedFields.find( i => i.attribute === 'host_architecture' );
          const labelFilter = _flattenedFields.find( i => i.attribute === 'host_os_label' );

          const vendorOptions = {};
          const typeOptions = {};
          const archOptions = {};
          const labelOptions = {};

          if ( isNotEmpty( osVersionData.host_vendor ) && isNotEmpty( vendorFilter ) ) {
            osVersionData.host_vendor.map( o => {
              vendorOptions[o] = o;
            } );
            vendorFilter.options = vendorOptions;
          }

          if ( isNotEmpty( osVersionData.host_os_type ) && isNotEmpty( typeFilter ) ) {
            osVersionData.host_os_type.map( o => {
              typeOptions[o] = o;
            } );
            typeFilter.options = typeOptions;
          }

          if ( isNotEmpty( osVersionData.host_architecture ) && isNotEmpty( archFilter ) ) {
            osVersionData.host_architecture.map( o => {
              archOptions[o] = o;
            } );
            archFilter.options = archOptions;
          }

          if ( isNotEmpty( osVersionData.host_os_label ) && isNotEmpty( labelFilter ) ) {
            osVersionData.host_os_label.map( o => {
              labelOptions[o] = o;
            } );
            labelFilter.options = labelOptions;
          }
        }
      }
    }

    // work through all the assetTag data and set filter options
    if ( isNotEmpty( tags ) ) {
      const tagFilter = _flattenedFields.find( i => i.attribute === 'asset_tag_ids' );

      if ( isNotEmpty( tagFilter ) ) {
        tagFilter.options = tags;
      }
    }

    setFields( _fields );
    onRefresh();
  };

  // 1) setup the filters, calls fetchFilterOptions to finish filter options, calls onRefresh at the very end
  React.useEffect( ( ) => {
    if ( isNotEmpty( reportType ) ) {
      const _flattenedFields = [];
      const _fields = EMPTY_FILTERS[reportType];

      if ( isNotEmpty( _fields )  ) {
        Object.values( _fields ).map( group => {
          group.fields.map( field => {
            _flattenedFields.push( field );
          } );
        } );
      }
      fetchFilterOptions( _fields, _flattenedFields, reportType );
    }

    if ( decodeURLHash()['creating_report'] ) {
      const project = 'default';
      const model = 'base';
      const filters = {
        // eslint-disable-next-line camelcase
        extra_columns: [
          'created',
          'email_recipients',
          'format',
          'filters',
          'id',
          'label',
          'last_finished',
          'last_started',
          'schedule',
          'expiration',
          'type',
          'owner',
          'state',
          'display_options',
        ],
        // eslint-disable-next-line camelcase
        id_list: [ decodeURLHash()['report_id'] ],
      };

      if ( decodeURLHash()['report_id'] ) {
        makeRequest( 'SEARCH', '/model/base/exported_report', {
          project,
          model,
          filters,
        } ).then( response => {
          if ( isMounted ) {
            if ( response && response.results ) {
              setExistingReport( response.results[0] );
            } else {
              setExistingReport( {} );
            }
            openReportCreator( );
          }
        } );
      } else {
        setExistingReport( {} );
        openReportCreator( );
      }
    }

    return () => {
      isMounted = false;
    };
  }, [ reportType, tags ] );

  return (
    <React.Fragment>
      <ReportCreator
        existingReport={existingReport}
        setExistingReport={setExistingReport}
        advanced
      />
      <div
        id="riskInsightFiltersWrapper"
        // eslint-disable-next-line max-len
        className={ `riskInsightFiltersWrapper ${breakpointClass} ${reportType} ${ tableLoading || visualLoading ? 'disabled' : ''}` }
      >

        {
          isNotEmpty( fields ) &&
          <FilterForm
            advanced
            showAdvancedFilters={ showAdvancedFilters }
            fields={fields}
            onRefresh={onRefresh}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            reportType={reportType}
            visualCollapsed={visualCollapsed}
            noRefresh
          />
        }
        <button
          className={ `roundGlyphButton light showVisualToggle ${ visualCollapsed ? 'visualDisabled' : ''}` }
          onClick={ () => setVisualCollapsed( !visualCollapsed )}
          title="View/Hide Chart"
        >
          {
            visualCollapsed
              ? <React.Fragment>
                <InlineSVG type="expand" />
              </React.Fragment>
              : <React.Fragment>
                <InlineSVG type="collapse" />
              </React.Fragment>
          }
        </button>
        {
          ( tableLoading || visualLoading ) &&
          <div className="riskInsightFiltersMask" />
        }
      </div>
    </React.Fragment>

  );
};

export default InsightFilters;