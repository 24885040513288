/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import Loading from '../../../../shared/Loading';
import { OnboardingWizardContext } from '../../../../Contexts/OnboardingWizard';
import Modal from '../../../../shared/Modal';
import {
  isNotEmpty,
  unFocusForOnboarding,
} from '../../../../shared/Utilities';
import { updateRecords } from '../../../../shared/RecordCache';
import { CurrentUserContext } from '../../../../Contexts/CurrentUser';
import { hasFeatureAccess } from '../../../App/AccessControl';

const ModalBody = ( {
  editingMultiple,
  multipleImpactScore,
  setMultipleImpactScore,
  multipleRowIDs,
  loadingNodes,
} ) => {

  const isError = multipleImpactScore < 0;

  return (
    <React.Fragment>
      {
        editingMultiple &&
        <React.Fragment>
          { loadingNodes && <Loading /> }
          <p>You are about to edit { multipleRowIDs.length } record(s). Are you Sure?</p>
          <div className={ `${isError ? 'invalid altered' : ''} required inputWrapper` }>
            <label>New Impact Score</label>
            <input
              min={0}
              step={10_000}
              type="number"
              value={ multipleImpactScore }
              onChange={ e =>  setMultipleImpactScore( e.target.value ) }
            />
            {
              isError &&
              <div className="errorMessage">Impact score must be a positive number</div>
            }
          </div>
        </React.Fragment>

      }
    </React.Fragment>
  );
};

const ModalAction = ( { onEdit, editingMultiple, multipleImpactScore, loadingNodes } ) => {
  return (
    <button
      disabled={ multipleImpactScore < 0 || loadingNodes }
      className={ `submitBotton ${multipleImpactScore < 0 || loadingNodes ? 'disabled' : ''}` }
      onClick={ () => onEdit( editingMultiple ) }
    >
      Save
    </button>
  );
};

const EditModal = ( {
  showEditModal,
  setShowEditModal,
  selectedRow,
  setSelectedRow,
  editingMultiple,
  multipleImpactScore,
  setMultipleImpactScore,
  multipleRowIDs,
  loadingNodes,
  setLoadingNodes,
  onRefresh,
} ) => {

  const [ , , refreshWizard, , , , , , , ] = React.useContext( OnboardingWizardContext );
  const [ currentUser, licenseInfo ] = React.useContext( CurrentUserContext );

  const onEdit = ( multiple=false ) => {
    let newImpact;
    let updates;
    setLoadingNodes( true );
    if ( multiple ) {
      if (
        multipleImpactScore === null
        || multipleImpactScore === undefined
        || multipleImpactScore === ''
      ) {
        newImpact = 0;
      } else {
        newImpact = multipleImpactScore;
      }
      updates = [];
      multipleRowIDs.map( i => {
        updates.push( { 'id': i, 'impact': newImpact } );
      } );
    } else {
      newImpact = selectedRow.impact;
      // do not allow a null impact
      if (
        selectedRow.impact === null
        || selectedRow.impact === undefined
        || selectedRow.impact === ''
      ) {
        newImpact = 0;
      }
      updates = [ { 'id': selectedRow.id, 'impact': newImpact} ];
    }
    updateRecords( 'node', updates ).then( response => {
      if ( isNotEmpty( response ) ) {
        setMultipleImpactScore( 0 );
        setShowEditModal( false );
        onRefresh();
        if (
          isNotEmpty( currentUser )
          && isNotEmpty( licenseInfo )
          && hasFeatureAccess( currentUser, licenseInfo, 'f_onboarding' )
        ) {
          refreshWizard();
        }
        const elementToFocus = document.getElementById( 'sensitiveAssetFormWrapper' );
        unFocusForOnboarding( elementToFocus );
        setLoadingNodes( false );
      }
    } );
  };

  return (
    <React.Fragment>
      <Modal
        size="small"
        visible={showEditModal}
        setVisible={setShowEditModal}
        header={'Edit Impact Score'}
        elementClass="sensitiveAssetsModal"
        body={ <ModalBody
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          editingMultiple={editingMultiple}
          multipleImpactScore={multipleImpactScore}
          setMultipleImpactScore={setMultipleImpactScore}
          multipleRowIDs={multipleRowIDs}
          loadingNodes={loadingNodes}
        />
        }
        action={
          <ModalAction
            onEdit={onEdit}
            editingMultiple={editingMultiple}
            multipleImpactScore={multipleImpactScore}
            loadingNodes={loadingNodes}
          />
        }
      />
    </React.Fragment>
  );
};

export default EditModal;