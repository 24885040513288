/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  ACLRoleLabelMap,
  decodeURLHash,
  formatNumber,
  getExternalUsers,
  isEmpty,
  isNotEmpty,
  itemIsArray,
  triggerHashRefresh,
} from '../../../../../shared/Utilities';
import InlineSVG from '../../../../../shared/InlineSVG';
import { makeRequest } from '../../../../../../legacy/io';
import { CurrentUserContext } from '../../../../../Contexts/CurrentUser';
import AccessItem from './AccessItem';

import './style.scss';
import { FlashMessageQueueContext } from '../../../../../Contexts/FlashMessageQueue';
import UserDetails from '../../../../../shared/UserDetails';
import FormModal from '../../../../../shared/SetupComponents/FormModal';
import { getUserParams, refresh } from '../data';
import { getFieldValues } from '../../../../../shared/Form/Shared';
import PageCreateButton from '../../../../../shared/PageCreateButton';
import EmptyState from '../../../../../shared/EmptyState';
import { hasFeatureAccess, isAdmin } from '../../../../App/AccessControl';

const UserDetail = ( { setShowUserDetails, refreshAllUsers } ) => {

  const [ user, setUser ] = React.useState( null );
  const [ users, setUsers ] = React.useState( null );
  const [ externalUsers, setExternalUsers ] = React.useState( null );
  const [ currentUser, setCurrentUser, licenseInfo ] = React.useContext( CurrentUserContext );
  const [ formattedDashboards, setFormattedDashboards ] = React.useState( [] );
  const [ formattedTags, setFormattedTags ] = React.useState( [] );
  const [ formattedRemediationPlans, setFormattedRemediationPlans ] = React.useState( [] );

  const [ dashboardsCollapsed, setDashboardsCollapsed ] = React.useState( false );
  const [ tagsCollapsed, setTagsCollapsed ] = React.useState( false );
  const [ remediationPlansCollapsed, setRemediationPlansCollapsed ] = React.useState( false );
  const [ showEditModal, setShowEditModal ] = React.useState( false );
  const [ isValid, setIsValid ] = React.useState( false );
  const [ fields, setFields ] = React.useState( null );

  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  const getExistingAccessLevel = ( existingAccess, id, type, user ) => {

    if ( isNotEmpty( user ) ) {
      if ( type === 'dashboard' && user.acl_all_dashboards === true ) {
        if ( isNotEmpty( existingAccess ) ) {
          const _existingAccess = existingAccess.find( access => access[`${type}_id`] === id );
          if ( isNotEmpty( _existingAccess ) && _existingAccess.access_level === 'owner' ) {
            return 'owner';
          }
        }
        return 'viewer';
      }
      if ( type === 'asset_tag' && user.acl_all_asset_tags === true ) {
        if ( isNotEmpty( existingAccess ) ) {
          const _existingAccess = existingAccess.find( access => access[`${type}_id`] === id );
          if ( isNotEmpty( _existingAccess ) && _existingAccess.access_level === 'owner' ) {
            return 'owner';
          }
        }
        return 'viewer';
      }
      if ( type === 'remediation_plan' && user.acl_all_remediation_plans === true ) {
        if ( isNotEmpty( existingAccess ) ) {
          const _existingAccess = existingAccess.find( access => access[`${type}_id`] === id );
          if ( isNotEmpty( _existingAccess ) && _existingAccess.access_level === 'owner' ) {
            return 'owner';
          }
        }
        return 'viewer';
      }
    }

    if ( isNotEmpty( existingAccess ) ) {
      const _existingAccess = existingAccess.find( access => access[`${type}_id`] === id );

      if ( isNotEmpty( _existingAccess ) ) {
        return _existingAccess.access_level;
      }
      return null;
    }
    return null;
  };

  const setupAccessInformation = async ( user ) => {

    const _formattedDashboards = {};
    const _formattedTags = {};
    const _formattedRemediationPlans = {};

    const accessParams = {
      columns: [
        'id',
        'modified',
        'user_id',
        'access_level',
        'asset_tag_id',
        'dashboard_id',
        'remediation_plan_id',
      ],
      // eslint-disable-next-line camelcase
      filters: { field_map: { user_id: user.id } },
    };

    const dashboardParams = {
      columns: '*',
      filters: {},
      // eslint-disable-next-line camelcase
      order_by: [
        [ 'created', 'DESC' ],
      ],
      rows: [ 0, 100 ],
    };

    const existingAccess = await makeRequest( 'POST', '/fe/object_access/SELECT', accessParams );

    // this will need to be refactored at some point, for now, these endpoints do not allow you to get only
    // the items that a particular user has access to, so we need to get all of them and then filter them out
    const dashboardsRequest = await makeRequest( 'POST', '/fe/dashboard/SELECT', dashboardParams );
    const tagsRequest = await makeRequest( 'SEARCH', '/project/default/asset_tag', {
      // eslint-disable-next-line camelcase
      extra_columns: [
        'label',
        'color',
        'included_ranges',
        'excluded_ranges',
        'included_host_patterns',
        'excluded_host_patterns',
        'included_host_ids',
        'excluded_host_ids',
        'included_product_names',
        'excluded_product_names',
        'remediation_manager',
      ],
      // eslint-disable-next-line camelcase
      order_by:[ [ 'label', 'ASC' ] ],
    } );
    const remediationPlansRequest = await makeRequest( 'LIST', '/project/default/model/base/remediation_plan', {} );

    if ( isNotEmpty( dashboardsRequest ) && itemIsArray( dashboardsRequest ) ) {
      dashboardsRequest.map( dashboard => {
        const accessLevel = getExistingAccessLevel( existingAccess, dashboard.id, 'dashboard', user );

        if ( isAdmin( currentUser ) || ( accessLevel === 'viewer' || accessLevel === 'owner' ) ) {
          _formattedDashboards[dashboard.id] = {
            id: dashboard.id,
            label: dashboard.label,
            itemType: 'dashboard',
            accessLevel,
          };
        }
      } );
      setFormattedDashboards( _formattedDashboards );
    }

    if ( isNotEmpty( tagsRequest?.results ) ) {
      tagsRequest.results.map( tag => {
        const accessLevel = getExistingAccessLevel( existingAccess, tag.id, 'asset_tag', user );

        if ( isAdmin( currentUser ) || ( accessLevel === 'viewer' || accessLevel === 'owner' ) ) {
          _formattedTags[tag.id] = {
            id: tag.id,
            label: tag.label,
            color: tag.color,
            itemType: 'asset_tag',
            accessLevel,
          };
        }
      } );
      setFormattedTags( _formattedTags );
    }

    if ( isNotEmpty( remediationPlansRequest?.results ) ) {
      remediationPlansRequest.results.map( plan => {
        const accessLevel = getExistingAccessLevel( existingAccess, plan.id, 'remediation_plan', user );

        if ( isAdmin( currentUser ) || ( accessLevel === 'viewer' || accessLevel === 'owner' ) ) {
          _formattedRemediationPlans[plan.id] = {
            id: plan.id,
            label: plan.label,
            status: plan.status,
            itemType: 'remediation_plan',
            accessLevel,
          };
        }
      } );
      setFormattedRemediationPlans( _formattedRemediationPlans );
    }
  };

  const handleUsersCallback = ( _externalUsers, _users ) => {

    if ( isNotEmpty( _users ) && isNotEmpty( _externalUsers ) ) {
      _users.map( user => {
        user.externalUser = null;
        // eslint-disable-next-line camelcase
        user.mapped_user_id = '';
      } );

      Object.values( _externalUsers ).map( eu  => {
        if ( isNotEmpty( eu.web_user_id ) ) {
          const user = _users.find( u => u.id === eu.web_user_id );
          if ( isNotEmpty( user ) ) {
            user.externalUser = eu;
            // eslint-disable-next-line camelcase
            user.mapped_user_id = eu.id;
          }
        }
      } );
    }
    setUsers( _users );
    setExternalUsers( _externalUsers );
  };

  const onRefresh = async () => {

    const hash = decodeURLHash();

    if ( isNotEmpty( hash.item ) ) {
      // if you are logged in and trying to view your own user, and you are not an admin,
      // hit the profile endpoint to get the data
      const profileVersion = isNotEmpty( currentUser )
        && currentUser.id === hash.item
        && currentUser.acl_role !== 'admin';

      const userData = await refresh( hash.item, null, profileVersion, isAdmin( currentUser ) );

      if (
        isNotEmpty( currentUser )
        && isNotEmpty( userData )
        && isNotEmpty( userData.users )
        && itemIsArray( userData.users )
      ) {
        const [ _user ] = userData.users;

        setFields( userData.fields );
        if (
          isNotEmpty( currentUser )
          && isNotEmpty( licenseInfo )
          && hasFeatureAccess( currentUser, licenseInfo, 'f_external_users' )
        ) {
          getExternalUsers( null, handleUsersCallback, [ userData.users ] );
        }


        if ( isNotEmpty( _user ) ) {
          setUser( _user );
          setupAccessInformation( _user );
        }
      }
    } else {
      backToUsers();
    }
  };

  React.useEffect( () => {
    if (
      isNotEmpty( currentUser )
    ) {
      onRefresh();
    }
  }, [ currentUser ] );

  const handleAccessLevelChange = ( accessItem, accessItemType, accessLevel ) => {

    switch ( accessItemType ) {
    case 'dashboard':
      setFormattedDashboards( { ...formattedDashboards, [accessItem.id]: { ...accessItem, accessLevel } } );
      break;
    case 'asset_tag':
      setFormattedTags( { ...formattedTags, [accessItem.id]: { ...accessItem, accessLevel } } );
      break;
    case 'remediation_plan':
      setFormattedRemediationPlans( { ...formattedRemediationPlans, [accessItem.id]: { ...accessItem, accessLevel } } );
      break;
    default:
      break;
    }
  };

  const handleAccessSave = async () => {

    const accessItemsToUpsert = [];
    const accessItemsToDelete = [];

    const accessItems = [
      ...Object.values( formattedDashboards ),
      ...Object.values( formattedTags ),
      ...Object.values( formattedRemediationPlans ),
    ];

    accessItems.map( item => {
      const accessItem = {
        // eslint-disable-next-line camelcase
        user_id: user.id,
        // eslint-disable-next-line camelcase
        access_level: item.accessLevel,
        [`${item.itemType}_id`]: item.id,
      };

      if ( item.accessLevel === 'viewer' || item.accessLevel === 'owner' ) {
        accessItemsToUpsert.push( accessItem );
      } else {
        delete accessItem.access_level;
        accessItemsToDelete.push( accessItem );
      }
    } );

    if ( isNotEmpty( accessItemsToUpsert ) ) {
      await makeRequest( 'PUT', '/fe/object_access/UPSERT', accessItemsToUpsert );
    }
    if ( isNotEmpty( accessItemsToUpsert ) ) {
      await makeRequest( 'PUT', '/fe/object_access/DELETE', accessItemsToDelete );
    }

    addFlashMessage( {
      body: 'Saved access levels',
      type: 'success',
    } );

    onRefresh();
  };

  const onSaveUserDetails = async (
    user,
    isValid,
    fieldStates,
    onSaveCallback,
  ) => {
    if ( isValid && isNotEmpty( fieldStates ) ) {

      const userParams = getUserParams( user, fieldStates );
      let userRequest;

      const includedValues = getFieldValues( fieldStates, 'user' );

      if ( isNotEmpty( userParams?.id ) ) {

        delete userParams.authentication_provider_id;

        const profileVersion = isNotEmpty( currentUser )
          && currentUser.id === userParams.id
          && currentUser.acl_role !== 'admin';

        if ( profileVersion ) {
          userRequest = await makeRequest( 'POST', 'fe/profile/UPDATE', userParams );
        } else {
          userRequest = await makeRequest( 'PUT', '/fe/user/UPDATE', [ userParams ] );
        }

      // this is a create
      } else {
        userRequest = await makeRequest( 'PUT', '/fe/user/INSERT', [ userParams ] );
      }

      // success
      if ( isNotEmpty( userRequest ) ) {
        addFlashMessage( {
          body: 'Successfully saved user',
          type: 'success',
        } );
        if ( user && user.id === currentUser.id ) {
          setCurrentUser( userParams );
        }

        // if the user also mapped to an external user, need to send that request now as well before finishing
        // need to check for it this way because the default value is the null literal and it breaks other checks
        if (
          Object.keys( includedValues ).includes( 'mapped_user_id' )
        ) {

          let externalID;
          let webID;

          const records = [];

          // if we are removing the external mapping, then we need to do some tricky logic to clear it out.
          if ( includedValues.mapped_user_id === null && isNotEmpty( externalUsers ) ) {
            // need to find what the original mapping was and then clear out this user id,
            // (rather than the other way around)
            // eslint-disable-next-line max-len
            const externalUser = Object.values( externalUsers ).find( eu => eu.web_user_id === userRequest.results[0].id );

            if ( isNotEmpty( externalUser ) ) {
              externalID = externalUser.id;
            }
            webID = null;

            if ( isNotEmpty( externalID ) ) {
              // eslint-disable-next-line camelcase
              records.push( { id: externalID, web_user_id: webID } );
            }
          // if we are changing the mapped user, we need to clear out the old mapping and additionally add this mapping
          // again, tricky lookup logic becuase it is backwards
          } else if ( isNotEmpty( user ) && user.mapped_user_id !== includedValues.mapped_user_id ) {
            // need to find what the original mapping was and then clear out this user id,
            // (rather than the other way around)
            // eslint-disable-next-line max-len
            const originalMapping = Object.values( externalUsers ).find( eu => eu.id === user.mapped_user_id );
            // clear out the old mapping
            if ( isNotEmpty( originalMapping ) ) {
              // eslint-disable-next-line camelcase
              records.push( { id: originalMapping.id, web_user_id: null } );
            }
            // create the new mapping
            externalID = includedValues.mapped_user_id;
            webID = userRequest.results[0].id;
            // eslint-disable-next-line camelcase
            records.push( { id: externalID, web_user_id: webID } );
          }

          if ( isNotEmpty( records ) ) {
            const mappedUsersResponse = await makeRequest( 'UPDATE', '/model/base/external_users', { records } );

            if ( isNotEmpty( mappedUsersResponse ) && isNotEmpty( mappedUsersResponse.results ) ) {
              addFlashMessage( {
                type: 'success',
                body: 'Successfully mapped users',
              } );
              onRefresh();
              onSaveCallback();
            } else {
              addFlashMessage( {
                type: 'alert',
                // eslint-disable-next-line max-len
                body: 'There was an error mapping users, please make sure you have entered all of the details correctly',
              } );
            }
          } else {
            onRefresh();
            onSaveCallback();
          }
        } else {
          onRefresh();
          onSaveCallback();
        }
      // there are errors
      } else if ( userRequest.errors ) {
        userRequest.errors.map( e => {
          addFlashMessage( {
            type: 'alert',
            body: e,
          } );
        } );
      // not a correctly formatted response, likely a 500
      } else {
        addFlashMessage( {
          body: 'There was an error saving, please check the form values and try again.',
          type: 'alert',
        } );
      }
    }
  };

  const backToUsers = () => {
    setShowUserDetails( false );
    window.location.href = '#.=setup&page=users';
    refreshAllUsers();
    triggerHashRefresh();
  };

  return (
    <React.Fragment>
      {
        isNotEmpty( fields ) &&
        <FormModal
          recordType="user"
          showModal={showEditModal}
          setShowModal={setShowEditModal}
          isValid={isValid}
          setIsValid={setIsValid}
          selectedRecord={user}
          setSelectedRecord={setUser}
          allowEnterSubmit
          onSave={onSaveUserDetails}
          fields={fields}
          modalClass="twoColumn"
          onRefresh={onRefresh}
          users={users}
          externalUsers={externalUsers}
        />
      }
      {
        isNotEmpty( user ) &&
        <div className="userDetailPage_gridWrapper">
          <div className="userDetailsInformationPanel">
            {
              isAdmin( currentUser ) &&
              <PageCreateButton placement="left" >
                <button className="showAllUsersButton" onClick={ backToUsers }>
                  <InlineSVG type="carretLeft" />
                  <span>All Users</span>
                </button>
              </PageCreateButton>
            }
            <div className="detailsWrapper">
              <UserDetails user={user} />
              <div className="bodySectionItem">
                <label>
                  <span>Username</span>
                </label>
                <span>{ user.username }</span>
              </div>
              <div className="bodySectionItem">
                <label>
                  <span>Role</span>
                </label>
                <span>{ ACLRoleLabelMap[user.acl_role] }</span>
              </div>
              <div className="bodySectionItem">
                <label>
                  <span>Email Address</span>
                </label>
                <span>{ user.email_address || 'N/A' }</span>
              </div>
              <div className="bodySectionItem">
                <label>
                  <span>Given Name</span>
                </label>
                <span>{ user.given_name || 'N/A' }</span>
              </div>
              <div className="bodySectionItem">
                <label>
                  <span>Family Name</span>
                </label>
                <span>{ user.family_name || 'N/A' }</span>
              </div>
            </div>
            <button className="editButton" onClick={ () => setShowEditModal( true ) }>
              Edit User
            </button>
          </div>
          <div className="userDetails__MainPanel__Body">
            <h2 className="userDetails__MainPanel__Header"><InlineSVG type="locked" /> Access</h2>
            <div className={`collapsibleSectionWrapper ${dashboardsCollapsed ? 'collapsed' : ''}`}>
              <div
                className="collapsibleSectionHeader"
                onClick={() => setDashboardsCollapsed( !dashboardsCollapsed )}
              >
                <div className="headerLeft">
                  <InlineSVG type="reporting_nav" />
                  <h3> Dashboards</h3>
                </div>
                <div className="headerRight">
                  <strong className="sectionCount">
                    {/* eslint-disable-next-line max-len */}
                    { formatNumber( isNotEmpty( formattedDashboards ) ? Object.keys( formattedDashboards ).length : 0 ) }
                  </strong>
                  <span className="carretWrapper">
                    <InlineSVG type="carretUp" />
                  </span>
                </div>
              </div>
              <div className="collapsibleSectionBody">
                <div className="accessHeader">
                  <span className="column viewer">Viewer?</span>
                  <span className="column owner">Owner?</span>
                  <span className="column label">Dashboard Name</span>
                </div>
                <ul>
                  { isNotEmpty( formattedDashboards ) && Object.values( formattedDashboards ).map( dashboard => (
                    <AccessItem
                      key={dashboard.id}
                      accessItem={dashboard}
                      accessItemType="dashboard"
                      callback={ handleAccessLevelChange }
                    />
                  ) )}
                </ul>
                {
                  isEmpty( formattedDashboards ) &&
                  <EmptyState message="This user does not have access to any dashboards" />
                }
              </div>
            </div>
            <div className={`collapsibleSectionWrapper ${tagsCollapsed ? 'collapsed' : ''}`}>
              <div
                className="collapsibleSectionHeader"
                onClick={() => setTagsCollapsed( !tagsCollapsed )}
              >
                <div className="headerLeft">
                  <InlineSVG type="host_record" />
                  <h3> Tags</h3>
                </div>
                <div className="headerRight">
                  <strong className="sectionCount">
                    {/* eslint-disable-next-line max-len */}
                    { formatNumber( isNotEmpty( formattedTags ) ? Object.keys( formattedTags ).length : 0 ) }
                  </strong>
                  <span className="carretWrapper">
                    <InlineSVG type="carretUp" />
                  </span>
                </div>
              </div>
              <div className="collapsibleSectionBody">
                <div className="accessHeader">
                  <span className="column viewer">Viewer?</span>
                  <span className="column owner">Owner?</span>
                  <span className="column label">Tag Name</span>
                </div>
                <ul>
                  { isNotEmpty( formattedTags ) && Object.values( formattedTags ).map( tag => (
                    <AccessItem
                      key={tag.id}
                      accessItem={tag}
                      accessItemType="asset_tag"
                      callback={ handleAccessLevelChange }
                    />
                  ) )}
                </ul>
                {
                  isEmpty( formattedTags ) &&
                  <EmptyState message="This user does not have access to any tags" />
                }
              </div>
            </div>
            <div className={`collapsibleSectionWrapper ${remediationPlansCollapsed ? 'collapsed' : ''}`}>
              <div
                className="collapsibleSectionHeader"
                onClick={() => setRemediationPlansCollapsed( !remediationPlansCollapsed )}
              >
                <div className="headerLeft">
                  <InlineSVG type="remediation_nav" />
                  <h3> Remediation Plans</h3>
                </div>
                <div className="headerRight">
                  <strong className="sectionCount">
                    {/* eslint-disable-next-line max-len */}
                    { formatNumber( isNotEmpty( formattedRemediationPlans ) ? Object.keys( formattedRemediationPlans ).length : 0 ) }
                  </strong>
                  <span className="carretWrapper">
                    <InlineSVG type="carretUp" />
                  </span>
                </div>
              </div>
              <div className="collapsibleSectionBody">
                <div className="accessHeader">
                  <span className="column viewer">Viewer?</span>
                  <span className="column owner">Owner?</span>
                  <span className="column label">Plan Name</span>
                </div>
                <ul>
                  { isNotEmpty( formattedRemediationPlans ) && Object.values( formattedRemediationPlans ).map( plan => (
                    <AccessItem
                      key={plan.id}
                      accessItem={plan}
                      accessItemType="remediation_plan"
                      callback={ handleAccessLevelChange }
                    />
                  ) )}
                </ul>
                {
                  isEmpty( formattedRemediationPlans ) &&
                  <EmptyState message="This user does not have access to any remediation plans" />
                }
              </div>
            </div>
            {
              isAdmin( currentUser ) &&
              <div className="accessActions">
                <button
                  onClick={ handleAccessSave }
                  className="submitButton"
                >
                  Save Changes
                </button>
              </div>
            }
          </div>
        </div>
      }
    </React.Fragment>
  );
};

export default UserDetail;