/** *************************************************************
* Copyright (C) 2016-2022 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { HelpContext } from '../../Contexts/Help';
import InlineSVG from '../../shared/InlineSVG';
import Loading from '../../shared/Loading';
import { v4 as uuidv4 } from 'uuid';
import { Remarkable } from 'remarkable';
import {
  decodeURLHash,
  isNotEmpty,
  triggerHashRefresh,
  encodeURLHash,
} from '../../shared/Utilities';

import './style.scss';
import {
  getChangeLogContent,
  getLabelFor,
  getLicensesContent,
  getMainSectionPageKeys,
  getOrderedPagesForSection,
  getOrderedSectionsForMain,
  mainOrderAndLabels,
  pageOrderAndLabels,
  productInformationPages,
} from '../../../../../../../documentation/src/javascript/react/App/Shared';

const ProductInformationPageContent = ( { content, pageKey, md } ) => {

  const [ allContent, setAllContent ] = React.useState( null );

  React.useEffect( () => {
    if ( isNotEmpty( content ) && isNotEmpty( pageKey ) ) {
      if ( pageKey === 'changelogs' || pageKey === 'licenses' ) {
        const _content = [];

        Object.values( content ).map( c => _content.push( c.content ) );
        setAllContent( _content.reverse() );
      } else if ( pageKey === 'eula' ) {
        // eslint-disable-next-line max-len
        setAllContent( [ `<pre><strong>This software is protected by U.S. Pat. No. 12,015,631. • Confidential and Proprietary</strong><br><strong>Copyright © 2024 DeepSurface Security, Inc. All Rights Reserved.</strong><br><strong>DeepSurface is a registered trademark of DeepSurface Security, Inc.</strong><br><br>${content.content}</pre>` ] );
      }
    }
  }, [ content, pageKey ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( allContent ) &&
        allContent.map( ( content, i ) => {
          return <div
            key={ `${i}_${uuidv4()}`}
            className={ `markdownContent pageContent ${pageKey}`}
            dangerouslySetInnerHTML={
              { __html: md.render( content ) }
            }
          />;
        } )
      }
    </React.Fragment>
  );
};

const HelpDocumentation = ( ) => {
  /* eslint-disable camelcase */

  /* eslint-disable camelcase */
  const [ helpContent, setHelpContent ] = React.useState( null );
  const [ mainKey, setMainKey ] = React.useState( 'user_guide' );
  const [ sectionKey, setSectionKey ] = React.useState( 'reporting' );
  const [ pageKey, setPageKey ] = React.useState( 'user_guide_main_index' );
  const [ previousPage, setPreviousPage ] = React.useState( null );
  const [ nextPage, setNextPage ] = React.useState( null );

  const helpContentContainer = React.useRef( null );

  const [
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    fetchHelpDocumentation,
  ] = React.useContext( HelpContext );

  const [ loading, setLoading ] = React.useState( false );

  const md = new Remarkable( {
    html: true,
  } );

  const setupHelpContent = async () => {
    setLoading( true );
    let _help_page, _previousPage, _nextPage, _content, _mainKey, _sectionKey, _pageKey;
    const hash = decodeURLHash();

    if ( isNotEmpty( hash.help_page ) ) {
      _help_page = hash.help_page;
    } else {
      _help_page = 'documentation_index';
      encodeURLHash( { help_page: _help_page } );
    }

    // need to do something completely different for the dynamically generated and built product info pages
    if ( productInformationPages.includes( _help_page ) ) {
      _mainKey = 'product_information';
      _sectionKey = null;
      _pageKey = _help_page;
      if ( _help_page === 'changelogs' ) {
        _content = await getChangeLogContent();
      }
      if ( _help_page === 'licenses' ) {
        _content = await getLicensesContent();
      }
      if ( _help_page === 'eula' ) {
        _content = await fetchHelpDocumentation( _help_page );
      }
    // regular page
    } else {
      _content = await fetchHelpDocumentation( _help_page );
      const _keys = getMainSectionPageKeys( 'page', _help_page );

      ( { _mainKey, _sectionKey, _pageKey } = _keys );
    }
    const pageIndex = pageOrderAndLabels.findIndex( p => p.pageKey === _help_page );

    if ( pageIndex > 0 ) {
      _previousPage = pageOrderAndLabels[pageIndex - 1];
    }
    if ( pageIndex < pageOrderAndLabels.length ) {
      _nextPage = pageOrderAndLabels[pageIndex + 1];
    }

    setMainKey( _mainKey );
    setSectionKey( _sectionKey );
    setPageKey( _pageKey );
    setPreviousPage( _previousPage );
    setNextPage( _nextPage );
    setHelpContent( _content );
    setLoading( false );
  };

  const handleHashChange = () => {
    setupHelpContent();
  };

  React.useEffect( ( ) => {
    setupHelpContent();

    const hash = decodeURLHash();

    if ( isNotEmpty( hash.help_page ) ) {
      const page = pageOrderAndLabels.find( p => p.pageKey === hash.help_page );
      const _mainKey = page?.mainKey;
      const _sectionKey = page?.sectionKey;
      const _pageKey = hash.help_page;

      setMainKey( _mainKey );
      setSectionKey( _sectionKey );
      setPageKey( _pageKey );
    }

    window.addEventListener( 'hashchange', handleHashChange );
    return () => {
      window.removeEventListener( 'hashchange', handleHashChange );
    };
  }, [ helpContentContainer ] );

  const handleMenuClick = ( type, key, fromBreadcrumb=false ) => {
    const _keys = getMainSectionPageKeys( type, key );

    if ( isNotEmpty( helpContentContainer ) && isNotEmpty( helpContentContainer.current ) ) {
      helpContentContainer.current.scrollTop = 0;
    }

    const { _mainKey, _sectionKey, _pageKey } = _keys;

    // if the we are clicking on a section, and it is already open, close it and select the index of the main
    if ( type === 'section' && _sectionKey === sectionKey && !fromBreadcrumb ) {
      setMainKey( _mainKey );
      setSectionKey( null );
      setPageKey( `${_mainKey}_main_index` );
      encodeURLHash( { help_page: `${_mainKey}_main_index` } );
    // if the we are clicking on a main, and it is already open, close it and select the documentation index instead
    } else if ( type === 'main' && _mainKey === mainKey && !fromBreadcrumb ) {
      setMainKey( null );
      setSectionKey( null );
      setPageKey( 'documentation_index' );
      encodeURLHash( { help_page: 'documentation_index' } );
    // everything else
    } else if ( type === 'main' && key === 'product_information' ) {
      // set the selected keys
      setMainKey( 'product_information' );
      setSectionKey( null );
      setPageKey( 'changelogs' );
      // add to hash and trigger the fetch of the content
      encodeURLHash( { help_page: 'changelogs' } );
    } else {
      // set the selected keys
      setMainKey( _mainKey );
      setSectionKey( _sectionKey );
      setPageKey( _pageKey );
      // add to hash and trigger the fetch of the content
      encodeURLHash( { help_page: _pageKey } );
    }
    triggerHashRefresh();
  };

  return (
    <div className="helpDocumentationContainer">
      <div className="helpMenu" id="helpMenu" >
        <ul>
          {
            mainOrderAndLabels.map( _main => {
              if ( _main.mainKey !== 'product_information' ) {
                return <li
                  // eslint-disable-next-line max-len
                  className={ `menuMain ${_main.mainKey} collapsibleSectionWrapper ${ mainKey !== _main.mainKey ? 'collapsed' : ''}` }
                  key={ `${_main.mainKey}_${uuidv4()}`}
                >
                  <div
                    className="menuMainLabel collapsibleSectionHeader"
                    onClick={ () => handleMenuClick( 'main', _main.mainKey ) }
                  >
                    <div className="headerLeft">
                      <h3>{ _main.label }</h3>
                    </div>
                    <div className="headerRight">
                      <strong className="sectionCount">
                        { getOrderedSectionsForMain( _main.mainKey )?.length }
                      </strong>
                      <span className="carretWrapper">
                        <InlineSVG type="carretUp"/>
                      </span>
                    </div>
                  </div>
                  <div className="collapsibleSectionBody">
                    <ul>
                      {
                        getOrderedSectionsForMain( _main.mainKey )?.map( _section => {
                          return <li
                            // eslint-disable-next-line max-len
                            className={ `menuSection ${_section.sectionKey} collapsibleSectionWrapper ${ sectionKey !== _section.sectionKey ? 'collapsed' : ''}` }
                            key={ `${_section.sectionKey}_${uuidv4()}`}
                          >
                            <div
                              className="menuSectionLabel collapsibleSectionHeader"
                              onClick={ () => handleMenuClick( 'section', _section.sectionKey ) }
                            >
                              <div className="headerLeft">
                                { _section.label }
                              </div>
                              <div className="headerRight">
                                <strong className="sectionCount">
                                  { getOrderedPagesForSection( _main.mainKey, _section.sectionKey )?.length - 1 }
                                </strong>
                                <span className="carretWrapper">
                                  <InlineSVG type="carretUp"/>
                                </span>
                              </div>
                            </div>
                            <div className="collapsibleSectionBody">
                              <ul>
                                {
                                  // eslint-disable-next-line max-len
                                  getOrderedPagesForSection( _main.mainKey, _section.sectionKey )?.map( _page => {
                                    if (
                                      !_page?.pageKey?.includes( '_section_index' )
                                      && !_page?.pageKey?.includes( '_main_index' )
                                    ) {
                                      return <li
                                        className={ `menuPage ${_page.pageKey}`}
                                        key={ `${_page.pageKey}_${uuidv4()}`}
                                      >
                                        <div
                                          className={ `menuPageLabel ${ pageKey === _page.pageKey ? 'isSelected' : ''}`}
                                          onClick={ () => handleMenuClick( 'page', _page.pageKey ) }
                                        >
                                          { _page.label }
                                        </div>
                                      </li>;
                                    }
                                  } )
                                }
                              </ul>
                            </div>
                          </li>;
                        } )
                      }
                    </ul>
                  </div>
                </li>;
              }
              return <React.Fragment key={uuidv4()}>
              </React.Fragment>;
            } )
          }
          {/* the change logs are dynamic, so need to do something a bit different for how they are shown */}
          <li
            // eslint-disable-next-line max-len
            className={ `menuMain product_information collapsibleSectionWrapper ${ mainKey !== 'product_information' ? 'collapsed' : ''}` }
            key={ 'product_information' }
          >
            <div
              className="menuMainLabel collapsibleSectionHeader"
              onClick={ () => handleMenuClick( 'main', 'product_information' ) }
            >
              <div className="headerLeft">
                <h3>Product Information</h3>
              </div>
              <div className="headerRight">
                <strong className="sectionCount">3</strong>
                <span className="carretWrapper">
                  <InlineSVG type="carretUp"/>
                </span>
              </div>
            </div>
            <div className="collapsibleSectionBody">
              <ul>
                <li className="menuPage changelogs" >
                  <div
                    className={ `menuPageLabel ${ pageKey === 'changelogs' ? 'isSelected' : ''}`}
                    onClick={ () => handleMenuClick( 'page', 'changelogs' ) }
                  >
                    Changelogs
                  </div>
                </li>
                <li className="menuPage licenses" >
                  <div
                    className={ `menuPageLabel ${ pageKey === 'licenses' ? 'isSelected' : ''}`}
                    onClick={ () => handleMenuClick( 'page', 'licenses' ) }
                  >
                    Open Source Licenses
                  </div>
                </li>
                <li className="menuPage eula" >
                  <div
                    className={ `menuPageLabel ${ pageKey === 'eula' ? 'isSelected' : ''}`}
                    onClick={ () => handleMenuClick( 'page', 'eula' ) }
                  >
                    End User License Agreement (EULA)
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <div className="helpDocumentationWrapper" id="helpDocumentationWrapper" >
        { loading && <Loading /> }
        {
          ( isNotEmpty( helpContent ) ) &&
          <React.Fragment>
            {
              ( isNotEmpty( pageKey ) && pageKey !== 'documentation_index' ) &&
              <div className="helpDocumentationHeader">
                <div className="breadcrumbsContainer">
                  <div
                    className="breadcrumbLink"
                    onClick={ () => handleMenuClick( 'main', mainKey, true ) }
                  >
                    { getLabelFor( mainKey, 'main' ) }
                  </div>
                  <span>/</span>
                  {
                    isNotEmpty( sectionKey ) &&
                    <React.Fragment>
                      <div
                        className="breadcrumbLink"
                        onClick={ () => handleMenuClick( 'section', sectionKey, true ) }
                      >
                        { getLabelFor( sectionKey, 'section' ) }
                      </div>
                      <span>/</span>
                    </React.Fragment>
                  }
                  <div className="breadcrumbLink current">
                    { getLabelFor( pageKey, 'page' ) }
                  </div>
                </div>
                <h1 className="pageHeader">{ getLabelFor( pageKey, 'page' ) }</h1>
              </div>
            }
            <div
              // eslint-disable-next-line max-len
              className={ `helpContentWrapper main--${mainKey} section--${sectionKey} page--${pageKey}` }
              ref={helpContentContainer}
            >
              {
                isNotEmpty( helpContent ) &&
                <React.Fragment>
                  {
                    ( isNotEmpty( pageKey ) && productInformationPages.includes( pageKey ) )
                      ? <ProductInformationPageContent content={helpContent} pageKey={pageKey} md={md} />
                      : <div
                        className="markdownContent pageContent"
                        dangerouslySetInnerHTML={
                          { __html: md.render( helpContent.content ) }
                        }
                      />
                  }
                </React.Fragment>
              }
            </div>
            <div className="helpDocumentationFooter">
              {
                isNotEmpty( previousPage )
                  ? <button
                    onClick={ () => handleMenuClick( 'page', previousPage.pageKey ) }
                    className="helpPaginationButton previous"
                  >
                    <InlineSVG type="carretLeft" />
                    {
                      isNotEmpty( previousPage.sectionKey )
                        ? <span>{ getLabelFor( previousPage.sectionKey, 'section' ) }: </span>
                        : <React.Fragment>
                          {
                            isNotEmpty( previousPage.mainKey )
                              ? <span>{ getLabelFor( previousPage.mainKey, 'main' ) }: </span>
                              : <span>Documentation: </span>
                          }
                        </React.Fragment>
                    }
                    <strong>{ getLabelFor( previousPage.pageKey, 'page' ) }</strong>
                  </button>
                  : <button className="helpPaginationButton next disabled">
                    <InlineSVG type="carretLeft" />
                    <strong>First Page</strong>
                  </button>
              }
              {
                isNotEmpty( nextPage )
                  ? <button
                    onClick={ () => handleMenuClick( 'page', nextPage.pageKey ) }
                    className="helpPaginationButton next"
                  >
                    {
                      isNotEmpty( nextPage.sectionKey )
                        ? <span>{ getLabelFor( nextPage.sectionKey, 'section' ) }: </span>
                        : <span>{ getLabelFor( nextPage.mainKey, 'main' ) }: </span>
                    }
                    <strong>{ getLabelFor( nextPage.pageKey, 'page' ) }</strong>
                    <InlineSVG type="carretRight" />
                  </button>
                  : <button disabled className="helpPaginationButton next disabled">
                    <strong>Last Page</strong>
                    <InlineSVG type="carretRight" />
                  </button>
              }
            </div>
          </React.Fragment>
        }
      </div>
    </div>
  );
};

export default HelpDocumentation;