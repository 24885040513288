/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import Area from '../../../../../shared/Charts/Area';
import { debounce, formatUnixDate, globalColors, isNotEmpty } from '../../../../../shared/Utilities';

import { v4 as uuidv4 } from 'uuid';

import './PeerPercentileOverTime.scss';

import YAxisLines from '../../../../../shared/Charts/AxisLines/YAxisLines';
import YAxisLabels from '../../../../../shared/Charts/AxisLabels/YAxisLabels';
import XAxisLines from '../../../../../shared/Charts/AxisLines/XAxisLines';
import XAxisLabels from '../../../../../shared/Charts/AxisLabels/XAxisLabels';
import EmptyState from '../../../../../shared/EmptyState';

const PeerPercentileOverTime = ( {
  item,
  data,
  settings,
  adjustSVGAspectRatio,
  svgAspectRatio,
  svgContainerRef,
} ) => {

  const MAX_POINTS = 80;

  const [ chartData, setChartData ] = React.useState( null );
  const [ xAxisLabels, setXAxisLabels ] = React.useState( null );

  const [ svgContainerHeight, setSVGContainerHeight ] = React.useState( 400 );
  const svgContainerWidth = 1_000;

  const setupData = async ( data ) => {

    const formatData = overTimeData => {
      // the highest percentile (helps draw the graph)
      const max = 1;

      // if there are too many points, they will not fit or look good in a graph, this will reduce the amount down
      // to the MAX_POINTS allowed
      const maxGroupSize = Math.ceil( overTimeData.results.length / MAX_POINTS );

      // this reduces all the points down into an array of arrays
      const groupedPoints = overTimeData.results.reduce( ( allGroupedPoints, point, index ) => {

        const groupIndex = Math.floor( index / maxGroupSize );

        if( !allGroupedPoints[groupIndex] ) {
          allGroupedPoints[groupIndex] = [];
        }

        allGroupedPoints[groupIndex].push( point );

        return allGroupedPoints;
      }, [] );

      // this is the formatted points available for the chart that will be filled up by the below map
      const _data = {};
      let firstTimestamp;
      let lastTimeStamp;

      // map over our groups to format the data in just the right way for the chart to interpret
      groupedPoints.map( ( group, groupIndex ) => {

        let highestPercentile = 0;
        let targetPoint = null;

        group.map( point => {
          if ( point.percentile >= highestPercentile ) {
            highestPercentile = point.percentile;
            targetPoint = point;
          }
        } );

        group.map( ( point, pointIndex ) => {
          // the first point of the first group
          if ( groupIndex === 0 && pointIndex === 0 ) {
            firstTimestamp = point.date;
            _data[point.date + 0.000001] = {
              percentile: point.percentile,
              risk: point.risk,
              originalIndex: groupIndex,
              timestamp: point.date + 0.000001,
              totalPoints: groupedPoints.length + 2,
              original: [ point ],
              id: `${point.date}_${point.risk}_${uuidv4()}`,
            };
          // last point of the last group
          } else if ( groupIndex === groupedPoints.length - 1 && pointIndex === group.length - 1 ) {
            lastTimeStamp = point.date;
            _data[point.date + 0.000001] = {
              percentile: point.percentile,
              risk: point.risk,
              originalIndex: groupIndex + 1,
              timestamp: point.date + 0.000001,
              totalPoints: groupedPoints.length + 2,
              original: [ point ],
              id: `${point.date}_${point.risk}_${uuidv4()}`,
            };
          // regular group
          } else {
            _data[targetPoint.date + 0.000001] = {
              percentile: targetPoint.percentile,
              risk: targetPoint.risk,
              originalIndex: groupIndex + 1,
              timestamp: targetPoint.date + 0.000001,
              totalPoints: groupedPoints.length + 2,
              original: group,
              id: `${point.date}_${point.risk}_${uuidv4()}`,
            };
          }
        } );

      } );

      if ( isNotEmpty( firstTimestamp ) && isNotEmpty( lastTimeStamp ) ) {
        const xlabels = [];

        const timestampDelta = lastTimeStamp - firstTimestamp;

        xlabels.push( formatUnixDate( firstTimestamp ) );
        xlabels.push( formatUnixDate( firstTimestamp + ( timestampDelta * ( 2 / 6 ) ) ) );
        xlabels.push( formatUnixDate( firstTimestamp + ( timestampDelta * ( 3 / 6 ) ) ) );
        xlabels.push( formatUnixDate( firstTimestamp + ( timestampDelta * ( 4 / 6 ) ) ) );
        xlabels.push( formatUnixDate( firstTimestamp + ( timestampDelta * ( 5 / 6 ) ) ) );
        xlabels.push( formatUnixDate( lastTimeStamp ) );

        setXAxisLabels( xlabels );
      }

      if (
        isNotEmpty( overTimeData.results )
        && isNotEmpty( _data )
      ) {
        const _chartData = {
          original: overTimeData.results,
          transformed: _data,
          max,
          yAxis: 'percentile',
          xAxis: 'timestamp',
        };

        if ( isNotEmpty( overTimeData.results[ overTimeData.results.length - 1 ] ) ) {
          _chartData.latestPoint = overTimeData.results[ overTimeData.results.length - 1 ];
          _chartData.currentPercentile = overTimeData.results[ overTimeData.results.length - 1 ].percentile || 0;
        }
        setChartData( _chartData );
      } else {
        setChartData( null );
      }
    };

    if ( isNotEmpty( data?.results ) ) {
      // IMPORTANT! Keep this log -DMC 2023-06-26
      console.log( 'Percentile Results: ', data.results );
      formatData( data );
    }
  };

  React.useEffect( ( ) => {
    if ( isNotEmpty( data ) ) {
      setupData( data );
    }
  }, [ data, item, settings ] );

  // sets up resize aspect ratio event listener
  React.useEffect( ( ) => {
    if ( isNotEmpty( svgContainerRef ) && isNotEmpty( svgContainerRef.current ) ) {
      adjustSVGAspectRatio();
      window.addEventListener( 'resize', debounce( () => {
        adjustSVGAspectRatio();
      }, 100 ) );
      return () => window.removeEventListener( 'resize', debounce );
    }
  }, [ svgContainerRef, settings, chartData ] );

  React.useEffect( () => {
    if ( isNotEmpty( svgAspectRatio ) && svgAspectRatio !== 0 ) {
      setSVGContainerHeight( svgContainerWidth / svgAspectRatio );
    } else {
      setSVGContainerHeight( 400 );
    }
  }, [ svgAspectRatio ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( chartData )
          ? <div className="chartPlusAxis" ref={svgContainerRef} >
            <YAxisLabels yMax={ 100 } percentageTics />
            <YAxisLines lineCount={ 4 } />
            {
              isNotEmpty( xAxisLabels ) &&
              <XAxisLines lineCount={xAxisLabels.length - 1} variant="area" />
            }
            {
              ( isNotEmpty( svgContainerHeight ) && isNotEmpty( svgContainerWidth ) ) &&
              <svg
                viewBox={ `0 0 ${svgContainerWidth} ${svgContainerHeight}` }
                xmlns="http://www.w3.org/2000/svg"
                className="areaPlusBarWrapper"
                id="areaPlusBarWrapper"
                preserveAspectRatio="none"
              >
                <Area
                  data={ chartData }
                  stroke="darkBlue"
                  fill="darkBlue"
                  containerHeight={ svgContainerHeight }
                  containerWidth={ svgContainerWidth }
                  withoutWrapper
                  fullHeight
                  noXAxis
                  adjustYMin
                />
              </svg>
            }
            {
              ( settings?.include_legend ) &&
              <Legend legendData={ {
                percentile: {
                  fill: globalColors['darkBlue'],
                  stroke: globalColors['darkBlue'],
                  label: 'Peer Percentile',
                },
              } } />
            }
          </div>
          : <EmptyState message="No peer information available" />
      }
      {
        isNotEmpty( xAxisLabels ) &&
        <XAxisLabels
          labels={xAxisLabels}
          variant="area"
          elementClass={ `${ settings?.include_legend ? '' : 'fullWidth'}`}
        />
      }
    </React.Fragment>
  );
};

export default PeerPercentileOverTime;