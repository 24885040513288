/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { getDimensionsAndOffset, isNotEmpty } from '../../../../shared/Utilities';
import Loading from '../../../../shared/Loading';
import { tallyTypeWidgets, UneditableWidgets, v2WidgetComponentMap, v2WidgetsWithLegends } from '../shared';
import { FullScreenVisualContext } from '../../../../Contexts/FullScreenVisual';
import InlineSVG from '../../../../shared/InlineSVG';
import WidgetDescription from './v2/WidgetDescription';

import './WidgetWrapper.scss';
import WidgetHeader from './v2/WidgetHeader';
import { TagsContext } from '../../../../Contexts/Tags';
import EmptyState from '../../../../shared/EmptyState';

const WidgetWrapperV2 = ( {
  widget,
  layoutWidgets,
  removeWidget,
  editMode,
  getDataForWidget,
  setCurrentWidget,
  setShowWidgetEditor,
  setSelectedWidgetVariant,
  printing=false,
} ) => {
  const [ , , showVisual, , showFullScreenVisual, , , ] = React.useContext( FullScreenVisualContext );
  const [ tags ] = React.useContext( TagsContext );

  // used to calculate the aspectRatio
  const svgContainerRef = React.useRef( null );
  const widgetContainerRef = React.useRef( null );
  const [ svgAspectRatio, setSVGAspectRatio ] = React.useState( null );

  const Content = v2WidgetComponentMap[widget.key];

  const [ widgetLoading, setWidgetLoading ] = React.useState( false );
  const [ widgetData, setWidgetData ] = React.useState( null );
  const [ layoutWidget, setLayoutWidget ] = React.useState( null );

  const setupWidget = async( widget ) => {
    if ( isNotEmpty( widget ) ) {
      setWidgetLoading( true );
      let _data = await getDataForWidget( widget, tags );
      // need to slightly reformat the data for the tally widgets so that they widget can read it, this changed
      // after the fe/ endpoints were updated
      if ( tallyTypeWidgets.includes( widget.key ) ) {
        _data = { results: { ..._data } };
      }
      setWidgetLoading( false );
      setWidgetData( _data );
    }
  };

  React.useEffect( () => {
    if ( isNotEmpty( widget ) ) {
      setupWidget( widget );

      if ( isNotEmpty( layoutWidgets ) ) {
        setLayoutWidget( layoutWidgets[widget.i] );
      }
    }
  }, [ widget, tags ] );

  const adjustSVGAspectRatio = () => {
    const widgetContainerDimensions = getDimensionsAndOffset( widgetContainerRef?.current );
    const svgContainerDimensions = getDimensionsAndOffset( svgContainerRef?.current );
    if ( isNotEmpty( widgetContainerDimensions ) && isNotEmpty( svgContainerDimensions ) ) {
      // const widthDifference = widgetContainerDimensions.width - svgContainerDimensions.width;

      const additionalHeight = widget?.settings?.include_description ? ( 16 * 8 ) : ( 16 * 4 );
      const adjustedHeight = widgetContainerDimensions.height - additionalHeight;
      const aspectRatio = svgContainerDimensions.width / adjustedHeight;
      // console.log( aspectRatio );
      setSVGAspectRatio( aspectRatio );
    }
  };

  React.useEffect( () => {
    adjustSVGAspectRatio();
  }, [ layoutWidgets ] );

  React.useEffect( () => {
    if ( printing === true ) {
      adjustSVGAspectRatio();
    } else {
      setTimeout( () => {
        adjustSVGAspectRatio();
      }, 150 );
    }
  }, [ printing ] );

  // any specific classes for widgets depending on settings
  const settingsClass = () => {
    const classes = [];
    if ( isNotEmpty( widget ) && isNotEmpty( widget.settings ) ) {
      if ( widget.settings.include_description ) {
        classes.push( 'withDescription' );
      }
      classes.push( widget.settings.version );
      if ( isNotEmpty( widget.settings.comparison_version ) ) {
        classes.push( widget.settings.comparison_version );
      }
    }
    return classes.join( ' ' );
  };

  const handleWidgetEditClick = widget => {
    setCurrentWidget( widget );
    setShowWidgetEditor( true );
    setSelectedWidgetVariant( widget.key );
  };

  return (
    <React.Fragment>
      { widgetLoading && <Loading /> }
      {
        ( isNotEmpty( Content ) && isNotEmpty( widget ) && isNotEmpty( widget.key ) )
          ? <div
            // eslint-disable-next-line
            className={ `${ ( v2WidgetsWithLegends.includes( widget.key ) || widget?.settings?.version === 'barchart' ) ? 'withLegend' : '' }  widgetHeight-${layoutWidget?.h} widgetWidth-${layoutWidget?.w} ${editMode ? 'editMode' : ''} dashboardWidgetWrapper ${widget.key} ${settingsClass()}` }
            id={ `dashboardWidget-${widget.key}-${widget.i}` }
            ref={widgetContainerRef}
          >
            <WidgetHeader
              widget={ widget }
              data={ widgetData }
              settings={ widget.settings }
            />
            <div className="widgetContent">
              {
                (
                  isNotEmpty( widget )
                  && isNotEmpty( widget.settings )
                  && widget.settings?.include_description
                ) &&
                <WidgetDescription
                  item={ widget }
                  data={ widgetData }
                  settings={ widget.settings }
                />
              }
              {
                <Content
                  item={ widget }
                  settings={ widget.settings }
                  printing={printing}
                  data={ widgetData }
                  adjustSVGAspectRatio={ adjustSVGAspectRatio }
                  svgAspectRatio={svgAspectRatio}
                  svgContainerRef={svgContainerRef}
                />
              }
              <button
                className="fullScreenVisualToggleButton"
                onClick={ () => showFullScreenVisual(
                  <React.Fragment>
                    <WidgetHeader
                      widget={ widget }
                      data={ widgetData }
                    />
                    {
                      (
                        isNotEmpty( widget )
                        && isNotEmpty( widget.settings )
                        && widget.settings?.include_description
                      ) &&
                      <WidgetDescription
                        item={ widget }
                        data={ widgetData }
                        settings={ widget.settings }
                      />
                    }
                    <Content
                      item={ widget }
                      settings={ widget.settings }
                      printing={printing}
                      data={ widgetData }
                    />
                  </React.Fragment>,
                  // eslint-disable-next-line max-len
                  `${ ( v2WidgetsWithLegends.includes( widget.key ) || widget?.settings?.version === 'barchart' ) ? 'withLegend' : '' } ${widget.key} ${settingsClass()} dashboardWidget ${widget?.key} ${widget?.settings?.version}`,
                ) }
              >
                { showVisual ? <InlineSVG type="exitFullscreen" /> : <InlineSVG type="fullscreen" /> }
              </button>
            </div>
            {
              editMode &&
              <div className="editModeFooter">
                <span
                  className="editModeButton draggable"
                >
                  <InlineSVG elementClass="draggableIcon" type="draggable"/>
                </span>
                {
                  isNotEmpty( !UneditableWidgets.includes( widget.key ) ) &&
                  <button
                    className="editModeButton edit"
                    onClick={ () => handleWidgetEditClick( widget ) }
                  >
                    <InlineSVG type="edit" />
                  </button>
                }
                <button
                  className="editModeButton delete"
                  onClick={ () => removeWidget( widget ) }
                >
                  <InlineSVG type="delete" version="status--red" />
                </button>
              </div>
            }
          </div>
          : <EmptyState message="No widget found" />
      }
    </React.Fragment>
  );
};

export default WidgetWrapperV2;