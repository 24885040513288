/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';

import './SpecificRemediationPlan.scss';
import {
  globalColors,
  isNotEmpty,
  pluralizeType,
  recordSorter,
  riskToRating,
  userDisplayName,
} from '../../../../../shared/Utilities';
import { makeRequest } from '../../../../../../legacy/io';
import { fetchAndFormatTaskRecordData } from '../../../../Remediation/RemediationPlans/RemediationPlanItem';
import Legend from '../../../../../shared/Charts/Legend';
import RatingBadge from '../../../../../shared/RatingBadge';
import { percentComplete } from '../../../../Remediation/RemediationPlans/Shared';
import InlineSVG from '../../../../../shared/InlineSVG';
import EmptyState from '../../../../../shared/EmptyState';

const SpecificRemediationPlan = ( { settings, data } ) => {

  const [ formattedTasks, setFormattedTasks ] = React.useState( null );
  const [ formattedUsers, setFormattedUsers ] = React.useState( null );
  const [ formattedPlan, setFormattedPlan ] = React.useState( null );

  const taskAction = task => {
    let _text = '';
    if ( task.original ) {
      if ( task.original.type === 'host_patches' ) {
        _text = <span>Apply <strong>{task.original.num_items} {task.original.type.split( '_' )[1]}</strong></span>;
      }
      if ( task.original.type === 'patch_hosts' ) {
        _text = <span>Apply to <strong>{task.original.num_items} {task.original.type.split( '_' )[1]}</strong></span>;
      }
      if ( task.original.type === 'host_vulnerabilities' ) {
        _text = <span>Fix <strong>{task.original.num_items} {task.original.type.split( '_' )[1]}</strong></span>;
      }
      if ( task.original.type === 'vulnerability_hosts' ) {
        _text = <span>Fix on <strong>{task.original.num_items} {task.original.type.split( '_' )[1]}</strong></span>;
      }
    }

    return _text;
  };
  // eslint-disable-next-line max-len
  const taskIcon = task => <InlineSVG type={ `${pluralizeType( task.normalizedType )}Alt` } elementClass="taskIcon" />;

  const legendData = {
    risk: {
      label: 'Risk Reduction',
      key: 'risk',
      fill: globalColors['filter--teal'],
    },
    instances: {
      label: 'Instances Reduction',
      key: 'instances',
      fill: globalColors['filter--blue'],
    },
  };

  const setupData = async ( plan, users ) => {

    setFormattedPlan( plan );

    const _users = {};

    if ( isNotEmpty( users ) ) {
      users.map( u => {
        _users[u.id] = u;
      } );
    }
    let _tasks;
    const taskFetch = await makeRequest( 'FETCH', '/project/default/model/base/remediation_task', {
      // eslint-disable-next-line camelcase
      remediation_plan_id: plan.id,
    } );

    if ( isNotEmpty( taskFetch ) && isNotEmpty( taskFetch.results ) ) {
      _tasks = taskFetch.results;
    } else {
      _tasks = [];
    }

    if ( isNotEmpty( _tasks ) ) {
      const _formatted = await fetchAndFormatTaskRecordData( _tasks, _users );
      _formatted.sort( ( a, b ) => recordSorter( 'risk', false, a, b ) );
      setFormattedTasks( _formatted );
    }
    setFormattedUsers( _users );
  };

  React.useEffect( () => {
    if ( isNotEmpty( data ) && isNotEmpty( settings ) && 'plan_id' in settings ) {
      if (
        isNotEmpty( data.plans )
        && isNotEmpty( data.plans.results )
        && isNotEmpty( data.users )
        && isNotEmpty( data.users.results )
      ) {
        const plan = data.plans.results.find( p => p.id === settings.plan_id );

        if ( isNotEmpty( plan ) ) {
          setupData( plan, data.users.results );
        }
      }
    }
  }, [ settings, data ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( formattedPlan ) &&
        <h2><strong>Remediation Plan: </strong>{ formattedPlan.label }</h2>
      }
      {
        ( isNotEmpty( formattedTasks ) && isNotEmpty( formattedUsers ) )
          ? <div className="remediationChartWrapper">
            <span className="taskCount">Tasks <strong>({formattedTasks?.length})</strong></span>
            {
              formattedTasks.map( ( task, index ) => {
                return <div className="remediationTaskWrapper" key={index} >
                  <label>
                    <section>
                      <RatingBadge item={task?.original} rating={ riskToRating( task?.original?.risk ) } />
                      { taskIcon( task ) }
                      <span>{ task.label }:</span>
                      <span className="action">{ taskAction( task ) }</span>

                    </section>
                    <section className="owner">
                      {
                        isNotEmpty( formattedUsers[task.original.owner] )
                          ? userDisplayName( formattedUsers[task.original.owner] )
                          : 'Unassigned'
                      }
                    </section>
                  </label>
                  <div className="labelAndBarWrapper">
                    <span>{ `${ percentComplete( task.original.original_risk, task.original.risk ) }%` }</span>
                    <div className="taskCompletionWrapper risk">
                      <div
                        className="taskCompletionBar"
                        style={{
                          background: globalColors['filter--teal'],
                          width: `${ percentComplete( task.original.original_risk, task.original.risk ) }%`,
                        } }
                      />
                    </div>
                    <span className="categoryLabelWrapper">
                      Risk
                    </span>
                  </div>
                  <div className="labelAndBarWrapper">
                    <span>
                      { `${ percentComplete( task.original.original_num_instances, task.original.num_instances ) }%` }
                    </span>
                    <div className="taskCompletionWrapper instances">
                      <div
                        className="taskCompletionBar"
                        // eslint-disable-next-line max-len
                        style={{ background: globalColors['filter--blue'], width: `${ percentComplete( task.original.original_num_instances, task.original.num_instances ) }%`} }
                      />
                    </div>
                    <span className="categoryLabelWrapper">
                      Instances
                    </span>
                  </div>
                </div>;
              } )
            }
          </div>
          : <EmptyState message="No data available" />
      }
      <Legend horizontal legendData={ legendData } />
    </React.Fragment>
  );
};

export default SpecificRemediationPlan;