/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';

import './VulnerabilityInstancesTotal.scss';
import { formatNumber, isNotEmpty } from '../../../../../shared/Utilities';
import InlineSVG from '../../../../../shared/InlineSVG';
import EmptyState from '../../../../../shared/EmptyState';

const VulnerabilityInstancesTotal = ( { data, settings } ) => {

  const [ total, setTotal ] = React.useState( 0 );

  React.useEffect( () => {
    if (
      isNotEmpty( data )
      && isNotEmpty( data.results )
      && isNotEmpty( data.results._total )
    ) {
      setTotal( data.results._total );
    }
  }, [ data, settings ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( total )
          ? <div className="bigNumberWrapper">
            <h3>Vulnerability Instances</h3>
            <h2>
              <InlineSVG type="vulnerabilitiesAlt" />
              { formatNumber( total ) }
            </h2>
            <span>found in your environment</span>
          </div>
          : <EmptyState message="No data available" />
      }
    </React.Fragment>
  );
};

export default VulnerabilityInstancesTotal;