/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

/* eslint-disable camelcase, max-len */

import React from 'react';

// reporting pages
import Dashboards               from '../Reporting/Dashboards/indexV2.js';
import Exports                  from '../Reporting/Reports/index.js';

import ExploreModel             from '../Explore/Model/index.js';

// remediation pages
import RemediationPlans         from '../Remediation/RemediationPlans/index.js';
import RemediationSettings      from '../Remediation/RemediationSettings/index.js';
import RemediationTicketing     from '../Remediation/RemediationTicketing/index.js';
import AcceptedRiskPlans        from '../Remediation/AcceptedRisk/AcceptedRiskPlans.js';


// risk insight reports
import Hosts                    from '../RiskInsight/Reports/Hosts/index.js';
import Patches                  from '../RiskInsight/Reports/Patches/index.js';
import VulnerabilityAnalysis    from '../RiskInsight/Reports/VulnerabilityAnalysis/index.js';
import VulnerabilityInstances   from '../RiskInsight/Reports/VulnerabilityInstances/index.js';
import Paths                    from '../Explore/Paths/index.js';
import UsersRisk                from '../RiskInsight/Reports/Users/index.js';

// setup pages
import Admin                    from '../Setup/Settings/Admin/index.js';
import SmtpSettings             from '../Setup/Settings/SmtpSettings/index.js';
import Certificates             from '../Setup/Settings/Certificates/index.js';
import SensitiveAssetsPolicies  from '../Setup/Settings/SensitiveAssets/Policies.js';
import SensitiveAssetsManual    from '../Setup/Settings/SensitiveAssets/Manual.js';
import Users                    from '../Setup/Authentication/Users/index.js';
import AuthenticationProviders  from '../Setup/Authentication/AuthenticationProviders/index.js';
import Tags                     from '../Setup/Tags/index.js';
import OutboundProxy            from '../Setup/Settings/OutboundProxy/index.js';
import Hidden                   from '../Setup/Settings/Hidden/index.js';

// scanning pages
import ScanningDashboard        from '../Scanning/Dashboard/index.js';
import Agents                   from '../Scanning/Agents/index.js';
import UserManaged              from '../Scanning/UserManaged/index.js';
import AgentlessSettings        from '../Scanning/AgentlessSettings/index.js';
import ScanCredentials          from '../Scanning/ScanCredentials/index.js';
import ScanGroups               from '../Scanning/ScanGroups/index.js';
import ScanningGeneralSettings  from '../Scanning/GeneralSettings/index.js';
import CloudScanning            from '../Scanning/CloudScanning/index.js';
import NetworkConnectivity      from '../Scanning/NetworkConnectivity/index.js';
import SubordinateScanners      from '../Scanning/SubordinateScanners/index.js';
import VulnerabilityScanners    from '../Scanning/VulnerabilityScanners/index.js';

// activity pages
import Tasks                    from '../Activity/Tasks/index.js';
import ConfigurationAlerts      from '../Activity/ConfigurationAlerts/index.js';
import NotificationSettings     from '../Activity/NotificationSettings/index.js';
import ScanLogs                 from '../Activity/ScanLogs/index.js';

import { isEmpty, logout } from '../../shared/Utilities/index.js';

export const betaPages = {
  scanning: [ 'cloud_scanning' ],
  setup: [ 'integrations' ],
};
export const newPages = {
  scanning: [ 'user_managed' ],
  setup: [ 'tags' ],
};
export const betaRoutes = [
  'remediation',
];
export const newRoutes = [];

export const isBetaRoute = route => {
  return betaRoutes.includes( route );
};
export const isNewRoute = route => {
  return newRoutes.includes( route );
};
export const isBetaPage = ( route, page ) => {
  if ( isEmpty( betaPages[route] ) || isEmpty( route ) || isEmpty( page ) ) {
    return false;
  }
  return betaPages[route].includes( page );
};
export const isNewPage = ( route, page ) => {
  if ( isEmpty( newPages[route] ) || isEmpty( route ) || isEmpty( page ) ) {
    return false;
  }
  return newPages[route].includes( page );
};

export const isCredentialed = [
  'agentless_settings',
  'credentials',
  'scan_groups',
];

export const isScanningSettings = [
  'scanning_general_settings',
  'subordinates',
  'vulnerability_scanners',
  'connectivity_settings',
  'cloud_scanning',
];

export const isSensitiveAssets = [
  'sensitive_assets_policies',
  'sensitive_assets_manual',
];

export const isAuthentication = [
  'providers',
  'users',
];

export const isGeneralSettings = [
  'admin',
  'smtp',
  'certificates',
  'outbound_proxy',
];

export const pagesMap = {

  reporting: {
    label: 'Reporting',
    iconKey: 'reporting_nav',
    slug: 'reporting',
    isCurrent: 'reporting',
    link: '#.=reporting&page=reporting_dashboard',
    items: {
      reporting_dashboard: {
        label: 'Dashboard',
        link: '#.=reporting&page=reporting_dashboard',
        isCurrent: 'reporting_dashboard',
        slug: 'reporting_dashboard',
        component: <Dashboards />,
      },
      exports: {
        label: 'Exports',
        link: '#.=reporting&page=exports',
        isCurrent: 'exports',
        slug: 'exports',
        component: <Exports />,
      },
    },
  },
  risk_insight: {
    label: 'Risk Insight',
    iconKey: 'risk_insight_nav',
    slug: 'risk_insight',
    isCurrent: 'risk_insight',
    link: '#.=risk_insight&report=hosts&item_count=100&order_by=filtered_risk&order_direction=DESC&host_has_sensitive_nodes=null&current_page=1&accepted_risk=false',
    items: {
      hosts: {
        label: 'Hosts',
        link: '#.=risk_insight&report=hosts&item_count=100&order_by=filtered_risk&order_direction=DESC&host_has_sensitive_nodes=null&current_page=1&accepted_risk=false',
        isCurrent: 'hosts',
        slug: 'hosts',
        component: <Hosts />,
      },
      patches: {
        label: 'Patches',
        link: '#.=risk_insight&report=patches&risk_type=cumulative_risk&item_count=100&order_by=filtered_risk&order_direction=DESC&superseded=unsuperseded&current_page=1&accepted_risk=false',
        isCurrent: 'patches',
        slug: 'patches',
        component: <Patches />,
      },
      vulnerabilities: {
        label: 'Vulnerabilities',
        link: '#.=risk_insight&report=vulnerabilities&item_count=100&order_by=filtered_risk&order_direction=DESC&patchable=null&current_page=1&accepted_risk=false',
        isCurrent: 'vulnerabilities',
        slug: 'vulnerabilities',
        component: <VulnerabilityAnalysis />,
      },
      users: {
        label: 'Users',
        link: '#.=risk_insight&report=users&item_count=100&order_by=filtered_risk&order_direction=DESC&current_page=1',
        isCurrent: 'users',
        slug: 'users',
        component: <UsersRisk />,
      },
      instances: {
        label: 'Vulnerability Instances',
        // eslint-disable-next-line max-len
        link: '#.=risk_insight&report=instances&group_type=host&order_by=filtered_risk&order_direction=DESC&item_count=100&current_page=1&accepted_risk=false',
        isCurrent: 'instances',
        slug: 'instances',
        component: <VulnerabilityInstances />,
      },
    },
  },
  remediation: {
    label: 'Remediation',
    iconKey: 'remediation_nav',
    slug: 'remediation',
    isCurrent: 'remediation',
    link: '#.=remediation&page=remediation_plans',
    items: {
      remediation_plans: {
        label: 'Plans',
        isCurrent: 'remediation_plans',
        slug: 'remediation_plans',
        link: '#.=remediation&page=remediation_plans',
        component: <RemediationPlans />,
      },
      accepted_risk_plans: {
        label: 'Accepted Risk',
        isCurrent: 'accepted_risk_plans',
        slug: 'accepted_risk_plans',
        link: '#.=remediation&page=accepted_risk_plans',
        // borderClass: 'bottom',
        component: <AcceptedRiskPlans />,
      },
      remediation_settings: {
        label: 'Remediation Settings',
        isCurrent: 'remediation_settings',
        slug: 'remediation_settings',
        link: '#.=remediation&page=remediation_settings',
        // borderClass: 'bottom',
        component: <RemediationSettings />,
      },
      remediation_ticketing: {
        label: 'Remediation Integrations',
        isCurrent: 'remediation_ticketing',
        slug: 'remediation_ticketing',
        link: '#.=remediation&page=remediation_ticketing',
        // borderClass: 'bottom',
        component: <RemediationTicketing />,
      },
    },
  },
  explore: {
    label: 'Explore',
    iconKey: 'explore_nav',
    slug: 'explore',
    isCurrent: 'explore',
    link: '#.=explore&page=explore_model&type=scope',
    items: {
      explore_model: {
        label: 'Model',
        isCurrent: 'explore_model',
        slug: 'explore_model',
        link: '#.=explore&page=explore_model&type=scope',
        component: <ExploreModel />,
      },
      paths: {
        label: 'Paths',
        link: '#.=explore&page=explore_paths&item_count=100&current_page=1',
        isCurrent: 'explore_paths',
        slug: 'explore_paths',
        component: <Paths />,
      },
    },
  },
  activity: {
    label: 'Activity',
    iconKey: 'activity_nav',
    slug: 'activity',
    isCurrent: 'activity',
    link: '#.=activity&page=tasks',
    items: {
      tasks: {
        label: 'Tasks',
        isCurrent: 'tasks',
        slug: 'tasks',
        link: '#.=activity&page=tasks',
        component: <Tasks />,
      },
      configuration_alerts: {
        label: 'Config. Alerts',
        isCurrent: 'configuration_alerts',
        slug: 'configuration_alerts',
        link: '#.=activity&page=configuration_alerts&item_count=100&current_page=1',
        component: <ConfigurationAlerts />,
      },
      scan_logs: {
        label: 'Scan Logs',
        isCurrent: 'scan_logs',
        slug: 'scan_logs',
        link: '#.=activity&page=scan_logs&item_count=100&current_page=1',
        // borderClass: 'bottom',
        component: <ScanLogs />,
      },
      activity_settings_notification: {
        label: 'Notification Settings',
        link: '#.=activity&page=notification_settings',
        isCurrent: 'notification_settings',
        slug: 'notification_settings',
        component: <NotificationSettings />,
      },
    },
  },
  scanning: {
    label: 'Scanning',
    iconKey: 'scanning_nav',
    slug: 'scanning',
    isCurrent: 'scanning',
    // eslint-disable-next-line max-len
    link: '#.=scanning&page=scanning_dashboard&current_page=1&sort_by=local_name&sort_direction=DESC&scanning_mode=agent&item_count=100',
    items: {
      scanning_dashboard: {
        label: 'DeepSurface Scanning Status',
        isCurrent: 'scanning_dashboard',
        slug: 'scanning_dashboard',
        link: '#.=scanning&page=scanning_dashboard&current_page=1&sort_by=local_name&sort_direction=DESC&scanning_mode=agent&item_count=100',
        component: <ScanningDashboard />,
      },
      scanning_settings_user_managed: {
        label: 'User Managed Configuration',
        link: '#.=scanning&page=user_managed',
        isCurrent: 'user_managed',
        slug: 'user_managed',
        component: <UserManaged />,
      },
      scanning_settings_agents: {
        label: 'Agents Configuration',
        link: '#.=scanning&page=agents',
        isCurrent: 'agents',
        slug: 'agents',
        component: <Agents />,
      },
      credentialed: {
        label: 'Credentialed',
        link: '#.=scanning&page=agentless_settings',
        isCurrent: isCredentialed,
        slug: 'agentless_settings',
        borderClass: 'both',
        items: {
          agentless_settings: {
            label: 'Settings',
            link: '#.=scanning&page=agentless_settings',
            slug: 'agentless_settings',
            isCurrent: 'agentless_settings',
            component: <AgentlessSettings />,
          },
          credentials: {
            label: 'Credentials',
            link: '#.=scanning&page=credentials',
            slug: 'credentials',
            isCurrent: 'credentials',
            component: <ScanCredentials />,
          },
          scan_groups: {
            label: 'Scan Groups',
            link: '#.=scanning&page=scan_groups',
            slug: 'scan_groups',
            isCurrent: 'scan_groups',
            component: <ScanGroups />,
          },
        },
      },
      settings: {
        label: 'Settings',
        link: '#.=scanning&page=scanning_general_settings',
        isCurrent: isScanningSettings,
        slug: 'scanning_general_settings',
        items: {
          scanning_general_settings: {
            label: 'General Settings',
            link: '#.=scanning&page=scanning_general_settings',
            isCurrent: 'scanning_general_settings',
            slug: 'scanning_general_settings',
            component: <ScanningGeneralSettings />,
          },
          vulnerability_scanners: {
            label: 'Vulnerability Sources',
            link: '#.=scanning&page=vulnerability_scanners',
            isCurrent: 'vulnerability_scanners',
            slug: 'vulnerability_scanners',
            component: <VulnerabilityScanners />,
          },
          cloud_scanning: {
            label: 'Cloud Scanning',
            link: '#.=scanning&page=cloud_scanning',
            isCurrent: 'cloud_scanning',
            slug: 'cloud_scanning',
            component: <CloudScanning />,
          },
          subordinates: {
            label: 'Subordinates',
            link: '#.=scanning&page=subordinates',
            isCurrent: 'subordinates',
            slug: 'subordinates',
            component: <SubordinateScanners />,
          },
          network_connectivitiy: {
            label: 'Network Connectivity',
            link: '#.=scanning&page=connectivity_settings',
            isCurrent: 'connectivity_settings',
            slug: 'connectivity_settings',
            component: <NetworkConnectivity />,
          },
        },
      },
    },
  },
  setup: {
    label: 'Setup',
    iconKey: 'setup_nav',
    slug: 'setup',
    isCurrent: 'setup',
    // eslint-disable-next-line max-len
    link: '#.=setup&page=sensitive_assets_policies',
    items: {
      sensitive_assets: {
        label: 'Sensitive Assets',
        link: '#.=setup&page=sensitive_assets_policies',
        isCurrent: isSensitiveAssets,
        slug: 'sensitive_assets_policies',
        borderClass: 'bottom',
        items: {
          sensitive_assets_policies: {
            label: 'Policies',
            link: '#.=setup&page=sensitive_assets_policies',
            slug: 'sensitive_assets_policies',
            isCurrent: 'sensitive_assets_policies',
            component: <SensitiveAssetsPolicies />,
          },
          sensitive_assets_manual: {
            label: 'Manual Assignment',
            link: '#.=setup&page=sensitive_assets_manual&current_page=1&type=any&impact=either_impact&item_count=100&sort_by=node_analysis.combined_impact&sort_direction=DESC&combined_impact=either_impact',
            slug: 'sensitive_assets_manual',
            isCurrent: 'sensitive_assets_manual',
            component: <SensitiveAssetsManual />,
          },
        },
      },
      tags: {
        label: 'Tags',
        link: '#.=setup&page=tags&current_page=1&item_count=100',
        slug: 'tags',
        isCurrent: 'tags',
        component: <Tags />,
      },
      authentication: {
        label: 'Authentication',
        link: '#.=setup&page=providers',
        isCurrent: isAuthentication,
        slug: 'providers',
        borderClass: 'both',
        items: {
          providers: {
            label: 'Providers',
            link: '#.=setup&page=providers',
            slug: 'providers',
            isCurrent: 'providers',
            component: <AuthenticationProviders />,
          },
          users: {
            label: 'Users',
            link: '#.=setup&page=users',
            slug: 'users',
            isCurrent: 'users',
            component: <Users />,
          },
        },
      },
      general_settings: {
        label: 'General Settings',
        link: '#.=setup&page=admin',
        isCurrent: isGeneralSettings,
        slug: 'admin',
        items: {
          admin: {
            label: 'Admin',
            link: '#.=setup&page=admin',
            slug: 'admin',
            isCurrent: 'admin',
            component: <Admin />,
          },
          smtp: {
            label: 'SMTP Settings',
            link: '#.=setup&page=smtp',
            slug: 'smtp',
            isCurrent: 'smtp',
            component: <SmtpSettings />,
          },
          certificates: {
            label: 'Certificates',
            link: '#.=setup&page=certificates',
            slug: 'certificates',
            isCurrent: 'certificates',
            component: <Certificates />,
          },
          outbound_proxy: {
            label: 'Outbound Proxy',
            link: '#.=setup&page=outbound_proxy',
            slug: 'outbound_proxy',
            isCurrent: 'outbound_proxy',
            component: <OutboundProxy />,
          },
        },
      },
      hidden: {
        label: 'Hidden',
        isHidden: true,
        link: '#.=setup&page=hidden',
        slug: 'hidden',
        isCurrent: 'hidden',
        component: <Hidden />,
      },
    },
  },
  user_menu: {
    lower: true,
    slug: 'user_menu',
    items: {
      edit_profile: {
        label: 'Edit Profile',
        slug: 'edit_profile',
        action: null,
      },
      onboarding_wizard: {
        label: 'Onboarding',
        slug: 'onboarding_wizard',
        action: null,
      },
      about: {
        label: 'About',
        isCurrent: 'about',
        slug: 'about',
        link: '#.=about',
      },
      logout: {
        label: 'Log Out',
        action: () => logout(),
      },
    },
  },
};
