/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';

import './ScanningScanGroupBreakdown.scss';
import { tagColors, isNotEmpty } from '../../../../../shared/Utilities';
import Donut from '../../../../../shared/Charts/Donut';
import Legend from '../../../../../shared/Charts/Legend';
import EmptyState from '../../../../../shared/EmptyState';

const ScanningScanGroupBreakdown = ( { data, settings } ) => {
  const [ scanGroupTotal, setScanGroupTotal ] = React.useState( 1 );
  const [ scanGroupData, setScanGroupData ] = React.useState( null );
  const [ hoveredSeriesIdentifier, setHoveredSeriesIdentifier ] = React.useState( null );

  const setupData = ( data ) => {
    if ( isNotEmpty( data ) ) {

      const { scan_group, scanGroups } = data;

      if ( isNotEmpty( scan_group ) && isNotEmpty( scanGroups ) ) {
        const availableColors = [ ...tagColors.four ];
        availableColors.shift();

        const _scanGroupData = {};
        const total = Object.values( scan_group ).reduce( ( accum, count ) => ( accum + count ), 0 );

        Object.entries( scan_group ).map( ( [ sgID, count ], index ) => {

          const sg = scanGroups?.results?.find( sg => sg.id === sgID );

          if ( isNotEmpty( sg ) ) {
            _scanGroupData[sgID] = {
              label: sg.label,
              stroke: availableColors[index % 8],
              value: ( count / total ) * 100,
              originalCount: count,
              total: count,
              key: sgID,
            };
          }
        } );

        setScanGroupTotal( total );
        setScanGroupData( _scanGroupData );
      }


    }

  };

  React.useEffect( () => {
    if ( isNotEmpty( data ) ) {
      setupData( data );
    }
  }, [ data, settings ] );

  return (
    <React.Fragment>
      {
        ( isNotEmpty( scanGroupTotal ) && isNotEmpty( scanGroupData ) )
          ? <React.Fragment>
            <Donut
              data={scanGroupData}
              total={scanGroupTotal}
              hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
              setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
            />
            <Legend
              legendData={ scanGroupData }
              hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
              setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
            />
          </React.Fragment>
          : <EmptyState message="No scan group data available" />
      }
    </React.Fragment>
  );
};

export default ScanningScanGroupBreakdown;