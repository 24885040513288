/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { formatNumber, isNotEmpty } from '../../../shared/Utilities';
import SectionDescription from './SectionDescription';
import { thirdPartyAttributes } from '../shared';
import RecordList from '../../../shared/RecordList';

const needsDescription = [
  'num_vulnerabilities',
  'num_hosts',
  'num_patches',
  'scan_results',
  'supersedes',
  'superseded_by',
  'unpatchable_vulnerabilities',
  'additional_actions',
];

const SectionContent = ( {
  record,
  sectionKey,
  sectionCount,
  sectionData,
  recordType,
  relatedPaths,
  riskType,
  // setHoveredListItemID,
  // setHoveredListItemType,
  // setHoveredListItemRating,

  // recordCard variables
  recordCardRecord,
  setRecordCardRecord,
  recordCardType,
  setRecordCardType,
  showRecordCard,
  setShowRecordCard,
} ) => {
  return (
    <React.Fragment>
      {
        (
          isNotEmpty( sectionKey )
          && isNotEmpty( sectionData )
          && isNotEmpty( sectionCount )
          && isNotEmpty( recordType )
        ) &&
        <React.Fragment>
          {
            needsDescription.includes( sectionKey ) &&
            <SectionDescription
              sectionKey={sectionKey}
              sectionCount={sectionCount}
              sectionData={sectionData}
              recordType={recordType}
              record={record}
              relatedPaths={relatedPaths}
              riskType={riskType}
            />
          }
          {
            isNotEmpty( sectionData ) &&
            <React.Fragment>
              {
                thirdPartyAttributes.includes( sectionKey )
                  ? <div className="thirdPartySectionBodyWrapper">
                    { sectionData }
                  </div>
                  : <React.Fragment>
                    {
                      ( sectionKey === 'sensitive_nodes' || sectionKey === 'sensitive_nodes_at_risk' )
                        ? <React.Fragment>
                          <RecordList
                            records={ sectionData?.assets }
                            orderBy={ riskType }
                            sectionKey={ sectionKey }
                            reportType={ sectionKey }
                          />
                          <div className="exposedAssetsWrapper">
                            {/* eslint-disable-next-line max-len */}
                            <h4>Exposed via attack paths <strong>({ formatNumber( sectionData?.exposed?.length || 0 ) })</strong></h4>
                            <RecordList
                              records={ sectionData?.exposed }
                              orderBy={ riskType }
                              sectionKey={ sectionKey }
                              reportType={ sectionKey }
                            />
                          </div>
                        </React.Fragment>
                        : <RecordList
                          records={ sectionData }
                          orderBy={ riskType }
                          sectionKey={ sectionKey }
                          reportType={ sectionKey }

                          // recordCard variables
                          recordCardRecord={recordCardRecord}
                          setRecordCardRecord={setRecordCardRecord}
                          recordCardType={recordCardType}
                          setRecordCardType={setRecordCardType}
                          showRecordCard={showRecordCard}
                          setShowRecordCard={setShowRecordCard}
                        />
                    }
                  </React.Fragment>
              }
            </React.Fragment>
          }
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default SectionContent;