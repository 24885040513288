/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { decodeURLHash, formatNumber, isNotEmpty } from '../Utilities';
import InlineSVG from '../InlineSVG';

import './AttackScenarioCountLink.scss';

const AttackScenarioCountLink = ( { recordType='host', attackScenario } ) => {

  const [ href, setHref ] = React.useState( '' );

  React.useEffect( () => {
    if ( isNotEmpty( attackScenario ) && isNotEmpty( recordType ) ) {
      const hash = decodeURLHash();

      const { item } = hash;

      if ( isNotEmpty( item ) && isNotEmpty( attackScenario?.key ) ) {
        if ( attackScenario.key === 'exploitable_vulns_at_risk_count' ) {
          // eslint-disable-next-line max-len
          setHref( `#.=risk_insight&report=instances&group_type=vulnerability&order_by=filtered_risk&order_direction=DESC&item_count=100&current_page=1&accepted_risk=false&include_risk=true&instances_visual_mode=categories&category=carries_risk&${recordType}_ids=%5B"${item}"%5D&exploit_statuses=%5B"weaponized"%5D&risk_reduction=%5B"gt_map"%2C0%5D` );
        } else {
          // eslint-disable-next-line max-len
          setHref( `#.=risk_insight&report=instances&group_type=vulnerability&order_by=filtered_risk&order_direction=DESC&item_count=100&current_page=1&accepted_risk=false&include_risk=true&instances_visual_mode=categories&category=carries_risk&${recordType}_ids=%5B"${item}"%5D&attack_scenario=${attackScenario?.key}&risk_reduction=%5B"gt_map"%2C0%5D` );
        }
      }
    }
  }, [ recordType, attackScenario ] );
  return (
    <React.Fragment>
      {
        isNotEmpty( href ) &&
        <a
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          className={ `attackScenarioCountLink ${attackScenario?.key}` }
        >
          <div className="iconAndCountWrapper">
            <InlineSVG type={attackScenario?.key} />
            { formatNumber( attackScenario?.value ) }
          </div>
          <div className="labelAndLinkWrapper">
            { attackScenario?.label }
            <InlineSVG type="newTabLink" />
          </div>
        </a>
      }
    </React.Fragment>
  );
};

export default AttackScenarioCountLink;