/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../../../../shared/Utilities';

import './AccessItem.scss';
import { CurrentUserContext } from '../../../../../Contexts/CurrentUser';

const AccessItem = ( {
  accessItem,
  accessItemType,
  // existingAccess,
  callback=() => {},
} ) => {

  const [ currentUser ] = React.useContext( CurrentUserContext );

  const handleCheckboxChange = ( e, accessLevel ) => {
    let _value = null;
    if ( accessLevel === 'viewer' ) {
      if ( e.target.checked ) {
        _value = 'viewer';
      }
    }
    if ( accessLevel === 'owner' ) {
      if ( e.target.checked ) {
        _value = 'owner';
      }
    }
    callback( accessItem, accessItemType, _value );
  };

  return (
    <React.Fragment>
      {
        isNotEmpty( accessItem ) &&
        <li className="objectAccessItemRow">
          <div className="checkboxesWrapper">
            <label>
              <div
                // eslint-disable-next-line max-len
                className={ `checkboxFieldWrapper ${currentUser?.acl_role !== 'admin' ? 'disabled' : ''} ${ accessItem.accessLevel === 'viewer' ? 'checked' : '' }` }
              >
                <input
                  type="checkbox"
                  checked={ accessItem.accessLevel === 'viewer' }
                  disabled={ currentUser?.acl_role !== 'admin' }
                  onChange={ e => handleCheckboxChange( e, 'viewer' ) }
                />
              </div>
            </label>
            <label>
              {
                accessItemType !== 'asset_tag' &&
                <div
                  // eslint-disable-next-line max-len
                  className={ `checkboxFieldWrapper ${currentUser?.acl_role !== 'admin' ? 'disabled' : ''} ${ accessItem.accessLevel === 'owner' ? 'checked' : '' }` }
                >
                  <input
                    type="checkbox"
                    checked={ accessItem.accessLevel === 'owner' }
                    disabled={ currentUser?.acl_role !== 'admin' }
                    onChange={ e => handleCheckboxChange( e, 'owner' ) }
                  />
                </div>
              }
            </label>
          </div>
          <div className="labelWrapper">
            <span>{ accessItem.label }</span>
          </div>
        </li>
      }
    </React.Fragment>
  );
};

export default AccessItem;