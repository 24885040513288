/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../Utilities';

import './style.scss';

const HTTPStatusHandler = ( { response } ) => {

  const statusDetailMap = {
    403: 'Forbidden',
  };

  return (
    <React.Fragment>
      {
        isNotEmpty( response ) &&
        <div className={ `httpStatusHandlerContainer status--${response.status}` }>
          <div className="httpStatusHeader">
            <h2>
              <strong>{response.status || 'Unknown' }</strong>
              <span>{ response.statusText }</span>
            </h2>
          </div>
          <div className="httpStatusBody">
            {
              isNotEmpty( response.detail?.warnings )
                ?  response.detail.warnings.map( ( warning, index ) => {
                  return <p key={index}>
                    { warning }
                  </p>;
                } )
                : statusDetailMap[response.status]
            }
          </div>
        </div>
      }
    </React.Fragment>
  );
};

export default HTTPStatusHandler;