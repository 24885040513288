/** *************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
import React from 'react';

import SetupPage from '../../../shared/SetupComponents/SetupPage';

import {
  recordData,
} from './data';
import { getActiveIntegrations, isNotEmpty } from '../../../shared/Utilities';
import { getFieldValues } from '../../../shared/Form/Shared';
import { FlashMessageQueueContext } from '../../../Contexts/FlashMessageQueue';

import './style.scss';
import { makeRequest } from '../../../../legacy/io';

const Ticketing = () => {

  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  const [ integrations, setIntegrations ] = React.useState( [] );

  // need to get all the integrations (which are actually just a type of credential), filter out the unecessary ones
  const onRefresh = async () => {
    // returns an object of the type { jira: [], service_now: [], ... }
    const _integrations = await getActiveIntegrations();

    const _flattenedIntegrations = [];

    // need to flatten the returned active integrations into just an array to iterate over, in the future, we might
    // want to separate this list by protocol, but not necessary for now with only Jira wired up -- DMC 2022-08-15
    if ( isNotEmpty( _integrations ) ) {
      Object.values( _integrations ).map( intArray => {
        intArray.map( int => {
          _flattenedIntegrations.push( int );
        } );
      } );
    }
    setIntegrations( _flattenedIntegrations );
  };

  const onSave = async (
    existingIntegration,
    isValid,
    fieldStates,
    cachedProjects,
    refresh=true,
  ) => {
    // these attrs are nested within the settings json column
    const settingsAttrs = [
      'project',
      'project_name',
      'project_id',
      'credential_id',
      'subject_prefix',
      'destination_email_address',
      'format_as_html',
      'email_label',
      'server',
      'label',
    ];

    // these attrs need to map to the associated credential
    const credentialAttrs = [
      'username',
      'secret',
      'label',
      'domain',
    ];

    if ( isValid && isNotEmpty( fieldStates ) ) {
      const values = getFieldValues( fieldStates, 'integration' );
      let updatedIntegration = { category: 'vulnerability_scanner', settings: { } };

      if ( isNotEmpty( existingIntegration ) ) {
        updatedIntegration = {
          ...updatedIntegration,
          tool: existingIntegration.tool,
          settings: {
            ...existingIntegration.settings,
          },
        };
      }

      const updatedCredential = { };

      // map through all the form values and put them into the correct place
      if ( isNotEmpty( values ) ) {
        Object.entries( values ).map( ( [ attr, val ] ) => {
          // this is a setting
          if ( settingsAttrs.includes( attr ) ) {
            updatedIntegration.settings[attr] = val;
          // this needs to be part of the credential
          } else if ( credentialAttrs.includes( attr ) ) {
            updatedCredential[attr] = val;
          // this is part of the third_party_setting
          } else {
            updatedIntegration[attr] = val;
          }
        } );

        // we don't actually need all the attrs ( help )
        delete updatedIntegration.help;

        // we are editing an existing integration
        if ( isNotEmpty( existingIntegration ) ) {

          // map over the existing ID so that we are not creating new integrations
          updatedIntegration.id = existingIntegration.id;

          // there is already a credential
          if ( isNotEmpty( existingIntegration.settings?.credential_id ) ) {

            updatedCredential.id = existingIntegration.settings.credential_id;

            if ( updatedCredential.secret === '' || updatedCredential.secret === 'null' ) {
              delete updatedCredential.secret;
            }
          }
        } else if ( updatedIntegration?.tool === 'jira' ) {

          updatedIntegration.category = 'ticketing_software';

          const cachedProject = cachedProjects.find( p => p.project_key === values.project );

          /* eslint-disable camelcase */
          if ( isNotEmpty( cachedProject ) ) {
            if ( isNotEmpty( updatedIntegration.settings ) ) {
              // jira
              updatedIntegration.settings.project_name = cachedProject.project_name;
              updatedIntegration.settings.project_id = cachedProject.project_id;
              updatedIntegration.settings.project_avatar = cachedProject.extra_data.avatar;
            } else {
              updatedIntegration.settings = {
                // jira
                project_name: cachedProject.project_name,
                project_id: cachedProject.project_id,
                project_avatar: cachedProject.extra_data.avatar,
              };
            }
          }
        }

        // upsert the credential first
        const credResponse = await makeRequest(
          'UPSERT',
          '/project/default/credential',
          { 'records': [ updatedCredential ] },
        );

        let credID;

        if ( isNotEmpty( credResponse ) && isNotEmpty( credResponse.results ) ) {
          credID = credResponse.results[0].id;
        }

        // if the cred upsert went through without a hitch, upsert the third_party_setting
        if ( isNotEmpty( credID ) ) {
          // eslint-disable-next-line camelcase
          updatedIntegration.settings.credential_id = credID;

          if ( updatedIntegration.tool === 'jira' || updatedIntegration.tool === 'email' ) {
            updatedIntegration.category = 'ticketing_software';
          } else {
            updatedIntegration.category = 'vulnerability_scanner';
          }

          const thirdPartyResponse = await makeRequest(
            'UPSERT',
            '/project/default/third_party_setting',
            { 'records': [ updatedIntegration ] },
          );
          // either an error or success
          if ( thirdPartyResponse?.errors || thirdPartyResponse?.results ) {
            if ( thirdPartyResponse.errors ) {
              thirdPartyResponse.errors.map( e => {
                addFlashMessage( {
                  type: 'alert',
                  body: e,
                } );
              } );
            // went through without issue
            } else {
              if ( refresh ) {
                onRefresh();
              }
              addFlashMessage( {
                body: `Integration Successfully ${existingIntegration.id ? 'updated' : 'configured'}`,
                type: 'success',
              } );

              return thirdPartyResponse.results[0];
            }
          // 500
          } else {
            addFlashMessage( {
              body: 'There was an error saving the integration, please check the form for any misconfigurations',
              type: 'alert',
            } );
          }
        }
      }
    }
  };

  return (
    <SetupPage
      onRefresh={onRefresh}
      onSave={onSave}
      records={integrations}
      setRecords={setIntegrations}
      recordType="integration"
      recordData={recordData}
      alternateItemLayout
    />
  );
};

export default Ticketing;
