/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import { adjustedExportedReportValues } from '../ReportCreator/data';
import { isEmpty, isNotEmpty, isTruthy, itemIsArray, itemIsObject, itemIsString } from '../Utilities';

import {
  isPresent,
  isValidWeeklySchedule,
} from './Validators';

const CRED_OPTIONS = {
  'smb-wmi': 'SMB/WMI',
  'winrm-psrp': 'WinRM w/ PSRP',
  'ssh-password': 'SSH Password',
  'ssh-key': 'SSH Private Key',
  'smb': 'SMB (deprecated)',
};

// super special adjustment logic that the exported report needs
export const typeNeedsAdjusting = [
  'exported_report',
];

// overriddes disabled if an existing record
export const editNotAllowed = [
  'exported_report',
  'vulnerability_source',
  'integration',
  'cloud_scanner',
  'scan_credential',
  'authentication_provider',
  'user',
];

// master list of all fields that dont allow edit
export const fieldsToDisable = [
  'format',
  'tool',
  'protocol',
  'type',
  'provider',
];

// these field types need to have label within the specific field component for layout,
// styling, or functionality purposes
export const needsOwnLabel = [
  'override',
  'checkbox',
  'selectList',
  'contentBlock',
  'searchResults',
];

// these record types have the tool_ added to the attribute so that there can be multiple of
// the same attribute per form
export const needsNormalizing = [
  'vulnerability_source',
  'cloud_scanner',
  'authentication_provider',
  'integration',
];

// nested options for scan_credentials
export const credOptions = [
  'elevate_source',
  'elevate_method',
  'ports',
];

// nested pam_settings for scan_credentials
export const credPamSettings = [
  'local_user',
  'url',
  'tofu',
  'target_domain',
  'target_username',
  'secret_name',
  'safe_name',
  'require_ip_match',
];

// specific validators for specific field types
const fieldTypeValidators = {
  weeklySchedule: {
    validator: isValidWeeklySchedule,
  },
};

// secret types that are allowed to be blank on edit
export const isSecretType = ( field, type ) => {
  return normalizedAttribute( field, type ) === 'secret'
  || normalizedAttribute( field, type ) === 'password'
  || normalizedAttribute( field, type ) === 'elevate_secret'
  || normalizedAttribute( field, type ) === 'api_secret'
  || normalizedAttribute( field, type ) === 'pam_client_key'
  || normalizedAttribute( field, type ) === 'credential_secret';
};

// returns the actual record attribute with the prefix removed if it has one
export const normalizedAttribute = ( field, type='' ) => {
  let _attr = field.attribute;

  if ( needsNormalizing.includes( type ) ) {
    _attr = field.attribute.split( '_' );

    if ( _attr.length > 1 ) {
      _attr.shift();
    }

    _attr = _attr.join( '_' );

    // for authentication providers, the attribute is actually 'port'
    // but the form has one for each type to avoid object key clash
    if (
      type === 'authentication_provider' && ( _attr === 'port-starttls' || _attr === 'port-ldaps' )
    ) {
      _attr = 'port';
    }
  }

  // for users, the api_secret and api_key are represented by a single field, need to combine them
  if ( type === 'user' && _attr === 'api_credentials' ) {
    _attr = 'api_key';
  }

  return _attr;
};

// shortcut function just to get a flattened attr/value obj. for form submission
export const getFieldValues = ( fieldStates, type=null ) => {
  if ( isNotEmpty( fieldStates ) ) {
    const flattenedFields = {};

    Object.entries( fieldStates ).map( ( [ attr, state ] ) => {
      if ( state.included ) {
        const _attr = normalizedAttribute( { attribute: attr }, type );
        flattenedFields[_attr] = state.updatedValue;
      }
    } );

    return flattenedFields;
  }
};

// gets the actual value from the record and does any adjustments necessary
const getValue = ( field, value ) => {
  // special logic for 'formNull' situations, aka, the front end needs the string 'null'
  // the backend needs the literal null
  if ( value === null && field.type !== 'text' && field.type !== 'textarea' ) {
    return 'null';
  }

  // this handles undefined, null, etc. coming back from the server and showing up as a text value
  if ( ( field.type === 'text' || field.type === 'password' ) && isEmpty( value ) ) {
    return '';
  }

  // special logic for splitting textareas
  if ( ( field.type === 'textarea' || field.type === 'hidden' ) && field.needsSplit ) {
    return value || [];
  }

  if ( field.type === 'apiCredentials' ) {
    return { key: value, secret: '' };
  }

  // make sure a number is a number, works with float or int strings
  if ( field.type === 'number' && itemIsString( value ) ) {
    // is a 'float' string
    if ( value.includes( '.' ) ) {
      return parseFloat( value );
    }
    // is an 'int' string
    return parseInt( value );
  }

  // sometimes the backend stores true/false values as strings/num/booleans, need to normalize
  if ( field.type === 'checkbox' ) {
    if ( field.attribute === 'wazuhapi_include_wazuh_host' ) {
      if ( value === false ) {
        return false;
      }
      return true;
    }
    return isTruthy( value );
  }

  // override fields are complicated, they need to store a lot of information in order to know the default,
  // and if a field is overridden or not, only the actual overrideValue gets saved to the server, but the front end
  // needs to know a lot more to function correctly
  if ( field.type ===  'override' ) {
    let overrideValue;

    if ( field.overrideInputType === 'checkbox' ) {
      overrideValue = isTruthy( value );
    } else {
      overrideValue = value;
    }

    return { isOverridden: true, overrideValue, globalDefault: field.defaultValue.globalDefault };
  }

  return isNotEmpty( value ) ? value : field.defaultValue;
};

// lots of custom/edge-cases/type specific logic for getting the original value from the record...
// you'd be surprised...
export const getOriginalValue = ( field, record, type ) => {

  // record types that have nested attrs
  const typesWithNested = [
    'scan_credential',
    'authentication_provider',
    'scan_group',
    'vulnerability_source',
    'cloud_scanner',
    'agent',
    'escalation',
    'integration',
  ];

  // nested settings for authentication_provider
  const authProviderSettings = [
    'domain',
    'servers',
    'port-starttls',
    'port-ldaps',
    'protocol',
    'tofu',
    'acs_host_port',
    'metadata',
  ];

  // nested settings for scan_groups
  const scanGroupSettings = [
    'avoid_rescan_period',
    'maximum_duration',
    'scan_workers',
    'mssql_grant_privileges',
    'scan_domains',
    'winrm_allow_http',
    'enable_nas_scan',
    'nas_scan_depth',
  ];

  // nested settings for vulnerability_scanners
  const vulnScannerSettings = [
    'domain',
    'port',
    'secret',
    'server',
    'username',
    'tofu',
    'use_proxy',
    'auth_type',
    'regions',
    'edition',
    'include_wazuh_host',
  ];

  // nested settings for integrations
  // UPDATE: flattened to one level, no longer necessary
  const integrationSettings = [
    // 'domain',
    // 'project',
    // 'username',
    // 'secret',
    // 'label',
    // 'subject_prefix',
    // 'destination_email_address',
    // 'format_as_html',
    // 'email_label',
  ];

  // nested schedule settings for agents
  const agentSchedule = [
    'frequency',
    'blackouts',
    'blackout_enabled',
  ];

  // nested settings for cloud_scanners
  const cloudScannerSettings = [
    'use_proxy',
  ];

  const escalationVulnerabilityFields = [
    'description',
    'urls',
    'identifier',
    'effort',
  ];

  const escalationEsclationFields = [
    'user_interaction',
    'disruption',
    'exploit_development',
    'identification',
    'certainty',
    'completeness',
  ];

  if ( isNotEmpty( field ) && isNotEmpty( record ) && isNotEmpty( type ) ) {

    const _normalizedAttr = normalizedAttribute( field, type );

    // this recordType has nested attributes that need to be grabbed
    if ( typesWithNested.includes( type ) ) {
      if ( type === 'scan_credential' ) {
        if ( credOptions.includes( _normalizedAttr ) ) {
          if ( isNotEmpty( record.options ) ) {
            return getValue( field, record?.options[_normalizedAttr] );
          }
          return '';
        // if this is a true existing record (meaning it came from the server, not a "workingRecord")
        // then we need to get the pam settings out of the nested block, otherwise it comes from the top level
        } else if ( isNotEmpty( record.pam_settings ) && credPamSettings.includes( _normalizedAttr ) ) {
          if ( isNotEmpty( record.pam_settings ) ) {
            return getValue( field, record?.pam_settings[_normalizedAttr] );
          }
          return '';
        }
        return getValue( field, record[_normalizedAttr] );
      }

      if ( type === 'authentication_provider' ) {
        if ( authProviderSettings.includes( _normalizedAttr ) ) {
          return getValue( field, record.settings[_normalizedAttr] );
        }
        return getValue( field, record[_normalizedAttr] );

      }

      if ( type === 'scan_group' ) {
        if ( scanGroupSettings.includes( _normalizedAttr ) ) {
          if ( Object.keys( record.settings ).includes( _normalizedAttr ) ) {
            return getValue( field, record.settings[_normalizedAttr] );
          }
          return field.defaultValue;
        }
        return getValue( field, record[_normalizedAttr] );
      }

      if ( type === 'vulnerability_source' ) {
        if ( vulnScannerSettings.includes( _normalizedAttr ) ) {
          return getValue( field, record.settings[_normalizedAttr] );
        }
        return getValue( field, record[_normalizedAttr] );

      }

      if ( type === 'integration' ) {
        if ( integrationSettings.includes( _normalizedAttr ) ) {
          return getValue( field, record.settings[_normalizedAttr] );
        }
        return getValue( field, record[_normalizedAttr] );

      }

      if ( type === 'cloud_scanner' ) {
        if ( cloudScannerSettings.includes( _normalizedAttr ) ) {
          if ( isNotEmpty( record.settings ) ) {
            return getValue( field, record.settings[_normalizedAttr] );
          }
          return false;
        }
        return getValue( field, record[_normalizedAttr] );

      }

      if ( type === 'agent' ) {
        if ( agentSchedule.includes( _normalizedAttr ) ) {
          if ( isNotEmpty( record.schedule ) ) {
            return getValue( field, record.schedule[_normalizedAttr] );
          }
          // first time setting up an agent
          return getValue( field, '' );

        }
        return getValue( field, record[_normalizedAttr] );

      }

      if ( type === 'escalation' ) {
        if ( escalationVulnerabilityFields.includes( _normalizedAttr ) ) {
          return getValue( field, record.vulnerabilityData[_normalizedAttr] );
        }
        if ( escalationEsclationFields.includes( _normalizedAttr ) ) {
          return getValue( field, record.escalationData[_normalizedAttr] );
        }
        return getValue( field, record[_normalizedAttr] );
      }

    // just get the regular attr
    } else {
      return getValue( field, record[_normalizedAttr] );
    }
  } else if ( isNotEmpty( field ) ) {
    return field.defaultValue;
  }
};

// helper function to see if a given condition is met for fields
// checks each condition
const conditionIsMet = ( fieldStates, condition ) => {

  // condition can have either a check function for more complex logic, or a simple value that needs
  // to be compared against ie:
  //  { attribute: 'some_other_field', check: isNotEmpty }
  if ( isNotEmpty( condition.check ) && isNotEmpty( fieldStates[condition.attribute] ) ) {
    return condition.check( fieldStates[condition.attribute].updatedValue, fieldStates );
  }
  // a conditional can be met by many different values
  if (
    isNotEmpty( condition.value )
    && itemIsArray( condition.value )
    && isNotEmpty( fieldStates[condition.attribute] )
  ) {
    return condition.value.includes( fieldStates[condition.attribute].updatedValue );
  }
  if ( isNotEmpty( fieldStates[condition.attribute] ) ) {
    return fieldStates[condition.attribute].updatedValue === condition.value;
  }
  return false;
};

// loops through all the fields and resets conditionals (ie includes/disabled)
export const checkFormConditionals = (
  fieldStates,
  fields,
  existingRecord,
  recordType,
  excludeAttr=null,
) => {

  const _fieldStates = { ...fieldStates };

  // set up which fields need to be included/disabled/required
  // and updates conditionalOptions/conditionalLabels, based on conditional logic
  Object.entries( _fieldStates ).map( ( [ attr, state ] ) => {

    // we already did the changed field, don't do it again.
    if ( attr !== excludeAttr ) {

      const _field = fields.find( f => f.attribute === attr );

      const _state = { ...state };

      let shouldDisable = checkFieldConditional( 'disable', _field, _fieldStates );
      const shouldRequire = checkFieldConditional( 'require', _field, _fieldStates );

      const hasWarning = checkFieldConditional( 'warn', _field, _fieldStates );

      const shouldInclude = checkFieldConditional( 'include', _field, _fieldStates );

      if ( isNotEmpty( _field ) ) {
        // don't need to bother doing anything if we do not even need to include the field
        if ( shouldInclude ) {
          // disable logic
          if ( editNotAllowed.includes( recordType ) ) {
            if ( isNotEmpty( existingRecord ) ) {
              // need to do some special logic to allow the user to switch between windows type creds
              if (
                recordType === 'scan_credential'
              ) {
                shouldDisable  = false;
                // need to do some special logic to allow the user to switch
                // between windows type creds
                if ( _field.attribute === 'protocol' && recordType === 'scan_credential' ) {
                  _field.options = CRED_OPTIONS;
                }
              }
            // new record, need to reset the options for creds back to all,
            // not just windows types... in case it got overwritten
            } else if ( recordType === 'scan_credential' ) {
              shouldDisable  = false;
              // need to do some special logic to allow the user to switch between windows type creds
              if ( _field.attribute === 'protocol' && recordType === 'scan_credential' ) {
                _field.options = CRED_OPTIONS;
              }
            }
          }

          // conditionalOptions check, for select fields whose available options could change
          // depending on the state of other fields in the form (nested sub options, etc.)
          if ( isNotEmpty( _field.conditionalOptions ) ) {
            const conditionalOptionsKey = _field.conditionalOptions.attribute;
            const allOptions = _field.conditionalOptions.options;

            if ( isNotEmpty( _fieldStates ) && isNotEmpty( _fieldStates[conditionalOptionsKey] ) ) {
              let value = _fieldStates[conditionalOptionsKey].updatedValue;

              // special situation for the accepted risk plan reason, don't actually care about the value, just
              // whether it is a risk or no risk vuln.
              if ( itemIsObject( value ) && _field.attribute === 'reason_vulnerability' ) {
                value = value.nofix ? 'nofix' : 'all';
              }

              const newOptions = allOptions[value] || {};
              _field.options = newOptions;

              // if the value of this field is not actually an allowed option anymore,
              // need to adjust the defaultValue
              if ( !Object.keys( newOptions ).includes( _state.updatedValue ) ) {
                _state.updatedValue = _field.defaultValue;
              }
            }
          }

          // some fields have conditionalLabels based on the value of another field. In these cases, the field and
          // attribute are still the same, but the label needed to change for some reason
          if ( isNotEmpty( _field.conditionalLabel ) ) {
            const conditionalLabelKey = _field.conditionalLabel.attribute;
            const allLabels = _field.conditionalLabel.labels;

            if ( isNotEmpty( _fieldStates ) && isNotEmpty( _fieldStates[conditionalLabelKey] ) ) {
              const value = _fieldStates[conditionalLabelKey].updatedValue;
              const newLabel = allLabels[value] || _field.defaultLabel || '';
              _field.label = newLabel;
            }
          }
        } else {
          shouldDisable  = false;
        }
      }


      // the user just made a change to the form, so we need to alert them by
      // showing the errors for the now required field
      if ( shouldRequire && isNotEmpty( excludeAttr ) && _state.required !== shouldRequire ) {
        _state.altered = true;
      }

      _state.required = shouldRequire;
      _state.disabled = shouldDisable;
      _state.included = shouldInclude;
      _state.hasWarning = hasWarning;

      _fieldStates[attr] = _state;
    }
  } );

  return _fieldStates;
};

// checks to see if conditional inclusion/enabling/conditionalOptions/conditionalLabel requirement is met
export const checkFieldConditional = ( conditionalType, field, fieldStates ) => {

  // checking for an existing conditional on this field
  if ( isNotEmpty( field ) && field[`${conditionalType}If`] ) {
    // conditionals can be an array of conditionals or just one, need to check for all cases
    if ( itemIsObject( field[`${conditionalType}If`] ) ) {
      return conditionIsMet( fieldStates, field[`${conditionalType}If`] );
    } else if ( itemIsArray( field[`${conditionalType}If`] ) ) {

      const conditionChecks = [];

      field[`${conditionalType}If`].map( condition => {
        conditionChecks.push( conditionIsMet( fieldStates, condition ) );
      } );

      return conditionChecks.every( c => c === true );
    }
  // the default values if the field being questioned does not have the following conditional
  } else if ( conditionalType === 'include' ) {
    return true;
  } else if ( conditionalType === 'disable' ) {
    return false;
  } else if ( conditionalType === 'require' ) {
    return field ? field.required : false;
  } else if ( conditionalType === 'warn' ) {
    return false;
  }
};

// called if there are specific logic for different types of records
// the adjustment logic lives in the respective code where this is needed and is exported
export const adjustValuesForRecordType = ( type, record, fieldStates ) => {
  let _adjustedStates;

  if ( type === 'exported_report' ) {
    _adjustedStates = adjustedExportedReportValues( record, fieldStates );
  }
  return _adjustedStates;
};

// called whenever a trigger would necessitate the entire form being re-validated
// (ie onChange for field, existingRecord, fields)
export const validateForm = ( fieldStates, fields, editMode, recordType, changedField=null ) => {

  let changedAttr = null;

  const shouldValidate =( attr, fieldState ) => {
    let should = false;
    if ( fieldState.included ) {
      // special check for disabled blackout, which is included, but should not be required if disabled
      if ( attr === 'blackouts' ) {
        if ( fieldState.disabled === false ) {
          should = true;
        }
      // only validate override fields that have been overridden
      } else if (
        isNotEmpty( fieldState.updatedValue )
        && Object.keys( fieldState.updatedValue ).includes( 'isOverridden' )
      ) {
        if ( fieldState.updatedValue?.isOverridden === true ) {
          should = true;
        } else {
          should = false;
        }
      } else {
        should = true;
      }
    }
    return should;
  };

  const _allErrors = [];

  if ( isNotEmpty( changedField ) ) {
    changedAttr = changedField.field.attribute;
    if ( shouldValidate( changedAttr, fieldStates[changedAttr] ) ) {
      const fieldErrors = validateField(
        changedField.field,
        changedField.value,
        changedField.internallyValid,
        editMode,
        recordType,
        fieldStates,
      );
      _allErrors[changedAttr] = fieldErrors;
      fieldStates[changedAttr].internallyValid = isEmpty( fieldErrors );
    } else {
      fieldStates[changedAttr].internallyValid = true;
      delete _allErrors[changedAttr];
    }
  }

  Object.entries( fieldStates ).map( ( [ attr, state ] ) => {
    // we already did the changed field, don't do it again.
    if ( attr !== changedAttr ) {
      const _field = fields.find( f => f.attribute === attr );
      // only validate if this field should be validated (included, overridden, etc. )
      if ( shouldValidate( attr, state ) ) {
        const fieldErrors = validateField(
          _field,
          state.updatedValue,
          state.internallyValid,
          editMode,
          recordType,
          fieldStates,
        );
        _allErrors[attr] = fieldErrors;
      } else {
        delete _allErrors[attr];
      }
    }
  } );

  return _allErrors;
};

// main validation logic, checks for 3 things
// 1. custom validation for this specific field
// 2. additional html input validation (min, max, etc.)
// 3. whether the field is required or not
export const validateField = ( field, value, internallyValid=true, editMode, recordType, fieldStates ) => {

  const errors = [];

  if ( isNotEmpty( field ) ) {
    let _value = value;
    // for some field types that actual value that we need to validate is just part of the value that we are getting
    // ie: for override fields we only need to validate the actual input value, not the entire value object.
    if ( field.type === 'override' ) {
      _value = value.overrideValue || '';
    }

    // collection fields have internal validation that happens at the field level, this does a quick check for that as
    // well, the field should have an internalValidationMessage to display, but to be backwards compatible, pushing
    // a default message if not.
    if ( internallyValid === false ) {
      errors.push( field.internalValidationMessage || 'Invalid' );
    }

    // password is not required while editing a record
    if ( isSecretType( field, recordType ) && editMode ) {
      if ( _value === '' ) {
        return errors;
      }
      if ( field.validators ) {
        field.validators.map( v => {
          if ( !v.check( _value ) ) {
            errors.push( v.errorMessage );
          }
        } );
      }
      return errors;

    }

    // if field has custom validation method
    if ( field.validators ) {
      field.validators.map( v => {
        // special check to pass in extra options to check against
        if ( field.duplicateTestArray ) {
          if ( !v.check( _value, field.duplicateTestArray ) ) {
            errors.push( v.errorMessage );
          }
        }

        if ( !v?.check( _value ) ) {
          errors.push( v.errorMessage );
        }
      } );
    }

    // custom validation for field type
    if ( Object.keys( fieldTypeValidators ).includes( field.type ) ) {

      if ( !fieldTypeValidators[field.type]?.validator.check( _value ) ) {
        errors.push( fieldTypeValidators[field.type]?.validator.errorMessage );
      }
    }

    // any html validations present
    if ( field.htmlProps ) {
      let parsedVal = _value;
      if ( typeof _value === 'string' ) {
        if ( _value.includes( '.' ) ) {
          parsedVal = parseFloat( _value );
        } else {
          parsedVal = parseInt( _value );
        }
      }

      const unitMap = {
        s: 'second(s)',
        m: 'minute(s)',
        h: 'hour(s)',
        d: 'day(s)',
      };

      if ( isNotEmpty( field.htmlProps.max ) && ( parsedVal > field.htmlProps.max ) ) {
        let lessThan = field.htmlProps.max;
        if ( field.type === 'duration' && isNotEmpty( field.selectedUnit ) ) {
          lessThan = convertUnitForValidation(
            field.htmlProps.max,
            field.expectedUnit,
            field.selectedUnit,
          );
        }
        errors.push( `Must be less than ${lessThan} ${unitMap[field.selectedUnit] || ''}` );
      }

      if ( isNotEmpty( field.htmlProps.min ) && ( parsedVal < field.htmlProps.min ) ) {
        const greaterThan = field.htmlProps.min;
        errors.push( `Must be greater than ${greaterThan} ${unitMap[field.expectedUnit] || ''}` );
      }
    }

    // if it is required, either always, or conditionally
    if ( field.required || fieldStates[field.attribute].required ) {
      if ( !isPresent.check( _value ) ) {
        errors.push( isPresent.errorMessage );
      }
    }
  }

  return errors;
};

// the duration field logic needed for converting and validating
export const convertUnitForValidation = ( amount, original, updated ) => {

  let convertedValue;

  if ( original === updated ) {
    return amount;
  }

  if ( original === 's' ) {
    if ( updated === 'm' ) {
      convertedValue = amount / 60;
    }
    if ( updated === 'h' ) {
      convertedValue = amount / 60 / 60;
    }
    if ( updated === 'd' ) {
      convertedValue = amount / 60 / 60 / 24;
    }
  }

  if ( original === 'm' ) {
    if ( updated === 's' ) {
      convertedValue = amount * 60;
    }
    if ( updated === 'h' ) {
      convertedValue = amount / 60;
    }
    if ( updated === 'd' ) {
      convertedValue = amount / 60 / 24;
    }
  }

  if ( original === 'h' ) {
    if ( updated === 's' ) {
      convertedValue = amount * 60 * 60;
    }
    if ( updated === 'm' ) {
      convertedValue = amount * 60;
    }
    if ( updated === 'd' ) {
      convertedValue = amount / 24;
    }
  }

  if ( original === 'd' ) {
    if ( updated === 's' ) {
      convertedValue = amount * 60 * 60 * 24;
    }
    if ( updated === 'm' ) {
      convertedValue = amount * 60 * 24;
    }
    if ( updated === 'h' ) {
      convertedValue = amount * 24;
    }
  }

  return convertedValue;
};

// the duration field logic needed to initialize a duration field
// correctly with the value from the server
export const convertValueForDuration = ( _val, _unit, input ) => {

  let convertedValue;

  // early return, no conversion required if user is selecting the same unit
  if ( _unit === input.expectedUnit ) {
    return _val;
  }

  if ( input.expectedUnit === 's' ) {
    if ( _unit === 'm' ) {
      convertedValue = _val * 60;
    }
    if ( _unit === 'h' ) {
      convertedValue = _val * 60 * 60;
    }
    if ( _unit === 'd' ) {
      convertedValue = _val * 60 * 60 * 24;
    }
  }

  if ( input.expectedUnit === 'm' ) {
    if ( _unit === 's' ) {
      convertedValue = _val / 60;
    }
    if ( _unit === 'h' ) {
      convertedValue = _val * 60;
    }
    if ( _unit === 'd' ) {
      convertedValue = _val * 60 * 24;
    }
  }

  if ( input.expectedUnit === 'h' ) {
    if ( _unit === 's' ) {
      convertedValue = _val / 60 / 60;
    }
    if ( _unit === 'm' ) {
      convertedValue = _val / 60;
    }
    if ( _unit === 'd' ) {
      convertedValue = _val * 24;
    }
  }

  if ( input.expectedUnit === 'd' ) {
    if ( _unit === 's' ) {
      convertedValue = _val / 60 / 60 / 24;
    }
    if ( _unit === 'm' ) {
      convertedValue = _val / 60 / 24;
    }
    if ( _unit === 'h' ) {
      convertedValue = _val / 24;
    }
  }

  return convertedValue;
};
