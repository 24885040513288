/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  debounce,
  formatNumber,
  globalColors,
  isEmpty,
  isNotEmpty,
  itemIsArray,
  itemIsObject,
  riskToRating,
} from '../../../../../shared/Utilities';
import YAxisLabels from '../../../../../shared/Charts/AxisLabels/YAxisLabels';
import Bar from '../../../../../shared/Charts/Bar';
import Legend from '../../../../../shared/Charts/Legend';
import InlineSVG from '../../../../../shared/InlineSVG';

import './Comparison.scss';
import {
  categoryLabelsAndDescription,
  deprioritizedKeys,
  forReviewKeys,
  parentCategoryKeys,
  prioritizedKeys,
} from './VulnerabilityInstancesCategories';
import MultiBar from '../../../../../shared/Charts/MultiBar';
import EmptyState from '../../../../../shared/EmptyState';
import { TagsContext } from '../../../../../Contexts/Tags';

const ComparisonAnalysis = ( {
  item,
  settings,
  data,
  svgAspectRatio,
  adjustSVGAspectRatio,
  svgContainerRef,
} ) => {

  const [ widgetVersion, setWidgetVersion ] = React.useState( null );
  const [ chartData, setChartData ] = React.useState( null );
  const [ seriesLegendData, setSeriesLegendData ] = React.useState( null );

  const [ trendDirection, setTrendDirection ] = React.useState( 'up' );
  const [ trendDelta, setTrendDelta ] = React.useState( 0 );
  const [ yMax, setYMax ] = React.useState( null );
  const [ hoveredSeriesIdentifier, setHoveredSeriesIdentifier ] = React.useState( null );

  const [ tags ] = React.useContext( TagsContext );

  React.useEffect( () => {
    if ( isNotEmpty( svgContainerRef ) && isNotEmpty( svgContainerRef.current ) ) {
      adjustSVGAspectRatio();
      window.addEventListener( 'resize', debounce( () => {
        adjustSVGAspectRatio();
      }, 100 ) );
      return () => window.removeEventListener( 'resize', debounce );
    }
  }, [ svgContainerRef, widgetVersion, chartData, yMax ] );

  const getStrippedDataForComparison = (
    attribute,
    data,
    isInstanceCategory=false,
  ) => {

    const strippedData = [];

    let startPoint, endPoint;
    if ( isNotEmpty( data ) && isNotEmpty( data.start ) ) {
      if ( data.start.length > 1 ) {

        let lowest;

        if ( isNotEmpty( data ) && isNotEmpty( data.start ) && itemIsArray( data.start ) ) {
          data.start.map( point => {
            if ( isInstanceCategory ) {
              if ( isEmpty( lowest ) ) {
                lowest = point;
              } else if ( point.instance_categories[attribute] < lowest.instance_categories[attribute] ) {
                lowest = point;
              }
            } else if ( isEmpty( lowest ) ) {
              lowest = point;
            } else if ( point[attribute] < lowest[attribute] ) {
              lowest = point;
            }
          } );
          startPoint = lowest;
        }

      } else {
        [ startPoint ] = data.start;
      }
    }
    if ( isNotEmpty( data ) && isNotEmpty( data.end ) && itemIsArray( data.end ) ) {
      if ( data.end.length > 1 ) {
        let highest;

        data.end.map( point => {
          if ( isInstanceCategory ) {
            if ( isEmpty( highest ) ) {
              highest = point;
            } else if ( point.instance_categories[attribute] > highest.instance_categories[attribute] ) {
              highest = point;
            }
          } else if ( isEmpty( highest ) ) {
            highest = point;
          } else if ( point[attribute] > highest[attribute] ) {
            highest = point;
          }
        } );
        endPoint = highest;
      } else {
        [ endPoint ] = data.end;
      }
    }

    if ( isNotEmpty( startPoint ) ) {
      const _startPoint = {
        // eslint-disable-next-line max-len
        value: isInstanceCategory ? startPoint.instance_categories[attribute] : startPoint[attribute],
        timestamp: startPoint.created,
        original: startPoint,
        seriesKey: attribute,
        isInstanceCategory,
      };
      if ( isInstanceCategory ) {
        _startPoint.stroke = fillForInstanceCategory( attribute, true );
        _startPoint.fill = fillForInstanceCategory( attribute, true, true );
      }
      strippedData.push( _startPoint );
    } else {
      strippedData.push( null );
    }

    if ( isNotEmpty( endPoint ) ) {
      const _endPoint = {
        value: isInstanceCategory ? endPoint.instance_categories[attribute] : endPoint[attribute],
        timestamp: endPoint.created,
        original: endPoint,
        seriesKey: attribute,
        isInstanceCategory,
      };
      if ( isInstanceCategory ) {
        _endPoint.stroke = fillForInstanceCategory( attribute, true );
        _endPoint.fill = fillForInstanceCategory( attribute, true );
      }
      strippedData.push( _endPoint );
    } else {
      strippedData.push( null );
    }
    return strippedData;
  };

  // figure out which version of the widget we need to display, there are three options:
  // 1. bar chart of current vs. some previous point
  // 2. a multiBar chart of several current points vs. several previous points
  // 3. a big number w/ a text trend and description
  React.useEffect( () => {

    if ( isNotEmpty( settings ) && 'comparison_version' in settings ) {

      // number is pretty simple
      if ( settings.comparison_version === 'number' ) {
        setWidgetVersion( 'number' );
      // will be multibar if it is vuln. instance and we are looking at several categories
      } else if (
        settings.report_type === 'vulnerability_instances'
        &&  settings.category_version === 'specific_categories'
      ) {
        setWidgetVersion( 'multiBar' );
      // ntohing else has any analysis breakdowns, always bar
      } else {
        setWidgetVersion( 'bar' );
      }
    }
  }, [ settings, tags ] );

  // once we know what version we are dealing with, we need to figure out what data to look and format the data
  // according to how it needs to be displayed
  React.useEffect( () => {
    if (
      isNotEmpty( settings )
      && 'report_type' in settings
      && isNotEmpty( widgetVersion )
      && isNotEmpty( data )
    ) {

      const comparisonLabelMap = {
        day: 'Yesterday',
        week: 'Last Week',
        month: 'Last Month',
        quarter: 'Last Quarter',
        year: 'Last Year',
      };

      let attribute = '';
      if ( settings.report_type === 'hosts' ) {
        attribute = 'num_hosts';
      }
      if ( settings.report_type === 'patches' ) {
        attribute = 'num_patches';
      }
      if ( settings.report_type === 'vulnerabilities' ) {
        attribute = 'num_vulnerabilities';
      }
      if ( settings.report_type === 'vulnerability_instances' ) {
        attribute = 'num_instances';
      }
      if ( settings.report_type === 'risk' ) {
        attribute = 'risk';
      }

      // used for all chart types as an intermediary for transforming data
      let strippedData = [];

      // final form of the data for all versions
      const _chartData = {};

      if ( itemIsObject( data ) ) {
        // need to break the data out into series to have multiBar
        if ( settings.category_version === 'specific_categories' && isNotEmpty( settings.included_categories ) ) {
          const _strippedData = [];
          settings.included_categories.map( categoryKey => {
            _strippedData.push( getStrippedDataForComparison( categoryKey, data, true ) );
          } );
          strippedData = _strippedData;
        // need to just get the data normally for a single series
        } else {
          strippedData = getStrippedDataForComparison( attribute, data );
        }
      }

      let _max = Math.max( ...strippedData.map( p => isNotEmpty( p ) ? p.value : 0 ) );

      if ( widgetVersion === 'bar' || widgetVersion === 'number' ) {

        const current = strippedData[strippedData.length - 1];
        const [ comparison ] = strippedData;

        if ( isNotEmpty( current ) ) {
          const _comparisonValue = isNotEmpty( comparison ) ? comparison.value : null;

          _max = Math.max( current.value, isNotEmpty( comparison ) ? comparison.value : 0 );

          if ( isNotEmpty( _comparisonValue ) ) {
            let _trendDirection = 'up';

            let _trendDelta = 0;
            if ( current.value === comparison.value ) {
              _trendDirection = 'even';
              _trendDelta = 0;
            } else if ( current.value > comparison.value ) {
              _trendDirection = 'up';
              _trendDelta = current.value - comparison.value;
            } else {
              _trendDirection = 'down';
              _trendDelta = comparison.value - current.value;
            }

            if ( settings.report_type === 'risk' ) {
              _trendDelta = Math.floor( _trendDelta );
            }

            const strokeForTrendDirection = {
              up: globalColors['status--red--75'],
              down: globalColors['status--green--75'],
              even: globalColors['grey'],
            };

            const comparisonFillForTrendDirection = {
              up: globalColors['status--red--10'],
              down: globalColors['status--green--10'],
              even: globalColors['grey--divider'],
            };

            _chartData.comparison = {
              label: comparisonLabelMap[settings?.comparison_date || 'month' ],
              stroke: strokeForTrendDirection[_trendDirection],
              fill: comparisonFillForTrendDirection[_trendDirection],
              outline: true,
              value: settings.report_type === 'risk' ? Math.floor( comparison.value ) : comparison.value,
              originalCount: comparison.value,
              total: settings.report_type === 'risk' ? Math.floor( comparison.value ) : comparison.value,
              key: 'comparison',
            };

            _chartData.current = {
              label: 'Current',
              stroke: strokeForTrendDirection[_trendDirection],
              fill: strokeForTrendDirection[_trendDirection],
              value: settings.report_type === 'risk' ? Math.floor( current.value ) : current.value,
              originalCount: current.value,
              total: settings.report_type === 'risk' ? Math.floor( current.value ) : current.value,
              key: 'current',
            };

            setTrendDelta( _trendDelta );
            setTrendDirection( _trendDirection );
          } else {
            _chartData.comparison = {
              label: comparisonLabelMap[settings?.comparison_date || 'month' ],
              stroke: globalColors['grey'],
              fill: globalColors['grey--divider'],
              outline: true,
              value: _comparisonValue,
              originalCount: _comparisonValue,
              total: _comparisonValue,
              isUnknown: true,
              key: 'comparison',
            };
            _chartData.current = {
              label: 'Current',
              stroke: globalColors['grey'],
              fill: globalColors['grey'],
              value: settings.report_type === 'risk' ? Math.floor( current.value ) : current.value,
              originalCount: current.value,
              total: settings.report_type === 'risk' ? Math.floor( current.value ) : current.value,
              key: 'current',
            };
            setTrendDelta( 'unknown' );
            setTrendDirection( 'unknown' );
          }
        }
      }
      if ( widgetVersion === 'multiBar' ) {
        if ( isNotEmpty( strippedData ) ) {
          let _seriesMax = 0;

          const _seriesLegendData = {};

          strippedData.map( series => {

            if ( isNotEmpty( series ) && itemIsArray( series ) ) {
              series.map( point => {
                if ( isEmpty( _seriesLegendData[point.seriesKey] ) ) {

                  const { label } = categoryLabelsAndDescription[point.seriesKey];

                  _seriesLegendData[point.seriesKey] = {
                    label,
                    stroke: point.stroke,
                    comparisonFill: point.fill,
                    currentFill: point.stroke,
                    isSplit: true,
                  };
                }

                if ( point.value > _seriesMax ) {
                  _seriesMax = point.value;
                }
              } );
            }
          } );
          _max = _seriesMax;
          _chartData.max = _seriesMax;
          _chartData.series = strippedData;
          setSeriesLegendData( _seriesLegendData );
        }
      }
      setYMax( _max );
      setChartData( _chartData );
    }
  }, [ settings, widgetVersion, data ] );

  const getHeaderForNumberVariant = reportType => {
    if ( isNotEmpty( reportType ) ) {
      if ( reportType === 'hosts' ) {
        return 'Current Host Count';
      }
      if ( reportType === 'patches' ) {
        return 'Current Outstanding Patches Count';
      }
      if ( reportType === 'vulnerabilities' ) {
        return 'Current Outstanding Vulnerabilities Count';
      }
      if ( reportType === 'vulnerability_instances' ) {
        return 'Current Outstanding Vulnerability Instances Count';
      }
      if ( reportType === 'risk' ) {
        return 'Current Risk Score';
      }
      return 'Current Count';
    }
    return 'Current Count';
  };

  const getIconForNumberVariant = reportType => {
    if ( isNotEmpty( reportType ) ) {
      if ( reportType === 'hosts' ) {
        return <InlineSVG type="host_record" />;
      }
      if ( reportType === 'patches' ) {
        return <InlineSVG type="patch_record" />;
      }
      if ( reportType === 'vulnerabilities' ) {
        return <InlineSVG type="vulnerability_record" />;
      }
      if ( reportType === 'vulnerability_instances' ) {
        return <InlineSVG type="vulnerability_record" />;
      }
      return <InlineSVG type="dsLogoMinimal" />;
    }
    return <InlineSVG type="dsLogoMinimal" />;
  };

  const wordsForComparisonDate = key => {
    if ( isNotEmpty( key ) ) {
      if ( key === 'day' ) {
        return 'yesterday';
      }
      if ( key === 'week' ) {
        return 'last week';
      }
      if ( key === 'month' ) {
        return 'last month';
      }
      if ( key === 'quarter' ) {
        return 'last quarter';
      }
      return 'last year';
    }
    return 'last month';
  };

  const fillForInstanceCategory = ( category, asHex=false, asComparison=false ) => {
    if ( isNotEmpty( category ) ) {
      if ( parentCategoryKeys.includes( category ) ) {
        if ( category === 'prioritized' ) {
          if ( asHex ) {
            if ( asComparison ) {
              return globalColors['status--red--10'];
            }
            return globalColors['status--red'];
          }
          return 'status--red';
        }
        if ( category === 'deprioritized' ) {
          if ( asHex ) {
            if ( asComparison ) {
              return globalColors['status--green--10'];
            }
            return globalColors['status--green'];
          }
          return 'status--green';
        }
        if ( category === 'for_review' ) {
          if ( asHex ) {
            if ( asComparison ) {
              return globalColors['grey--divider'];
            }
            return globalColors['grey'];
          }
          return 'grey';
        }
        return 'darkBlue';
      } else if ( prioritizedKeys.includes( category ) ) {
        if ( asHex ) {
          if ( asComparison ) {
            return globalColors['status--red--10'];
          }
          return globalColors['status--red--75'];
        }
        return 'status--red--75';
      } else if ( deprioritizedKeys.includes( category ) ) {
        if ( asHex ) {
          if ( asComparison ) {
            return globalColors['status--green--10'];
          }
          return globalColors['status--green--75'];
        }
        return 'status--green--75';
      } else if ( forReviewKeys.includes( category ) ) {
        if ( asHex ) {
          if ( asComparison ) {
            return globalColors['grey--background'];
          }
          return globalColors['grey--icon'];
        }
        return 'grey--icon';
      }
      if ( asHex ) {
        if ( asComparison ) {
          return globalColors['grey--divider'];
        }
        return globalColors['darkBlue'];
      }
      return 'darkBlue';
    }
    if ( asHex ) {
      if ( asComparison ) {
        return globalColors['grey--divider'];
      }
      return globalColors['darkBlue'];
    }
    return 'darkBlue';
  };

  return (
    <React.Fragment>
      {
        ( isNotEmpty( chartData ) && isNotEmpty( widgetVersion ) && isNotEmpty( yMax ) )
          ? <React.Fragment>
            {
              widgetVersion === 'number' &&
              <div
                id={ `historyOverTimeSVGWrapper-${item.i}` }
                className="overtimeNumberWrapper"
              >
                <h2>{ getHeaderForNumberVariant( settings?.report_type ) }</h2>
                <div
                  // eslint-disable-next-line max-len
                  className={ `countWrapper ${settings?.report_type} ${ ( settings?.report_type === 'risk' && isNotEmpty( chartData.current?.value ) ) ? `riskClass--${ riskToRating( chartData.current.value ) }` : '' }` }
                >
                  { getIconForNumberVariant( settings?.report_type ) }
                  <strong>{ formatNumber( chartData.current?.value || 0 )}</strong>
                </div>
                <div className="trendWrapper">
                  <div className={ `trendIconWrapper ${trendDirection}` }>
                    {
                      ( trendDirection === 'even' || trendDirection === 'unknown' )
                        ? <span>--</span>
                        : <InlineSVG type={ `trending_${trendDirection}` } />
                    }
                  </div>
                  <span>
                    {
                      trendDirection === 'unknown'
                        // eslint-disable-next-line max-len
                        ? `insufficient data to show comparison to ${ wordsForComparisonDate( settings?.comparison_date ) }`
                        : <React.Fragment>
                          {/* eslint-disable-next-line max-len */}
                          { trendDirection === 'down' ? 'Decreased' : 'Increased' } by <strong>{ formatNumber( trendDelta ) }</strong> since { wordsForComparisonDate( settings?.comparison_date ) }
                        </React.Fragment>
                    }

                  </span>
                </div>
              </div>
            }
            {
              widgetVersion === 'bar' &&
              <div
                id={ `historyOverTimeSVGWrapper-${item.i}` }
                className="overtimeBarWrapper"
                ref={svgContainerRef}
              >
                <YAxisLabels yMax={ yMax } />
                <Bar
                  data={chartData}
                  hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
                  setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
                  svgAspectRatio={svgAspectRatio}
                />
                <Legend
                  legendData={chartData}
                  hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
                  setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
                />
              </div>
            }
            {
              widgetVersion === 'multiBar' &&
              <div
                id={ `historyOverTimeSVGWrapper-${item.i}` }
                className="overtimeMultiBarWrapper"
                ref={svgContainerRef}
              >
                <YAxisLabels yMax={ yMax } />
                <MultiBar
                  data={chartData}
                  hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
                  setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
                  svgAspectRatio={svgAspectRatio}
                />
                {
                  isNotEmpty( seriesLegendData ) &&
                  <Legend
                    legendData={seriesLegendData}
                    hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
                    setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
                  />
                }
              </div>
            }
          </React.Fragment>
          : <EmptyState message="Insufficient data" />
      }
    </React.Fragment>
  );
};

export default ComparisonAnalysis;