/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  formatNumber,
  formatUnixTime,
  isEmpty,
  isNotEmpty,
  reportTypeDisplayName,
  riskToRating,
  vulnScannerNameMap,
  vulnerabilityScannerLogo,
} from '../../../../../shared/Utilities';
import DataTable from '../../../../../shared/DataTable';
import { RiskContext } from '../../../../../Contexts/Risk';
import RatingBadge from '../../../../../shared/RatingBadge';
import RiskReduction from '../../../../../shared/RiskReduction';
import ExploitStatus from '../../../../../shared/ExploitStatus';
import InlineSVG from '../../../../../shared/InlineSVG';

import './VulnerabilityInstancesTable.scss';
import RecordCard from '../../../../RecordDetails/RecordCard';
import EmptyState from '../../../../../shared/EmptyState';

const VulnerabilityInstancesTable = ( { settings, data } ) => {

  const [ tableData, setTableData ] = React.useState( null );
  const [ selectedSignature, setSelectedSignature ] = React.useState( null );

  const [ , targetRisk ] = React.useContext( RiskContext );

  const formatIpAddresses = addresses => {
    const formatted = [];
    if ( addresses ) {
      addresses.map( address => {
        return formatted.push( address.split( '/' )[0] );
      } );
    }
    return formatted.join( ', ' );
  };

  // formatted name of signature (instances)
  const ScannerSignature = ( { row, link=true } ) => {
    let scanner;

    if ( row.scanner in vulnScannerNameMap ) {
      scanner = vulnScannerNameMap[row.scanner];
    }

    if ( !link ) {
      return <p>{`${ scanner ?? row.scanner } - ${ row.signature }`}</p>;
    }

    return (
      <button
        title={`${ scanner } - ${ row.signature }`}
        className={ `relatedSignatureButton ${riskToRating( row?.risk ) }`}
        onClick={ () => setSelectedSignature( row ) }
      >
        { vulnerabilityScannerLogo( row?.scanner ) }
        <span className="name">{`${ scanner ?? row.scanner } - ${ row.signature }`}</span>
      </button>
    );
  };

  // callback closing the signature card
  const handleRecordCardClose = () => {
    setSelectedSignature( null );
  };

  const insightGoToRecord = row => {

    let reportType;
    if ( settings.group_by === 'host' ) {
      reportType = 'hosts';
    } else if ( settings.group_by === 'patch' ) {
      reportType = 'patches';
    } else {
      reportType = 'vulnerabilities';
    }

    const redirectURL = encodeURIComponent( window.location.href );

    // eslint-disable-next-line max-len
    window.location.href = `#.=reports&report=${reportType}&item=${row.id}${ reportType === 'patches' ? '&risk_type=risk' : ''}&redirect_url=${redirectURL}`;
  };

  // The data returned from the server needs to be massaged and adjusted for display
  const transformRowData = row => {
    const riskRating = riskToRating( row.filtered_risk, targetRisk );

    switch ( settings.group_by ) {
    case 'host':
      return {
        risk: <div className={ `rowRiskWrapper ${riskRating}` }>
          <RatingBadge altVersion rating={ riskToRating( row.filtered_risk ) } />
          <RiskReduction item={ row } riskType="filtered_risk" />
        </div>,
        name: <div className="nameLink" onClick={ () => insightGoToRecord( row ) }>
          { reportTypeDisplayName( row, settings.group_by ) }
        </div>,
        vulns: formatNumber( row.num_vulnerabilities ),
        // eslint-disable-next-line camelcase
        product_name: row.product_name,
        addresses: <pre>{ formatIpAddresses( row?.addresses ) }</pre>,
        // eslint-disable-next-line camelcase
        last_scanned: isEmpty( row.last_scanned ) ? 'Never': formatUnixTime( row.last_scanned ),
        id: row.id,
        originalRecord: row,
        actions: <React.Fragment>
          <button className="selectRecordButton" onClick={ () => insightGoToRecord( row ) }>
            <span>View</span>
            <InlineSVG type="carretRight" version="primary"/>
          </button>
        </React.Fragment>,
      };
    case 'patch':
      return {
        risk: <div className={ `rowRiskWrapper ${riskRating}` }>
          <RatingBadge altVersion rating={ riskToRating( row.filtered_risk ) } />
          <RiskReduction item={ row } riskType="filtered_risk" />
        </div>,
        name: <div className="nameLink" onClick={ () => insightGoToRecord( row ) }>
          { reportTypeDisplayName( row, settings.group_by ) }
        </div>,
        hosts: formatNumber( row.num_hosts ),
        vulns: formatNumber( row.num_vulnerabilities ),
        id: row.id,
        originalRecord: row,
        actions: <React.Fragment>
          <button className="selectRecordButton" onClick={ () => insightGoToRecord( row ) }>
            <span>View</span>
            <InlineSVG type="carretRight" version="primary"/>
          </button>
        </React.Fragment>,
      };
    case  'vulnerability':
      return {
        risk: <div className={ `rowRiskWrapper ${riskRating}` }>
          <RatingBadge altVersion rating={ riskToRating( row?.filtered_risk ) } />
          <RiskReduction item={ row } riskType="filtered_risk" />
        </div>,
        name: <div className="nameLink" onClick={ () => insightGoToRecord( row ) }>
          { reportTypeDisplayName( row, settings.group_by ) }
        </div>,
        // eslint-disable-next-line camelcase
        exploit_status: <ExploitStatus status={row?.exploit_status} fullVersion={false} />,
        CVSS: row?.cvss_base_score,
        hosts: formatNumber( row?.num_hosts ),
        id: row.id,
        originalRecord: row,
        actions: <React.Fragment>
          <button className="selectRecordButton" onClick={ () => insightGoToRecord( row ) }>
            <span>View</span>
            <InlineSVG type="carretRight" version="primary"/>
          </button>
        </React.Fragment>,
      };
    case 'signature':
      return {
        risk: <div className={ `rowRiskWrapper ${riskRating}` }>
          <RatingBadge altVersion rating={ riskToRating( row?.filtered_risk ) } />
          <RiskReduction item={ row } riskType="filtered_risk" />
        </div>,
        // eslint-disable-next-line camelcase
        scanner_signature: <ScannerSignature row={ row } />,
        name: <p title={ row.title }>{ row.title }</p>,
        hosts: formatNumber( row.num_hosts ),
        vulns: formatNumber( row.num_vulnerabilities ),
        id: row.id,
        originalRecord: row,
      };
    }
    /* eslint-enable camelcase */
  };

  React.useEffect( () => {
    if ( isNotEmpty( settings ) && isNotEmpty( settings.group_by ) && isNotEmpty( data ) ) {

      const _tableData = [];
      const limit = settings?.item_count || 10;

      data.results.map( ( row, index ) => {
        if ( index < limit ) {
          const rowData = transformRowData( row );
          _tableData.push( rowData );
        }
      } );
      setTableData( _tableData );
    }
  }, [ settings, data ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( selectedSignature ) &&
        <RecordCard
          record={ selectedSignature }
          type="signature"
          context="risk_insight_signature_instances"
          show={ isNotEmpty( selectedSignature ) }
          setShow={ handleRecordCardClose }
          options={ {
            isDismissable: true,
            // eslint-disable-next-line camelcase
            include_shade: true,
          } }
        />
      }
      {
        isNotEmpty( tableData )
          ? <div className={ `vulnerabilityInstancesWidgetTableWrapper ${settings?.group_by}`}>
            <DataTable
              data={ tableData }
              allowHover
            />
          </div>
          : <EmptyState message="No data available" />
      }
    </React.Fragment>
  );
};

export default VulnerabilityInstancesTable;