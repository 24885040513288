/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { attackScenarioLabelMap, formatNumber, isNotEmpty, riskToRating } from '../../shared/Utilities';
import InlineSVG from '../../shared/InlineSVG';

import './RecordStatistic.scss';
import ExploitStatus from '../../shared/ExploitStatus';

const RecordStatistic = ( {
  record,
  statisticsKey,
  recordInstanceData,
  riskType,
  fullPanelVersion=false,
  linkHref=null,
} ) => {

  const [ content, setContent ] = React.useState( null );

  const labelForStat = {
    /* eslint-disable camelcase */
    risk_reduction: 'Risk Reduction',
    vulnerability_instances: 'Vuln. Instances',
    patches: 'All Patches',
    unsuperseded_patches: 'Unsuperseded Patches',
    risk_rating: 'Risk Rating',
    sensitive_nodes: 'Sensitive Assets',
    vulnerabilities: 'Vulnerabilities',
    exploitable_vulns_at_risk_count: 'Exploitable Vulns. ',
    exploit_status: 'Exploit Status',
    hosts: 'Affected Hosts',
    active_hosts: 'Recently Accessed Hosts',
    domain_groups: 'Domain Groups',
    steps: 'Steps Required',
    num_vulnerabilities: 'Vulnerabilities Exploited',
    users_at_risk: 'Users at Risk',
    /* eslint-enable camelcase */
  };

  const iconForStat = {
    /* eslint-disable camelcase */
    risk_reduction: <React.Fragment></React.Fragment>,
    vulnerability_instances: <InlineSVG type="vulnerabilitiesAlt" />,
    patches: <InlineSVG type="patchesAlt" />,
    unsuperseded_patches: <InlineSVG type="patchesAlt" />,
    risk_rating: <React.Fragment></React.Fragment>,
    sensitive_nodes: <InlineSVG type="folderAlt" />,
    vulnerabilities: <InlineSVG type="vulnerabilitiesAlt" />,
    exploitable_vulns_at_risk_count: <InlineSVG type="vulnerabilitiesAlt" />,
    exploit_status: <React.Fragment></React.Fragment>,
    hosts: <InlineSVG type="hostsAlt" />,
    active_hosts: <InlineSVG type="hostsAlt" />,
    domain_groups: <InlineSVG type="domain_group" />,
    steps: <InlineSVG type="segmentsAlt" />,
    num_vulnerabilities: <InlineSVG type="vulnerability_record" />,
    users_at_risk: <InlineSVG type="domain_group" />,
    /* eslint-enable camelcase */
  };

  const attributeForStat = {
    /* eslint-disable camelcase */
    vulnerability_instances: () => formatNumber( recordInstanceData?.tally?.results?._total ),
    patches: () => formatNumber( record.num_patches ),
    unsuperseded_patches: () => formatNumber( record.num_unsuperseded_patches ),
    sensitive_nodes: () => formatNumber( record.num_sensitive_nodes ),
    vulnerabilities: () => formatNumber( record.num_vulnerabilities ),
    exploitable_vulns_at_risk_count: () => formatNumber( record.exploitable_vulns_at_risk_count ),
    exploit_status: () => <ExploitStatus status={ record.exploit_status } />,
    active_hosts: () => formatNumber( record.active_hosts?.length ),
    domain_groups: () => formatNumber( record.domain_groups?.length ),
    hosts: () => formatNumber( record.num_hosts ),
    steps: () => formatNumber( record.edges?.length || 0 ),
    num_vulnerabilities: () => formatNumber( record.pathTopVulnerabilities?.length || 0 ),
    /* eslint-enable camelcase */
  };

  const getIconForStat = ( stat ) => {
    if ( Object.keys( attackScenarioLabelMap ).includes( stat ) ) {
      return <InlineSVG type={stat} />;
    }
    return iconForStat[stat];
  };

  const getLabelForStat = ( stat ) => {
    if ( Object.keys( attackScenarioLabelMap ).includes( stat ) ) {
      return attackScenarioLabelMap[stat];
    }
    if ( fullPanelVersion && stat === 'vulnerabilities' ) {
      return 'All Vulnerabilities';
    }
    return labelForStat[stat];
  };

  const getAttributeForStat = ( stat ) => {
    if ( Object.keys( attackScenarioLabelMap ).includes( stat ) ) {
      return formatNumber( record.vulnerability_type_counts[stat] );
    }
    return attributeForStat[stat]();
  };

  const needsAlertIcon = () => statisticsKey === 'sensitive_nodes' && getAttributeForStat( statisticsKey ) !== '0';

  // pulling this out so that I don't have to repeat it down below
  const statContent = ( item, stat, riskType ) => {
    return (
      <React.Fragment>
        <span className="statisticLabel">
          { getLabelForStat( stat ) }
        </span>
        {/* eslint-disable-next-line max-len */}
        <span className={ `statisticValue ${stat} ${riskToRating( item[riskType] )} ${needsAlertIcon() ? 'needsAlert' : ''}` }>
          { getIconForStat( stat ) }
          { getAttributeForStat( stat ) }
        </span>
      </React.Fragment>
    );
  };

  React.useEffect( () => {
    if ( isNotEmpty( record ) ) {
      setContent( statContent( record, statisticsKey, riskType ) );
    }
  }, [ record, statisticsKey, riskType ] );

  return (
    <React.Fragment>
      {
        ( isNotEmpty( record ) && isNotEmpty( statisticsKey ) ) &&
        <React.Fragment>
          {
            isNotEmpty( linkHref )
              ? <a
                className={ `recordStatisticWrapper ${statisticsKey} asLink` }
                href={ linkHref }
                target="_blank"
                rel="noopener noreferrer"
              >
                { isNotEmpty( content ) && content }
              </a>
              : <div className={ `recordStatisticWrapper ${statisticsKey}` }>
                { isNotEmpty( content ) && content }
              </div>
          }
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default RecordStatistic;