/** **************************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
****************************************************************************/

import React from 'react';
import { makeRequest } from '../../legacy/io';
import { isFalsey, isNotEmpty } from '../shared/Utilities';

export const CurrentUserContext = React.createContext();

export const CurrentUserProvider = ( { children } ) => {

  const [ currentUser, setCurrentUser ] = React.useState( {} );
  const [ licenseInfo, setLicenseInfo ] = React.useState( {} );
  const [ demoMode, setDemoMode ] = React.useState( false );

  React.useEffect( () => {
    let isMounted = true;
    makeRequest( 'POST', 'fe/profile/FETCH', {} ).then( response => {
      if ( isMounted ) {
        setCurrentUser( response );
      }
    } );
    makeRequest( 'FETCH', '/license_info' ).then( response => {
      if ( isMounted ) {
        setLicenseInfo( response );
      }
    } );
    makeRequest( 'ICONFIG', '/global_setting', { key: 'DEMO_MODE' } ).then( response => {
      // setDemoMode( true );
      if ( isMounted && isNotEmpty( response ) ) {
        if ( isFalsey( response.results ) ) {
          setDemoMode( false );
        } else {
          setDemoMode( true );
        }
      }
    } );
    return () => {
      isMounted = false;
    };
  }, [] );

  const data = [
    currentUser,
    setCurrentUser,
    licenseInfo,
    demoMode,
  ];

  return (
    <CurrentUserContext.Provider value={ data }>
      { children }
    </CurrentUserContext.Provider>
  );
};
