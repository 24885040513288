/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';

import EmptyState from '../../../../../shared/EmptyState';
import Form from '../../../../../shared/Form';

import './Step2.scss';

import {
  isNotEmpty,
  pluralizeType,
} from '../../../../../shared/Utilities';
import InlineSVG from '../../../../../shared/InlineSVG';
import { getRecords } from '../../../../../shared/RecordCache';
import RecordList from '../../../../../shared/RecordList';

const Step2 = ( {
  plan,
  setPlan,
  planItems,
  setPlanItems,
  isActive=false,
} ) => {
  // inputs, values, validation for step 1
  const [ step2Fields, setStep2Fields ] = React.useState( [] );

  const [ recordType, setRecordType ] = React.useState( 'host' );

  // show/hide for accordion toggles on step 1
  const [ collapsed, setCollapsed ] = React.useState( { host: true, patch: true, vulnerability: true } );

  const [ remediationItemsLoading, setRemediationItemsLoading ] = React.useState( false );

  const [ remediationItems, setRemediationItems ] = React.useState( [] );

  React.useEffect( () => {
    const _step2Fields = [
      {
        type: 'select',
        attribute: 'type',
        label: 'Type',
        disabled: isActive,
        options: {
          host: 'Host',
          patch: 'Patch',
          vulnerability: 'Vulnerability',
        },
        defaultValue: 'host',
      },
      {
        type: 'debouncedText',
        attribute: 'keywords',
        label: 'Keywords',
        placeholder: 'Keywords...',
        defaultValue: '',
        disabled: isActive,
      },
    ];
    if ( isActive ) {
      setCollapsed( {
        host: false,
        patch: false,
        vulnerability: false,
      } );
    }
    setStep2Fields( _step2Fields );
  }, [ isActive ] );

  // called whenever user selects a type or keyword search
  const refreshRemediationItems = async ( formState ) => {
    if ( isNotEmpty( formState ) && isNotEmpty( formState.fieldStates ) ) {
      setRemediationItemsLoading( true );
      const _type = formState.fieldStates['type'].updatedValue || 'host';
      const _keywords = formState.fieldStates['keywords'].updatedValue || '';
      setRemediationItems( [] );
      showOnlySection( _type );
      setRecordType( _type );

      let fetchType = 'host';

      const params = {
        rows: [ 0, 100 ],
        filters: {},
      };

      if ( _type === 'host' ) {
        params.filters = {
          // eslint-disable-next-line camelcase
          host_has_sensitive_nodes: null,
          // eslint-disable-next-line camelcase
          has_host: true,
          // eslint-disable-next-line camelcase
          accepted_risk: false,
          // eslint-disable-next-line camelcase
          host_keywords: _keywords,
        };
        // eslint-disable-next-line camelcase
        params.risk_type = 'direct_risk';
        // eslint-disable-next-line camelcase
        params.order_by = [
          [ 'filtered_risk', 'DESC' ],
          [ 'local_name', 'ASC' ],
        ];
      }

      if ( _type === 'patch' ) {
        fetchType = 'patch';
        params.filters = {
          superseded: 'unsuperseded',
          // eslint-disable-next-line camelcase
          accepted_risk: false,
          // eslint-disable-next-line camelcase
          patch_keywords: _keywords,
        };
        // eslint-disable-next-line camelcase
        params.risk_type = 'cumulative_risk';
        // eslint-disable-next-line camelcase
        params.order_by = [
          [ 'filtered_risk', 'DESC' ],
          [ 'vendor', 'ASC' ],
          [ 'identifier', 'DESC' ],
        ];
      }

      if ( _type === 'vulnerability' ) {
        fetchType = 'vulnerability';
        params.filters = {
          patchable: null,
          // eslint-disable-next-line camelcase
          accepted_risk: false,
          // eslint-disable-next-line camelcase
          vulnerability_keywords: _keywords,
        };
        // eslint-disable-next-line camelcase
        params.risk_type = 'direct_risk';
        // eslint-disable-next-line camelcase
        params.order_by = [
          [ 'filtered_risk', 'DESC' ],
          [ 'identifier', 'ASC' ],
        ];
      }

      const records = await getRecords( fetchType, params, true );
      setRemediationItems( records );
      setRemediationItemsLoading( false );
    }
  };

  const isCollapsed = type => {
    return collapsed[type];
  };

  const toggleCollapseSection = type => {
    setCollapsed( { ...collapsed, [type]: !collapsed[type] } );
  };

  const showOnlySection = type => {
    const _collapsed = {
      host: true,
      patch: true,
      vulnerability: true,
    };
    _collapsed[type] = false;
    setCollapsed( _collapsed );
  };

  const totalItemCount = () => {
    let count = 0;

    if ( isNotEmpty( planItems ) ) {
      Object.values( planItems ).map( subset => {
        subset.map( () => {
          count += 1;
        } );
      } );
    }
    return count;
  };

  return (
    <React.Fragment>
      {
        ( isNotEmpty( step2Fields ) && !isActive ) &&
        <React.Fragment>
          <div className="panel searchResults remediationPanel">
            <h2>
              { `Available ${pluralizeType( recordType, true ) } (${remediationItems.length})` }
            </h2>
            <Form
              fields={step2Fields}
              trackUpdates={false}
              onChangeCallback={ refreshRemediationItems }
            />
            <RecordList
              orderBy="filtered_risk"
              records={ remediationItems }
              reportType={ recordType }
              sectionKey={ recordType }
              remediationOptions={ {
                plan,
                setPlan,
                planItems,
                setPlanItems,
                remediationItemsLoading,
                isActive,
              } }
            />
          </div>
        </React.Fragment>
      }
      <div className={`${isActive && 'isActive'} panel selectedItems remediationPanel`}>
        {
          isActive
            ? <h2>{ `Risk Items included (${totalItemCount()})` }</h2>
            : <h2>{ `Risk Items to include (${totalItemCount()})` }</h2>
        }
        {
          isNotEmpty( planItems )
            ? <React.Fragment>
              {
                Object.entries( planItems ).map( ( [ type, items ], index ) => {
                  return  <div
                    key={index}
                    className={`${isCollapsed( type ) ? 'collapsed' : ''} collapsibleSectionWrapper`}
                  >
                    <div
                      className="collapsibleSectionHeader"
                      onClick={ () => toggleCollapseSection( type ) }
                    >
                      <div className="headerLeft">
                        <h3>
                          <InlineSVG type={ `${pluralizeType( type )}Alt` }/>
                          {pluralizeType( type, true )}
                        </h3>
                      </div>
                      <div className="headerRight">
                        <strong className="sectionCount">
                          {items.length}
                        </strong>
                        <div className="carretWrapper">
                          <InlineSVG type="carretUp" />
                        </div>
                      </div>
                    </div>
                    <div className="collapsibleSectionBody">
                      {
                        isNotEmpty( items )
                          ? <RecordList
                            orderBy="filtered_risk"
                            records={ items }
                            reportType={ type }
                            sectionKey={ type }
                            remediationOptions={ {
                              plan,
                              setPlan,
                              planItems,
                              setPlanItems,
                              remediationItemsLoading,
                              isActive,
                            } }
                          />
                          : <EmptyState message={`No ${pluralizeType( type )} selected`} />
                      }
                    </div>
                  </div>;
                } )
              }
            </React.Fragment>
            : <EmptyState message="Selected risk items will appear here" />
        }
      </div>
    </React.Fragment>
  );
};

export default Step2;