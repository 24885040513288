/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  capitalize,
  decodeURLHash,
  encodeURLHash,
  isNotEmpty,
  isTruthy,
  itemIsArray,
  paramsToFilters,
  pluralizeType,
  userDisplayName,
} from '../../../shared/Utilities';
import { CurrentUserContext } from '../../../Contexts/CurrentUser';
import { defaultRemdiationItems, planOptionsForReportType, remediationItemsKey, reportTypes } from '../Table/shared';
import { makeRequest } from '../../../../legacy/io';
import { openReportCreator } from '../../../shared/ReportCreator';
import InlineSVG from '../../../shared/InlineSVG';
import Toggle from '../../../shared/Toggle';
import Dropdown from '../../../shared/Dropdown';
import InsightFilters from '../Filters';
import InsightVisual from '../Visual';
import './style.scss';
import AddToPlanMenu from '../../Remediation/AddToPlanMenu';
import { canConfigure } from '../../App/AccessControl';
import { buildParamsForRiskInsight } from '../../../shared/RecordCache';
import { DOES_NOT_EXPIRE } from '../../../shared/ReportCreator/data';

const ReportHeader = ( {
  reportType,
  onRefresh,
  visualCollapsed,
  setVisualCollapsed,
  selectRecord,
  loading,
  riskType=null,
  instancesVisualData=null,
  remediationItems,
  setRemediationItems,
  records,
} ) => {

  const [ visualMode, setVisualMode ] = React.useState( 'visual' );
  const [ currentUser, , licenseInfo ] = React.useContext( CurrentUserContext );

  const handleIncludeRiskCheck = isChecked => {
    if ( isTruthy( isChecked ) ) {
      // eslint-disable-next-line camelcase
      encodeURLHash( { include_risk: 'true', current_page: 1 } );
    } else {
      // eslint-disable-next-line camelcase
      encodeURLHash( { include_risk: 'false', current_page: 1 } );
    }
    onRefresh();
  };

  // eslint-disable-next-line camelcase
  const handleOrderByChange = order_by => {
    // eslint-disable-next-line camelcase
    encodeURLHash( { order_by, current_page: 1 } );
    onRefresh();
  };

  // eslint-disable-next-line camelcase
  const handleOrderDirectionChange = order_direction => {
    // eslint-disable-next-line camelcase
    encodeURLHash( { order_direction, current_page: 1 } );
    onRefresh();
  };

  // eslint-disable-next-line camelcase
  const handleRiskTypeChange = risk_type => {
    // eslint-disable-next-line camelcase
    encodeURLHash( { risk_type, current_page: 1 } );
    onRefresh();
  };

  // eslint-disable-next-line camelcase
  const handlePatchableChange = patchable => {
    // eslint-disable-next-line camelcase
    encodeURLHash( { patchable, current_page: 1 } );
    onRefresh();
  };

  // eslint-disable-next-line camelcase
  const handleGroupTypeChange = group_type => {
    // eslint-disable-next-line camelcase
    if ( group_type === 'patch_cumulative' ) {
      // eslint-disable-next-line camelcase
      encodeURLHash( { group_type, current_page: 1, risk_type: 'cumulative_risk' } );
    // eslint-disable-next-line camelcase
    } else if ( group_type === 'patch' ) {
      // eslint-disable-next-line camelcase
      encodeURLHash( { group_type, current_page: 1, risk_type: 'direct_risk' } );
    } else {
      // eslint-disable-next-line camelcase
      encodeURLHash( { group_type, current_page: 1, risk_type: 'direct_risk' } );
    }

    onRefresh();
  };

  // eslint-disable-next-line camelcase
  const handleInstancesVisualChange = instances_visual_mode => {
    // eslint-disable-next-line camelcase
    encodeURLHash( { instances_visual_mode, current_page: 1 } );
    onRefresh();
  };

  const exportReport = format => {
    const today = new Date();
    // eslint-disable-next-line max-len
    const date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}-${today.getHours()}-${today.getMinutes()}-${today.getSeconds()}`;

    const hash = decodeURLHash();

    const reportID = uuidv4();

    const reportType = isNotEmpty( hash.report ) ? hash.report : 'vulnerability_data';

    let _type = reportTypes[reportType];

    if ( _type === 'instance' ) {
      _type = `instance_${ hash.group_type || 'host'}`;
    }

    const newReport = {
      owner: currentUser.id,
      // eslint-disable-next-line max-len
      label: `${userDisplayName( currentUser, true )}_${capitalize( reportTypes[reportType] )}_Report---${date}`,
      format,
      type: _type,
      id: reportID,
      expiration: DOES_NOT_EXPIRE,
      // eslint-disable-next-line camelcase
      email_recipients: [],
      // eslint-disable-next-line camelcase
      display_options: {},
    };

    if ( reportTypes[reportType] === 'instance' ) {
      // eslint-disable-next-line camelcase
      newReport.display_options.group_type = hash.group_type || 'host';
    }

    if (
      newReport.owner === null
      || newReport.owner === undefined
      || newReport.owner === ''
      || newReport.owner === 'null'
    ) {
      newReport.owner = null;
    }

    const filters = paramsToFilters();

    const params = buildParamsForRiskInsight( filters, reportTypes[reportType] );

    if ( isNotEmpty( params ) ) {
      const { filters, order_by, risk_type, rows } = params;
      newReport.filters = filters;
      // eslint-disable-next-line camelcase
      newReport.order_by = order_by;
      // eslint-disable-next-line camelcase
      newReport.risk_type = risk_type;
      // eslint-disable-next-line
      newReport.item_count = rows[1];
    }

    if ( format === 'xlsx' ) {
      // eslint-disable-next-line camelcase
      newReport.display_options = { full_xlsx_results: true };
      // eslint-disable-next-line camelcase
      newReport.item_count = 200_000;
    }

    if ( isNotEmpty( newReport.filters ) ) {
      delete newReport.filters.include_risk;
      delete newReport.filters.creating_report;
    }

    makeRequest( 'PUT', '/fe/exported_report/INSERT', [ newReport ] ).then( response => {
      if ( response && itemIsArray( response ) ) {
        makeRequest( 'START', '/model/base/exported_report', { id: reportID } ).then( () => {
          window.location.href = `#.=reporting&page=exports&report_id=${reportID}`;
        } );
      }
    } );
  };

  // for the table actions (currently only has export options)
  const getExportMenuItems = () => {
    const items = [
      <div
        onClick={ () => exportReport( 'xlsx' ) }
        title={`Export ${ capitalize( reportType ) } Report`}
      >
        <InlineSVG type="xlsx" size="xlarge" version="special" />
        <span>Quick Export as .xlsx</span>
      </div>,
    ];

    items.push(
      <div
        onClick={ () => exportReport( 'pdf' ) }
        title={`Export ${ capitalize( reportType ) } Report`}
      >
        <InlineSVG type="pdf" size="xlarge" version="special" />
        <span>Quick Export as .pdf</span>
      </div>,
    );

    items.push(
      <div onClick={ () => openReportCreator() }>
        <InlineSVG type="setup" />
        Configure Export
      </div>,
    );

    return items;
  };

  const orderByOptionsForReportType = {
    host: <React.Fragment>
      <option value={ 'filtered_risk' } >Risk</option>
      <option value={ 'num_vulnerabilities' } >Vulnerability Count</option>
    </React.Fragment>,
    patch: <React.Fragment>
      <option value={ 'filtered_risk' } >Risk</option>
      <option value={ 'num_vulnerabilities' } >Vulnerability Count</option>
      <option value={ 'num_hosts' } >Host Count</option>
    </React.Fragment>,
    vulnerability: <React.Fragment>
      <option value={ 'filtered_risk' } >Risk</option>
      <option value={ 'cvss_base_score' } >CVSS Score</option>
      <option value={ 'exploit_status' } >Exploit Status</option>
      <option value={ 'num_hosts' } >Affected Hosts</option>
      <option value={ 'cvss_exploit' } >CVSS Score then Exploit Status</option>
      <option value={ 'cvss_hosts' } >CVSS Score then Affected Hosts</option>
      <option value={ 'exploit_cvss' } >Exploit Status then CVSS Score</option>
      <option value={ 'exploit_hosts' } >Exploit Status then Affected Hosts</option>
      <option value={ 'hosts_exploit' } >Affected Hosts then CVSS Score</option>
    </React.Fragment>,
  };

  const reportTypeSpecificFields = {
    patch: [
      <div className="riskTypeWrapper selectFieldWrapper">
        <span className="labelWrapper">Risk Type</span>
        <label>
          <select
            value={ decodeURLHash().risk_type || 'cumulative_risk' }
            onChange={ e => handleRiskTypeChange( e.target.value ) }
          >
            <option value={ 'cumulative_risk' } >Cumulative</option>
            <option value={ 'direct_risk' } >Direct</option>
          </select>
        </label>
      </div>,
    ],
    vulnerability: [
      <div className="patchableWrapper selectFieldWrapper">
        <span className="labelWrapper">Patches Available?</span>
        <label>
          <select
            value={ decodeURLHash().patchable || 'null' }
            onChange={ e => handlePatchableChange( e.target.value ) }
          >
            <option value={ 'null' } >Any</option>
            <option value={ 'true' } >Yes</option>
            <option value={ 'false' } >No</option>
          </select>
        </label>
      </div>,
    ],
    instance: [
      <div className="groupTypeSelector selectFieldWrapper">
        <label>
          <span className="labelWrapper">Grouped by: </span>
          <select
            value={ decodeURLHash().group_type }
            onChange={ e => handleGroupTypeChange( e.target.value ) }
          >
            <option value="host" >Host</option>
            <option value="patch_cumulative" >Patch (Cumulative)</option>
            <option value="patch" >Patch (Non-cumulative)</option>
            <option value="vulnerability" >Vulnerability</option>
            <option value="signature" >Scanner Signature</option>
          </select>
        </label>
      </div>,
      <div className="instancesVisualModeSelector selectFieldWrapper">
        <label>
          <span className="labelWrapper">Viewing: </span>
          <select
            value={ decodeURLHash().instances_visual_mode }
            onChange={ e => handleInstancesVisualChange( e.target.value ) }
          >
            <option value="categories" >Categories</option>
            <option value="widgets" >Widgets</option>
            <option value="icicle" >Icicle</option>
          </select>
        </label>
      </div>,
    ],
  };

  const addToPlanMenuCallback = ( isAcceptedRisk=false ) => {
    setRemediationItems( defaultRemdiationItems );
    if ( isAcceptedRisk ) {
      onRefresh();
    }
  };

  const recordTypeIconKey = {
    host: 'hosts_record',
    patch: 'patches_record',
    vulnerability: 'vulnerabilities_record',
    instance: 'vulnerabilities_record',
    user: 'users_record',
    path: 'paths_record',
  };

  const allowsRemediation = [
    'host',
    'patch',
    'vulnerability',
    'instance',
  ];

  return (
    <React.Fragment>
      {
        isNotEmpty( reportType ) &&
        <div className={ `riskInsightIndexReportHeader ${reportType} ${visualCollapsed ? 'visualCollapsed' : ''}` }>
          {
            (
              allowsRemediation.includes( reportType )
              && isNotEmpty( remediationItems[remediationItemsKey( reportType )] )
            ) &&
            <AddToPlanMenu
              items={ remediationItems[ remediationItemsKey( reportType ) ] }
              itemsKey={ remediationItemsKey( reportType ) }
              callback={ addToPlanMenuCallback }
              planOptions={ planOptionsForReportType[remediationItemsKey( reportType )] }
            />
          }

          <div className="riskInsightIndexHeaderRow">
            <div className="left">
              <div className="recordIconWrapper">
                <InlineSVG type={ recordTypeIconKey[reportType] } />
              </div>
              <h2><strong>{ capitalize( pluralizeType( reportType ) ) } Report: </strong>Sorted by</h2>
              {
                isNotEmpty( orderByOptionsForReportType[reportType] )
                  ? <div className="orderByWrapper selectFieldWrapper">
                    <label>
                      <select
                        value={ decodeURLHash().order_by || 'filtered_risk' }
                        onChange={ e => handleOrderByChange( e.target.value ) }
                      >
                        { orderByOptionsForReportType[reportType] }
                      </select>
                    </label>
                  </div>
                  : <span className="noOrderByOptions">Risk</span>
              }
              <div className="orderDirectionWrapper selectFieldWrapper">
                <label>
                  <select
                    value={ decodeURLHash().order_direction || 'DESC' }
                    onChange={ e => handleOrderDirectionChange( e.target.value ) }
                  >
                    <option value={ 'DESC' } >DESC</option>
                    <option value={ 'ASC' } >ASC</option>
                  </select>
                </label>
              </div>
              {
                isNotEmpty( reportTypeSpecificFields[reportType] ) &&
                reportTypeSpecificFields[reportType].map( ( f, i ) => {
                  return <React.Fragment key={i}>
                    { f }
                  </React.Fragment>;
                } )
              }
              <label className="includeRiskFieldWrapper">
                <div
                  // eslint-disable-next-line max-len
                  className={ `checkboxFieldWrapper  ${isTruthy( decodeURLHash().include_risk ) ? 'checked' : ''}` }
                >
                  <input
                    type="checkbox"
                    onChange={ e => handleIncludeRiskCheck( e.target.checked ) }
                    checked={ isTruthy( decodeURLHash().include_risk ) }
                  />
                </div>
                <span className="labelWrapper">Include Risk?</span>
              </label>
            </div>
          </div>
          {
            reportType !== 'path' &&
            <Toggle
              toggled={ visualMode }
              setToggled={ setVisualMode }
              elementClass="instanceVisualModeToggle optionCount--2"
              options= { {
                visual: <React.Fragment>
                  <InlineSVG type="risk_insight_nav" />
                  Visual
                </React.Fragment>,
                filters: <React.Fragment>
                  <InlineSVG type="filterAlt" />
                  Filters
                </React.Fragment>,
              } }
            />
          }
          {
            reportType !== 'path' &&
            <Dropdown
              disabled={ !canConfigure( licenseInfo ) }
              trigger={
                // eslint-disable-next-line max-len
                <button disabled={!canConfigure( licenseInfo )} className={ `${ !canConfigure( licenseInfo ) ? 'disabled' : ''} exportOptionsMenuTrigger` }>
                  <span>Export with Filters</span>
                </button>
              }
              menuItems={ getExportMenuItems() }
              elementClass="exportOptionsMenuWrapper"
              menuElementClass="exportOptionsMenu"
            />
          }
          <InsightFilters
            onRefresh={onRefresh}
            visualCollapsed={visualCollapsed}
            setVisualCollapsed={setVisualCollapsed}
            reportType={reportType}
            showAdvancedFilters={ visualMode === 'filters' }
          />
          {
            visualMode === 'visual' &&
            <InsightVisual
              reportType={reportType}
              selectRecord={selectRecord}
              onRefresh={onRefresh}
              records={records}
              riskType={ isNotEmpty( riskType ) ? riskType : 'filtered_risk' }
              loading={loading}
              visualData={instancesVisualData}
            />
          }
        </div>
      }
    </React.Fragment>
  );
};

export default ReportHeader;