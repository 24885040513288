/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { RiskContext } from '../../Contexts/Risk';
import {
  cvssToRating,
  formatNumber,
  globalColors,
  isNotEmpty,
  reportTypeDisplayName,
  riskToRating,
  vulnerabilityScannerLogo,
} from '../Utilities';
import RiskReduction from '../RiskReduction';
import InlineSVG from '../InlineSVG';
import ItemPercentageBar from './ItemPercentageBar';

import './RecordListItem.scss';
import ExploitStatus from '../ExploitStatus';
import PatchRollup from '../../components/RecordDetails/Modals/PatchRollup';
import SensitiveAssetItem from './SensitiveAssetItem';
import AttackScenarioCountLink from './AttackScenarioCountLink';
import UserAtRiskLink from './UserAtRiskLink';

const riskTypes = [
  'risk',
  'filtered_risk',
  'direct_risk',
];

const numTypes = [
  'num_hosts',
  'num_vulnerabilities',
  'num_patches',
];

const otherTypes = [
  'cvss_base_score',
  'exploit_status',
];

const exploitStatusLabelMap = {
  'weaponized': 'Weaponized',
  'poc': 'PoC Published',
  'published_details': 'Details Published',
  'private': 'Private',
};

const fillPalette = {
  critical: globalColors.critical,
  high: globalColors.high,
  moderate: globalColors.moderate,
  low: globalColors.low,
  minimal: globalColors.minimal,
  primary: globalColors.primary,

  'critical-cvss': globalColors['critical--15'],
  'high-cvss': globalColors['high--15'],
  'moderate-cvss': globalColors['moderate--15'],
  'low-cvss': globalColors['low--15'],
  'minimal-cvss': globalColors['minimal--15'],
  'primary-cvss': globalColors['primary--15'],

  darkBlue: globalColors.darkBlue,

  default: globalColors['grey--icon'],
  unknown: globalColors['grey--icon'],

  private: globalColors['grey--divider'],
  poc: globalColors.low,
  // eslint-disable-next-line camelcase
  published_details: globalColors.moderate,
  weaponized: globalColors.high,
};

const needsNoFormatting = [
  'products',
  'descendants',
  'domain_groups',
  'software',
];

const needsVulnerabilityFormatting = [
  'edge_vulnerabilities',
  'accepted_risk_vulnerabilities',
  'num_vulnerabilities',
  'unpatchable_vulnerabilities',
  'patch_vulnerabilities_direct',
  'patch_vulnerabilities_indirect',
  'priority_vulnerabilities',

  // reportTypes
  'vulnerabilities',
  'vulnerability',
];

const needsPatchFormatting = [
  'num_patches',
  'superseded_by',
  'supersedes',
  'priority_patches',

  // reportTypes
  'patches',
  'patch',
];

const patchNeedsRollup = [
  'num_patches',
];

const needsHostFormatting = [
  'accepted_risk_hosts',
  'num_hosts',
  'active_hosts',
  'priority_hosts',

  // reportTypes
  'hosts',
  'host',
];

const needsSignatureFormatting = [
  'scan_results',
];

const needsUserFormatting = [
  'priority_users',

  // reportTypes
  'users',

  // from risk contribution
  'desktop_users_at_risk',
];

const needsActionsFormatting = [
  'additional_actions',
];

const needsSensitiveAssetFormatting = [
  'sensitive_nodes',
  'sensitive_nodes_at_risk',
  'sensitive_assets',
];

const needsAttackScenarioFormatting = [
  'vulnerability_type_counts',
  'attack_scenarios',
];

const needsUsersAtRiskFormatting = [
  'users_at_risk',
];

const RecordListItem = ( {
  record,
  maxAttributeValue,
  reportType,
  orderByAttribute='filtered_risk',
  sectionKey,
  setHoveredListItemID=() => {},
  setHoveredListItemType=() => {},
  setHoveredListItemRating=() => {},
  remediationOptions=null,
  vulnerabilityOptions=null,
} ) => {

  const [ formattedItem, setFormattedItem ] = React.useState( null );
  const [ globalRisk ] = React.useContext( RiskContext );

  // selection toggling (used for remediation, but potentially more in the future)
  // eslint-disable-next-line max-len
  const hasBeenSelected = ( item, type, planItems={} ) => planItems[type] && planItems[type].map( i => i.id ).includes( item.id );

  const toggleSelection = async ( item, type, getter={}, setter=() => {} ) => {
    if ( remediationOptions?.isActive === false ) {
      const _planItems = { ...getter };

      if ( hasBeenSelected( item, type, getter ) ) {
        const _items = _planItems[type].filter( i => i.id !== item.id );
        _planItems[type] = _items;
        setter( _planItems );
      } else {
        const _items = [ ..._planItems[type], item ];
        _planItems[type] = _items;
        setter( _planItems );
      }
    }
  };

  // this will highlight something in the paths graph above on hover
  // host will highlight a scope box
  // vuln. will highlight a segment that contains that vuln.
  const handleMouseEnter = ( record, sectionKey ) => {
    if ( sectionKey === 'num_hosts' ) {
      setHoveredListItemID( record.id );
      setHoveredListItemType( 'scope' );
      setHoveredListItemRating( riskToRating( record[orderByAttribute] ) );
    }
  };

  const handleMouseLeave = ( ) => {
    setHoveredListItemID( null );
    setHoveredListItemType( null );
    setHoveredListItemRating( null );
  };

  const getClassKey = ( item, attribute='risk' ) => {
    let _key = 'default';
    if ( isNotEmpty( item ) ) {
      const _value = item[attribute];


      if ( riskTypes.includes( attribute ) ) {
        if (
          sectionKey !== 'active_hosts'
          && ( needsHostFormatting.includes( reportType ) || needsHostFormatting.includes( sectionKey ) )
        ) {
          if ( !item.has_host ) {
            _key = 'unknown';
          } else {
            _key = riskToRating( _value );
          }
        } else {
          _key = riskToRating( _value );
        }
      }

      if ( otherTypes.includes( attribute ) ) {
        if ( attribute === 'exploit_status' ) {
          _key = _value;
        }
        if ( attribute === 'cvss_base_score' ) {
          _key = cvssToRating( _value );
        }
      }

      if ( numTypes.includes( attribute ) ) {
        _key = 'darkBlue';
      }
    }
    return _key;
  };

  // returns a fill color depending on the attr
  const getFillForAttribute = ( item, attribute='risk', max=1 ) => {
    let _fill = fillPalette.default;
    if ( isNotEmpty( item ) ) {
      let _paletteKey = getClassKey( item, attribute, max );
      if ( attribute === 'cvss_base_score' ) {
        _paletteKey = `${_paletteKey}-cvss`;
      }

      _fill = fillPalette[_paletteKey];
    }
    return _fill;
  };

  // returns a border color depending on the attr
  const getStrokeForAttribute = ( item, attribute='risk', max=1 ) => {
    let _stroke = globalColors.grey;
    if ( isNotEmpty( item ) ) {
      const _paletteKey = getClassKey( item, attribute, max );
      _stroke = fillPalette[_paletteKey];
    }
    return _stroke;
  };

  const formattedValue = ( item, attribute='risk', max=1 ) => {

    const _classKey = getClassKey( item, attribute, max );
    if ( isNotEmpty( item ) ) {
      if ( riskTypes.includes( attribute ) ) {
        if (
          ( needsHostFormatting.includes( reportType ) )
          || ( needsHostFormatting.includes( sectionKey ) )
        ) {
          if ( !item.has_host ) {
            return <RiskReduction item={item} itemType="host" riskType={ attribute } />;
          }
          return <RiskReduction item={item} riskType={ attribute } />;
        }
        return <RiskReduction item={item} riskType={ attribute } />;
      }
      if ( otherTypes.includes( attribute ) ) {
        if ( attribute === 'cvss_base_score' ) {
          return <strong className={ `cvssRatingWrapper risk-${_classKey}` }>
            { item[attribute] }
          </strong>;
        }
        if ( attribute === 'exploit_status' ) {
          return <strong className={ `exploitStatusWrapper risk-${item[attribute]}` }>
            { exploitStatusLabelMap[item[attribute]] }
          </strong>;
        }
      }
      if ( numTypes.includes( attribute ) ) {
        return <strong className={ `numCountWrapper risk-${_classKey}` }>
          { formatNumber( item[attribute] ) }
        </strong>;
      }
      return <strong className="emptyAttributeWrapper"> -- </strong>;
    }
  };

  React.useEffect( () => {
    // need to check for reportType or sectionKey, could be either
    if (
      isNotEmpty( record )
      && isNotEmpty( orderByAttribute )
      && isNotEmpty( maxAttributeValue )
      && ( isNotEmpty( reportType ) || isNotEmpty( sectionKey ) )
    ) {

      const _key = isNotEmpty( reportType ) ? reportType : sectionKey;

      const _fill = getFillForAttribute( record, orderByAttribute, maxAttributeValue );
      const _stroke = getStrokeForAttribute( record, orderByAttribute, maxAttributeValue );

      // no formatting
      if ( needsNoFormatting.includes( _key ) ) {
        setFormattedItem( <span className="unformatted">{ record }</span> );
      }

      // hosts formatting
      if ( needsHostFormatting.includes( _key ) ) {
        if ( isNotEmpty( remediationOptions ) ) {
          setFormattedItem(
            <div
              // eslint-disable-next-line max-len
              className={ `${ remediationOptions?.isActive === true ? 'disableClicks' : '' } ${hasBeenSelected( record, 'host', remediationOptions?.planItems ) ? 'isSelected' : ''} fullHover notLink withPercentageBar risk-${getClassKey( record, orderByAttribute, maxAttributeValue ) }` }
              onClick={ () => toggleSelection(
                record,
                'host',
                remediationOptions?.planItems,
                remediationOptions?.setPlanItems,
              ) }
            >
              <div className="itemContent">
                <span className="name">{ reportTypeDisplayName( record, 'host' ) }</span>
                {
                  isNotEmpty( maxAttributeValue ) &&
                  <ItemPercentageBar
                    value={ record[orderByAttribute] }
                    fill={ getFillForAttribute( record, orderByAttribute, maxAttributeValue ) }
                    stroke={ getStrokeForAttribute( record, orderByAttribute, maxAttributeValue ) }
                    maxValue={ maxAttributeValue }
                  />
                }
                {
                  formattedValue( record, orderByAttribute, maxAttributeValue )
                }
              </div>
              {
                remediationOptions?.isActive === false &&
                <React.Fragment>
                  {
                    hasBeenSelected( record, 'host', remediationOptions?.planItems )
                      ? <InlineSVG type="remove" />
                      : <InlineSVG type="add" />
                  }
                </React.Fragment>
              }
            </div>,
          );
        } else {
          setFormattedItem(
            <a
              // eslint-disable-next-line max-len
              href={ `#.=risk_insight&report=hosts&item_count=100&order_by=filtered_risk&order_direction=DESC&item=${record.id}&current_page=1` }
              target="_blank"
              rel="noreferrer noopener"
              // eslint-disable-next-line max-len
              className={ `fullHover withPercentageBar risk-${getClassKey( record, orderByAttribute, maxAttributeValue ) }` }
            >
              <div className="itemContent">
                <span className="name">{ reportTypeDisplayName( record, 'host' ) }</span>
                {
                  isNotEmpty( maxAttributeValue ) &&
                  <ItemPercentageBar
                    value={ record[orderByAttribute] }
                    fill={ getFillForAttribute( record, orderByAttribute, maxAttributeValue ) }
                    stroke={ getStrokeForAttribute( record, orderByAttribute, maxAttributeValue ) }
                    maxValue={ maxAttributeValue }
                  />
                }
                {
                  formattedValue( record, orderByAttribute, maxAttributeValue )
                }
              </div>
              <InlineSVG type="newTabLink" />
            </a>,
          );
        }
      }

      // patches formatting
      if ( needsPatchFormatting.includes( _key ) ) {
        if ( isNotEmpty( remediationOptions ) ) {
          const linkContent = <div
            // eslint-disable-next-line max-len
            className={ `${ remediationOptions?.isActive === true ? 'disableClicks' : '' } ${hasBeenSelected( record, 'patch', remediationOptions?.planItems ) ? 'isSelected' : ''} fullHover notLink withPercentageBar risk-${getClassKey( record, orderByAttribute, maxAttributeValue ) }` }
            onClick={ () => toggleSelection(
              record,
              'patch',
              remediationOptions?.planItems,
              remediationOptions?.setPlanItems,
            ) }
          >
            <div className="itemContent">
              <span className="name">{ reportTypeDisplayName( record, 'patch' ) }</span>
              {
                isNotEmpty( maxAttributeValue ) &&
              <ItemPercentageBar
                value={ record[orderByAttribute] }
                fill={ getFillForAttribute( record, orderByAttribute, maxAttributeValue ) }
                stroke={ getStrokeForAttribute( record, orderByAttribute, maxAttributeValue ) }
                maxValue={ maxAttributeValue }
              />
              }
              {
                formattedValue( record, orderByAttribute, maxAttributeValue )
              }
            </div>
            {
              remediationOptions?.isActive === false &&
              <React.Fragment>
                {
                  hasBeenSelected( record, 'patch', remediationOptions?.planItems )
                    ? <InlineSVG type="remove" />
                    : <InlineSVG type="add" />
                }
              </React.Fragment>
            }
          </div>;

          setFormattedItem( <React.Fragment>
            {
              patchNeedsRollup.includes( sectionKey )
                ? <div className="patchItemWithRollupWrapper">
                  <PatchRollup
                    item={record}
                    riskType={orderByAttribute}
                    itemRisk={ record[orderByAttribute] }
                    itemRating={ riskToRating( record[orderByAttribute] ) }
                  />
                  { linkContent }
                </div>
                : linkContent
            }
          </React.Fragment>,
          );
        } else {
          const linkContent = <a
            // eslint-disable-next-line max-len
            href={ `#.=risk_insight&report=patches&risk_type=cumulative_risk&item_count=100&superseded=unsuperseded&order_by=filtered_risk&order_direction=DESC&item=${record.id}&current_page=1` }
            target="_blank"
            rel="noreferrer noopener"
            // eslint-disable-next-line max-len
            className={ `fullHover withPercentageBar risk-${getClassKey( record, orderByAttribute, maxAttributeValue ) }` }
          >
            <div className="itemContent">
              <span className="name">{ reportTypeDisplayName( record, 'patch' ) }</span>
              {
                isNotEmpty( maxAttributeValue ) &&
                <ItemPercentageBar
                  value={ record[orderByAttribute] }
                  fill={ getFillForAttribute( record, orderByAttribute, maxAttributeValue ) }
                  stroke={ getStrokeForAttribute( record, orderByAttribute, maxAttributeValue ) }
                  maxValue={ maxAttributeValue }
                />
              }
              {
                formattedValue( record, orderByAttribute, maxAttributeValue )
              }
            </div>
            <InlineSVG type="newTabLink" />
          </a>;

          setFormattedItem( <React.Fragment>
            {
              patchNeedsRollup.includes( sectionKey )
                ? <div className="patchItemWithRollupWrapper">
                  <PatchRollup
                    item={record}
                    riskType={orderByAttribute}
                    itemRisk={ record[orderByAttribute] }
                    itemRating={ riskToRating( record[orderByAttribute] ) }
                  />
                  { linkContent }
                </div>
                : linkContent
            }
          </React.Fragment>,
          );
        }
      }

      // vulnerability formatting
      if ( needsVulnerabilityFormatting.includes( _key ) ) {
        // variant used to add a vuln. to a remediation plan
        if ( isNotEmpty( remediationOptions ) ) {
          const innerContent = <React.Fragment>
            <div className="itemContent">
              <span className="name vulnerability">{ reportTypeDisplayName( record, 'vulnerability' ) }</span>
              {
                ( isNotEmpty( maxAttributeValue ) && orderByAttribute !== 'exploit_status' ) &&
                <ItemPercentageBar
                  value={ record[orderByAttribute] }
                  fill={ _fill }
                  stroke={ _stroke }
                  maxValue={ maxAttributeValue }
                />
              }
              {
                orderByAttribute === 'exploit_status' &&
                <ExploitStatus fullVersion={ false } status={ record.exploit_status } />
              }
              {
                formattedValue( record, orderByAttribute, maxAttributeValue )
              }
            </div>
            {
              remediationOptions?.isActive === false &&
              <React.Fragment>
                {
                  hasBeenSelected( record, 'vulnerability', remediationOptions?.planItems )
                    ? <InlineSVG type="remove" />
                    : <InlineSVG type="add" />
                }
              </React.Fragment>
            }

          </React.Fragment>;

          setFormattedItem(
            <div
              // eslint-disable-next-line max-len
              className={ `${ remediationOptions?.isActive === true ? 'disableClicks' : '' } ${hasBeenSelected( record, 'vulnerability', remediationOptions?.planItems ) ? 'isSelected' : ''} fullHover notLink withPercentageBar risk-${getClassKey( record, orderByAttribute, maxAttributeValue ) }` }
              onClick={ () => toggleSelection(
                record,
                'vulnerability',
                remediationOptions?.planItems,
                remediationOptions?.setPlanItems,
              ) }
            >
              { innerContent }
            </div>,
          );
        // variant used in edge list of vulns. to select and see more information about a given vuln.
        } else if ( isNotEmpty( vulnerabilityOptions ) ) {
          const innerContent = <React.Fragment>
            <div className="itemContent">
              <span className="name vulnerability">{ reportTypeDisplayName( record, 'vulnerability' ) }</span>
              {
                ( isNotEmpty( maxAttributeValue ) && orderByAttribute !== 'exploit_status' ) &&
                <ItemPercentageBar
                  value={ record[orderByAttribute] }
                  fill={ _fill }
                  stroke={ _stroke }
                  maxValue={ maxAttributeValue }
                />
              }
              {
                orderByAttribute === 'exploit_status' &&
                <ExploitStatus fullVersion={ false } status={ record.exploit_status } />
              }
              {
                formattedValue( record, orderByAttribute, maxAttributeValue )
              }
            </div>
            <InlineSVG type="carretRight" />
          </React.Fragment>;

          setFormattedItem(
            <div
              // eslint-disable-next-line max-len
              className={ `${vulnerabilityOptions?.selectedEdgeVulnerability?.id === record.id ? 'isSelected' : ''} fullHover notLink withPercentageBar risk-${getClassKey( record, orderByAttribute, maxAttributeValue ) }` }
              onClick={ () => vulnerabilityOptions?.toggleSelectedEdgeVulnerability( record ) }
            >
              { innerContent }
            </div>,
          );
        } else {
          const innerContent = <React.Fragment>
            <div className="itemContent">
              <span className="name vulnerability">{ reportTypeDisplayName( record, 'vulnerability' ) }</span>
              {
                ( isNotEmpty( maxAttributeValue ) && orderByAttribute !== 'exploit_status' ) &&
                <ItemPercentageBar
                  value={ record[orderByAttribute] }
                  fill={ _fill }
                  stroke={ _stroke }
                  maxValue={ maxAttributeValue }
                />
              }
              {
                orderByAttribute === 'exploit_status' &&
                <ExploitStatus fullVersion={ false } status={ record.exploit_status } />
              }
              {
                formattedValue( record, orderByAttribute, maxAttributeValue )
              }
            </div>
          </React.Fragment>;

          setFormattedItem(
            <a
              // eslint-disable-next-line max-len
              href={ `#.=risk_insight&report=vulnerabilities&item_count=100&order_by=filtered_risk&order_direction=DESC&item=${record.id}&current_page=1` }
              target="_blank"
              rel="noreferrer noopener"
              // eslint-disable-next-line max-len
              className={ `fullHover withPercentageBar risk-${getClassKey( record, orderByAttribute, maxAttributeValue ) }` }
            >
              { innerContent }
              <InlineSVG type="newTabLink" />
            </a>,
          );
        }
      }

      // user formatting
      if ( needsUserFormatting.includes( _key ) ) {
        setFormattedItem(
          <a
            // eslint-disable-next-line max-len
            href={ `#.=risk_insight&report=users&item=${record.id}&order_by=filtered_risk&order_direction=DESC&item_count=100&current_page=1` }
            target="_blank"
            rel="noreferrer noopener"
            className={ `fullHover withPercentageBar risk-${riskToRating( record[orderByAttribute] ) }` }
          >
            <div className="itemContent">
              <span className="name">{ reportTypeDisplayName( record, 'user' ) }</span>
              {
                isNotEmpty( maxAttributeValue ) &&
                <ItemPercentageBar
                  value={ record[orderByAttribute] }
                  fill={ getFillForAttribute( record, orderByAttribute, maxAttributeValue ) }
                  stroke={ getStrokeForAttribute( record, orderByAttribute, maxAttributeValue ) }
                  maxValue={ maxAttributeValue }
                />
              }
              {
                formattedValue( record, orderByAttribute, maxAttributeValue )
              }
            </div>
            <InlineSVG type="newTabLink" />
          </a>,
        );
      }

      // signature formatting
      if ( needsSignatureFormatting.includes( _key ) ) {
        setFormattedItem(
          <button
            onClick={ () => {} }
            className={ `relatedSignatureButton ${riskToRating( record.record?.risk ) }`}
          >
            <div className="labelWrapper">
              { vulnerabilityScannerLogo( record.record?.scanner ) }
              <strong>{ record.label }</strong>
              <span>{ record.signature }</span>
            </div>
            <span className="timestamp" >{ record.timestamp }</span>
          </button>,
        );
      }

      // actions formatting
      if ( needsActionsFormatting.includes( _key ) ) {
        setFormattedItem(
          <a
            href={ record.href }
            target="_blank"
            rel="noreferrer noopener"
            className="additionalActionLink"
          >
            <span>{ record.identifier}: <strong>{ record.label }</strong></span>
            <InlineSVG type="link" />
          </a>,
        );
      }

      // sensitive asset formatting
      if ( needsSensitiveAssetFormatting.includes( _key ) ) {
        setFormattedItem( <SensitiveAssetItem asset={ record } includeDot={ _key === 'sensitive_nodes_at_risk' } /> );
      }

      // attackScenario formatting
      if ( needsAttackScenarioFormatting.includes( _key ) ) {
        setFormattedItem( <AttackScenarioCountLink attackScenario={record} recordType="host" /> );
      }

      // users at risk formatting
      if ( needsUsersAtRiskFormatting.includes( _key ) ) {
        setFormattedItem( <UserAtRiskLink user={record} /> );
      }
    }
  }, [
    record,
    maxAttributeValue,
    globalRisk,
    reportType,
    sectionKey,
    orderByAttribute,
    remediationOptions,
    vulnerabilityOptions,
  ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( formattedItem ) &&
        <li
          className="recordListItem"
          onMouseEnter={ e => handleMouseEnter( record, sectionKey, e ) }
          onMouseLeave={ handleMouseLeave }
        >
          { formattedItem }
        </li>
      }
    </React.Fragment>
  );
};

export default RecordListItem;