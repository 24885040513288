/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { makeRequest } from '../../../../legacy/io';
import {
  decodeURLHash,
  getDimensionsAndOffset,
  isEmpty,
  isNotEmpty,
  itemIsArray,
  promiseAllSequential,
  useLocalStorage,
} from '../../../shared/Utilities';
import { aclWidgetKeys, fetchForCacheKey, getLatestWidgetVersion, getWidgetCacheKey } from './shared';
import { Responsive } from 'react-grid-layout';
import ReportCreator, { openReportCreator } from '../../../shared/ReportCreator';
import { NavigationContext } from '../../../Contexts/Navigation';
import { v4 as uuidv4 } from 'uuid';

import './style.scss';
import WidgetWrapperV2 from './Widgets/WidgetWrapperV2';
import { FlashMessageQueueContext } from '../../../Contexts/FlashMessageQueue';
import Loading from '../../../shared/Loading';
import WidgetEditorModal from './WidgetEditorModal';
import PageHeader from '../../../shared/PageHeader';
import DashboardEditorV2 from './DashboardEditorV2';
import InlineSVG from '../../../shared/InlineSVG';
import PageCreateButton from '../../../shared/PageCreateButton';
import DashboardSelector from './DashboardSelector';
import { PrintingContext } from '../../../Contexts/Printing';
import { CurrentUserContext } from '../../../Contexts/CurrentUser';
import { TagsContext } from '../../../Contexts/Tags';
import { canConfigure, hasFeatureAccess } from '../../App/AccessControl';
import EmptyState from '../../../shared/EmptyState';

const Grid = Responsive;

const DashboardsV2 = ( ) => {

  const dashboardWrapperRef = React.useRef( null );
  const editorRef = React.useRef( null );

  const [ expandLeftNavigation ] = React.useContext( NavigationContext );
  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );
  const [ , setPrintingOrientation ] = React.useContext( PrintingContext );
  const [ currentUser, , licenseInfo ] = React.useContext( CurrentUserContext );

  const [ tags ] = React.useContext( TagsContext );

  // the current dashboard that should be rendered, stored in local storage
  const [ currentLayoutID, setCurrentLayoutID ] = useLocalStorage( 'DSdashboardCurrentLayoutID', '' );
  // the options for the dashboard selector
  // { [uuid]: { h, w, x, y, etc. }, etc. }
  const [ dashboardOptions, setDashBoardOptions ] = React.useState( null );
  const [ currentDashboard, setCurrentDashboard ] = React.useState( null );

  // used to hold all the vars needed for the grid to render the box for a widget
  // { [i]: { h, w, x, y, etc. }, etc. }
  const [ layoutWidgets, setLayoutWidgets ] = React.useState( null );
  // used to hold all the vars needed for the widgets to display the content that they should
  // { [i]: { settings, label, version, etc. }, etc. }
  const [ contentWidgets, setContentWidgets ] = React.useState( null );

  const [ showWidgetEditor, setShowWidgetEditor ] = React.useState( false );
  const [ printing, setPrinting ] = React.useState( false );
  const [ showPageBreaks, setShowPageBreaks ] = useLocalStorage( 'DSdashboardShowPageBreaks', false );
  const [ pageCount, setPageCount ] = React.useState( 1 );
  const [ gridWidth, setGridWidth ] = React.useState( 1270 );
  const [ editMode, setEditMode ] = React.useState( false );
  const [ initialDashboardFetchComplete, setInitialDashboardFetchComplete ] = React.useState( false );
  const [ existingReport, setExistingReport ] = React.useState( null );
  const [ gridLoading, setGridLoading ] = React.useState( false );
  const [ updatedDashboardForm, setUpdatedDashboardForm ] = React.useState( null );
  const [ groupedComplianceData, setGroupedComplianceData ] = React.useState( null );
  const [ complianceFieldsOptions, setComplianceFieldsOptions ] = React.useState( null );
  const [ ownedDashboards, setOwnedDashboards ] = React.useState( null );
  const [ prefetchedACLWidgetData, setPrefetchedACLWidgetData ] = React.useState( null );

  // widget and variant related vars, need to be here so they can pass down to all children
  const [ selectedWidgetVariant, setSelectedWidgetVariant ] = React.useState( null );
  const [ selectedWidgetOptions, setSelectedWidgetOptions ] = React.useState( null );
  const [ selectedWidgetCategory, setSelectedWidgetCategory ] = React.useState( null );
  // IMPORTANT: this is the recordLabel that gets saved after selecting a record for the top N paths,
  // prevents a totally unnecessary separate lookup later for the label display
  const [ recordLabel, setRecordLabel ] = React.useState( null );
  const [ currentWidget, setCurrentWidget ] = React.useState( null );

  // only called once on page load (will trigger a data prefetch)
  // 1. uploads all the current builtin dashboards so that they are always current
  // 2. calls setupWidgetState
  // 3. sets up the report creator if needed
  const onLoad = async () => {
    const _ownedDashboards = [];


    // fetch any dashboards that this user has access to
    const accessParams = {
      columns: [
        'id',
        'modified',
        'user_id',
        'access_level',
        'asset_tag_id',
        'dashboard_id',
        'remediation_plan_id',
      ],
      // eslint-disable-next-line camelcase
      filters: { field_map: { user_id: currentUser.id } },
    };

    const _access = await makeRequest( 'POST', '/fe/object_access/SELECT', accessParams );

    if ( isNotEmpty( _access ) && itemIsArray( _access ) ) {
      _access.map( item => {
        if ( isNotEmpty( item.dashboard_id ) ) {
          _ownedDashboards.push( item );
        }
      } );
      setOwnedDashboards( _ownedDashboards );
    }

    setupWidgetState( _ownedDashboards );

    const hash = decodeURLHash();

    if ( hash.creating_report ) {
      const project = 'default';
      const model = 'base';
      const filters = {
        // eslint-disable-next-line camelcase
        extra_columns: [
          'created',
          'email_recipients',
          'format',
          'filters',
          'id',
          'label',
          'last_finished',
          'last_started',
          'schedule',
          'expiration',
          'type',
          'owner',
          'state',
          'display_options',
        ],
        // eslint-disable-next-line camelcase
        id_list: [ hash.report_id ],
      };

      if ( isNotEmpty( hash.report_id ) ) {
        const reportRequest = await makeRequest( 'SEARCH', '/model/base/exported_report', {
          project,
          model,
          filters,
        } );

        if ( reportRequest && reportRequest.results ) {
          setExistingReport( reportRequest.results[0] );
        } else {
          setExistingReport( null );
        }
        openReportCreator();
      } else {
        setExistingReport( null );
        openReportCreator();
      }
    }
  };

  // eslint-disable-next-line max-len
  // called anytime the data changes (on page load, or dash selector change, when a dashboard is created/updated/removed)
  // 1. fetches all existing dashboards
  // 2. separates each existing dashboard into:
  //    a. objects needed for layout
  //    b. objects needed for getting the content
  //    c. one can retrieve from the other because they will both be keyed off of the `i` value of each widget.
  const setupWidgetState = async ( userAccessDashboards=[] ) => {

    const _layoutWidgets = {};
    const _contentWidgets = {};
    const _dashboardOptions = {};
    const _uniqueCacheKeys = new Set();
    let _currentDashboard = null;

    let _currentID = isNotEmpty( currentLayoutID ) ? currentLayoutID : '00000000-0000-0000-0000-000000000001';

    const dashboardParams = {
      columns: '*',
      filters: {},
      // eslint-disable-next-line camelcase
      order_by: [
        [ 'created', 'DESC' ],
      ],
      rows: [ 0, 100 ],
    };

    const dashboardsResponse = await makeRequest( 'POST', '/fe/dashboard/SELECT', dashboardParams );

    if ( isNotEmpty( dashboardsResponse && itemIsArray( dashboardsResponse ) ) ) {
      // if we have dashboards (should always have at least builtin) just need to save them to state
      // before saving them, we do need to see if any of the widgets need to be updated to the latest version
      // the state will have all the dashboards as an object keyed by id (uuid) and used for the selector
      dashboardsResponse.map( dash => {
        const _dash = {
          ...dash,
          widgets: dash?.widgets?.widgets?.map( w => {
            if ( w.version === 2 ) {
              return w;
            }
            const _latest = getLatestWidgetVersion( w );
            return _latest;
          } ),
        };
        // add to options for the selector
        _dashboardOptions[_dash.id] = _dash;
      } );

      // if the currentlayout id stored in localStorage has been deleted, reset to the default
      if ( isNotEmpty( _dashboardOptions ) && !Object.keys( _dashboardOptions ).includes( _currentID ) ) {
        // _currentID = '00000000-0000-0000-0000-000000000001';
        // setCurrentLayoutID( '00000000-0000-0000-0000-000000000001' );

        [ _currentID ] = Object.keys( _dashboardOptions );
        setCurrentLayoutID( Object.keys( _dashboardOptions )[0] );
      }

      if (
        isNotEmpty( userAccessDashboards )
        && itemIsArray( userAccessDashboards )
        && !Object.keys( _dashboardOptions ).includes( _currentID )
      ) {
        _currentID = userAccessDashboards[0].dashboard_id;
      }

      let prefetchedACLData = {};
      const aclFetchWidgetIDs = [];

      if ( isNotEmpty( _dashboardOptions ) ) {
        _currentDashboard = _dashboardOptions[_currentID];

        if ( isNotEmpty( _currentDashboard ) && isNotEmpty( _currentDashboard.widgets ) ) {


          _currentDashboard.widgets.map( widget => {

            if ( aclWidgetKeys.includes( widget.key ) ) {
              aclFetchWidgetIDs.push( widget.i );
            }

            widget.cacheKey = getWidgetCacheKey( widget );

            // if this cacheKey is not already in the global dashboard cache, add it to the keys that need a fetch
            if ( isEmpty( window.dashboardCache[widget.cacheKey] ) ) {
              _uniqueCacheKeys.add( widget.cacheKey );
            }

            const deconstructedWidget = deconstructWidget( widget );

            _layoutWidgets[widget.i] = deconstructedWidget?.layoutWidget;
            _contentWidgets[widget.i] = deconstructedWidget?.contentWidget;
          } );
          if ( isNotEmpty( aclFetchWidgetIDs ) ) {
            const aclWidgetDataRequest = await makeRequest(
              'POST',
              '/fe/dashboard/widget_data/FETCH',
              {
                // eslint-disable-next-line camelcase
                dashboard_id: _currentDashboard.id,
                // eslint-disable-next-line camelcase
                widget_instance_ids: aclFetchWidgetIDs,
              },
            );
            if ( isNotEmpty( aclWidgetDataRequest ) ) {
              prefetchedACLData = aclWidgetDataRequest;
              setPrefetchedACLWidgetData( prefetchedACLData );
            }
          }
        }
      }

      if ( isNotEmpty( _uniqueCacheKeys ) ) {
        fillDashboardCache( _uniqueCacheKeys, aclFetchWidgetIDs, prefetchedACLData, _currentDashboard.widgets );
      } else {
        setInitialDashboardFetchComplete( true );
      }
      setLayoutWidgets( _layoutWidgets );
      setContentWidgets( _contentWidgets );
      setDashBoardOptions( _dashboardOptions );
      setCurrentDashboard( _currentDashboard );
      updatePageCounts();
    }
  };

  const getPrefetchedACLWidgetData = ( widgetID, prefetchedACLData ) => {
    if ( isNotEmpty( prefetchedACLData ) && isNotEmpty( prefetchedACLData[widgetID] ) ) {
      return prefetchedACLData[widgetID];
    }
    return null;
  };

  // initial setup of the cache, makes sure that there is the correct data in the cache for each widget on load
  const fillDashboardCache = async ( cacheKeys, aclFetchWidgetIDs, prefetchedACLData, dashboardWidgets ) => {
    if ( isNotEmpty( cacheKeys ) ) {

      // turn the set into an array so that we can get the index later and map over it
      const cacheKeysAsArray = [ ...cacheKeys ];

      // get all the fetches needed for each key
      const fetches = cacheKeysAsArray.map( k => {
        const [ widgetKey ] = k.split( '|' );

        if ( !aclWidgetKeys.includes( widgetKey ) ) {
          return fetchForCacheKey( k, tags, aclWidgetKeys.includes( widgetKey ), prefetchedACLData );
        }
      } );

      // if there are needed fetches, that means that they have not been fetched, resolve the promises and add the
      // data to the global cache
      if ( isNotEmpty( fetches ) ) {
        setGridLoading( true );
        const resolvedResponses = await promiseAllSequential( fetches, { chunkSize: 4 } );

        if ( isNotEmpty( resolvedResponses ) ) {
          cacheKeysAsArray.map( ( key, index ) => {
            window.dashboardCache[key] = resolvedResponses[index];
          } );
        }
      }
    }

    // for the prefetched widgets, fill the cache with the data from dashboard/widget_data/FETCH
    if ( isNotEmpty( aclFetchWidgetIDs ) && isNotEmpty( prefetchedACLData ) ) {
      aclFetchWidgetIDs.map( widgetID => {
        const widgetData = getPrefetchedACLWidgetData( widgetID, prefetchedACLData );
        if ( isNotEmpty( widgetData ) ) {
          const dashWidget = dashboardWidgets.find( w => w.i === widgetID );
          if ( isNotEmpty( dashWidget ) ) {
            window.dashboardCache[dashWidget.cacheKey] = widgetData;
          }
        }
      } );
    }
    setGridLoading( false );

    const exampleComplianceFetch = await makeRequest( 'POST', '/fe/history/compliance/SELECT', {
      columns: '*',
      // eslint-disable-next-line camelcase
      order_by: [
        [ 'created', 'ASC' ],
        [ 'id', 'ASC' ],
      ],
      'rows': [ 0, 2_000 ],
    } );

    if ( isNotEmpty( exampleComplianceFetch ) && itemIsArray( exampleComplianceFetch ) ) {
      const _groupedComplianceData = {};
      const _complianceFieldsOptions = {};

      exampleComplianceFetch.map( point => {
        if ( isNotEmpty( point ) && isNotEmpty( point.regulation ) && isNotEmpty( point.created ) ) {
          // very first one
          if ( isEmpty( _groupedComplianceData[point.created] ) ) {
            _groupedComplianceData[point.created] = { [point.regulation]: { [point.control]: point } };
          // timestamp already exists, first of this regulation
          } else if (
            isNotEmpty( _groupedComplianceData[point.created] )
            && isEmpty( _groupedComplianceData[point.created][point.regulation] )
          ) {
            _groupedComplianceData[point.created][point.regulation] = { [point.control]: point };
          // timestamp and regulation already exist
          } else {
            _groupedComplianceData[point.created][point.regulation][point.control] = point;
          }
        }
      } );

      setGroupedComplianceData( _groupedComplianceData );

      if ( isNotEmpty( _groupedComplianceData ) ) {

        const [ latestTimestamp ] = Object.keys( _groupedComplianceData );

        if ( isNotEmpty( _groupedComplianceData[latestTimestamp] ) ) {
          Object.entries( _groupedComplianceData[latestTimestamp] ).map( ( [ regulationKey, controls ] ) => {

            const controlOptions = { all: 'All Controls' };

            Object.keys( controls ).map( controlKey => controlOptions[controlKey] = controlKey );
            _complianceFieldsOptions[regulationKey] = controlOptions;
          } );
        }
      }
      setComplianceFieldsOptions( _complianceFieldsOptions );
    }
    setInitialDashboardFetchComplete( true );
  };

  // called anytime a widget needs to get its data (create/edit)
  // 1. first checks the cache
  // 2. if the cache does not have the data this widget needs, it will kick off the fetch and add to the cache
  const getDataForWidget = async ( widget, tags ) => {

    let data;
    if ( isNotEmpty( widget ) ) {
      const { cacheKey } = widget;

      if ( isNotEmpty( cacheKey ) ) {

        if ( isNotEmpty( window.dashboardCache[cacheKey] ) ) {
          data = window.dashboardCache[cacheKey];
        } else {
          const fetch = fetchForCacheKey( cacheKey, tags );

          if ( isNotEmpty( prefetchedACLWidgetData ) && isNotEmpty( prefetchedACLWidgetData[widget.i] ) ) {
            data = prefetchedACLWidgetData[cacheKey];
          } else {
            data = await fetch();
          }
          window.dashboardCache[cacheKey] = data;
        }
      }
    }
    return data;
  };

  // called to split a saved widget into its layout and content parts
  const deconstructWidget = widget => {

    if ( isNotEmpty( widget ) ) {
      // eslint-disable-next-line max-len
      const { cacheKey, fullLabel, h, i, key, label, maxH, maxW, minH, minW, resizeHandles, settings, version, w, x, y } = widget;

      const layoutWidget = { h, i, key, maxH, maxW, minH, minW, resizeHandles, w, x, y, cacheKey };
      const contentWidget = {
        fullLabel,
        label,
        settings,
        version,
        i,
        key,
        cacheKey,
      };

      return ( { layoutWidget, contentWidget } );
    }
    return ( { } );
  };

  // 1. first thing that happens on page load
  React.useEffect( () => {
    if ( isNotEmpty( currentUser ) ) {
      onLoad();
    }
  }, [ tags, currentUser ] );

  // adjusts sizing when printing
  React.useEffect( ( ) => {
    if ( printing ) {
      setGridWidth( 960 );
      setPrintingOrientation( 'landscape' );
    } else {
      const leftPadding = expandLeftNavigation ? 400 : 124;
      setGridWidth( window.innerWidth - leftPadding );
      setTimeout( () => {
        setPrintingOrientation( 'portrait' );
      }, 100 );
    }
  }, [ printing ] );

  const handleBeforePrint = () => {
    setPrinting( true );
  };

  const handleAfterPrint = () => {
    setPrinting( false );
  };

  // sets up the resize listener so that the padding can correctly be applied to the top of the grid to account
  // for the size of the editor
  React.useEffect( ( ) => {
    adjustGridWidthAndPadding();
    window.addEventListener( 'resize', adjustGridWidthAndPadding );
    window.addEventListener( 'beforeprint', handleBeforePrint );
    window.addEventListener( 'afterprint', handleAfterPrint );
    return () => {
      window.removeEventListener( 'resize', adjustGridWidthAndPadding );
      window.removeEventListener( 'beforeprint', handleBeforePrint );
      window.removeEventListener( 'afterprint', handleAfterPrint );
    };
  }, [ editMode, expandLeftNavigation ] );

  const adjustGridWidthAndPadding = () => {
    if ( !printing ) {
      // full width - the sidebar

      const leftPadding = expandLeftNavigation ? 390 : 124;
      setGridWidth( window.innerWidth - leftPadding );
    }
  };

  // callback from onLayoutChange (comes with react grid layout)
  // all this needs to do is reset the layoutWidgets state with the new state from the manipulation of the grid
  const handleLayoutChange = layout => {
    if ( isNotEmpty( layout ) ) {
      const _layoutWidgets = {};

      layout.map( widget => {
        let oldLayoutWidget = {};

        if ( isNotEmpty( layoutWidgets ) && isNotEmpty( layoutWidgets[widget.i] ) ) {
          oldLayoutWidget = layoutWidgets[widget.i];
        }

        const { h, w, x, y, i, maxH, maxW, minH, minW, resizeHandles } = widget;

        _layoutWidgets[widget.i] = { ...oldLayoutWidget, h, w, x, y, i, maxH, maxW, minH, minW, resizeHandles };
      } );
      setLayoutWidgets( _layoutWidgets );
    }
  };

  // when entering editmode
  const editAndConfigure = () => {
    setEditMode( true );
    setTimeout( () => {
      adjustGridWidthAndPadding();
    }, 100 );
  };

  const handleExportButtonClick = () => {
    if ( currentDashboard.id === '00000000-0000-0000-0000-000000000000' ) {
      openReportCreator();
    } else {
      setGridWidth( 960 );
      setPrinting( true );
      setPrintingOrientation( 'landscape' );
      setTimeout( () => {
        window.print();
      }, 100 );
    }
  };

  const updatePageCounts = () => {
    if ( isNotEmpty( dashboardWrapperRef ) && isNotEmpty( dashboardWrapperRef.current ) ) {
      const { height } = getDimensionsAndOffset( dashboardWrapperRef.current );

      const _pageCount = Math.ceil( height / 720 );

      if ( isNotEmpty( _pageCount ) && _pageCount > 1 ) {
        setPageCount( _pageCount );
      } else {
        setPageCount( 1 );
      }
    }
  };

  const handleShowPageBreakClick = () => {
    updatePageCounts();
    setShowPageBreaks( !showPageBreaks );
  };

  // need to adjust the page count when the grid changes, so that the page breaks update accordingly
  React.useEffect( () => {
    updatePageCounts();
  }, [ layoutWidgets ] );

  const copyDashboard = async () => {
    if ( isNotEmpty( currentDashboard ) ) {
      const copiedDashboard = { ...currentDashboard };

      const uuid = uuidv4();

      copiedDashboard.widgets = { widgets: copiedDashboard.widgets };

      delete copiedDashboard.id;
      delete copiedDashboard.created;
      delete copiedDashboard.modified;
      delete copiedDashboard.builtin;

      copiedDashboard.id = uuid;
      copiedDashboard.label = `${copiedDashboard.label} (copy)`;
      copiedDashboard.shared = false;

      await makeRequest( 'PUT', '/fe/dashboard/INSERT', [ copiedDashboard ] );

      const accessItem = {
        // eslint-disable-next-line camelcase
        user_id: currentUser.id,
        // eslint-disable-next-line camelcase
        access_level: 'owner',
        // eslint-disable-next-line camelcase
        dashboard_id: uuid,
      };

      await makeRequest( 'PUT', '/fe/object_access/UPSERT', [ accessItem ] );

      setCurrentLayoutID( uuid );

      addFlashMessage( {
        body: 'Successfully copied dashboard',
        type: 'success',
      } );
      window.location.reload();

      setEditMode( false );
    }
  };

  const openWidgetEditorFor = widgetCategory => {
    setSelectedWidgetCategory( widgetCategory );
    setShowWidgetEditor( true );
  };

  const closeWidgetEditor = () => {
    setSelectedWidgetCategory( null );
    setShowWidgetEditor( false );
    setCurrentWidget( null );
    setSelectedWidgetVariant( null );
  };

  // widget CRUD functions, add, remove, edit
  const addWidget = widget => {
    if ( isNotEmpty( widget ) ) {

      const getNewWidgetY = () => {
        let _otherItems = [];
        if ( isNotEmpty( currentDashboard?.widgets ) ) {
          _otherItems = [ ...currentDashboard.widgets ];
          _otherItems = _otherItems.sort( ( a, b ) => b.y - a.y );
          return _otherItems[0].y + _otherItems[0].h;
        }
        return 0;
      };

      widget.y = getNewWidgetY();

      if ( isEmpty( widget.x ) ) {
        widget.x = 0;
      }
      widget.cacheKey = getWidgetCacheKey( widget );

      const deconstructedWidget = deconstructWidget( widget );

      const _layoutWidgets = { ...layoutWidgets };
      const _contentWidgets = { ...contentWidgets };
      _layoutWidgets[widget.i] = deconstructedWidget?.layoutWidget;
      _contentWidgets[widget.i] = deconstructedWidget?.contentWidget;

      setLayoutWidgets( _layoutWidgets );
      setContentWidgets( _contentWidgets );

      // scroll container to newly added widget for convenience
      const scrollingContainer = document.getElementById( 'reportingContainer' );
      if ( isNotEmpty( scrollingContainer ) ) {
        window.setTimeout( () => {
          scrollingContainer.scrollTop = scrollingContainer.scrollHeight;
        }, 100 );
      }
    }
  };

  // similar to adding a new widget, just with less adjustments needed
  const editWidget = widget => {
    if ( isNotEmpty( widget ) ) {

      // need to reset the cacheKey in case settings changed that would effect what it is
      widget.cacheKey = getWidgetCacheKey( widget );

      // we need to update the content version of this widget only because this is only called when editing
      // the settings, etc. we do however need to update the layout version with the new cacheKey

      const _layoutWidgets = { ...layoutWidgets };
      const _contentWidgets = { ...contentWidgets };

      _layoutWidgets[widget.i] = { ..._layoutWidgets[widget.i], cacheKey: widget.cacheKey };
      _contentWidgets[widget.i] = widget;

      setLayoutWidgets( _layoutWidgets );
      setContentWidgets( _contentWidgets );
    }
  };

  const removeWidget = widget => {
    if (
      isNotEmpty( widget )
      && isNotEmpty( widget.i )
      && isNotEmpty( layoutWidgets )
      && isNotEmpty( contentWidgets )
    ) {
      const _layoutWidgets = { ...layoutWidgets };
      const _contentWidgets = { ...contentWidgets };

      delete _layoutWidgets[widget.i];
      delete _contentWidgets[widget.i];

      setLayoutWidgets( _layoutWidgets );
      setContentWidgets( _contentWidgets );
    }
  };

  return (
    <React.Fragment>
      <ReportCreator
        existingReport={existingReport}
        setExistingReport={setExistingReport}
        advanced
      />
      <WidgetEditorModal
        showWidgetEditor={ showWidgetEditor}
        setShowWidgetEditor={ setShowWidgetEditor}
        selectedWidgetVariant={ selectedWidgetVariant }
        setSelectedWidgetVariant={ setSelectedWidgetVariant }
        selectedWidgetOptions={ selectedWidgetOptions }
        setSelectedWidgetOptions={ setSelectedWidgetOptions }
        selectedWidgetCategory={ selectedWidgetCategory }
        recordLabel={ recordLabel }
        setRecordLabel={ setRecordLabel }
        closeWidgetEditor={ closeWidgetEditor }
        addWidget={ addWidget }
        editWidget={editWidget}
        setSelectedWidgetCategory={ setSelectedWidgetCategory }
        currentWidget={ currentWidget }
        setCurrentWidget={ setCurrentWidget }
        groupedComplianceData={groupedComplianceData}
        complianceFieldsOptions={ complianceFieldsOptions }
      />
      <PageHeader elementClass="reportingDashboardPageHeader">
        {
          editMode
            ? <React.Fragment>
              <DashboardEditorV2
                setEditMode={setEditMode}
                currentLayoutID={currentLayoutID}
                setCurrentLayoutID={setCurrentLayoutID}
                currentDashboard={currentDashboard}
                setCurrentDashboard={setCurrentDashboard}
                updatedDashboardForm={updatedDashboardForm}
                setUpdatedDashboardForm={setUpdatedDashboardForm}
                editorRef={editorRef}
                layoutWidgets={layoutWidgets}
                contentWidgets={contentWidgets}
                openWidgetEditorFor={openWidgetEditorFor}
                setupWidgetState={setupWidgetState}
              />
            </React.Fragment>
            : <React.Fragment>
              {
                isNotEmpty( currentDashboard ) &&
                <React.Fragment>
                  <h2>
                    {
                      currentDashboard.builtin
                      && <InlineSVG type="primaryLogoBug" version="bug" size="logoBug" elementClass="dsLogo" />
                    }
                    {
                      isNotEmpty( currentDashboard?.label )
                        ? currentDashboard.label
                        : 'Default Dashboard'
                    }
                    {
                      (
                        !currentDashboard.builtin
                        && hasFeatureAccess( currentUser, licenseInfo, 'f_manage_dashboards' )
                      ) &&
                      <button
                        onClick={ editAndConfigure }
                        className="editModeButton"
                        disabled={!canConfigure( licenseInfo )}
                      >
                        <InlineSVG type="setup" />
                        Configure
                      </button>
                    }
                    {
                      hasFeatureAccess( currentUser, licenseInfo, 'f_manage_dashboards' ) &&
                      <button
                        className={ `copyDashboardButton ${!canConfigure( licenseInfo ) ? 'disabled' : '' }` }
                        disabled={!canConfigure( licenseInfo )}
                        onClick={ copyDashboard }
                        title="Duplicate Current Report?"
                      >
                        <InlineSVG type="copy"/>
                      </button>
                    }

                  </h2>
                  <div className="exportActions">
                    <div className="checkboxWrapper" onClick={ handleShowPageBreakClick }>
                      {
                        showPageBreaks
                          ? <InlineSVG type="checkboxChecked" />
                          : <InlineSVG type="checkbox" />
                      }
                      <label>Show page breaks?</label>
                    </div>
                    <button
                      disabled={ !canConfigure( licenseInfo ) }
                      className="exportMenuTrigger"
                      onClick={ handleExportButtonClick }
                    >
                      {
                        (
                          currentDashboard.builtin
                          && currentDashboard.id === '00000000-0000-0000-0000-000000000000'
                        )
                          ? <InlineSVG type="exportFile" />
                          : <InlineSVG type="print" />
                      }
                      {
                        (
                          currentDashboard.builtin
                          && currentDashboard.id === '00000000-0000-0000-0000-000000000000'
                        )
                          ? <span>Export</span>
                          : <span>Print/Save PDF</span>
                      }
                    </button>
                  </div>

                </React.Fragment>
              }
            </React.Fragment>
        }
      </PageHeader>
      <PageCreateButton>
        {
          isNotEmpty( dashboardOptions ) &&
          <DashboardSelector
            setEditMode={setEditMode}
            options={dashboardOptions}
            setDashBoardOptions={setDashBoardOptions}
            currentLayoutID={currentLayoutID}
            setCurrentLayoutID={setCurrentLayoutID}
            currentDashboard={currentDashboard}
            setCurrentDashboard={setCurrentDashboard}
            setLayoutWidgets={setLayoutWidgets}
            setContentWidgets={setContentWidgets}
            ownedDashboards={ownedDashboards}
          />
        }
      </PageCreateButton>
      {
        isEmpty( currentDashboard ) &&
        <EmptyState message="No Dashboards available" />
      }
      <div ref={dashboardWrapperRef} className={ `dashboardsGridWrapper ${printing ? 'printing' : ''}` } >
        { gridLoading && <Loading text="Loading data..." /> }
        {
          ( isNotEmpty( pageCount ) && showPageBreaks ) &&
          <div className="pagesContainer">
            {
              Array( pageCount ).fill().map( ( p, i ) => <div key={i} className="pageWrapper" /> )
            }
          </div>
        }
        {
          (
            isNotEmpty( layoutWidgets )
            && isNotEmpty( contentWidgets )
            && isNotEmpty( Grid )
            && isNotEmpty( gridWidth )
            && initialDashboardFetchComplete
          ) &&
          <Grid
            className="layout"
            width={ gridWidth }
            layouts={ { default: Object.values( layoutWidgets ) } }
            breakpoints={{ default: 0 }}
            cols={ { default: 6 } }
            rowHeight={ 62 }
            margin={ [ 0, 0 ] }
            isDraggable={ editMode }
            isResizable={ editMode }
            isDroppable={ editMode }
            isBounded
            onLayoutChange={ handleLayoutChange }
          >
            {
              Object.values( layoutWidgets ).map( widget => {
                return <div key={widget.i} >
                  <WidgetWrapperV2
                    widget={contentWidgets[widget.i]}
                    layoutWidgets={layoutWidgets}
                    addWidget={addWidget}
                    editWidget={editWidget}
                    removeWidget={removeWidget}
                    editMode={editMode}
                    printing={printing}
                    getDataForWidget={getDataForWidget}
                    setCurrentWidget={setCurrentWidget}
                    setShowWidgetEditor={setShowWidgetEditor}
                    setSelectedWidgetVariant={setSelectedWidgetVariant}
                  />
                </div>;
              } )
            }
          </Grid>
        }
      </div>
    </React.Fragment>
  );
};

export default DashboardsV2;