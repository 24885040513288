/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { attackScenarioLabelMap, isNotEmpty, uniqueArray } from '../../../shared/Utilities';
import ReductionAndRating from '../../../shared/RiskExplanation/ReductionAndRating';
import { recordRiskRating } from '../shared';
import CollapsibleSection from './CollapsibleSection';
import InlineSVG from '../../../shared/InlineSVG';
import { makeRequest } from '../../../../legacy/io';

import './RiskContribution.scss';
import { getRecords } from '../../../shared/RecordCache';

const RiskContribution = ( { record, recordType, riskType } ) => {

  const [ riskClass, setRiskClass ] = React.useState( 'default' );
  const [ adjustedRiskType, setAdjustedRiskType ] = React.useState( null );
  const [ attackScenarioStats, setAttackScenarioStats ] = React.useState( null );

  const [ desktopUsersAtRisk, setDesktopUsersAtRisk ] = React.useState( [] );
  const [ hijackableUsers, setHijackableUsers ] = React.useState( [] );
  const [ sensitiveAssets, setSensitiveAssets ] = React.useState( null );

  const setupUserData = async () => {
    const allUserIDs = uniqueArray( [ ...record.desktop_users_at_risk, ...record.hijackable_users ] );

    /* eslint-disable camelcase */
    if ( isNotEmpty( allUserIDs ) ) {
      const params = {
        model: 'base',
        project: 'default',
        filters: {
          field_map: { type: 'domain_user' },
          extra_columns: [
            'name',
            'node_id',
            'domain_user_analysis.domain_name',
            'domain_user_analysis.risk',
            'domain_user_analysis.active_hosts',
            'domain_user_analysis.domain_groups',
            'sid',
          ],
          id_list: allUserIDs,
          order_by: [
            [ 'domain_user_analysis.risk', 'DESC' ],
            [ 'name', 'ASC' ],
          ],
        },
        rownums: [ 0, allUserIDs.length ],
      };

      const fetchedUsers = await makeRequest( 'SEARCH', '/model/domain_user', params );

      if ( isNotEmpty( fetchedUsers ) && isNotEmpty( fetchedUsers.results ) ) {

        // eslint-disable-next-line max-len
        const _desktopUsersAtRisk = fetchedUsers.results.filter( u => record.desktop_users_at_risk.includes( u.id ) );
        const _hijackableUsers = fetchedUsers.results.filter( u => record.hijackable_users.includes( u.id ) );

        setDesktopUsersAtRisk( _desktopUsersAtRisk );
        setHijackableUsers( _hijackableUsers );
      }
    }
  };

  const setUpSensitiveAssets = async () => {
    let _sensitiveAssets = [];
    const _scopeIDs = [];


    if ( recordType === 'host' && isNotEmpty( record.sensitive_nodes_at_risk ) ) {
      // eslint-disable-next-line camelcase
      const fetchedNodes = await getRecords( 'node', { id_list: record.sensitive_nodes_at_risk } );
      if ( isNotEmpty( fetchedNodes ) ) {
        fetchedNodes.map( n => {
          _sensitiveAssets.push( { ...n, isExposed: true } );
          _scopeIDs.push( n.scope_id );
        } );
      }
    }

    // eslint-disable-next-line camelcase
    const fetchedScopes = await getRecords( 'scope', { id_list: uniqueArray( _scopeIDs ) } );

    const _scopes = {};
    if ( isNotEmpty( fetchedScopes ) ) {
      fetchedScopes.map( s => _scopes[s.id] = s );
    }

    _sensitiveAssets.map( a => {
      a.scope = _scopes[a.scope_id];
    } );

    _sensitiveAssets = _sensitiveAssets.sort( ( a, b ) => b.combined_impact - a.combined_impact );


    setSensitiveAssets( _sensitiveAssets );
  };

  React.useEffect( () => {
    if (
      isNotEmpty( record )
      && isNotEmpty( recordType )
      && recordType === 'host'
    ) {
      const _attackScenarioStats = [];

      if ( isNotEmpty( record.vulnerability_type_counts ) ) {
        Object.keys( attackScenarioLabelMap ).map( key => {
          if ( key in record.vulnerability_type_counts ) {
            _attackScenarioStats.push( {
              key,
              label: attackScenarioLabelMap[key],
              value: record.vulnerability_type_counts[key],
            } );
          }
        } );
      }

      if ( isNotEmpty( record.exploitable_vulns_at_risk_count ) ) {
        _attackScenarioStats.push( {
          key: 'exploitable_vulns_at_risk_count',
          label: 'Exploitable Vulns.',
          value: record.exploitable_vulns_at_risk_count,
        } );
      }
      setAttackScenarioStats( _attackScenarioStats );
      setRiskClass( recordRiskRating( record, recordType ) );
    }

    if ( isNotEmpty( record ) && isNotEmpty( riskType ) ) {
      if ( 'filtered_risk' in record ) {
        setAdjustedRiskType( 'filtered_risk' );
      } else {
        setAdjustedRiskType( riskType );
      }
    } else {
      setAdjustedRiskType( 'filtered_risk' );
    }

    if ( recordType === 'host' && isNotEmpty( record ) ) {
      setupUserData();
      setUpSensitiveAssets();
    }
  }, [ record, riskType ] );

  return (
    <div className={ `riskContributionWrapper ${riskClass} ${recordType}`}>
      <ReductionAndRating
        record={record}
        recordType={recordType}
        riskClass={riskClass}
        riskType={adjustedRiskType}
      />
      <div className="description">
        Factors contributing to this risk rating
      </div>
      {
        isNotEmpty( attackScenarioStats ) &&
        <CollapsibleSection
          sectionKey="attack_scenarios"
          sectionInfo={ {
            label: 'Vuln. Attack Scenarios',
            icon: <InlineSVG type="vulnerabilitiesAlt" />,
            open: true,
            getData: () => {},
          } }
          prefetchedData={attackScenarioStats}
          record={record}
          recordType={recordType}
          riskType={riskType}
        />
      }
      {
        ( isNotEmpty( desktopUsersAtRisk ) || isNotEmpty( hijackableUsers ) ) &&
        <CollapsibleSection
          sectionKey="users_at_risk"
          sectionInfo={ {
            label: 'At Risk Domain/Local Users',
            icon: <InlineSVG type="user_record" />,
            open: true,
            getData: () => {},
          } }
          prefetchedData={ [ ...desktopUsersAtRisk, ...hijackableUsers ] }
          record={record}
          recordType={recordType}
          riskType={riskType}
        />
      }
      {
        isNotEmpty( sensitiveAssets ) &&
        <CollapsibleSection
          sectionKey="sensitive_assets"
          sectionInfo={ {
            label: 'Exposed Sensitive Assets',
            icon: <InlineSVG type="folderAlt" />,
            open: true,
            getData: () => {},
          } }
          prefetchedData={sensitiveAssets}
          record={record}
          recordType={recordType}
          riskType={riskType}
        />
      }
    </div>
  );
};

export default RiskContribution;