/* eslint-disable camelcase */
import React from 'react';
import {
  capitalize,
  complianceFrameworkLabelMap,
  complianceFrameworkLinkMap,
  formatNumber,
  getGlobalSettings,
  isNotEmpty,
} from '../../../shared/Utilities';
import { getRecordDetails, getRecords, getTallies } from '../../../shared/RecordCache';
import { makeRequest } from '../../../../legacy/io';
import { getGlobalRisk } from '../../../shared/GlobalRisk';
import SpecificRecord from './Widgets/v2/SpecificRecord';
import RiskBreakdown from './Widgets/v2/RiskBreakdown';
import SpecificCategory from './Widgets/v2/SpecificCategory';
import RiskScore from './Widgets/v2/RiskScore';
import PeerPercentile from './Widgets/v2/PeerPercentile';
import PeerPercentileOverTime from './Widgets/v2/PeerPercentileOverTime';
import Text from './Widgets/v2/Text';
import ExploitStatusBreakdown from './Widgets/v2/ExploitStatusBreakdown';
import VulnerabilityInstancesTable from './Widgets/v2/VulnerabilityInstancesTable';
import RemediationPlans from './Widgets/v2/RemediationPlans';
import Paths from './Widgets/v2/Paths';
import PriorityRecords from './Widgets/v2/PriorityRecords';
import CVSSBreakdown from './Widgets/v2/CVSSBreakdown';
import VulnerabilityInstancesCategories, {
  categoryLabelsAndDescription,
} from './Widgets/v2/VulnerabilityInstancesCategories';
import RiskOverTimeV3 from './Widgets/v2/RiskOverTimeV3';
import OSFamilyBreakdown from './Widgets/v2/OSFamilyBreakdown';
import TagBreakdown from './Widgets/v2/TagBreakdown';
import VulnerabilityAgeBreakdown from './Widgets/v2/VulnerabilityAgeBreakdown';
import VulnerabilityInstancesTotal from './Widgets/v2/VulnerabilityInstancesTotal';
import ScanningAgeBreakdown from './Widgets/v2/ScanningAgeBreakdown';
import ScanningScanGroupBreakdown from './Widgets/v2/ScanningScanGroupBreakdown';
import ScanningAgentVersionBreakdown from './Widgets/v2/ScanningAgentVersionBreakdown';
import ScanningTotal from './Widgets/v2/ScanningTotal';
import TopRecordDetails from './Widgets/v2/TopRecordDetails';
import RecordTotal from './Widgets/v2/RecordTotal';
import SpecificRemediationPlan from './Widgets/v2/SpecificRemediationPlan';
import OverTime from './Widgets/v2/OverTime';
import Comparison from './Widgets/v2/Comparison';
import RiskGrade from './Widgets/v2/RiskGrade';
import ComplianceGrade from './Widgets/v2/ComplianceGrade';

export const aclWidgetKeys = [
  'hosts_specific',
  'hosts_top_details',
  'hosts_priority',
  'patches_specific',
  'patches_top_details',
  'patches_priority',
  'vulnerabilities_specific',
  'vulnerabilities_top_details',
  'vulnerabilities_priority',
];

export const defaultParams = { project: 'default', model: 'base' };

export const tallyTypeWidgets = [
  'vulnerability_instances_global',
  'vulnerability_instances_exploit_status_breakdown',
  'vulnerability_instances_cvss_breakdown',
  'vulnerability_instances_os_family_breakdown',
  'vulnerability_instances_tag_breakdown',
  'vulnerability_instances_vulnerability_age_breakdown',
  'vulnerability_instances_total',
];

export const multipleOrderByOptions = [
  'cvss_exploit',
  'cvss_hosts',
  'exploit_cvss',
  'exploit_hosts',
  'hosts_cvss',
  'hosts_exploit',
];

export const keyToAttrMap = {
  hosts: 'num_hosts',
  vulnerabilities: 'num_vulnerabilities',
  cvss: 'cvss_base_score',
  exploit: 'exploit_status',
};

export const doesNotNeedData = [
  'risk_target',
  'risk_score',
  'text_free_form',
];

const CURRENT_VERSION = 2;

// some convenience vars so that the widgets fit on a pdf 11*8.5, each row is 60px, 12 rows can fit on a landscape pdf
const pageHeight = {
  full: 12,
  threeQuarters: 9,
  twoThirds: 8,
  half: 6,
  third: 4,
  quarter: 3,
  sixth: 2,
  twelfth: 1,
};

// some convenience vars so that the widgets fit on a pdf 11*8.5, 6 columns
const pageWidth = {
  full: 6,
  half: 3,
  twoThirds: 4,
  third: 2,
};

// different params needed for the different fetches
export const paramsMap = {
  table: {
    hosts: {
      rownums: [ 0, 200 ],
      model: 'base',
      group_type: 'host',
      project: 'default',
      filters: {
        num_sensitive_nodes: null,
        has_host: true,
        accepted_risk: false,
      },
      order_by: [
        [ 'filtered_risk', 'DESC' ],
        [ 'id', 'DESC' ],
      ],
    },
    patches: {
      model: 'base',
      project: 'default',
      rownums: [ 0, 200 ],
      filters: {
        superseded: null,
        accepted_risk: false,
      },
      order_by: [
        [ 'risk', 'DESC' ],
        [ 'vendor', 'ASC' ],
        [ 'id', 'DESC' ],
      ],
      group_type: 'patch_cumulative',
    },
    vulnerabilities: {
      rownums: [ 0, 200 ],
      model: 'base',
      group_type: 'vulnerability',
      project: 'default',
      filters: {
        patchable: null,
        exploit_status: null,
      },
      order_by: [
        [ 'filtered_risk', 'DESC' ],
        [ 'id', 'DESC' ],
      ],
    },
    users: {
      rownums: [ 0, 200 ],
      model: 'base',
      group_type: 'host',
      project: 'default',
      filters: {
        rownums: [ 0, 200 ],
        field_map: {
          type: 'domain_user',
        },
        extra_columns: [
          'name',
          'node_id',
          'domain_user_analysis.domain_name',
          'domain_user_analysis.risk',
          'domain_user_analysis.active_hosts',
          'domain_user_analysis.domain_groups',
          'sid',
        ],
        order_by: [
          [ 'domain_user_analysis.risk', 'DESC' ],
          [ 'id', 'DESC' ],
        ],
      },
    },
  },
  metric: {
    category: defaultParams,
    risk: defaultParams,
    target: {},
    hosts: null,
    percentile: null,
  },
  cvss: defaultParams,
  exploit_status: defaultParams,
  vulnerability_instances: defaultParams,
  peer_comparison: null,
  risk_over_time: {},
  top_paths: {
    // eslint-disable-next-line camelcase
    extra_columns: [
      'keywords',
      'risk',
      'modified',
      'edges',
      'node_labels',
    ],
    // eslint-disable-next-line camelcase
    order_by: [
      [ 'risk', 'DESC' ],
    ],
    rownums: [ 0, 5 ],
  },
  remediation_plans: {
    // eslint-disable-next-line camelcase
    extra_columns: [
      'username',
      'given_name',
      'family_name',
      'email_address',
    ],
    'order_by':[ [ 'username', 'ASC' ] ],
  },
  group: {
    project: 'default',
    model: 'base',
    filters: { 'accepted_risk': false },
    rownums: [ 0, 200 ],
    group_type: 'host',
    order_by: [ [ 'filtered_risk', 'DESC' ] ],
  },
  scanning: { filters: {} },
};

// -------------------------------------------------------------------------
// --------------------------------V2 Components, Widgets, caching, fetching
// -------------------------------------------------------------------------

// v2 widgets, each of the widget keys needs to have a corresponding component and cacheKey
export const availableWidgetsV2 = {
  host: {
    label: 'Host',
    icon: 'hostsAlt',
    options: {
      // record card
      specific: {
        version: 2,
        label: 'Specific Host',
        fullLabel: '',
        key: 'hosts_specific',
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.third,
        maxW: pageWidth.half,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.third,
        settings: {
          record_id: '',
          report_type: 'host',
          include_description: false,
        },
      },
      hosts_top_details: {
        version: 2,
        label: 'Top Hosts w/ Details',
        fullLabel: '',
        key: 'hosts_top_details',
        minH: pageHeight.third,
        maxH: pageHeight.full * 4,
        minW: pageWidth.third,
        maxW: pageWidth.full,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.full,
        w: pageWidth.third,
        settings: {
          item_count: 3,
          report_type: 'hosts',
          orientation: 'vertical',
        },
      },
      // table, list, barchart
      priority: {
        version: 2,
        label: 'Highest Priority Hosts',
        fullLabel: 'Hosts: Highest Priority (100)',
        key: 'hosts_priority',
        minH: pageHeight.third,
        maxH: pageHeight.full * 4,
        minW: pageWidth.third,
        maxW: pageWidth.full,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.half,
        settings: {
          version: 'table',
          item_count: 25,
          report_type: 'hosts',
          include_rating: true,
          include_user: false,
          include_os_type: false,
          include_description: true,
        },
      },
      over_time: {
        version: 2,
        label: 'Over Time',
        fullLabel: 'Hosts: Count Over Time',
        key: 'hosts_over_time',
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.third,
        maxW: pageWidth.full,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.twoThirds,
        settings: {
          report_type: 'hosts',
        },
      },
      comparison: {
        version: 2,
        label: 'Comparison',
        fullLabel: 'Hosts: Comparison',
        key: 'hosts_comparison',
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.quarter,
        maxW: pageWidth.full,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.half,
        settings: {
          report_type: 'hosts',
        },
      },
      total: {
        version: 2,
        label: 'Total',
        fullLabel: '',
        key: 'hosts_total',
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.third,
        maxW: pageWidth.twoThirds,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.third,
        w: pageWidth.third,
        settings: {
          report_type: 'hosts',
        },
      },
      // donut of hosts by risk category
      global: {
        version: 2,
        label: 'Host Risk Breakdown',
        fullLabel: 'Hosts: Risk Breakdown',
        key: 'hosts_global',
        settings: {
          report_type: 'hosts',
          include_description: true,
        },
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.third,
        maxW: pageWidth.twoThirds,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.third,
        w: pageWidth.third,
      },
    },
  },
  patch: {
    label: 'Patch',
    icon: 'patchesAlt',
    options: {
      // record card
      specific: {
        version: 2,
        label: 'Specific Patch',
        fullLabel: '',
        key: 'patches_specific',
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.third,
        maxW: pageWidth.half,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.third,
        settings: {
          record_id: '',
          report_type: 'patch',
          include_description: false,
        },
      },
      patches_top_details: {
        version: 2,
        label: 'Top Patches w/ Details',
        fullLabel: '',
        key: 'patches_top_details',
        minH: pageHeight.third,
        maxH: pageHeight.full * 4,
        minW: pageWidth.third,
        maxW: pageWidth.full,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.full,
        w: pageWidth.third,
        settings: {
          item_count: 3,
          report_type: 'patches',
          orientation: 'vertical',
        },
      },
      // table, list, barchart
      priority: {
        version: 2,
        label: 'Highest Priority Patches',
        fullLabel: 'Patches: Highest Priority (100)',
        key: 'patches_priority',
        minH: pageHeight.third,
        maxH: pageHeight.full * 4,
        minW: pageWidth.third,
        maxW: pageWidth.full,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.half,
        settings: {
          version: 'table',
          item_count: 25,
          report_type: 'patches',
          include_rating: true,
          include_description: true,
        },
      },
      over_time: {
        version: 2,
        label: 'Over Time',
        fullLabel: 'Patches: Count Over Time',
        key: 'patches_over_time',
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.third,
        maxW: pageWidth.full,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.twoThirds,
        settings: {
          report_type: 'patches',
        },
      },
      comparison: {
        version: 2,
        label: 'Comparison',
        fullLabel: 'Patches: Comparison',
        key: 'patches_comparison',
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.quarter,
        maxW: pageWidth.full,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.half,
        settings: {
          report_type: 'patches',
        },
      },
      total: {
        version: 2,
        label: 'Total',
        fullLabel: '',
        key: 'patches_total',
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.third,
        maxW: pageWidth.twoThirds,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.third,
        w: pageWidth.third,
        settings: {
          report_type: 'patches',
        },
      },
      // donut of hosts by risk category
      global: {
        version: 2,
        label: 'Patch Risk Breakdown',
        fullLabel: 'Patches: Risk Breakdown',
        key: 'patches_global',
        settings: {
          report_type: 'patches',
          include_description: true,
        },
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.third,
        maxW: pageWidth.twoThirds,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.third,
        w: pageWidth.third,
      },
    },
  },
  vulnerability: {
    label: 'Vulnerability',
    icon: 'vulnerabilitiesAlt',
    options: {
      // record card
      specific: {
        version: 2,
        label: 'Specific Vulnerability',
        fullLabel: '',
        key: 'vulnerabilities_specific',
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.third,
        maxW: pageWidth.half,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.third,
        settings: {
          record_id: '',
          report_type: 'vulnerability',
          include_description: false,
        },
      },
      vulnerabilities_top_details: {
        version: 2,
        label: 'Top Vulnerabilities w/ Details',
        fullLabel: '',
        key: 'vulnerabilities_top_details',
        minH: pageHeight.third,
        maxH: pageHeight.full * 4,
        minW: pageWidth.third,
        maxW: pageWidth.full,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.full,
        w: pageWidth.third,
        settings: {
          item_count: 3,
          report_type: 'vulnerabilities',
          orientation: 'vertical',
        },
      },
      // table, list, barchart
      priority: {
        version: 2,
        label: 'Highest Priority Vulnerabilities',
        fullLabel: 'Vulnerabilities: Highest Priority (100)',
        key: 'vulnerabilities_priority',
        minH: pageHeight.third,
        maxH: pageHeight.full * 4,
        minW: pageWidth.third,
        maxW: pageWidth.full,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.half,
        settings: {
          version: 'table',
          item_count: 25,
          report_type: 'vulnerabilities',
          include_rating: true,
          include_description: true,
        },
      },
      over_time: {
        version: 2,
        label: 'Over Time',
        fullLabel: 'Vulnerabilities: Count Over Time',
        key: 'vulnerabilities_over_time',
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.third,
        maxW: pageWidth.full,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.twoThirds,
        settings: {
          report_type: 'vulnerabilities',
        },
      },
      comparison: {
        version: 2,
        label: 'Comparison',
        fullLabel: 'Vulnerabilities: Comparison',
        key: 'vulnerabilities_comparison',
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.quarter,
        maxW: pageWidth.full,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.half,
        settings: {
          report_type: 'vulnerabilities',
        },
      },
      total: {
        version: 2,
        label: 'Total',
        fullLabel: '',
        key: 'vulnerabilities_total',
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.third,
        maxW: pageWidth.twoThirds,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.third,
        w: pageWidth.third,
        settings: {
          report_type: 'vulnerabilities',
        },
      },
      // donut of hosts by risk category
      global: {
        version: 2,
        label: 'Vulnerability Risk Breakdown',
        fullLabel: 'Vulnerabilities: Risk Breakdown',
        key: 'vulnerabilities_global',
        settings: {
          report_type: 'vulnerabilities',
          include_description: true,
        },
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.third,
        maxW: pageWidth.twoThirds,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.third,
        w: pageWidth.third,

      },
    },
  },
  user: {
    label: 'User',
    icon: 'domainUser',
    options: {
      // table, list, barchart
      priority: {
        version: 2,
        label: 'Highest Priority Users',
        fullLabel: 'Users: Highest Priority',
        key: 'users_priority',
        minH: pageHeight.third,
        maxH: pageHeight.full * 4,
        minW: pageWidth.third,
        maxW: pageWidth.full,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.half,
        settings: {
          version: 'table',
          item_count: 25,
          report_type: 'users',
          include_rating: true,
          include_description: true,
        },
      },
    },
  },
  compliance: {
    label: 'Compliance',
    icon: 'compliance',
    options: {
      current: {
        version: 2,
        label: 'Current Snapshot',
        fullLabel: 'Compliance: Current',
        key: 'compliance_current',
        minH: pageHeight.third,
        maxH: pageHeight.full * 3,
        minW: pageWidth.third,
        maxW: pageWidth.full,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.twoThirds,
      },
      // over_time: {
      //   version: 2,
      //   label: 'Over Time',
      //   fullLabel: 'Compliance: Over Time',
      //   key: 'compliance_over_time',
      //   minH: pageHeight.third,
      //   maxH: pageHeight.full,
      //   minW: pageWidth.third,
      //   maxW: pageWidth.full,
      //   resizeHandles: [ 's', 'e' ],
      //   h: pageHeight.half,
      //   w: pageWidth.twoThirds,
      // },
      comparison: {
        version: 2,
        label: 'Comparison',
        fullLabel: 'Compliance: Comparison',
        key: 'compliance_comparison',
        minH: pageHeight.third,
        maxH: pageHeight.full * 3,
        minW: pageWidth.third,
        maxW: pageWidth.full,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.twoThirds,
      },
      grade: {
        version: 2,
        label: 'Compliance Grade',
        fullLabel: '',
        key: 'compliance_grade',
        settings: {
          include_description: false,
        },
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.third,
        maxW: pageWidth.twoThirds,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.third,
      },
      // specific: {
      //   version: 2,
      //   label: 'Specific',
      //   fullLabel: 'Complieance: Specific',
      //   key: 'compliance_specific',
      //   minH: pageHeight.third,
      //   maxH: pageHeight.full,
      //   minW: pageWidth.quarter,
      //   maxW: pageWidth.full,
      //   resizeHandles: [ 's', 'e' ],
      //   h: pageHeight.half,
      //   w: pageWidth.half,
      // },
    },
  },
  path: {
    label: 'Path',
    icon: 'pathsAlt',
    options: {
      // paths by host/patch/vuln
      // specific: {
      //   version: 2,
      //   label: 'Top Paths (specifc item)',
      //   fullLabel: '',
      //   key: 'paths_specific',
      //   minH: pageHeight.third,
      //   maxH: pageHeight.full,
      //   minW: pageWidth.twoThirds,
      //   maxW: pageWidth.full,
      //   resizeHandles: [ 's', 'e' ],
      //   h: pageHeight.half,
      //   w: pageWidth.full,
      //   settings: {
      //     record_id: '',
      //     report_type: 'host',
      //     item_count: 3,
      //     include_description: true,
      //   },
      // },
      // system-wide paths
      global: {
        version: 2,
        label: 'Top Paths (system wide)',
        fullLabel: 'Top 5 Critical Paths',
        key: 'paths_global',
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.twoThirds,
        maxW: pageWidth.full,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.full,
        settings: {
          item_count: 5,
          include_description: true,
        },
      },
    },
  },
  instances: {
    label: 'Vuln. Instance',
    icon: 'vulnerabilitiesAlt',
    options: {
      // full, simplified
      global: {
        version: 2,
        label: 'Categories',
        fullLabel: '',
        key: 'vulnerability_instances_global',
        minH: pageHeight.half,
        maxH: pageHeight.full,
        minW: pageWidth.full,
        maxW: pageWidth.full,
        resizeHandles: [ 's' ],
        h: pageHeight.twoThirds,
        w: pageWidth.full,
        settings: {
          version: 'full',
          include_description: true,
        },
      },
      over_time: {
        version: 2,
        label: 'Over Time',
        fullLabel: 'Vulnerability Instances: Count Over Time',
        key: 'vulnerability_instances_over_time',
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.third,
        maxW: pageWidth.full,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.twoThirds,
        settings: {
          report_type: 'vulnerability_instances',
        },
      },
      comparison: {
        version: 2,
        label: 'Comparison',
        fullLabel: 'Vulnerability Instances: Comparison',
        key: 'vulnerability_instances_comparison',
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.quarter,
        maxW: pageWidth.full,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.half,
        settings: {
          report_type: 'vulnerability_instances',
        },
      },
      // breakdown by exploit status
      exploit_status: {
        version: 2,
        label: 'Exploit Status Breakdown',
        fullLabel: 'Vulnerability Instances: Exploit Status Breakdown',
        key: 'vulnerability_instances_exploit_status_breakdown',
        settings: {
          include_description: true,
        },
        minH: pageHeight.third,
        maxH: pageHeight.twoThirds,
        minW: pageWidth.third,
        maxW: pageWidth.half,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.third,
      },
      // breakdown by CVSS
      cvss: {
        version: 2,
        label: 'CVSS Breakdown',
        fullLabel: 'Vulnerability Instances: CVSS Breakdown',
        key: 'vulnerability_instances_cvss_breakdown',
        settings: {
          include_description: true,
          version: 'barchart',
        },
        minH: pageHeight.third,
        maxH: pageHeight.twoThirds,
        minW: pageWidth.third,
        maxW: pageWidth.twoThirds,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.third,
      },
      // the lower table groupings host/patch/vuln/sig
      // not really necessary considering the priority widget is there, will consider adding back if really necessary
      // table: {
      //   version: 2,
      //   label: 'Table by group',
      //   fullLabel: 'Vulnerability Instances: Groupings',
      //   key: 'vulnerability_instances_table',
      //   minH: pageHeight.half,
      //   maxH: pageHeight.full * 4,
      //   minW: pageWidth.twoThirds,
      //   maxW: pageWidth.full,
      //   h: pageHeight.twoThirds,
      //   w: pageWidth.twoThirds,
      //   settings: {
      //     group_by: 'hosts',
      //     include_description: true,
      //   },
      //   resizeHandles: [ 's' ],
      // },
      os_family: {
        version: 2,
        label: 'OS Family Breakdown',
        fullLabel: 'Vulnerability Instances: OS Family Breakdown',
        key: 'vulnerability_instances_os_family_breakdown',
        settings: {
          include_description: true,
        },
        minH: pageHeight.third,
        maxH: pageHeight.twoThirds,
        minW: pageWidth.third,
        maxW: pageWidth.half,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.third,
      },
      tag: {
        version: 2,
        label: 'Tag Breakdown',
        fullLabel: 'Vulnerability Instances: Tag Breakdown',
        key: 'vulnerability_instances_tag_breakdown',
        settings: {
          include_description: true,
        },
        minH: pageHeight.third,
        maxH: pageHeight.twoThirds,
        minW: pageWidth.third,
        maxW: pageWidth.half,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.third,
      },
      vulnerability_age: {
        version: 2,
        label: 'Vulnerability Age Breakdown',
        fullLabel: 'Vulnerability Instances: Vulnerability Age Breakdown',
        key: 'vulnerability_instances_vulnerability_age_breakdown',
        settings: {
          include_description: true,
        },
        minH: pageHeight.third,
        maxH: pageHeight.twoThirds,
        minW: pageWidth.third,
        maxW: pageWidth.full,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.twoThirds,
        w: pageWidth.twoThirds,
      },
      // just a donut of a specific category
      specific: {
        version: 2,
        label: 'Specific Instance Category',
        fullLabel: 'Vulnerability Instances: Category',
        key: 'vulnerability_instances_category',
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.third,
        maxW: pageWidth.twoThirds,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.third,
        settings: {
          category: 'carries_risk',
          include_description: false,
        },
      },
      // just a big number... kind of dumb
      total: {
        version: 2,
        label: 'Total',
        fullLabel: '',
        key: 'vulnerability_instances_total',
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.third,
        maxW: pageWidth.twoThirds,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.third,
        w: pageWidth.third,
      },
    },
  },
  risk: {
    label: 'Risk',
    icon: 'notificationAlert',
    options: {
      // risk score
      score: {
        version: 2,
        label: 'Risk Score',
        fullLabel: '',
        key: 'risk_score',
        settings: {
          include_description: false,
        },
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.third,
        maxW: pageWidth.twoThirds,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.third,
      },
      // risk score
      grade: {
        version: 2,
        label: 'Risk Grade',
        fullLabel: '',
        key: 'risk_grade',
        settings: {
          include_description: false,
        },
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.third,
        maxW: pageWidth.twoThirds,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.third,
      },
      // peer score
      peer: {
        version: 2,
        label: 'Peer Percentile',
        fullLabel: '',
        key: 'risk_peer_percentile',
        settings: {
          include_description: false,
        },
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.third,
        maxW: pageWidth.twoThirds,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.third,
      },
      // donut of host/patch/vuln risk scores
      global: {
        version: 2,
        label: 'Risk Score Breakdown',
        fullLabel: 'Risk: Score Breakdown',
        key: 'risk_global',
        settings: {
          include_description: true,
          report_type: 'hosts',
        },
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.third,
        maxW: pageWidth.twoThirds,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.third,
      },
      // risk over time w/ current
      risk_over_time: {
        version: 2,
        label: 'Risk Over Time',
        fullLabel: 'Risk: Over Time',
        key: 'risk_over_time',
        minH: pageHeight.half,
        maxH: pageHeight.full,
        minW: pageWidth.full,
        maxW: pageWidth.full,
        resizeHandles: [ 's' ],
        h: pageHeight.twoThirds,
        w: pageWidth.full,
        settings: {
          include_escalations: true,
          include_hosts: true,
          patch_tuesday: false,
          include_description: true,
        },
      },
      // peer over time w/ current
      peer_over_time: {
        version: 2,
        label: 'Peer Percentile Over Time',
        fullLabel: 'Risk: Peer Percentile Over Time',
        key: 'risk_peer_percentile_over_time',
        minH: pageHeight.half,
        maxH: pageHeight.full,
        minW: pageWidth.half,
        maxW: pageWidth.full,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.twoThirds,
        settings: {
          include_description: true,
        },
      },
      // target risk (looks similar to risk score)
      target_risk: {
        version: 2,
        label: 'Target Risk',
        fullLabel: '',
        key: 'risk_target',
        settings: {
          include_description: false,
        },
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.third,
        maxW: pageWidth.twoThirds,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.third,
      },
      simplified_over_time: {
        version: 2,
        label: 'Simplified Over Time',
        fullLabel: 'Risk: Over Time',
        key: 'risk_simplified_over_time',
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.third,
        maxW: pageWidth.full,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.twoThirds,
        settings: {
          report_type: 'risk',
        },
      },
      comparison: {
        version: 2,
        label: 'Comparison',
        fullLabel: 'Risk: Comparison',
        key: 'risk_comparison',
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.quarter,
        maxW: pageWidth.full,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.half,
        settings: {
          report_type: 'risk',
        },
      },
    },
  },
  scanning: {
    label: 'Scanning',
    icon: 'scanning_nav',
    options: {
      scanning_age_breakdown: {
        version: 2,
        label: 'Scanning Age Breakdown',
        fullLabel: 'Scanning: Scanning Age Breakdown',
        key: 'scanning_age_breakdown',
        settings: {
          include_description: true,
        },
        minH: pageHeight.third,
        maxH: pageHeight.twoThirds,
        minW: pageWidth.third,
        maxW: pageWidth.half,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.third,
        w: pageWidth.third,
      },
      version_breakdown: {
        version: 2,
        label: 'Agent Version Breakdown',
        fullLabel: 'Scanning: Agent Version Breakdown',
        key: 'scanning_agent_version_breakdown',
        settings: {
          include_description: true,
        },
        minH: pageHeight.third,
        maxH: pageHeight.twoThirds,
        minW: pageWidth.third,
        maxW: pageWidth.half,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.third,
        w: pageWidth.third,
      },
      scan_group_breakdown: {
        version: 2,
        label: 'Scan Group Breakdown',
        fullLabel: 'Scanning: Scan Group Breakdown',
        key: 'scanning_scan_group_breakdown',
        settings: {
          include_description: true,
        },
        minH: pageHeight.third,
        maxH: pageHeight.twoThirds,
        minW: pageWidth.third,
        maxW: pageWidth.half,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.third,
        w: pageWidth.third,
      },
      // just a big number... kind of dumb
      total: {
        version: 2,
        label: 'Total',
        fullLabel: '',
        key: 'scanning_total',
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.third,
        maxW: pageWidth.twoThirds,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.third,
        w: pageWidth.third,
      },
    },
  },
  remediation: {
    label: 'Remediation',
    icon: 'remediation_nav',
    options: {
      // bar chart of plan completion
      plans: {
        version: 2,
        label: 'All Plans',
        fullLabel: 'Remediation: All Plans',
        key: 'remediation_plans',
        settings: {
          include_description: true,
        },
        minH: pageHeight.third,
        maxH: pageHeight.full * 2,
        minW: pageWidth.third,
        maxW: pageWidth.full,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.half,
      },
      // bar chart of plan completion
      specific_plan: {
        version: 2,
        label: 'Specific Plan',
        fullLabel: '',
        key: 'remediation_specific_plan',
        settings: {
          include_description: true,
          plan_id: '',
        },
        minH: pageHeight.third,
        maxH: pageHeight.full * 2,
        minW: pageWidth.third,
        maxW: pageWidth.full,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.half,
      },
    },
  },
  text: {
    label: 'Text',
    icon: 'text',
    options: {
      free_form: {
        version: 2,
        label: 'Free Form Text',
        fullLabel: '',
        key: 'text_free_form',
        settings: {
          heading: '',
          // eslint-disable-next-line max-len
          content: '',
        },
        minH: pageHeight.sixth,
        maxH: pageHeight.full,
        minW: pageWidth.third,
        maxW: pageWidth.full,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.third,
        w: pageWidth.full,
      },
    },
  },
};

// v2 components, components mapped to the keys of the available widgts
export const v2WidgetComponentMap = {
  hosts_specific: SpecificRecord,
  hosts_priority: PriorityRecords,
  hosts_global: RiskBreakdown,
  hosts_top_details: TopRecordDetails,
  hosts_over_time: OverTime,
  hosts_comparison: Comparison,
  hosts_total: RecordTotal,

  patches_specific: SpecificRecord,
  patches_priority: PriorityRecords,
  patches_global: RiskBreakdown,
  patches_top_details: TopRecordDetails,
  patches_over_time: OverTime,
  patches_comparison: Comparison,
  patches_total: RecordTotal,

  vulnerabilities_specific: SpecificRecord,
  vulnerabilities_priority: PriorityRecords,
  vulnerabilities_global: RiskBreakdown,
  vulnerabilities_top_details: TopRecordDetails,
  vulnerabilities_over_time: OverTime,
  vulnerabilities_comparison: Comparison,
  vulnerabilities_total: RecordTotal,

  compliance_current: Comparison,
  compliance_comparison: Comparison,
  compliance_over_time: OverTime,
  compliance_grade: ComplianceGrade,
  // compliance_specific: Comparison,

  users_priority: PriorityRecords,

  // paths_specific: Paths,
  paths_global: Paths,

  vulnerability_instances_global: VulnerabilityInstancesCategories,
  vulnerability_instances_over_time: OverTime,
  vulnerability_instances_comparison: Comparison,
  vulnerability_instances_exploit_status_breakdown: ExploitStatusBreakdown,
  vulnerability_instances_cvss_breakdown: CVSSBreakdown,
  vulnerability_instances_os_family_breakdown: OSFamilyBreakdown,
  vulnerability_instances_tag_breakdown: TagBreakdown,
  vulnerability_instances_vulnerability_age_breakdown: VulnerabilityAgeBreakdown,
  vulnerability_instances_total: VulnerabilityInstancesTotal,
  vulnerability_instances_table: VulnerabilityInstancesTable,
  vulnerability_instances_category: SpecificCategory,

  risk_score: RiskScore,
  risk_peer_percentile: PeerPercentile,
  risk_grade: RiskGrade,
  risk_global: RiskBreakdown,
  risk_over_time: RiskOverTimeV3,
  risk_peer_percentile_over_time: PeerPercentileOverTime,
  risk_target: RiskScore,
  risk_comparison: Comparison,
  risk_simplified_over_time: OverTime,

  remediation_plans: RemediationPlans,
  remediation_specific_plan: SpecificRemediationPlan,

  scanning_age_breakdown: ScanningAgeBreakdown,
  scanning_scan_group_breakdown: ScanningScanGroupBreakdown,
  scanning_agent_version_breakdown: ScanningAgentVersionBreakdown,
  scanning_total: ScanningTotal,

  text_free_form: Text,
  // text_heading: Heading,
};

// V2 the key needed to get/set the data in the cache, each cacheKey maps to a v2 widget key
export const getWidgetCacheKey = widget => {
  if ( isNotEmpty( widget ) ) {
    const { key, settings } = widget;

    if ( isNotEmpty( key ) ) {
      switch  ( key ) {
      case 'hosts_specific':
        return `hosts_specific|id_${settings?.record_id_host}`;
      case 'hosts_top_details':
        return `hosts_top_details|count_${settings?.item_count}|order_${settings?.order_by}`;
      case 'hosts_priority':
        return `hosts_priority|order_${settings?.order_by}|tags_${settings?.asset_tag_ids?.join( ',' ) || ''}`;
      case 'hosts_comparison':
        // eslint-disable-next-line max-len
        return `analysis_tag_history_comparison|comparison_${settings?.comparison_date}|breakdown_${ settings?.include_tag_breakdown ? 'tag' : ''}`;
      case 'hosts_over_time':
        // eslint-disable-next-line max-len
        return `analysis_tag_history_over_time|comparison_${settings?.duration}|breakdown_${ settings?.include_tag_breakdown ? 'tag' : ''}`;
      case 'hosts_total':
        // this same fetch is used for many widgets, does not match key
        return 'risk_breakdown';
      case 'hosts_global':
        // this same fetch is used for many widgets, does not match key
        return 'risk_breakdown';
      case 'patches_specific':
        return `patches_specific|id_${settings?.record_id_patch}`;
      case 'patches_top_details':
        return `patches_top_details|count_${settings?.item_count}|order_${settings?.order_by}`;
      case 'patches_priority':
        return `patches_priority|order_${settings?.order_by}|tags_${settings?.asset_tag_ids?.join( ',' ) || ''}`;
      case 'patches_comparison':
        // eslint-disable-next-line max-len
        return `analysis_tag_history_comparison|comparison_${settings?.comparison_date}|breakdown_${ settings?.include_tag_breakdown ? 'tag' : ''}`;
      case 'patches_over_time':
        // eslint-disable-next-line max-len
        return `analysis_tag_history_over_time|comparison_${settings?.duration}|breakdown_${ settings?.include_tag_breakdown ? 'tag' : ''}`;
      case 'patches_total':
        // this same fetch is used for many widgets, does not match key
        return 'risk_breakdown';
      case 'patches_global':
        // this same fetch is used for many widgets, does not match key
        return 'risk_breakdown';
      case 'vulnerabilities_specific':
        return `vulnerabilities_specific|id_${settings?.record_id_vulnerability}`;
      case 'vulnerabilities_top_details':
        return `vulnerabilities_top_details|count_${settings?.item_count}|order_${settings?.order_by}`;
      case 'vulnerabilities_priority':
        // eslint-disable-next-line max-len
        return `vulnerabilities_priority|order_${settings?.order_by}|tags_${settings?.asset_tag_ids?.join( ',' ) || ''}`;
      case 'vulnerabilities_comparison':
        // eslint-disable-next-line max-len
        return `analysis_tag_history_comparison|comparison_${settings?.comparison_date}|breakdown_${ settings?.include_tag_breakdown ? 'tag' : ''}`;
      case 'vulnerabilities_over_time':
        // eslint-disable-next-line max-len
        return `analysis_tag_history_over_time|comparison_${settings?.duration}|breakdown_${ settings?.include_tag_breakdown ? 'tag' : ''}`;
      case 'vulnerabilities_total':
        // this same fetch is used for many widgets, does not match key
        return 'risk_breakdown';
      case 'vulnerabilities_global':
        // this same fetch is used for many widgets, does not match key
        return 'risk_breakdown';
      case 'compliance_current':
        // eslint-disable-next-line max-len
        return `analysis_compliance_comparison|comparison_${settings?.comparison_date}|regulation-${settings?.regulation}|control_${settings?.control}`;
      case 'compliance_comparison':
        // eslint-disable-next-line max-len
        return `analysis_compliance_comparison|comparison_${settings?.comparison_date}|regulation-${settings?.regulation}|control_${settings?.control}`;
      case 'compliance_over_time':
        // eslint-disable-next-line max-len
        return `analysis_compliance_over_time|comparison_${settings?.comparison_date}|regulation-${settings?.regulation}|control_${settings?.control}`;

      case 'compliance_grade':
        return 'analysis_compliance_comparison';
      case 'users_priority':
        return `users_priority|order_${settings?.order_by}|tags_${settings?.asset_tag_ids?.join( ',' ) || ''}`;
        // case 'paths_specific':
        // eslint-disable-next-line max-len
        //   return `paths_specific|hostid_${settings?.record_id_host}|patchid_${settings?.record_id_host}|vulnerabilitytid_${settings?.record_id_host}|domainusertid_${settings?.record_id_domain_user}|type_${settings?.report_type}|count_${settings?.item_count}`;

      case 'paths_global':
        return `paths_global|count_${settings?.item_count}`;
      case 'vulnerability_instances_global':
        return 'vulnerability_instances_category';
      case 'vulnerability_instances_comparison':
        // eslint-disable-next-line max-len
        return `analysis_tag_history_comparison|comparison_${settings?.comparison_date}|breakdown_${settings?.category_version}`;
      case 'vulnerability_instances_over_time':
        // eslint-disable-next-line max-len
        return `analysis_tag_history_over_time|comparison_${settings?.duration}|breakdown_${settings?.category_version}`;
      case 'vulnerability_instances_exploit_status_breakdown':
        return 'vulnerability_instances_exploit_status';
      case 'vulnerability_instances_cvss_breakdown':
        return 'vulnerability_instances_cvss';
      case 'vulnerability_instances_os_family_breakdown':
        return 'vulnerability_instances_os_family';
      case 'vulnerability_instances_tag_breakdown':
        return 'vulnerability_instances_asset_tag';
      case 'vulnerability_instances_vulnerability_age_breakdown':
        return 'vulnerability_instances_vulnerability_age';
      case 'vulnerability_instances_category':
        return 'vulnerability_instances_category';
      case 'vulnerability_instances_total':
        return 'vulnerability_instances_category';

      case 'risk_score':
        return 'risk_score';
      case 'risk_grade':
        return 'risk_peer_percentile';
      case 'risk_peer_percentile':
        return 'risk_peer_percentile';
      case 'risk_global':
        // this same fetch is used for many widgets, does not match key
        return 'risk_breakdown';
      case 'risk_over_time':
        return `risk_over_time|duration_${settings?.duration}`;
      case 'risk_peer_percentile_over_time':
        return 'risk_peer_percentile';
      case 'risk_target':
        return 'risk_target';
      case 'risk_comparison':
        // eslint-disable-next-line max-len
        return `analysis_tag_history_comparison|comparison_${settings?.comparison_date}|breakdown_${ settings?.include_tag_breakdown ? 'tag' : ''}`;
      case 'risk_simplified_over_time':
        // eslint-disable-next-line max-len
        return `analysis_tag_history_over_time|comparison_${settings?.duration}|breakdown_${ settings?.include_tag_breakdown ? 'tag' : ''}`;

      case 'scanning_age_breakdown':
        return `scanning|type_${settings?.scanning_type}`;
      case 'scanning_agent_version_breakdown':
        return 'scanning|type_agent';
      case 'scanning_scan_group_breakdown':
        return 'scanning|type_agentless';
      case 'scanning_total':
        return `scanning|type_${settings?.scanning_type}`;
      case 'remediation_plans':
        return 'remediation_plans';
      case 'remediation_specific_plan':
        return `remediation_plans|id_${settings?.plan_id}`;
      case 'text_free_form':
        return 'text_free_form';
      default:
        break;
      }
    }
  }
};

// V2 all of the fetching based on the cacheKey provided, needs to be a different fetch method for each cacheKey
export const fetchForCacheKey = ( cacheKey, tags=null, returnEmpty=false ) => {

  let tagCount = 10;

  let order_by = [
    [ 'filtered_risk', 'DESC' ],
    [ 'id', 'DESC' ],
  ];

  let days = 90;

  let asset_tag_ids = [];

  const complianceStartParams = {
    columns: '*',
    filters: {},
    order_by: [
      [ 'created', 'DESC' ],
      [ 'id', 'ASC' ],
    ],
    'rows': [ 0, 1_000 ],
  };

  const complianceEndParams = {
    ...complianceStartParams,
  };

  let rownums = [ 0, 3 ];
  // const pathCount = 3;

  let overtime_start_1 = null;
  let overtime_start_2 = null;
  let overtime_row_start = 100;

  let overtime_end_1 = null;
  let overtime_end_2 = null;
  let overtime_row_end = 100;

  const now = Math.floor( Date.now() );
  const day = 86_400_000;
  const week = day * 7;
  const month = day * 30;
  const quarter = month * 3;
  const year = day * 365;

  const keyToAttrMap = {
    hosts: 'num_hosts',
    vulnerabilities: 'num_vulnerabilities',
    cvss: 'cvss_base_score',
    exploit: 'exploit_status',
  };
  const emptyFetch = () => ( {} );

  let fn = emptyFetch;

  if ( returnEmpty ) {
    return emptyFetch;
  }

  if ( isNotEmpty( tags ) ) {
    tagCount = Object.keys( tags ).length;
  }

  if ( isNotEmpty( cacheKey ) ) {
    const [
      main_key,
      param_1,
      param_2,
      // param_3,
      // param_4,
      // param_5,
      // param_6,
    ] = cacheKey.split( '|' );
    switch ( main_key ) {
    // hosts
    case 'hosts_specific':
      if ( isNotEmpty( param_1 ) ) {
        const [ , id ] = param_1.split( '_' );
        if ( isNotEmpty( id ) ) {
          fn = () => getRecordDetails( 'host', id, {}, true );
        }
      }
      break;
    case 'hosts_top_details':
      // count
      if ( isNotEmpty( param_1 ) ) {
        const [ , count ] = param_1.split( '_' );
        if ( isNotEmpty( count ) ) {
          rownums = [ 0, parseInt( count ) ];
        }
      }
      // ordering
      if ( isNotEmpty( param_2 ) ) {
        let order = param_2.split( '_' );
        order.shift();
        order = order.join( '_' );
        if ( isNotEmpty( order ) ) {
          if ( multipleOrderByOptions.includes( order ) ) {
            const [ primaryKey, secondaryKey ] = order.split( '_' );

            order_by = [
              [ keyToAttrMap[primaryKey], 'DESC' ],
              [ keyToAttrMap[secondaryKey], 'DESC' ],
              [ 'id', 'DESC' ],
            ];
          } else {

            order_by = [
              [ order, 'DESC' ],
              [ 'id', 'DESC' ],
            ];
          }
        }
      }
      fn =  async () => {
        // console.log( 'here' );
        const _records = await getRecords( 'host', { order_by, rows: rownums }, true );

        if ( isNotEmpty( _records ) ) {
          const ids = _records.map( r => r.id );

          if ( isNotEmpty( ids ) ) {

            const promises = [];

            ids.map( id => {
              promises.push( getRecordDetails( 'host', id, {}, true ) );
            } );

            if ( isNotEmpty( promises ) ) {
              const _recordsWithDetails = await Promise.all( promises );

              if ( isNotEmpty( _recordsWithDetails ) ) {
                return _recordsWithDetails;
              }
              return [];
            }
            return [];
          }
        }
        return [];
      };
      break;
    case 'hosts_priority':
      // ordering
      if ( isNotEmpty( param_1 ) ) {
        let order = param_1.split( '_' );
        order.shift();
        order = order.join( '_' );
        if ( isNotEmpty( order ) ) {
          if ( multipleOrderByOptions.includes( order ) ) {
            const [ primaryKey, secondaryKey ] = order.split( '_' );

            order_by = [
              [ keyToAttrMap[primaryKey], 'DESC' ],
              [ keyToAttrMap[secondaryKey], 'DESC' ],
              [ 'id', 'DESC' ],
            ];
          } else {
            order_by = [
              [ order, 'DESC' ],
              [ 'id', 'DESC' ],
            ];
          }
        }
      }
      // asset tags
      if ( isNotEmpty( param_2 ) ) {
        const [ , commaTags ] = param_2.split( '_' );
        if ( isNotEmpty( commaTags ) ) {
          const tags = commaTags.split( ',' );
          asset_tag_ids = tags;
        }
      }

      if ( isNotEmpty( order_by ) ) {
        if ( isNotEmpty( asset_tag_ids ) ) {
          // console.log( 'here' );
          fn = () => getRecords(
            'host',
            {
              filters: {
                ...paramsMap.table.hosts.filters,
                asset_tag_ids,
              },
              order_by,
            },
            true,
          );
        } else {
          // console.log( 'here' );
          fn = () => getRecords(
            'host',
            {
              order_by,
            },
            true,
          );
        }
      } else {
        // console.log( 'here' );
        fn = () => getRecords(
          'host',
          null,
          true,
        );
      }
      break;

    // patches
    case 'patches_specific':
      if ( isNotEmpty( param_1 ) ) {
        const [ , id ] = param_1.split( '_' );
        if ( isNotEmpty( id ) ) {
          fn = () => getRecordDetails( 'patch', id, {}, true );
        }
      }
      break;
    case 'patches_top_details':
      order_by = [
        [ 'filtered_risk', 'DESC' ],
        [ 'id', 'DESC' ],
      ];
      // count
      if ( isNotEmpty( param_1 ) ) {
        const [ , count ] = param_1.split( '_' );
        if ( isNotEmpty( count ) ) {
          rownums = [ 0, parseInt( count ) ];
        }
      }
      if ( isNotEmpty( param_2 ) ) {
        let order = param_2.split( '_' );
        order.shift();
        order = order.join( '_' );
        if ( isNotEmpty( order ) ) {
          if ( multipleOrderByOptions.includes( order ) ) {
            const [ primaryKey, secondaryKey ] = order.split( '_' );

            order_by = [
              [ keyToAttrMap[primaryKey], 'DESC' ],
              [ keyToAttrMap[secondaryKey], 'DESC' ],
              [ 'id', 'DESC' ],
            ];
          } else {

            order_by = [
              [ order, 'DESC' ],
              [ 'id', 'DESC' ],
            ];
          }
        }
      }
      fn = async () => {
        // console.log( 'here' );
        const _records = await getRecords( 'patch', { order_by, rows: rownums }, true );

        if ( isNotEmpty( _records ) ) {
          const ids = _records.map( r => r.id );

          if ( isNotEmpty( ids ) ) {

            const promises = [];

            ids.map( id => {
              promises.push( getRecordDetails( 'patch', id, {}, true ) );
            } );

            if ( isNotEmpty( promises ) ) {
              const _recordsWithDetails = await Promise.all( promises );

              if ( isNotEmpty( _recordsWithDetails ) ) {
                return _recordsWithDetails;
              }
              return [];
            }
            return [];
          }
        }
        return [];
      };
      break;
    case 'patches_priority':
      order_by = [
        [ 'filtered_risk', 'DESC' ],
        [ 'id', 'DESC' ],
      ];
      // ordering
      if ( isNotEmpty( param_1 ) ) {
        let order = param_1.split( '_' );
        order.shift();
        order = order.join( '_' );
        if ( isNotEmpty( order ) ) {
          if ( multipleOrderByOptions.includes( order ) ) {
            const [ primaryKey, secondaryKey ] = order.split( '_' );

            order_by = [
              [ keyToAttrMap[primaryKey], 'DESC' ],
              [ keyToAttrMap[secondaryKey], 'DESC' ],
              [ 'id', 'DESC' ],
            ];
          } else {
            order_by = [
              [ order, 'DESC' ],
              [ 'id', 'DESC' ],
            ];
          }
        }
      }

      // asset tags
      if ( isNotEmpty( param_2 ) ) {
        const [ , commaTags ] = param_2.split( '_' );
        if ( isNotEmpty( commaTags ) ) {
          const tags = commaTags.split( ',' );
          asset_tag_ids = tags;
        }
      }

      if ( isNotEmpty( order_by ) ) {
        if ( isNotEmpty( asset_tag_ids ) ) {
          // console.log( 'here' );
          fn = () => getRecords(
            'patch',
            {
              filters: {
                ...paramsMap.table.patches.filters,
                asset_tag_ids,
              },
              order_by,
            },
            true,
          );
        } else {
          // console.log( 'here' );
          fn = () => getRecords(
            'patch',
            {
              order_by,
            },
            true,
          );
        }
      } else {
        // console.log( 'here' );
        fn = () => getRecords(
          'patch',
          null,
          true,
        );
      }
      break;

    // vulnerabilities
    case 'vulnerabilities_specific':
      if ( isNotEmpty( param_1 ) ) {
        const [ , id ] = param_1.split( '_' );
        if ( isNotEmpty( id ) ) {
          fn = () => getRecordDetails( 'vulnerability', id, {}, true );
        }
      }
      break;
    case 'vulnerabilities_top_details':
      // count
      if ( isNotEmpty( param_1 ) ) {
        const [ , count ] = param_1.split( '_' );
        if ( isNotEmpty( count ) ) {
          rownums = [ 0, count ];
        }
      }
      // ordering
      if ( isNotEmpty( param_2 ) ) {
        let order = param_2.split( '_' );
        order.shift();
        order = order.join( '_' );
        if ( isNotEmpty( order ) ) {
          if ( multipleOrderByOptions.includes( order ) ) {
            const [ primaryKey, secondaryKey ] = order.split( '_' );

            order_by = [
              [ keyToAttrMap[primaryKey], 'DESC' ],
              [ keyToAttrMap[secondaryKey], 'DESC' ],
              [ 'id', 'DESC' ],
            ];
          } else {

            order_by = [
              [ order, 'DESC' ],
              [ 'id', 'DESC' ],
            ];
          }
        }
      }
      fn = async () => {
        // console.log( 'here' );
        // eslint-disable-next-line max-len
        const _records = await getRecords( 'vulnerability', { order_by, rows: rownums }, true );

        if ( isNotEmpty( _records ) ) {
          const ids = _records.map( r => r.id );

          if ( isNotEmpty( ids ) ) {

            const promises = [];

            ids.map( id => {
              promises.push( getRecordDetails( 'vulnerability', id, {}, true ) );
            } );

            if ( isNotEmpty( promises ) ) {
              const _recordsWithDetails = await Promise.all( promises );

              if ( isNotEmpty( _recordsWithDetails ) ) {
                return _recordsWithDetails;
              }
              return [];
            }
            return [];
          }
        }
        return [];
      };
      break;
    case 'vulnerabilities_priority':
      // ordering
      if ( isNotEmpty( param_1 ) ) {
        let order = param_1.split( '_' );
        order.shift();
        order = order.join( '_' );
        if ( isNotEmpty( order ) ) {
          if ( multipleOrderByOptions.includes( order ) ) {
            const [ primaryKey, secondaryKey ] = order.split( '_' );

            order_by = [
              [ keyToAttrMap[primaryKey], 'DESC' ],
              [ keyToAttrMap[secondaryKey], 'DESC' ],
              [ 'id', 'DESC' ],
            ];
          } else {
            order_by = [
              [ order, 'DESC' ],
              [ 'id', 'DESC' ],
            ];
          }
        }
      }
      // asset tags
      if ( isNotEmpty( param_2 ) ) {
        const [ , commaTags ] = param_2.split( '_' );
        if ( isNotEmpty( commaTags ) ) {
          const tags = commaTags.split( ',' );
          console.log( tags );
          asset_tag_ids = tags;
        }
      }

      if ( isNotEmpty( order_by ) ) {
        if ( isNotEmpty( asset_tag_ids ) ) {
          // console.log( 'here' );
          fn = () => getRecords(
            'vulnerability',
            {
              filters: {
                ...paramsMap.table.vulnerabilities.filters,
                asset_tag_ids,
              },
              order_by,
            },
            true,
          );
        } else {
          // console.log( 'here' );
          fn = () => getRecords(
            'vulnerability',
            {
              order_by,
            },
            true,
          );
        }
      } else {
        // console.log( 'here' );
        fn = () => getRecords(
          'vulnerability',
          null,
          true,
        );
      }
      break;

    // users
    case 'users_priority':
      fn = () => makeRequest( 'SEARCH', '/model/domain_user', paramsMap.table.users );
      break;

    // vuln. instances, each separate, need to be combined into a single call later
    case 'vulnerability_instances_category':
      fn = () => getTallies( 'instances', { types: [ 'category' ] } );
      break;
    case 'vulnerability_instances_exploit_status':
      fn = () => getTallies( 'instances', { types: [ 'exploit_status' ] } );
      break;
    case 'vulnerability_instances_cvss':
      fn = () => getTallies( 'instances', { types: [ 'cvss' ] } );
      break;
    case 'vulnerability_instances_os_family':
      fn = () => getTallies( 'instances', { types: [ 'os_family' ] } );
      break;
    case 'vulnerability_instances_asset_tag':
      fn = () => getTallies( 'instances', { types: [ 'asset_tag' ] } );
      break;
    case 'vulnerability_instances_vulnerability_age':
      fn = () => getTallies( 'instances', { types: [ 'vulnerability_age' ] } );
      break;

    case 'paths_global':
      if ( isNotEmpty( param_1 ) ) {
        const [ , count ] = param_1.split( '_' );
        if ( isNotEmpty( count ) ) {
          rownums = [ 0, count ];
        }
      }

      fn = async () => {

        const params = {
          // eslint-disable-next-line camelcase
          extra_columns: [
            'keywords',
            'risk',
            'modified',
            'edges',
            'node_labels',
          ],
          // eslint-disable-next-line camelcase
          order_by: [
            [ 'risk', 'DESC' ],
          ],
          rownums,
        };
        const pathsResponse = await getRecords( 'path', params );

        if ( isNotEmpty( pathsResponse ) ) {
          // eslint-disable-next-line camelcase
          const path_ids = pathsResponse.map( p => p.id );

          // params for initial fetch of the related paths
          const relatedParams = {
            model: 'base',
            // eslint-disable-next-line camelcase
            path_ids,
            rownums,
          };

          const relatedPathsResponse = await makeRequest( 'FETCH', '/analysis/related_paths', relatedParams );

          return relatedPathsResponse;
        }
      };
      break;

    // risk
    case 'risk_score':
      fn = emptyFetch;
      break;
    case 'risk_peer_percentile':
      fn = () => makeRequest( 'FETCH', '/analysis/industry_percentile_history', defaultParams );
      break;
    case 'risk_over_time':

      if ( isNotEmpty( param_1 ) ) {
        const [ , duration ] = param_1.split( '_' );
        if ( isNotEmpty( duration ) ) {
          days = parseInt( duration );
          fn = async () => {
            const risk = await getGlobalRisk( { days } );
            const project = await getGlobalSettings( 'project' );

            return ( { risk, project } );
          };
          break;
        }
        fn = async () => {
          const risk = await getGlobalRisk( { days } );
          const project = await getGlobalSettings( 'project' );

          return ( { risk, project } );
        };
        break;
      }
      fn = async () => {
        const risk = await getGlobalRisk( { days } );
        const project = await getGlobalSettings( 'project' );

        return ( { risk, project } );
      };
      break;
    case 'risk_target':
      fn = emptyFetch;
      break;
    // risk, but also used for host/patch/vuln global and total
    case 'risk_breakdown':
      fn = () => makeRequest( 'TALLY', '/model/base/insight', {} );
      break;

    // scanning
    case 'scanning':
      if ( isNotEmpty( param_1 ) ) {
        const [ , type ] = param_1.split( '_' );
        if ( isNotEmpty( type ) ) {

          if ( type === 'agentless' ) {
            fn = async () => {
              const agentless = await makeRequest( 'TALLY', '/scan_status/agentless/default', { filters: {} } );
              const scanGroups = await makeRequest( 'FIND', '/project/default/scan_group', {} );

              return ( { ...agentless, scanGroups, total: agentless?.category?.all } );
            };
          }
          if ( type === 'agent' ) {
            fn = async () => {
              const agent = await makeRequest( 'TALLY', '/scan_status/agent/default', { filters: {} } );
              return ( { ...agent, total: agent?.category?.all } );
            };
          }
          if ( type === 'both' ) {
            fn = async () => {
              const agent = await makeRequest( 'TALLY', '/scan_status/agent/default', { filters: {} } );
              const agentless = await makeRequest( 'TALLY', '/scan_status/agentless/default', { filters: {} } );
              const scanGroups = await makeRequest( 'FIND', '/project/default/scan_group', {} );

              return ( {
                ...agent,
                ...agentless,
                scanGroups,
                agentTotal: agent?.category?.all,
                agentlessTotal: agentless?.category?.all,
              } );
            };
          }
        }
      }
      break;

    // remediation
    case 'remediation_plans':
      // specific plan
      if ( isNotEmpty( param_1 ) ) {
        const [ , id ] = param_1.split( '_' );

        if ( isNotEmpty( id ) ) {
          fn = async () => {
            const users = await makeRequest( 'POST', '/fe/user/SELECT', {
              // eslint-disable-next-line camelcase
              columns: [
                'authentication_provider_id',
                'username',
                'given_name',
                'family_name',
                'api_key',
                'setup_wizard_disabled',
                'email_address',
              ],
              order_by: [ [ 'username', 'ASC' ] ],
            } );

            const taskFetch = await makeRequest( 'FETCH', '/project/default/model/base/remediation_task', {
              // eslint-disable-next-line camelcase
              remediation_plan_id: id,
            } );
            if ( isNotEmpty( taskFetch ) ) {
              return ( { tasks: taskFetch.results, users } );
            }
            return {};
          };
        }
      }
      fn = async () => {
        const plans = await makeRequest( 'LIST', '/project/default/model/base/remediation_plan', {} );
        const users = await makeRequest( 'POST', '/fe/user/SELECT', {
          // eslint-disable-next-line camelcase
          columns: [
            'authentication_provider_id',
            'username',
            'given_name',
            'family_name',
            'api_key',
            'setup_wizard_disabled',
            'email_address',
          ],
          order_by: [ [ 'username', 'ASC' ] ],
        } );

        return ( { plans, users } );
      };
      break;

    // text
    case 'text_free_form':
      fn = emptyFetch;
      break;

    // history analysis, tag, compliance
    case 'analysis_compliance_comparison':
      // duration
      if ( isNotEmpty( param_1 ) ) {
        const [ , comparison ] = param_1.split( '_' );

        if ( isNotEmpty( comparison ) ) {
          if ( comparison === 'day' ) {
            overtime_start_1 = now - ( day * 5 );
            overtime_start_2 = null;
            overtime_row_start = 1_000;

            overtime_end_1 = now - day;
            overtime_end_2 = null;
            overtime_row_end = 1_000;
          }
          if ( comparison === 'week' ) {
            overtime_start_1 = now - week - ( day * 5 );
            overtime_start_2 = now - week + ( day * 5 );
            overtime_row_start = 1_000;

            overtime_end_1 = null;
            overtime_end_2 = null;
            overtime_row_end = 1_000;
          }
          if ( comparison === 'month' ) {
            overtime_start_1 = now - month - ( day * 5 );
            overtime_start_2 = now - month + ( day * 5 );
            overtime_row_start = 1_000;

            overtime_end_1 = null;
            overtime_end_2 = null;
            overtime_row_end = 1_000;
          }
          if ( comparison === 'quarter' ) {
            overtime_start_1 = now - quarter - ( day * 5 );
            overtime_start_2 = now - quarter + ( day * 5 );
            overtime_row_start = 1_000;

            overtime_end_1 = null;
            overtime_end_2 = null;
            overtime_row_end = 1_000;
          }
          if ( comparison === 'year' ) {
            overtime_start_1 = now - year - ( day * 5 );
            overtime_start_2 = now - year + ( day * 5 );
            overtime_row_start = 1_000;

            overtime_end_1 = null;
            overtime_end_2 = null;
            overtime_row_end = 1_000;
          }

          if ( isNotEmpty( overtime_start_1 ) ) {
            overtime_start_1 = overtime_start_1 / 1_000;
          }
          if ( isNotEmpty( overtime_start_2 ) ) {
            overtime_start_2 = overtime_start_2 / 1_000;
          }
          if ( isNotEmpty( overtime_end_1 ) ) {
            overtime_end_1 = overtime_end_1 / 1_000;
          }
          if ( isNotEmpty( overtime_end_2 ) ) {
            overtime_end_2 = overtime_end_2 / 1_000;
          }
          complianceStartParams.filters.gt_map = { created: overtime_start_1};
          complianceStartParams.filters.lt_map = { created: overtime_start_2 };
          complianceStartParams.rows = [ 0, overtime_row_start ];

          complianceEndParams.filters.gt_map = { created: overtime_end_1};
          complianceEndParams.filters.lt_map = { created: overtime_end_2 };
          complianceEndParams.rows = [ 0, overtime_row_end ];
        }
      }
      // regulation
      if ( isNotEmpty( param_2 ) ) {
        const [ , regulation ] = param_2.split( '-' );
        if ( isNotEmpty( regulation ) ) {
          complianceStartParams.filters.field_map = { regulation };
          complianceEndParams.filters.field_map = { regulation };
        }
      }
      fn = async () => {
        const start = await makeRequest( 'POST', '/fe/history/compliance/SELECT', complianceStartParams );
        const end = await makeRequest( 'POST', '/fe/history/compliance/SELECT', complianceEndParams );
        return ( { start, end } );
      };
      break;
    case 'analysis_compliance_over_time':
      if ( isNotEmpty( param_1 ) ) {
        const [ , duration ] = param_1.split( '_' );

        if ( isNotEmpty( duration ) ) {
          if ( duration === 'week' ) {
            overtime_start_1 = now - week - ( day * 5 );
          }
          if ( duration === 'month' ) {
            overtime_start_1 = now - month - ( day * 5 );
          }
          if ( duration === 'quarter' ) {
            overtime_start_1 = now - quarter - ( day * 5 );
          }
          if ( duration === 'year' ) {
            overtime_start_1 = now - year - ( day * 5 );
          }

          if ( isNotEmpty( overtime_start_1 ) ) {
            overtime_start_1 = overtime_start_1 / 1_000;
          }

          complianceEndParams.filters.gt_map = { created: overtime_start_1 };
        }
      }
      if ( isNotEmpty( param_2 ) ) {
        const [ , regulation ] = param_2.split( '-' );

        if ( isNotEmpty( regulation ) ) {
          complianceEndParams.filters.field_map = { regulation };
        }
      }
      fn = () => makeRequest( 'POST', '/fe/history/compliance/SELECT', complianceEndParams );
      break;
    case 'analysis_tag_history_over_time':
      if ( isNotEmpty( param_1 ) ) {
        const [ , duration ] = param_1.split( '_' );

        if ( isNotEmpty( duration ) ) {
          if ( duration === 'week' ) {
            overtime_start_1 = now - week - ( day * 5 );
          }
          if ( duration === 'month' ) {
            overtime_start_1 = now - month - ( day * 5 );
          }
          if ( duration === 'quarter' ) {
            overtime_start_1 = now - quarter - ( day * 5 );
          }
          if ( duration === 'year' ) {
            overtime_start_1 = now - year - ( day * 5 );
          }

          if ( isNotEmpty( overtime_start_1 ) ) {
            overtime_start_1 = overtime_start_1 / 1_000;
          }
          // analysis or tag history
          if ( isNotEmpty( param_2 ) ) {
            const [ , breakdown ] = param_2.split( '_' );

            if ( breakdown === 'tag' && isNotEmpty( tagCount ) ) {
              fn = () => makeRequest( 'POST', '/fe/history/tag/SELECT', {
                columns: '*',
                filters: {
                  gt_map: { created: overtime_start_1 },
                },
                order_by: [
                  [ 'created', 'ASC' ],
                  [ 'id', 'ASC' ],
                ],
                'rows': [ 0, ( tagCount * 400 ) ],
              } );
              break;
            }
            fn = () => makeRequest( 'POST', '/fe/history/analysis/SELECT', {
              columns: '*',
              filters: {
                gt_map: { created: overtime_start_1 },
              },
              order_by: [
                [ 'created', 'ASC' ],
                [ 'id', 'ASC' ],
              ],
              'rows': [ 0, 1_000 ],
            } );
            break;
          }
          fn = () => makeRequest( 'POST', '/fe/history/analysis/SELECT', {
            columns: '*',
            filters: {
              gt_map: { created: overtime_start_1 },
            },
            order_by: [
              [ 'created', 'ASC' ],
              [ 'id', 'ASC' ],
            ],
            'rows': [ 0, 1_000 ],
          } );
          break;
        }
        fn = () => makeRequest( 'POST', '/fe/history/analysis/SELECT', {
          columns: '*',
          order_by: [
            [ 'created', 'ASC' ],
            [ 'id', 'ASC' ],
          ],
          'rows': [ 0, 1_000 ],
        } );
        break;
      }
      fn = () => makeRequest( 'POST', '/fe/history/analysis/SELECT', {
        columns: '*',
        order_by: [
          [ 'created', 'ASC' ],
          [ 'id', 'ASC' ],
        ],
        'rows': [ 0, 1_000 ],
      } );
      break;
    case 'analysis_tag_history_comparison':
      if ( isNotEmpty( param_1 ) ) {
        const [ , comparison ] = param_1.split( '_' );

        if ( isNotEmpty( comparison ) ) {
          if ( comparison === 'day' ) {
            overtime_start_1 = now - ( day * 5 );
            overtime_start_2 = null;
            overtime_row_start = 50;

            overtime_end_1 = now - day;
            overtime_end_2 = null;
            overtime_row_end = 50;
          }
          if ( comparison === 'week' ) {
            overtime_start_1 = now - week - ( day * 5 );
            overtime_start_2 = now - week + ( day * 5 );
            overtime_row_start = 50;

            overtime_end_1 = null;
            overtime_end_2 = null;
            overtime_row_end = 50;
          }
          if ( comparison === 'month' ) {
            overtime_start_1 = now - month - ( day * 5 );
            overtime_start_2 = now - month + ( day * 5 );
            overtime_row_start = 50;

            overtime_end_1 = null;
            overtime_end_2 = null;
            overtime_row_end = 50;
          }
          if ( comparison === 'quarter' ) {
            overtime_start_1 = now - quarter - ( day * 5 );
            overtime_start_2 = now - quarter + ( day * 5 );
            overtime_row_start = 50;

            overtime_end_1 = null;
            overtime_end_2 = null;
            overtime_row_end = 50;
          }
          if ( comparison === 'year' ) {
            overtime_start_1 = now - year - ( day * 5 );
            overtime_start_2 = now - year + ( day * 5 );
            overtime_row_start = 50;

            overtime_end_1 = null;
            overtime_end_2 = null;
            overtime_row_end = 50;
          }

          if ( isNotEmpty( overtime_start_1 ) ) {
            overtime_start_1 = overtime_start_1 / 1_000;
          }
          if ( isNotEmpty( overtime_start_2 ) ) {
            overtime_start_2 = overtime_start_2 / 1_000;
          }
          if ( isNotEmpty( overtime_end_1 ) ) {
            overtime_end_1 = overtime_end_1 / 1_000;
          }
          if ( isNotEmpty( overtime_end_2 ) ) {
            overtime_end_2 = overtime_end_2 / 1_000;
          }
          // analysis or tag history
          if ( isNotEmpty( param_2 ) ) {
            const [ , breakdown ] = param_2.split( '_' );

            if ( breakdown === 'tag' ) {
              fn = async () => {
                const start = await makeRequest( 'POST', '/fe/history/tag/SELECT', {
                  columns: '*',
                  filters: {
                    gt_map: { created: overtime_start_1 },
                    lt_map: { created: overtime_start_2 },
                  },
                  order_by: [
                    [ 'created', 'DESC' ],
                    [ 'id', 'ASC' ],
                  ],
                  'rows': [ 0, overtime_row_start ],
                } );
                const end = await makeRequest( 'POST', '/fe/history/tag/SELECT', {
                  columns: '*',
                  filters: {
                    gt_map: { created: overtime_end_1 },
                    lt_map: { created: overtime_end_2 },
                  },
                  order_by: [
                    [ 'created', 'DESC' ],
                    [ 'id', 'ASC' ],
                  ],
                  'rows': [ 0, 50 ],
                } );

                return ( { start, end } );
              };
              break;
            }
            fn = async () => {
              const start = await makeRequest( 'POST', '/fe/history/analysis/SELECT', {
                columns: '*',
                filters: {
                  gt_map: { created: overtime_start_1 },
                  lt_map: { created: overtime_start_2 },
                },
                order_by: [
                  [ 'created', 'DESC' ],
                  [ 'id', 'ASC' ],
                ],
                'rows': [ 0, overtime_row_start ],
              } );
              const end = await makeRequest( 'POST', '/fe/history/analysis/SELECT', {
                columns: '*',
                filters: {
                  gt_map: { created: overtime_end_1 },
                  lt_map: { created: overtime_end_2 },
                },
                order_by: [
                  [ 'created', 'DESC' ],
                  [ 'id', 'ASC' ],
                ],
                'rows': [ 0, 1 ],
              } );

              return ( { start, end } );
            };
            break;
          }
          fn = async () => {
            const start = await makeRequest( 'POST', '/fe/history/analysis/SELECT', {
              columns: '*',
              filters: {
                gt_map: { created: overtime_start_1 },
                lt_map: { created: overtime_start_2 },
              },
              order_by: [
                [ 'created', 'DESC' ],
                [ 'id', 'ASC' ],
              ],
              'rows': [ 0, overtime_row_start ],
            } );
            const end = await makeRequest( 'POST', '/fe/history/analysis/SELECT', {
              columns: '*',
              filters: {
                gt_map: { created: overtime_end_1 },
                lt_map: { created: overtime_end_2 },
              },
              order_by: [
                [ 'created', 'DESC' ],
                [ 'id', 'ASC' ],
              ],
              'rows': [ 0, 1 ],
            } );

            return ( { start, end } );
          };
          break;
        }
        fn = () => makeRequest( 'POST', '/fe/history/analysis/SELECT', {
          columns: '*',
          order_by: [
            [ 'created', 'DESC' ],
            [ 'id', 'ASC' ],
          ],
          'rows': [ 0, 1_000 ],
        } );
        break;
      }
      fn = () => makeRequest( 'POST', '/fe/history/analysis/SELECT', {
        columns: '*',
        order_by: [
          [ 'created', 'DESC' ],
          [ 'id', 'ASC' ],
        ],
        'rows': [ 0, 1_000 ],
      } );
      break;
    default:
      break;
    }
    return fn;
  }
  return emptyFetch;
};

// -------------------------------------------------------------------------
// --------------------------------V2 Components, Widgets, caching, fetching
// -------------------------------------------------------------------------

export const legacyWidgetKeys = [
  'risk_over_time',
  'top_paths',
  'table',
  'priority',
  'metric',
  'vulnerability_instances',
  'peer_comparison',
  'exploit_status',
  'cvss',
  'remediation_plans',
];

export const getWidgetDescription = ( widget, data, settings ) => {

  if ( isNotEmpty( widget ) ) {
    switch  ( widget.key ) {
    case 'hosts_comparison':
      if ( settings?.include_tag_breakdown ) {
        return <p>A tag represents a user-configured group of assets ('hosts').</p>;
      }
      return null;
    case 'hosts_over_time':
      if ( settings?.include_tag_breakdown ) {
        return <p>A tag represents a user-configured group of assets ('hosts').</p>;
      }
      return null;
    case 'hosts_global':
      return <p>A breakdown of all <strong>{ formatNumber( data?.total ) } host(s)</strong> by risk rating.</p>;

    case 'patches_comparison':
      if ( settings?.include_tag_breakdown ) {
        return <p>A tag represents a user-configured group of assets ('hosts').</p>;
      }
      return null;
    case 'patches_over_time':
      if ( settings?.include_tag_breakdown ) {
        return <p>A tag represents a user-configured group of assets ('hosts').</p>;
      }
      return null;
    case 'patches_global':
      return <p>A breakdown of all <strong>{ formatNumber( data?.total ) } patch(es)</strong> by risk rating.</p>;

    case 'vulnerabilities_comparison':
      if ( settings?.include_tag_breakdown ) {
        return <p>A tag represents a user-configured group of assets ('hosts').</p>;
      }
      return null;
    case 'vulnerabilities_over_time':
      if ( settings?.include_tag_breakdown ) {
        return <p>A tag represents a user-configured group of assets ('hosts').</p>;
      }
      return null;
    case 'vulnerabilities_global':
      return <p>A breakdown of all <strong>{ formatNumber( data?.total ) } vulnerabilities</strong> by risk rating.</p>;

    case 'paths_global':
      return <p>A critical path shows how an outside attacker could reach vulnerable sensitve asset(s)</p>;

    case 'vulnerability_instances_global':
      // eslint-disable-next-line max-len
      return <p>A vulnerability instance represents a single vulnerability appearing on a single asset ('host'). DeepSurface organizes these instances into categories depending on how much risk they present.</p>;
    case 'vulnerability_instances_comparison':
      return <p>A vulnerability instance represents a single vulnerability appearing on a single asset ('host').</p>;
    case 'vulnerability_instances_over_time':
      if ( settings?.category_version === 'specific_categories' && isNotEmpty( settings?.included_categories ) ) {
        return <p>A vulnerability instance represents a single vulnerability appearing on a single asset ('host').</p>;
      } else if ( settings?.category_version === 'tag' ) {
        // eslint-disable-next-line max-len
        return <p>A vulnerability instance represents a single vulnerability appearing on a single asset ('host'). A tag represents a user-configured group of assets ('hosts').</p>;
      }
      return <p>A vulnerability instance represents a single vulnerability appearing on a single asset ('host').</p>;
    case 'vulnerability_instances_exploit_status_breakdown':
      // eslint-disable-next-line max-len
      return <p>The Exploit Status represents the likelihood of a CVE being exploited in the wild.</p>;
    case 'vulnerability_instances_cvss_breakdown':
      return <p>A vulnerability instance represents a single vulnerability appearing on a single asset ('host').</p>;
    case 'vulnerability_instances_os_family_breakdown':
      return <p>A vulnerability instance represents a single vulnerability appearing on a single asset ('host').</p>;
    case 'vulnerability_instances_tag_breakdown':
      return <p>A tag represents a user-configured group of assets ('hosts').</p>;
    case 'vulnerability_instances_vulnerability_age_breakdown':
      return <p>Vulnerability instances categorized by how long ago the vulnerability was first published.</p>;

    case 'compliance_current':
      // eslint-disable-next-line max-len
      return <p>For more information on <strong>{ complianceFrameworkLabelMap[settings?.regulation] }</strong> compliance, read more <a className="newTabLink inlineLink" target="_blank" rel="noopener noreferrer" href={complianceFrameworkLinkMap[settings?.regulation]}>here</a>.</p>;
    case 'compliance_comparison':
      // eslint-disable-next-line max-len
      return <p>For more information on <strong>{ complianceFrameworkLabelMap[settings?.regulation] }</strong> compliance, read more <a className="newTabLink inlineLink" target="_blank" rel="noopener noreferrer" href={complianceFrameworkLinkMap[settings?.regulation]}>here</a>.</p>;

    case 'risk_peer_percentile_over_time':
      // eslint-disable-next-line max-len
      return <p>How you compare to your peers, based on a combination of your industry vertical and size of the environment.</p>;
    case 'risk_comparison':
      if ( settings?.include_tag_breakdown ) {
        return <p>A tag represents a user-configured group of assets ('hosts').</p>;
      }
      return null;
    case 'risk_simplified_over_time':
      if ( settings?.include_tag_breakdown ) {
        return <p>A tag represents a user-configured group of assets ('hosts').</p>;
      }
      return null;

    case 'scanning_age_breakdown':
      return <p>A breakdown of how recently an asset ('host') was scanned by deepsurface.</p>;
    case 'scanning_agent_version_breakdown':
      return <p>A breakdown of how many assets ('hosts') were scanned by each DeepSurface Agent/User Managed Scan.</p>;
    case 'scanning_scan_group_breakdown':
      return <p>A breakdown of how many assets ('hosts') were scanned in each scan group.</p>;
    default:
      break;
    }
  }
};

export const widgetsWithNoBaseLabel = [
  'hosts_specific',
  'hosts_total',
  'patches_specific',
  'patches_total',
  'vulnerabilities_specific',
  'vulnerabilities_total',
  'risk_score',
  'risk_peer_percentile',
  'risk_grade',
  'risk_target',
  'scanning_total',
  'text_free_form',
  'vulnerability_instances_total',
];

// returns the base part of a widget label, the bold part (followed by a colon if there is more)
export const widgetHeaderLabelBase = {
  hosts_top_details: 'Hosts',
  hosts_priority: 'Hosts',
  hosts_comparison: 'Hosts',
  hosts_over_time: 'Hosts',
  hosts_global: 'Hosts',

  patches_top_details: 'Patches',
  patches_priority: 'Patches',
  patches_comparison: 'Patches',
  patches_over_time: 'Patches',
  patches_global: 'Patches',

  vulnerabilities_top_details: 'Vulnerabilities',
  vulnerabilities_priority: 'Vulnerabilities',
  vulnerabilities_comparison: 'Vulnerabilities',
  vulnerabilities_over_time: 'Vulnerabilities',
  vulnerabilities_global: 'Vulnerabilities',

  compliance_current: 'Compliance Snapshot',
  compliance_comparison: 'Compliance',
  compliance_over_time: 'Compliance',

  users_priority: 'Users',

  // paths_specific: 'Paths',
  paths_global: 'Paths',

  vulnerability_instances_global: 'Vulnerability Instances',
  vulnerability_instances_comparison: 'Vulnerability Instances',
  vulnerability_instances_over_time: 'Vulnerability Instances',
  vulnerability_instances_exploit_status_breakdown: 'Vulnerability Instances',
  vulnerability_instances_cvss_breakdown: 'Vulnerability Instances',
  vulnerability_instances_os_family_breakdown: 'Vulnerability Instances',
  vulnerability_instances_tag_breakdown: 'Vulnerability Instances',
  vulnerability_instances_vulnerability_age_breakdown: 'Vulnerability Instances',

  vulnerability_instances_category: 'Vulnerability Instance Category',

  risk_global: 'Risk',
  risk_over_time: 'Risk',
  risk_peer_percentile_over_time: 'Risk',
  risk_comparison: 'Risk',
  risk_simplified_over_time: 'Risk',

  scanning_age_breakdown: 'DeepSurface Scanning',
  scanning_agent_version_breakdown: 'DeepSurface Scanning',
  scanning_scan_group_breakdown: 'DeepSurface Scanning',

  remediation_plans: 'Remediation',
  remediation_specific_plan: 'Remediation',
};

export const getWidgetHeaderLabelModifier = ( widget ) => {

  const comparisonLabelMap = {
    day: 'Yesterday',
    week: 'Last Week',
    month: 'Last Month',
    quarter: 'Last Quarter',
    year: 'Last Year',
  };

  const orderByToWords = orderBy => {
    switch ( orderBy ) {
    case 'filtered_risk':
      return 'Risk Score';
    case 'cvss_base_score':
      return 'CVSS Score';
    case 'exploit_status':
      return 'Exploit Status';
    case 'num_hosts':
      return 'Number of Affected Hosts';
    case 'num_vulnerabilities':
      return 'Amount of Vulnerabilities';
    case 'cvss_exploit':
      return 'CVSS Score and then Exploit Status';
    case 'cvss_hosts':
      return 'CVSS Score and then Number of Affected Hosts';
    case 'exploit_cvss':
      return 'Exploit Status and then CVSS Score';
    case 'exploit_hosts':
      return 'Exploit Status and then Number of Hosts';
    case 'hosts_cvss':
      return 'Number of Hosts and then CVSS Score';
    case 'hosts_exploit':
      return 'Number of Hosts and then Exploit Status';
    default:
      return 'Risk Score';
    }
  };

  if ( isNotEmpty( widget ) && isNotEmpty( widget.key ) && !widgetsWithNoBaseLabel.includes( widget.key ) ) {
    const { key, settings } = widget;

    if ( isNotEmpty( key ) ) {
      switch  ( key ) {
      case 'hosts_top_details':
        // eslint-disable-next-line max-len
        return `Top ${settings?.item_count} ${ capitalize( settings?.report_type ) } Sorted by ${ orderByToWords( settings?.order_by ) }`;
      case 'hosts_priority':
        // eslint-disable-next-line max-len
        return `Top ${settings?.item_count} ${ capitalize( settings?.report_type ) } Sorted by ${ orderByToWords( settings?.order_by ) }`;
      case 'hosts_comparison':
        // eslint-disable-next-line max-len
        return `Current Count Compared to ${comparisonLabelMap[settings?.comparison_date || 'month']} ${settings?.include_tag_breakdown ? 'Broken Down by Tag' : ''}`;
      case 'hosts_over_time':
        // eslint-disable-next-line max-len
        return `Count Over the ${comparisonLabelMap[settings?.duration || 'month']} ${settings?.include_tag_breakdown ? 'Broken Down by Tag' : ''}`;
      case 'hosts_global':
        return 'risk breakdown';

      case 'patches_top_details':
        // eslint-disable-next-line max-len
        return `Top ${settings?.item_count} ${ capitalize( settings?.report_type ) } Sorted by ${ orderByToWords( settings?.order_by ) }`;
      case 'patches_priority':
        // eslint-disable-next-line max-len
        return `Top ${settings?.item_count} ${ capitalize( settings?.report_type ) } Sorted by ${ orderByToWords( settings?.order_by ) }`;
      case 'patches_comparison':
        // eslint-disable-next-line max-len
        return `Current Outstanding Count Compared to ${comparisonLabelMap[settings?.comparison_date || 'month']} ${settings?.include_tag_breakdown ? 'Broken Down by Tag' : ''}`;
      case 'patches_over_time':
        // eslint-disable-next-line max-len
        return `Outstanding Count Over the ${comparisonLabelMap[settings?.duration || 'month']} ${settings?.include_tag_breakdown ? 'Broken Down by Tag' : ''}`;
      case 'patches_global':
        return 'Risk Breakdown';

      case 'vulnerabilities_top_details':
        // eslint-disable-next-line max-len
        return `Top ${settings?.item_count} ${ capitalize( settings?.report_type ) } Sorted by ${ orderByToWords( settings?.order_by ) }`;
      case 'vulnerabilities_priority':
        // eslint-disable-next-line max-len
        return `Top ${settings?.item_count} ${ capitalize( settings?.report_type ) } Sorted by ${ orderByToWords( settings?.order_by ) }`;
      case 'vulnerabilities_comparison':
        // eslint-disable-next-line max-len
        return `Current Outstanding Count Compared to ${comparisonLabelMap[settings?.comparison_date || 'month']} ${settings?.include_tag_breakdown ? 'Broken Down by Tag' : ''}`;
      case 'vulnerabilities_over_time':
        // eslint-disable-next-line max-len
        return `Outstanding Count Over the ${comparisonLabelMap[settings?.duration || 'month']} ${settings?.include_tag_breakdown ? 'Broken Down by Tag' : ''}`;
      case 'vulnerabilities_global':
        // this same fetch is used for many widgets, does not match key
        return 'Risk Breakdown';

      case 'users_priority':
        return `Top ${settings?.item_count} ${ capitalize( settings?.report_type ) } Sorted by Risk Score`;

      // case 'paths_specific':
      //   return `top ${settings?.item_count} for ${settings?.report_type}`;
      case 'paths_global':
        return `Top ${settings?.item_count} critical paths in your environment`;

      case 'vulnerability_instances_global':
        return 'Categories Breakdown';
      case 'vulnerability_instances_comparison':
        // eslint-disable-next-line max-len
        return `Current Total Compared to ${comparisonLabelMap[settings?.comparison_date || 'month']} ${settings?.category_version === 'tag' ? 'Broken Down by Tag' : ''}`;
      case 'vulnerability_instances_over_time':
        if ( settings.category_version === 'specific_categories' && isNotEmpty( settings.included_categories ) ) {
          return `Category Count(s) Over the ${comparisonLabelMap[settings?.duration || 'month']}`;
        } else if ( settings.category_version === 'tag' ) {
          // eslint-disable-next-line max-len
          return `Total Instances Count over the ${comparisonLabelMap[settings?.duration || 'month']} Broken Down by Tag`;
        }
        return `Total Instances Count Over the ${comparisonLabelMap[settings?.duration || 'month']}`;
      case 'vulnerability_instances_exploit_status_breakdown':
        return 'Exploit Status Breakdown';
      case 'vulnerability_instances_cvss_breakdown':
        return 'CVSS Score Breakdown';
      case 'vulnerability_instances_os_family_breakdown':
        return 'OS Family Breakdown';
      case 'vulnerability_instances_tag_breakdown':
        return 'Tag Breakdown';
      case 'vulnerability_instances_vulnerability_age_breakdown':
        return 'Vulnerability Age Breakdown';
      case 'vulnerability_instances_category':
        return categoryLabelsAndDescription[settings.category].label;

      case 'compliance_current':
        // eslint-disable-next-line max-len
        return `${ complianceFrameworkLabelMap[settings?.regulation] } ${ settings?.control_segment === 'highest' ? 'Highest' : 'Lowest' } ${settings?.item_count} Passing Controls`;
      case 'compliance_comparison':
        // eslint-disable-next-line max-len
        return `${ complianceFrameworkLabelMap[settings?.regulation] } Current Average Passing Rate Compared to ${comparisonLabelMap[settings?.comparison_date || 'month']}`;

      case 'risk_global':
        return `${capitalize( settings?.report_type )} Breakdown`;
      case 'risk_over_time':
        return 'Over Time';
      case 'risk_peer_percentile_over_time':
        return 'Peer Percentile Over Time';
      case 'risk_comparison':
        // eslint-disable-next-line max-len
        return `Current Score Compared to ${comparisonLabelMap[settings?.comparison_date || 'month']} ${settings?.include_tag_breakdown ? 'Broken Down by Tag' : ''}`;
      case 'risk_simplified_over_time':
        // eslint-disable-next-line max-len
        return `Score Over the ${comparisonLabelMap[settings?.duration || 'month']} ${settings?.include_tag_breakdown ? 'Broken Down by Tag' : ''}`;

      case 'scanning_age_breakdown':
        return 'Age Breakdown';
      case 'scanning_agent_version_breakdown':
        return 'Version Breakdown';
      case 'scanning_scan_group_breakdown':
        return 'Scan Group Breakdown';
      case 'remediation_plans':
        return 'Plans';
      case 'remediation_specific_plan':
        return 'Plan';
      default:
        break;
      }
    }
  }
};

export const v2WidgetsWithLegends = [
  'hosts_global',
  'patches_global',
  'vulnerabilities_global',
  'risk_global',
  'vulnerability_instances_exploit_status_breakdown',
  'vulnerability_instances_cvss_breakdown',
  'vulnerability_instances_os_family_breakdown',
  'vulnerability_instances_vulnerability_age_breakdown',
];

// only used for risk over time v2, needs to be refactored, then this can be removed entirely -DMC 2024-05-01
export const v2Fetches = {
  risk_breakdown: () => makeRequest( 'TALLY', '/model/base/insight', {} ),
  tally: () => getTallies( 'instances', { types: [ 'category', 'exploit_status', 'cvss' ] } ),
  // eslint-disable-next-line max-len
  tally_os: () => getTallies( 'instances', { types: [ 'category', 'exploit_status', 'cvss', 'os_family' ] } ),
  // eslint-disable-next-line max-len
  tally_tag: () => getTallies( 'instances', { types: [ 'category', 'exploit_status', 'cvss', 'asset_tag' ] } ),
  // eslint-disable-next-line max-len
  tally_age: () => getTallies( 'instances', { types: [ 'category', 'exploit_status', 'cvss', 'vulnerability_age' ] } ),
  hosts_list: () => getRecords( 'HOST', paramsMap.table.hosts, true ),
  patches_list: () => getRecords( 'PATCH', paramsMap.table.patches, true ),
  vulnerabilities_list: () => getRecords( 'VULNERABILITY', paramsMap.table.vulnerabilities, true ),
  users_list: () => makeRequest( 'SEARCH', '/model/domain_user', paramsMap.table.users ),
  paths: () => getRecords( 'path', paramsMap.top_paths ),
  // paths_specific: () => {},
  percentile: () => makeRequest( 'FETCH', '/analysis/industry_percentile_history', defaultParams ),

  scanning_credentialed: () => makeRequest( 'TALLY', '/scan_status/agentless/default', { filters: {} } ),
  scanning_agent: () => makeRequest( 'TALLY', '/scan_status/agent/default', { filters: {} } ),
  scan_group: () => makeRequest( 'FIND', '/project/default/scan_group', {} ),

  remediationPlans: () => makeRequest( 'LIST', '/project/default/model/base/remediation_plan', {} ),
  remediationUsers: () => makeRequest( 'SEARCH', '/user', {
    // eslint-disable-next-line camelcase
    extra_columns: [
      'authentication_provider_id',
      'username',
      'given_name',
      'family_name',
      'api_key',
      'setup_wizard_disabled',
      'email_address',
    ],
    'order_by':[ [ 'username', 'ASC' ] ],
  } ),
  risk: ( options ) => getGlobalRisk( options ),
  project: () => getGlobalSettings( 'project' ),
  model: () => makeRequest( 'FETCH', '/model', defaultParams ),
};

// no settings for these widgets
export const UneditableWidgets = [
  'risk_score',
  'risk_peer_percentile',
  'risk_target',
];

// called to make sure all legacy widgets are updated and converted to the latest version (V2 for now)
export const getLatestWidgetVersion = widget => {
  if ( widget?.version === CURRENT_VERSION ) {
    return widget;
  }
  return legacyToV2Widget( widget );
};

// converts legacy widgets to v2 version analog
export const legacyToV2Widget = legacyWidget => {
  if (
    isNotEmpty( legacyWidget )
    && legacyWidget.version !== CURRENT_VERSION
    && isNotEmpty( legacyWidget.type )
    && legacyWidgetKeys.includes( legacyWidget.type )
  ) {

    let h, w, v2Version;

    switch ( legacyWidget.type ) {
    case 'risk_over_time':

      v2Version = availableWidgetsV2.risk.options.risk_over_time;
      ( { w } = legacyWidget );

      h = legacyWidget.h * 3;

      return ( {
        ...v2Version,
        settings: {
          ...v2Version.settings,
          version: 'table',
        },
        x: legacyWidget.x,
        y: legacyWidget.y,
        h,
        i: legacyWidget.i,
      } );
    case 'top_paths':
      v2Version = availableWidgetsV2.path.options.global;
      ( { w, h } = legacyWidget );

      if ( legacyWidget.h < v2Version.minH ) {
        h = v2Version.minH;
      } else if ( legacyWidget.h > v2Version.maxH ) {
        h = v2Version.maxH;
      }
      if ( legacyWidget.w < v2Version.minW ) {
        w = v2Version.minW;
      } else if ( legacyWidget.w > v2Version.maxW ) {
        w = v2Version.maxW;
      }
      return ( {
        ...v2Version,
        x: legacyWidget.x,
        y: legacyWidget.y,
        w,
        h,
        i: legacyWidget.i,
      } );
    case 'table':
      switch ( legacyWidget.settings.report_type ) {

      case 'hosts':
        v2Version = availableWidgetsV2.host.options.priority;
        ( { w, h } = legacyWidget );

        if ( legacyWidget.h < v2Version.minH ) {
          h = v2Version.minH;
        } else if ( legacyWidget.h > v2Version.maxH ) {
          h = v2Version.maxH;
        }
        if ( legacyWidget.w < v2Version.minW ) {
          w = v2Version.minW;
        } else if ( legacyWidget.w > v2Version.maxW ) {
          w = v2Version.maxW;
        }
        return ( {
          ...v2Version,
          settings: {
            ...v2Version.settings,
            version: 'table',
            report_type: 'hosts',
            include_rating: legacyWidget.settings.include_rating,
            include_user: legacyWidget.settings.include_user,
            include_os_type: legacyWidget.settings.include_os_type,
          },
          x: legacyWidget.x,
          y: legacyWidget.y,
          h,
          w,
          i: legacyWidget.i,
        } );
      case 'patches':
        v2Version = availableWidgetsV2.patch.options.priority;
        ( { w, h } = legacyWidget );

        if ( legacyWidget.h < v2Version.minH ) {
          h = v2Version.minH;
        } else if ( legacyWidget.h > v2Version.maxH ) {
          h = v2Version.maxH;
        }
        if ( legacyWidget.w < v2Version.minW ) {
          w = v2Version.minW;
        } else if ( legacyWidget.w > v2Version.maxW ) {
          w = v2Version.maxW;
        }
        return ( {
          ...v2Version,
          settings: {
            ...v2Version.settings,
            version: 'table',
            report_type: 'patches',
            include_rating: legacyWidget.settings.include_rating,
          },
          x: legacyWidget.x,
          y: legacyWidget.y,
          h,
          w,
          i: legacyWidget.i,
        } );
      case 'vulnerabilities':
        v2Version = availableWidgetsV2.vulnerability.options.priority;
        ( { w, h } = legacyWidget );

        if ( legacyWidget.h < v2Version.minH ) {
          h = v2Version.minH;
        } else if ( legacyWidget.h > v2Version.maxH ) {
          h = v2Version.maxH;
        }
        if ( legacyWidget.w < v2Version.minW ) {
          w = v2Version.minW;
        } else if ( legacyWidget.w > v2Version.maxW ) {
          w = v2Version.maxW;
        }
        return ( {
          ...v2Version,
          settings: {
            ...v2Version.settings,
            version: 'table',
            report_type: 'vulnerabilities',
            include_rating: legacyWidget.settings.include_rating,
          },
          x: legacyWidget.x,
          y: legacyWidget.y,
          h,
          w,
          i: legacyWidget.i,
        } );
      case 'users':
        v2Version = availableWidgetsV2.user.options.priority;
        ( { w, h } = legacyWidget );

        if ( legacyWidget.h < v2Version.minH ) {
          h = v2Version.minH;
        } else if ( legacyWidget.h > v2Version.maxH ) {
          h = v2Version.maxH;
        }
        if ( legacyWidget.w < v2Version.minW ) {
          w = v2Version.minW;
        } else if ( legacyWidget.w > v2Version.maxW ) {
          w = v2Version.maxW;
        }
        return ( {
          ...v2Version,
          settings: {
            ...v2Version.settings,
            version: 'table',
            report_type: 'users',
            include_rating: legacyWidget.settings.include_rating,
          },
          x: legacyWidget.x,
          y: legacyWidget.y,
          h,
          w,
          i: legacyWidget.i,
        } );
      }
      break;
    case 'priority':
      switch ( legacyWidget.settings.version ) {

      case 'list':
        switch ( legacyWidget.settings.report_type ) {

        case 'hosts':
          v2Version = availableWidgetsV2.host.options.priority;
          ( { w, h } = legacyWidget );

          if ( legacyWidget.h < v2Version.minH ) {
            h = v2Version.minH;
          } else if ( legacyWidget.h > v2Version.maxH ) {
            h = v2Version.maxH;
          }
          if ( legacyWidget.w < v2Version.minW ) {
            w = v2Version.minW;
          } else if ( legacyWidget.w > v2Version.maxW ) {
            w = v2Version.maxW;
          }
          return ( {
            ...v2Version,
            settings: {
              ...v2Version.settings,
              version: 'list',
              report_type: 'hosts',
              include_rating: legacyWidget.settings.include_rating,
              include_user: legacyWidget.settings.include_user,
              include_os_type: legacyWidget.settings.include_os_type,
            },
            x: legacyWidget.x,
            y: legacyWidget.y,
            h,
            w,
            i: legacyWidget.i,
          } );
        case 'patches':
          v2Version = availableWidgetsV2.patch.options.priority;
          ( { w, h } = legacyWidget );

          if ( legacyWidget.h < v2Version.minH ) {
            h = v2Version.minH;
          } else if ( legacyWidget.h > v2Version.maxH ) {
            h = v2Version.maxH;
          }
          if ( legacyWidget.w < v2Version.minW ) {
            w = v2Version.minW;
          } else if ( legacyWidget.w > v2Version.maxW ) {
            w = v2Version.maxW;
          }

          return ( {
            ...v2Version,
            settings: {
              ...v2Version.settings,
              version: 'list',
              report_type: 'patches',
              include_rating: legacyWidget.settings.include_rating,
            },
            x: legacyWidget.x,
            y: legacyWidget.y,
            h,
            w,
            i: legacyWidget.i,
          } );
        case 'vulnerabilities':
          v2Version = availableWidgetsV2.vulnerability.options.priority;
          ( { w, h } = legacyWidget );

          if ( legacyWidget.h < v2Version.minH ) {
            h = v2Version.minH;
          } else if ( legacyWidget.h > v2Version.maxH ) {
            h = v2Version.maxH;
          }
          if ( legacyWidget.w < v2Version.minW ) {
            w = v2Version.minW;
          } else if ( legacyWidget.w > v2Version.maxW ) {
            w = v2Version.maxW;
          }

          return ( {
            ...v2Version,
            settings: {
              ...v2Version.settings,
              version: 'list',
              report_type: 'vulnerabilities',
              include_rating: legacyWidget.settings.include_rating,
            },
            x: legacyWidget.x,
            y: legacyWidget.y,
            h,
            w,
            i: legacyWidget.i,
          } );
        case 'users':
          v2Version = availableWidgetsV2.user.options.priority;
          ( { w, h } = legacyWidget );

          if ( legacyWidget.h < v2Version.minH ) {
            h = v2Version.minH;
          } else if ( legacyWidget.h > v2Version.maxH ) {
            h = v2Version.maxH;
          }
          if ( legacyWidget.w < v2Version.minW ) {
            w = v2Version.minW;
          } else if ( legacyWidget.w > v2Version.maxW ) {
            w = v2Version.maxW;
          }

          return ( {
            ...v2Version,
            settings: {
              ...v2Version.settings,
              version: 'list',
              report_type: 'users',
              include_rating: legacyWidget.settings.include_rating,
            },
            x: legacyWidget.x,
            y: legacyWidget.y,
            h,
            w,
            i: legacyWidget.i,
          } );
        }
        break;

      case 'bar':
        switch ( legacyWidget.settings.report_type ) {

        case 'hosts':
          v2Version = availableWidgetsV2.host.options.priority;
          ( { w, h } = legacyWidget );

          if ( legacyWidget.h < v2Version.minH ) {
            h = v2Version.minH;
          } else if ( legacyWidget.h > v2Version.maxH ) {
            h = v2Version.maxH;
          }
          if ( legacyWidget.w < v2Version.minW ) {
            w = v2Version.minW;
          } else if ( legacyWidget.w > v2Version.maxW ) {
            w = v2Version.maxW;
          }

          return ( {
            ...v2Version,
            settings: {
              ...v2Version.settings,
              version: 'barchart',
              report_type: 'hosts',
              include_rating: legacyWidget.settings.include_rating,
              include_user: legacyWidget.settings.include_user,
              include_os_type: legacyWidget.settings.include_os_type,
            },
            x: legacyWidget.x,
            y: legacyWidget.y,
            h,
            w,
            i: legacyWidget.i,
          } );
        case 'patches':
          v2Version = availableWidgetsV2.patch.options.priority;
          ( { w, h } = legacyWidget );

          if ( legacyWidget.h < v2Version.minH ) {
            h = v2Version.minH;
          } else if ( legacyWidget.h > v2Version.maxH ) {
            h = v2Version.maxH;
          }
          if ( legacyWidget.w < v2Version.minW ) {
            w = v2Version.minW;
          } else if ( legacyWidget.w > v2Version.maxW ) {
            w = v2Version.maxW;
          }

          return ( {
            ...v2Version,
            settings: {
              ...v2Version.settings,
              version: 'barchart',
              report_type: 'patches',
              include_rating: legacyWidget.settings.include_rating,
            },
            x: legacyWidget.x,
            y: legacyWidget.y,
            h,
            w,
            i: legacyWidget.i,
          } );
        case 'vulnerabilities':
          v2Version = availableWidgetsV2.vulnerability.options.priority;
          ( { w, h } = legacyWidget );

          if ( legacyWidget.h < v2Version.minH ) {
            h = v2Version.minH;
          } else if ( legacyWidget.h > v2Version.maxH ) {
            h = v2Version.maxH;
          }
          if ( legacyWidget.w < v2Version.minW ) {
            w = v2Version.minW;
          } else if ( legacyWidget.w > v2Version.maxW ) {
            w = v2Version.maxW;
          }

          return ( {
            ...v2Version,
            settings: {
              ...v2Version.settings,
              version: 'barchart',
              report_type: 'vulnerabilities',
              include_rating: legacyWidget.settings.include_rating,
            },
            x: legacyWidget.x,
            y: legacyWidget.y,
            h,
            w,
            i: legacyWidget.i,
          } );
        case 'users':
          v2Version = availableWidgetsV2.user.options.priority;
          ( { w, h } = legacyWidget );

          if ( legacyWidget.h < v2Version.minH ) {
            h = v2Version.minH;
          } else if ( legacyWidget.h > v2Version.maxH ) {
            h = v2Version.maxH;
          }
          if ( legacyWidget.w < v2Version.minW ) {
            w = v2Version.minW;
          } else if ( legacyWidget.w > v2Version.maxW ) {
            w = v2Version.maxW;
          }

          return ( {
            ...v2Version,
            settings: {
              ...v2Version.settings,
              version: 'barchart',
              report_type: 'users',
              include_rating: legacyWidget.settings.include_rating,
            },
            x: legacyWidget.x,
            y: legacyWidget.y,
            h,
            w,
            i: legacyWidget.i,
          } );
        }
        break;
      }
      break;
    case 'metric':
      switch ( legacyWidget.settings.metric_type ) {
      case 'risk':
        v2Version = availableWidgetsV2.risk.options.score;
        ( { w, h } = legacyWidget );

        if ( legacyWidget.h < v2Version.minH ) {
          h = v2Version.minH;
        } else if ( legacyWidget.h > v2Version.maxH ) {
          h = v2Version.maxH;
        }
        if ( legacyWidget.w < v2Version.minW ) {
          w = v2Version.minW;
        } else if ( legacyWidget.w > v2Version.maxW ) {
          w = v2Version.maxW;
        }

        return ( {
          ...v2Version,
          x: legacyWidget.x,
          y: legacyWidget.y,
          h,
          w,
          i: legacyWidget.i,
        } );
      case 'target':
        v2Version = availableWidgetsV2.risk.options.target_risk;
        ( { w, h } = legacyWidget );

        if ( legacyWidget.h < v2Version.minH ) {
          h = v2Version.minH;
        } else if ( legacyWidget.h > v2Version.maxH ) {
          h = v2Version.maxH;
        }
        if ( legacyWidget.w < v2Version.minW ) {
          w = v2Version.minW;
        } else if ( legacyWidget.w > v2Version.maxW ) {
          w = v2Version.maxW;
        }

        return ( {
          ...v2Version,
          x: legacyWidget.x,
          y: legacyWidget.y,
          h,
          w,
          i: legacyWidget.i,
        } );
      case 'percentile':
        v2Version = availableWidgetsV2.risk.options.peer;
        ( { w, h } = legacyWidget );

        if ( legacyWidget.h < v2Version.minH ) {
          h = v2Version.minH;
        } else if ( legacyWidget.h > v2Version.maxH ) {
          h = v2Version.maxH;
        }
        if ( legacyWidget.w < v2Version.minW ) {
          w = v2Version.minW;
        } else if ( legacyWidget.w > v2Version.maxW ) {
          w = v2Version.maxW;
        }

        return ( {
          ...v2Version,
          x: legacyWidget.x,
          y: legacyWidget.y,
          h,
          w,
          i: legacyWidget.i,
        } );
      case 'hosts':
        v2Version = availableWidgetsV2.host.options.global;
        ( { w, h } = legacyWidget );

        if ( legacyWidget.h < v2Version.minH ) {
          h = v2Version.minH;
        } else if ( legacyWidget.h > v2Version.maxH ) {
          h = v2Version.maxH;
        }
        if ( legacyWidget.w < v2Version.minW ) {
          w = v2Version.minW;
        } else if ( legacyWidget.w > v2Version.maxW ) {
          w = v2Version.maxW;
        }

        return ( {
          ...v2Version,
          x: legacyWidget.x,
          y: legacyWidget.y,
          h,
          w,
          i: legacyWidget.i,
        } );
      default:
        v2Version = availableWidgetsV2.instances.options.specific;
        ( { w, h } = legacyWidget );

        if ( legacyWidget.h < v2Version.minH ) {
          h = v2Version.minH;
        } else if ( legacyWidget.h > v2Version.maxH ) {
          h = v2Version.maxH;
        }
        if ( legacyWidget.w < v2Version.minW ) {
          w = v2Version.minW;
        } else if ( legacyWidget.w > v2Version.maxW ) {
          w = v2Version.maxW;
        }

        return ( {
          ...v2Version,
          settings: {
            category: legacyWidget.settings.metric_type,
          },
          x: legacyWidget.x,
          y: legacyWidget.y,
          h,
          w,
          i: legacyWidget.i,
        } );
      }
    case 'vulnerability_instances':
      v2Version = availableWidgetsV2.instances.options.global;
      ( { w, h } = legacyWidget );

      if ( legacyWidget.h < v2Version.minH ) {
        h = v2Version.minH;
      } else if ( legacyWidget.h > v2Version.maxH ) {
        h = v2Version.maxH;
      }
      if ( legacyWidget.w < v2Version.minW ) {
        w = v2Version.minW;
      } else if ( legacyWidget.w > v2Version.maxW ) {
        w = v2Version.maxW;
      }

      return ( {
        ...v2Version,
        settings: {
          version: 'full',
        },
        x: legacyWidget.x,
        y: legacyWidget.y,
        w,
        h,
        i: legacyWidget.i,
      } );
    case 'peer_comparison':
      v2Version = availableWidgetsV2.risk.options.peer_over_time;
      ( { w, h } = legacyWidget );

      if ( legacyWidget.h < v2Version.minH ) {
        h = v2Version.minH;
      } else if ( legacyWidget.h > v2Version.maxH ) {
        h = v2Version.maxH;
      }
      if ( legacyWidget.w < v2Version.minW ) {
        w = v2Version.minW;
      } else if ( legacyWidget.w > v2Version.maxW ) {
        w = v2Version.maxW;
      }

      return ( {
        ...v2Version,
        x: legacyWidget.x,
        y: legacyWidget.y,
        w,
        h,
        i: legacyWidget.i,
      } );
    case 'exploit_status':
      v2Version = availableWidgetsV2.instances.options.exploit_status;
      ( { w, h } = legacyWidget );

      if ( legacyWidget.h < v2Version.minH ) {
        h = v2Version.minH;
      } else if ( legacyWidget.h > v2Version.maxH ) {
        h = v2Version.maxH;
      }
      if ( legacyWidget.w < v2Version.minW ) {
        w = v2Version.minW;
      } else if ( legacyWidget.w > v2Version.maxW ) {
        w = v2Version.maxW;
      }

      return ( {
        ...v2Version,
        x: legacyWidget.x,
        y: legacyWidget.y,
        w,
        h,
        i: legacyWidget.i,
      } );
    case 'cvss':
      v2Version = availableWidgetsV2?.instances?.options?.cvss;
      ( { w, h } = legacyWidget );

      if ( legacyWidget.h < v2Version.minH ) {
        h = v2Version.minH;
      } else if ( legacyWidget.h > v2Version.maxH ) {
        h = v2Version.maxH;
      }
      if ( legacyWidget.w < v2Version.minW ) {
        w = v2Version.minW;
      } else if ( legacyWidget.w > v2Version.maxW ) {
        w = v2Version.maxW;
      }

      return ( {
        ...v2Version,
        x: legacyWidget.x,
        y: legacyWidget.y,
        w,
        h,
        i: legacyWidget.i,
      } );
    case 'remediation_plans':
      v2Version = availableWidgetsV2.remediation.options.plans;
      ( { w, h } = legacyWidget );

      if ( legacyWidget.h < v2Version.minH ) {
        h = v2Version.minH;
      } else if ( legacyWidget.h > v2Version.maxH ) {
        h = v2Version.maxH;
      }
      if ( legacyWidget.w < v2Version.minW ) {
        w = v2Version.minW;
      } else if ( legacyWidget.w > v2Version.maxW ) {
        w = v2Version.maxW;
      }

      return ( {
        ...v2Version,
        x: legacyWidget.x,
        y: legacyWidget.y,
        w,
        h,
        i: legacyWidget.i,
      } );
    }

    return ( {} );
  }
};

// the available widgets for each part of the application, for now, only supported for the
// reporting dashboard and alltypes are available there
export const availableWidgetOptions = {
  reporting_dashboard: Object.keys( availableWidgetsV2 ),
  custom: Object.keys( availableWidgetsV2 ),
};

export const handleWindowResize = ( type, ref, item, onPageLoad=false, previousWidth, callback ) => {
  const heightForH = {
    risk_over_time: {
      3: 430,
      4: 610,
    },
    risk_over_time_description: {
      3: 290,
      4: 510,
    },
    peer_comparison: {
      3: 480,
    },
    peer_comparison_description: {
      3: 340,
    },
    vulnerability_instances: {
      2: 100,
    },
    priority: {
      1: 160,
      2: 230,
      3: 400,
      4: 560,
      5: 730,
      6: 900,
    },
    cvss: {
      2: 440,
      3: 600,
    },
    remediation_plans: {
      2: 440,
      3: 600,
      4: 760,
    },
  };

  const defaultWidth = {
    risk_over_time: window.innerWidth - 170,
    vulnerability_instances: window.innerWidth - 100,
    peer_comparison: window.innerWidth - 240,
    priority: window.innerWidth - 170,
    cvss: window.innerWidth - 170,
    remediation_plans: window.innerWidth - 170,
  };

  const adjustmentThreshold = 100;

  if ( ( Math.abs( window.innerWidth - previousWidth ) > adjustmentThreshold ) || onPageLoad ) {

    let _type = type;

    if (
      ( type === 'risk_over_time' || type === 'peer_comparison' )
      && item.settings?.include_description
    ) {
      _type = `${_type}_description`;
    }

    // height has to be based on the grid item height, so that it can be relied upon.
    // const height = svgContainerRef?.current?.clientHeight || 1;
    const height = heightForH[_type][item.h];
    const width = ref?.current?.clientWidth || defaultWidth[type];
    const ratio = width / height;

    callback( width, height, ratio, window.innerWidth );
  }
};