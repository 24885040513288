/* eslint-disable camelcase */
/** **************************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
****************************************************************************/

import React from 'react';
import InsightTable from '../../Table';

import {
  decodeURLHash,
  isNotEmpty,
} from '../../../../shared/Utilities';

import './style.scss';
import PageHeader from '../../../../shared/PageHeader';
import RecordCard from '../../../RecordDetails/RecordCard';
import ReportHeader from '../../ReportHeader';
import { defaultRemdiationItems } from '../../Table/shared';
import { getRecords, getRecordsCount, getTallies } from '../../../../shared/RecordCache';

const VulnerabilityInstances = () => {
  const [ loading, setLoading ] = React.useState( true );

  const [ visualData, setVisualData ] = React.useState( null );
  const [ remediationItems, setRemediationItems ] = React.useState( defaultRemdiationItems );

  const [ records, setRecords ] = React.useState( [] );
  const [ recordCount, setRecordCount ] = React.useState( 0 );

  // controls the top overview panel collapsed state, storing here to pass down to multiple components
  const [ visualCollapsed, setVisualCollapsed ] = React.useState( false );
  const [ selectedSignature, setSelectedSignature ] = React.useState( null );

  // eslint-disable-next-line no-unused-vars
  const isMounted = true;

  // main fetch method called whenever the filters change
  const onRefresh = async ( refreshTallies=true ) => {
    setLoading( true );
    const hash = decodeURLHash();
    const onCorrectPage = hash.report === 'instances';
    if ( onCorrectPage ) {
      await refreshTable();

      if ( refreshTallies ) {
        const _tallies = await getTallies( 'instances' );
        setVisualData( _tallies );
      }
    }
    setLoading( false );
  };

  const refreshTable = async () => {

    const _records = await getRecords( 'instances', null, true );
    const _count = await getRecordsCount( 'instances' );

    setRecords( _records );
    setRecordCount( _count );
  };

  // callback closing the signature card
  const handleRecordCardClose = () => {
    setSelectedSignature( null );
  };

  return (
    <React.Fragment>
      <PageHeader>
        <ReportHeader
          reportType="instance"
          onRefresh={onRefresh}
          visualCollapsed={visualCollapsed}
          setVisualCollapsed={setVisualCollapsed}
          records={records}
          loading={loading}
          instancesVisualData={visualData}
          remediationItems={remediationItems}
          setRemediationItems={setRemediationItems}
        />
      </PageHeader>
      <InsightTable
        records={records}
        loading={loading}
        reportType="instance"
        onRefresh={onRefresh}
        refreshTable={refreshTable}
        visualCollapsed={visualCollapsed}
        selectedSignature={selectedSignature}
        setSelectedSignature={setSelectedSignature}
        remediationItems={remediationItems}
        setRemediationItems={setRemediationItems}
        recordCount={recordCount}
      />

      {
        isNotEmpty( selectedSignature ) &&
        <RecordCard
          record={ selectedSignature }
          type="signature"
          context="risk_insight_signature_instances"
          show={ isNotEmpty( selectedSignature ) }
          setShow={ handleRecordCardClose }
          fetchRecordDetails
          options={ {
            isDismissable: true,
            // eslint-disable-next-line camelcase
            include_shade: true,
            isDraggable: true,
          } }
        />
      }
    </React.Fragment>
  );
};

export default VulnerabilityInstances;