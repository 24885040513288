/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import {
  search_model_records,
  makeRequest,
} from '../../../../../../legacy/io';
import { getRecord, getRecords } from '../../../../RecordCache';

import { isNotEmpty, userDisplayName } from '../../../../Utilities';

export const searchParamsForResultType = ( type, query, selected, existingRecord, additionalParams={} ) => {
  let params = {
    keywords: query,
    // eslint-disable-next-line camelcase
    extra_columns: [ 'label', 'identifier', 'vendor', 'vulnerability_analysis.nofix' ],
    rownums: [ 0, 50 ],
  };

  if ( type === 'external_user' ) {
    params = {
      filters: {
        keywords: query,
      },
      // eslint-disable-next-line camelcase
      third_party_setting_id: existingRecord ? existingRecord.third_party_setting_id : null,
    };
  }

  if ( type === 'host' ) {
    params ={
      rownums: [ 0, 200 ],
      model: 'base',
      // eslint-disable-next-line camelcase
      group_type: 'host',
      project: 'default',
      filters: {
        // eslint-disable-next-line camelcase
        num_sensitive_nodes: 'any',
        // eslint-disable-next-line camelcase
        accepted_risk: 'false',
        keywords: query,
        ...additionalParams,
      },
      // eslint-disable-next-line camelcase
      order_by: [ [ 'filtered_risk', 'DESC' ], [ 'local_name', 'ASC' ] ],
    };
  }

  if ( type === 'scope' ) {
    params = {
      ...params,
      // eslint-disable-next-line camelcase
      order_by: [ [ 'scope_analysis.risk', 'DESC' ], [ 'label', 'DESC' ] ],
      // eslint-disable-next-line camelcase
      extra_columns: [ ...params.extra_columns, 'scope_analysis.risk' ],
      // eslint-disable-next-line camelcase
      not_id_list: isNotEmpty( selected ) ? [ ...Object.keys( selected ) ] : [],
    };
  }

  if ( type === 'scanner_signature' ) {
    params = {
      model: 'base',
      project: 'default',
      filters: {
        keywords: query,
        // eslint-disable-next-line camelcase
        extra_columns: [ 'scanner', 'signature', 'title', 'signature_analysis.risk' ],
        rownums: [ 0, 50 ],
        // eslint-disable-next-line camelcase
        order_by: [ [ 'signature_analysis.risk', 'DESC' ] ],
        // eslint-disable-next-line camelcase
        not_id_list: isNotEmpty( selected ) ? [ ...Object.keys( selected ) ] : [],
      },
    };
  }

  if ( type === 'patch' ) {
    params = {
      ...params,
      // eslint-disable-next-line camelcase
      extra_columns: [ ...params.extra_columns, 'patch_analysis.risk' ],
      // eslint-disable-next-line camelcase
      order_by: [ [ 'patch_analysis.direct_risk', 'DESC' ] ],
    };
  }

  if ( type === 'vulnerability' ) {
    params = {
      ...params,
      // eslint-disable-next-line camelcase
      extra_columns: [ ...params.extra_columns, 'vulnerability_analysis.risk' ],
      // eslint-disable-next-line camelcase
      order_by: [ [ 'vulnerability_analysis.risk', 'DESC' ] ],
      ...additionalParams,
    };
  }

  return params;
};

export const displayValueForResultType = ( type, result ) => {

  if ( type === 'external_user' ) {
    return userDisplayName( result, false, 'jira' );
  }

  if ( type === 'host' ) {
    if ( isNotEmpty( result.label ) ) {
      const labelParts = result.label.split( ' ' );
      return labelParts[ labelParts.length - 1 ];
    }

    if ( isNotEmpty( result.local_name ) ) {
      return result.local_name;
    }
    return '-- No Name Available --';
  }

  if ( type === 'scope' ) {
    return result.label;
  }

  if ( type === 'scanner_signature' ) {
    return `${result.scanner} ${result.signature}`;
  }

  if ( type === 'patch' ) {
    return `${result.vendor} ${result.identifier}`;
  }

  if ( type ==='vulnerability' ) {
    return result.identifier;
  }
  return '';
};

export const getResultsForType = async( type, params ) => {
  let results;

  if ( type === 'scope' ) {
    results = ( await search_model_records( 'scope', params ) );
  }

  if ( type === 'host' ) {
    results = ( await getRecords( 'host', null, true ) );
  }

  if ( type === 'scanner_signature' ) {
    // eslint-disable-next-line
    results = ( await makeRequest( 'SEARCH', '/model/signature', params ) ).results;
  }

  if ( type === 'patch' || type === 'vulnerability' ) {
    results = ( await getRecords( type, null, true ) );
  }

  // stubbed for now, will replace once endpoints exist
  if ( type === 'external_user' ) {
    // eslint-disable-next-line
    results = ( await makeRequest( 'SEARCH', '/model/base/external_users', params ) ).results;
  }
  return results;
};

export const typeMap = type => {
  if ( type === 'scanner_signature' ) {
    return 'signature';
  }
  if ( type === 'host' ) {
    return 'scope';
  }
  return type;
};

export const getRecordForType = async( type, id, existingRecord ) => {
  let params = {
    project: 'default',
    model: 'base',
    filters: {
      // eslint-disable-next-line camelcase
      id_list: [ id ],
      // eslint-disable-next-line camelcase
      extra_columns: [ 'scanner', 'signature', 'identifier', 'vendor', 'label', 'title' ],
      rownums: [ 0, 10 ],
    },
  };
  // only for now
  if ( type === 'external_user' ) {
    params = {
      // eslint-disable-next-line camelcase
      third_party_setting: existingRecord.third_party_setting_id,
      rownums: [ 0, 100 ],
    };
    const { results } = await makeRequest( 'SEARCH', '/model/base/external_users', params );
    return results.find( u => u.id === id );
  }

  let record;
  if ( type === 'scope' || type === 'SCOPE' ) {
    record = await getRecord( 'scope', id, {} );
  } else {
    record = await getRecord( type, id, {}, true );
  }
  return record;
};