/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import Notification from '../Notification';

import {
  isEmpty,
  isNotEmpty,
  itemIsArray,
  itemIsString,
} from '../Utilities';
import { makeRequest } from '../../../legacy/io';
import { descriptionFormatters } from '../../components/Activity/ConfigurationAlerts/Formatters';
import { hasFeatureAccess } from '../../components/App/AccessControl';
import { CurrentUserContext } from '../../Contexts/CurrentUser';

export const getConfigurationAlertsFor = async ( sourceType ) => {

  let sourceAlerts = [];

  const configParams = {
    // eslint-disable-next-line camelcase, max-len
    field_map: {},
    keywords: '',
    rownums: [ 0, 1000 ],
    // eslint-disable-next-line camelcase, max-len
    extra_columns: [
      'id',
      'project_id',
      'source',
      'type',
      'subject',
      'priority',
      'details',
      'ignore',
      'created',
      'modified',
    ],
  };

  if ( itemIsString( sourceType ) ) {
    configParams.field_map.source = sourceType;
  }
  const response = await makeRequest( 'SEARCH', '/configuration_alert', configParams );

  if ( isNotEmpty( response ) && isNotEmpty( response.results ) ) {
    if ( itemIsArray( sourceType ) ) {
      sourceType.map( t => {
        response.results.map( alert => {
          if ( alert.source === t ) {
            sourceAlerts.push( alert );
          }
        } );
      } );
    // if there is only one source type, we have filtered the query already, no need to do any checks
    } else {
      sourceAlerts = response.results;
    }
  }
  return sourceAlerts;
};

const AlertBody = ( { alert, additional } ) => {

  const [ description, setDescription ] = React.useState( null );

  React.useEffect( () => {
    if ( alert ) {
      // eslint-disable-next-line camelcase, max-len
      let formatter = descriptionFormatters[alert.source];

      if ( !formatter ) {
        formatter = JSON.stringify;
      }

      const _description = formatter( alert );

      setDescription( _description );
    }
  }, [ alert, additional ] );

  return (
    <React.Fragment>
      { ( isNotEmpty( description ) && isNotEmpty( description.content ) ) && description.content }
      { isNotEmpty( additional ) && <div className="additionalContent">{ additional }</div> }
    </React.Fragment>

  );
};

const ConfigurationAlert = ( { sourceType, overrideWith='', additional=null } ) => {
  const [ alertOptions, setAlertOptions ] = React.useState( [] );
  const [ currentUser, , licenseInfo ] = React.useContext( CurrentUserContext );

  React.useEffect( () => {
    if (
      isNotEmpty( currentUser )
      && isNotEmpty( licenseInfo )
      && hasFeatureAccess( currentUser, licenseInfo, 'f_configuration_alerts' )
    ) {
      getConfigurationAlertsFor( sourceType ).then( alerts => {
        const options = [];

        if ( isNotEmpty( alerts ) ) {
          alerts.map( a => {
            options.push( {
              type: 'alert',
              message: <AlertBody alert={a} additional={additional} />,
            } );
          } );
          setAlertOptions( options );
        }
      } );
    }
  }, [ sourceType, currentUser, licenseInfo ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( alertOptions ) &&
        <React.Fragment>
          {
            isEmpty( overrideWith )
              ? <React.Fragment>
                {
                  alertOptions.map( ( n, i ) => {
                    return  <Notification options={n} key={i} />;
                  } )
                }
              </React.Fragment>
              : <Notification options={{ type: 'alert', message: overrideWith }} />
          }

        </React.Fragment>

      }
    </React.Fragment>
  );
};

export default ConfigurationAlert;
