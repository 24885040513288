/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../../../../shared/Utilities';
import { getWidgetHeaderLabelModifier, widgetHeaderLabelBase, widgetsWithNoBaseLabel } from '../../shared';

import './WidgetHeader.scss';
import TagList from '../../../../RiskInsight/Tags/List';
import { TagsContext } from '../../../../../Contexts/Tags';

const WidgetHeader = ( {
  widget,
  data,
  settings,
} ) => {
  // the bold part followed by a colon
  const [ labelBase, setLabelBase ] = React.useState( null );
  // regular part
  const [ labelModifier, setLabelModifier ] = React.useState( null );
  // bold in parens, depicting count
  // const [ labelCount, setLabelCount ] = React.useState( null );

  const [ tagMemberships, setTagMemberships ] = React.useState( null );
  const [ tags ] = React.useContext( TagsContext );

  React.useEffect( () => {
    if ( isNotEmpty( widget ) ) {

      if ( isNotEmpty( widget ) && isNotEmpty( widget.key ) && !widgetsWithNoBaseLabel.includes( widget.key ) ) {
        const _labelBase = widgetHeaderLabelBase[widget.key];
        const _labelModifier = getWidgetHeaderLabelModifier( widget );

        if ( isNotEmpty( _labelBase ) ) {
          setLabelBase( _labelBase );
        }
        if ( isNotEmpty( _labelModifier ) ) {
          setLabelModifier( _labelModifier );
        }
      } else {
        setLabelBase( null );
        setLabelModifier( null );
      }
    }
  }, [ widget, data ] );

  // find out what tags this host is a part of
  React.useEffect( () => {
    if (
      isNotEmpty( tags )
      && isNotEmpty( settings )
      && isNotEmpty( settings.asset_tag_ids )
      && isNotEmpty( widget )
      && widget.key === 'hosts_priority'
    ) {
      const _tags = {};

      settings.asset_tag_ids.map( id => {
        _tags[id] = tags[id];
      } );

      setTagMemberships( _tags );
    }
  }, [ tags, settings, widget ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( labelBase ) &&
        <div className="widgetHeaderContainer" >
          <div className="left">
            <strong className="widgetHeaderLabelBase">{ labelBase }</strong>
            {
              isNotEmpty( labelModifier ) &&
              <React.Fragment>
                <strong className="widgetHeaderLabelcolon">:</strong>
                <span className="widgetHeaderLabelModifier">{ labelModifier }</span>
              </React.Fragment>

            }
          </div>
          {
            isNotEmpty( tagMemberships ) &&
            <TagList tags={tagMemberships} truncation={ 3 } />
          }
        </div>
      }
    </React.Fragment>
  );
};

export default WidgetHeader;