/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import Form from '../../../../../shared/Form';
import { isNotEmpty } from '../../../../../shared/Utilities';
import { CurrentUserContext } from '../../../../../Contexts/CurrentUser';

import './Step1.scss';
import { hasFeatureAccess } from '../../../../App/AccessControl';

const Step1 = ( {
  plan,
  users,
  setUpdatedForm,
} ) => {

  const [ currentUser, , licenseInfo ] = React.useContext( CurrentUserContext );

  // label/description inputs for the entire plan that spans all steps
  const [ planFields, setPlanFields ] = React.useState( [] );

  // sets up the users and forms, called on init
  React.useEffect( () => {
    if ( isNotEmpty( currentUser ) && isNotEmpty( users ) ) {

      const canEdit = isNotEmpty( currentUser )
        && isNotEmpty( licenseInfo )
        && hasFeatureAccess( currentUser, licenseInfo, 'f_remediation' );

      const _planFields = {
        left: {
          fields: [
            {
              type: 'text',
              attribute: 'label',
              label: 'Plan Name (optional)',
              defaultValue: plan?.label || '',
              disabled: !canEdit,
            },
            {
              type: 'hidden',
              attribute: 'status',
              defaultValue: plan?.status || 'draft',
              disabled: !canEdit,
            },
            {
              type: 'select',
              attribute: 'owner',
              label: 'Owner',
              options: users,
              defaultValue: plan?.owner || currentUser.id,
              disabled: !canEdit,
            },
          ],
        },
        right: {
          fields: [
            {
              type: 'textarea',
              attribute: 'description',
              label: 'Description (optional)',
              defaultValue: plan?.description || '',
              disabled: !canEdit,
            },
          ],
        },
      };

      setPlanFields( _planFields );
    }
  }, [ currentUser, users, plan ] );

  return (
    <React.Fragment>
      <div className="planFormWrapper">
        {
          isNotEmpty( planFields ) &&
          <Form
            fields={planFields}
            existingRecord={ plan }
            recordType={ 'remediation_plan' }
            trackUpdates={ isNotEmpty( plan ) }
            onChangeCallback={ setUpdatedForm }
          />
        }
      </div>
    </React.Fragment>
  );
};

export default Step1;