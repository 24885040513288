/** **************************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
****************************************************************************/

import React from 'react';
import { makeRequest } from '../../legacy/io';

import {
  isNotEmpty,
} from '../shared/Utilities';
import { CurrentUserContext } from './CurrentUser';
import { hasFeatureAccess } from '../components/App/AccessControl';

export const TasksContext = React.createContext();

export const TasksProvider = ( { children } ) => {

  const [ currentTasks, setCurrentTasks ] = React.useState( {} );
  const [ currentUser, , licenseInfo ] = React.useContext( CurrentUserContext );

  let taskTimeout;

  React.useEffect( () => {
    let isMounted = true;

    if (
      isNotEmpty( currentUser )
      && isNotEmpty( licenseInfo )
      && hasFeatureAccess( currentUser, licenseInfo, 'f_tasks' )
    ) {
      makeRequest( 'STATUS', '/task', { project: 'default' } ).then( response => {
        if ( isMounted ) {
          setCurrentTasks( response.results.current );
        }
      } );
    }

    return () => {
      isMounted = false;
    };
  }, [ currentUser, licenseInfo ] );

  React.useEffect( () => {
    let isMounted = true;
    if ( isNotEmpty( currentTasks ) ) {
      const allStatus = [];

      // grab the status of all the task types
      Object.keys( currentTasks ).map( t => {
        if ( t === 'authenticated' || t === 'import' ) {
          allStatus.push( currentTasks[t].status );
        }
      } );
      // check to see if they are done, if so, clear timeout, if not, check again in 5 seconds
      if ( allStatus.every( s => [ 'completed', 'error' ].includes( s ) ) ) {
        clearTimeout( taskTimeout );
      } else {
        taskTimeout = setTimeout( () => {
          makeRequest( 'STATUS', '/task', { project: 'default' } ).then( response => {
            if ( isMounted ) {
              setCurrentTasks( response.results.current );
            }
          } );
        }, 6000 );
      }
    }
    return () => {
      isMounted = false;
    };
  }, [ currentTasks ] );

  const data = [
    currentTasks,
    setCurrentTasks,
  ];

  return (
    <TasksContext.Provider value={ data }>
      { children }
    </TasksContext.Provider>
  );
};

