/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty, itemIsArray, itemIsObject, uniqueArray } from '../Utilities';
import RecordListItem from './RecordListItem';

import './style.scss';
import Loading from '../Loading';

const RecordList = ( {
  orderBy,
  records,
  reportType,
  sectionKey='',
  setSelectedSignature=() => {},
  setModalContext=() => {},
  setHoveredListItemID=() => {},
  setHoveredListItemType=() => {},
  setHoveredListItemRating=() => {},
  remediationOptions=null,
  vulnerabilityOptions=null,

  setRecordCardRecord=() => {},
  setRecordCardType=() => {},
  setShowRecordCard=() => {},
} ) => {

  // const [ riskType, setRiskType ] = React.useState( 'risk' );
  const [ recordListRecords, setRecordListRecords ] = React.useState( [] );
  // const [ highestRisk, setHighestRisk ] = React.useState( 0 );

  const [ maxAttributeValue, setMaxAttributeValue ] = React.useState( 1 );
  const [ orderByAttribute, setOrderByAttribute ] = React.useState( 'risk' );

  React.useEffect( () => {
    if ( isNotEmpty( records ) && isNotEmpty( reportType ) ) {
      let _records;
      if ( itemIsObject( records ) ) {
        _records = [ ...Object.values( records ) ];
      } else if ( itemIsArray( records ) ) {
        _records = [ ...records ];
      }

      if ( isNotEmpty( _records ) ) {

        _records = uniqueArray( _records );
        _records = _records.sort( ( a, b ) => b[_orderByAttribute] - a[_orderByAttribute] );

        let _orderByAttribute = 'filtered_risk';

        // for legacy reasons, we need to check if the first record has a 'risk' attribute,
        // if so, we'll use that as the default orderByAttribute
        const [ first ] = _records;
        if ( isNotEmpty( first ) && itemIsObject( first ) && 'risk' in first ) {
          _orderByAttribute = 'risk';
        }

        const riskValues = _records.map( r => r[_orderByAttribute] || 0 );
        const _maxAttributeValue = Math.max( ...riskValues );

        _records = uniqueArray( _records );
        _records = _records.sort( ( a, b ) => b[_orderByAttribute] - a[_orderByAttribute] );

        setMaxAttributeValue( _maxAttributeValue );
        setRecordListRecords( _records );
        setOrderByAttribute( _orderByAttribute );
      }
    } else{
      setRecordListRecords( [] );
    }
  }, [ records, reportType, orderBy, remediationOptions ] );

  return (
    <React.Fragment>
      { ( isNotEmpty( remediationOptions ) && remediationOptions.remediationItemsLoading ) && <Loading /> }
      {
        ( isNotEmpty( recordListRecords ) && isNotEmpty( reportType ) && isNotEmpty( orderByAttribute ) ) &&
        <ul className={ `recordList ${reportType} ${sectionKey} orderBy-${orderByAttribute}`}>
          {
            recordListRecords.map( ( record, index ) => {
              return <RecordListItem
                key={index}
                record={record}
                reportType={reportType}
                sectionKey={ sectionKey }
                orderByAttribute={orderByAttribute}
                maxAttributeValue={maxAttributeValue}
                setSelectedSignature={setSelectedSignature}
                setModalContext={setModalContext}
                setHoveredListItemID={setHoveredListItemID}
                setHoveredListItemType={setHoveredListItemType}
                setHoveredListItemRating={setHoveredListItemRating}
                vulnerabilityOptions={vulnerabilityOptions}
                remediationOptions={remediationOptions}

                // recordCard variables
                setRecordCardRecord={setRecordCardRecord}
                setRecordCardType={setRecordCardType}
                setShowRecordCard={setShowRecordCard}
              />;
            } )
          }
        </ul>
      }
    </React.Fragment>
  );
};

export default RecordList;