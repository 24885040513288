/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import SetupForm from '../../../../shared/SetupComponents/SetupForm';
import Form from '../../../../shared/Form';
import { getGlobalSettings, isNotEmpty, updateGlobalSettings } from '../../../../shared/Utilities';
import { getFieldValues } from '../../../../shared/Form/Shared';
import { FlashMessageQueueContext } from '../../../../Contexts/FlashMessageQueue';
import { HelpTrigger } from '../../../HelpDocumentation/ContextualHelp/index.js';

const Hidden = ( ) => {

  let isMounted = true;

  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  const [ fields, setFields ] = React.useState( null );
  const [ record, setRecord ] = React.useState( null );
  const [ isValid, setIsValid ] = React.useState( true );

  // this is set in the form onChange callback
  const [ updatedForm, setUpdatedForm ] = React.useState( null );

  // fields object that represents what the Form component will turn into inputs,
  // with all of the necessary validation and help requirements
  const EMPTY_FIELDS = {
    global: {
      fields: [
        {
          type: 'checkbox',
          label: 'Assume Admin Role',
          attribute: 'assume_admin_role',
          defaultValue: false,
        },
        {
          type: 'checkbox',
          label: 'Show experimental features',
          attribute: 'show_experimental',
          defaultValue: false,
          help: <HelpTrigger helpKey="show_experimental" />,
        },
        {
          type: 'checkbox',
          label: 'Record scan debug information',
          attribute: 'scan_debug',
          defaultValue: false,
          help: <HelpTrigger helpKey="scan_debug" />,
        },
        {
          type: 'checkbox',
          label: 'Keep one recent scan blob per host',
          attribute: 'record_scan_inputs',
          defaultValue: false,
          help: <HelpTrigger helpKey="record_scan_inputs" />,
        },
        {
          type: 'checkbox',
          label: 'Enable automatic software upgrades',
          attribute: 'automatic_upgrades',
          defaultValue: false,
          help: <HelpTrigger helpKey="automatic_upgrades" />,
        },
        {
          type: 'checkbox',
          label: 'Keep demo data (don\'t erase it on first authenticated scan)',
          attribute: 'keep_demo_data',
          defaultValue: false,
        },
        {
          type: 'checkbox',
          label: 'Set tornado.access logger to level WARNING',
          attribute: 'quiet_tornado_access_logger',
          defaultValue: false,
          help: <HelpTrigger helpKey="quiet_tornado_access_logger" />,
        },
        {
          type: 'checkbox',
          label: 'Hide onboarding wizard',
          attribute: 'hide_onboarding_wizard',
          defaultValue: false,
        },
        {
          type: 'checkbox',
          label: 'Hide risk score',
          attribute: 'hide_risk_score',
          defaultValue: false,
        },
      ],
    },
  };

  // the init of the form, called on page load, once the global context has loaded
  React.useEffect( () => {
    onRefresh( );
    setFields( EMPTY_FIELDS );
  }, [] );

  React.useEffect( ( ) => {
    // important functionality that automatically
    // saves the form when navigating away from the page if the form is valid
    const parentEl = document.getElementById( 'pageContent' );
    parentEl.onleave = async () => {
      if ( isValid && isMounted ) {
        onSave( false );
        return true;
      }
    };

    return () => {
      isMounted = false;
      if ( parentEl ) {
        parentEl.onleave = null;
      }
    };
  }, [ updatedForm ] );

  // refreshes the form whenever one of the following happens:
  // 1. initial page load
  // 2. manual revert button is clicked in the footer
  // 3. a settings is changed and saved
  const onRefresh = async( ) => {

    const _global = await getGlobalSettings( 'global' );

    setRecord( {
      // global settings
      /* eslint-disable camelcase */
      show_experimental: _global?.show_experimental,
      scan_debug: _global?.scan_debug,
      record_scan_inputs: _global?.record_scan_inputs,
      automatic_upgrades: _global?.automatic_upgrades,
      keep_demo_data: _global?.keep_demo_data,
      quiet_tornado_access_logger: _global?.quiet_tornado_access_logger,
      hide_onboarding_wizard: _global?.hide_onboarding_wizard,
      hide_risk_score: _global?.hide_risk_score,
      assume_admin_role: _global?.assume_admin_role,
      /* eslint-enable camelcase */
    } );
  };

  // called when clicking save in the footer, or on page leave if the form is valid
  const onSave = async ( shouldRefresh=true ) => {
    // checks for validity and makes sure we have the latest values
    if ( isValid && isNotEmpty( updatedForm ) && isNotEmpty( updatedForm.fieldStates ) ) {
      const _values = getFieldValues( updatedForm.fieldStates, 'admin_settings' );
      const _global = { };

      // put the attrs and values into the correct spot
      Object.entries( _values ).map( ( [ attr, val ] ) => {
        _global[attr] = val;
      } );

      const updatedGlobal = await updateGlobalSettings( 'global', _global );

      // success
      if ( isNotEmpty( updatedGlobal.results ) ) {
        // only refresh if needed, ie, not when navigating away
        if (
          shouldRefresh
          && isMounted
        ) {
          onRefresh( );
          addFlashMessage( {
            type: 'success',
            body: 'Successfully updated settings.',
          } );
        }
        // force redraw for display changes
        window.location.reload();

        // formatted error
      } else if ( isNotEmpty( updatedGlobal.errors ) ) {
        const _errors = [];
        updatedGlobal?.errors.map( e => {
          _errors.push( e );
        } );
        if ( shouldRefresh && isMounted ) {
          addFlashMessage( {
            type: 'success',
            body: 'Successfully updated settings.',
          } );

          _errors.map( ( e ) => {
            addFlashMessage( {
              type: 'alert',
              body: e,
            } );
          } );
        }
      // likely 500
      } else if ( shouldRefresh && isMounted ) {
        addFlashMessage( {
          type: 'alert',
          body: 'There was an error saving settings, please check your connection and try again.',
        } );
      }
    }
  };

  return (
    <SetupForm elementClass="hiddenSettings">
      {
        ( isNotEmpty( fields ) && isNotEmpty( record ) ) &&
        <Form
          fields={fields}
          onChangeCallback={setUpdatedForm}
          existingRecord={record}
          recordType={'general_settings'}
          setIsValid={setIsValid}
        />
      }
      <div className="formActionsContainer">
        <div className="formActions">
          <button
            className="revertButton"
            onClick={onRefresh}
          >
            Revert
          </button>
          <button
            disabled={!isValid}
            onClick={onSave}
          >
            Save
          </button>
        </div>
      </div>
    </SetupForm>
  );
};

export default Hidden;