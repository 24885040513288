/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import {
  isEmpty,
  isNotEmpty,
  itemIsArray,
  itemIsObject,
  itemIsString,
} from '../Utilities';

export const VALIDATION_REGEXS = {
  // Safari doesn't allow for lookbehind regex, so it shouldn't be used here
  'awsID': /(^|[^A-Z0-9])[A-Z0-9]{20}(?![A-Z0-9])/g,
  'awsSecret': /(^|[^A-Za-z0-9/+=])[A-Za-z0-9/+=]{40}(?![A-Za-z0-9/+=])/g,
  'fqdn': /(?=^.{4,253}$)(^((?!-)[a-zA-Z0-9-]{0,62}[a-zA-Z0-9]\.)+[a-zA-Z]{2,63}$)/g,
  // eslint-disable-next-line no-control-regex
  'username': /(^[^\x00-\x1f]{2,}$)/g,
  // eslint-disable-next-line no-control-regex
  'password': /(^[^\x00-\x1f]{4,}$)/g,
  'UUID': /^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
  'email': /^[^@]+@[^@]+\.[^@]+/,
  'windowsDomain': /([^A-Za-z0-9_.-])+/g,
  'hypertextTransferProtocols': /^(http|https):\/\//g,
  // eslint-disable-next-line max-len
  'IP': /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/g,
  // eslint-disable-next-line max-len
  'IPv6': /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$/g,
};

// field type specific validators, currently just for weekly schedule
export const isValidWeeklySchedule = {
  check: value => {
    return itemIsObject( value )
      && isNotEmpty( value )
      && isNotEmpty( value.start_time )
      && isNotEmpty( value.end_time )
      && isNotEmpty( value.blackouts );
  },
  errorMessage: 'Schedule must contain a start time, end time, and at least one day',
};

export const isPresent = {
  check: value => isNotEmpty( value ),
  errorMessage: 'Cannot be blank',
};

export const isListOfPorts = {
  check: value => {
    // somehow got munged into an array of a single string with a bunch of commas, easier to just hack
    // in a quick fix
    if ( isNotEmpty( value ) && itemIsArray( value ) && value.length === 1 ) {
      const [ commaString ] = value;
      console.log( commaString );
      // eslint-disable-next-line max-len
      const _array = itemIsString( commaString ) ? commaString.split( ',' ).map( item =>  Number( item ) ) : [ commaString ];
      return _array.every( val => isValidPort?.check( val ) );
    } else if ( isNotEmpty( value ) && itemIsString( value ) ) {
      const _array = value.split( ',' ).map( item =>  Number( item ) );
      return _array.every( val => isValidPort?.check( val ) );
    } else if ( isNotEmpty( value ) ) {
      const numberArray = value.map( val => Number( val ) );
      return numberArray.every( val => isValidPort?.check( val ) );
    }
  },
  errorMessage: 'Must be a list of valid ports',
};

export const isFQDN = {
  check: value => {
    if ( isNotEmpty( value ) && value.match( VALIDATION_REGEXS['fqdn'] ) ) {
      return true;
    }
    return false;

  },
  errorMessage: 'Must be a valid fully qualified domain name',
};

export const isHostName = {
  check: value => {
    if ( value.match( VALIDATION_REGEXS['fqdn'] ) ) {
      return true;
    }
    return false;

  },
  errorMessage: 'Must be a valid hostname (no https://)',
};

// have to join IP and hostname for extra logic
export const isIPAddressOrHostname = {
  check: value => {
    if ( isEmpty( value ) ) {
      return false;
    } else if (
      value.match( VALIDATION_REGEXS['IP'] )
      || value.match( VALIDATION_REGEXS['IPv6'] )
      || value.match( VALIDATION_REGEXS['fqdn'] )
    ) {
      return true;
    }
    return false;
  },
  errorMessage: 'Must be a valid IP address or hostname (no https://)',
};

// special validator specific to IPV6
export const isIPV6 = {
  check: value => {
    if ( isNotEmpty( value ) && value.match( VALIDATION_REGEXS['IPv6'] ) ) {
      return true;
    }
    return false;
  },
  errorMessage: 'Must be a valid IP address or hostname (no https://)',
};

export const isWindowsDomain = {
  check: value => {
    if ( value.match( VALIDATION_REGEXS['windowsDomain'] ) ) {
      return false;
    }
    return true;

  },
  // eslint-disable-next-line max-len
  errorMessage: 'Must be a valid Windows Domain name (any letters, numbers, and/or the following special characters: - _ . )',
};

export const isHttpOrHttps = {
  check: value => value.match( VALIDATION_REGEXS['hypertextTransferProtocols'] ),
  errorMessage: 'Must start with http:// or https://',
};

export const isValidPort = {
  check: value =>  {
    return Number.isInteger( value ) && ( value > 0 && value <= 65535 );
  },
  errorMessage: 'Must be a valid port',
};

export const isNot25 = {
  check: value => value !== 25,
  errorMessage: '25 is considered an insecure port and is not valid for SMTP',
};

export const isUUID = {
  check: value => {
    if ( value?.trim().match( VALIDATION_REGEXS['UUID'] ) ) {
      return true;
    }
    return false;

  },
  errorMessage: 'Must be a valid UUID format',
};

export const isAWSID = {
  check: value => {
    if ( value.match( VALIDATION_REGEXS['awsID'] ) ) {
      return true;
    }
    return false;

  },
  errorMessage: 'Must be a valid 20 character AWS Access Key string',
};

export const isAWSSecret = {
  check: value => {
    if ( value.match( VALIDATION_REGEXS['awsSecret'] ) ) {
      return true;
    }
    return false;

  },
  errorMessage: 'Must be a valid 40 character AWS Secret Key string',
};

export const isValidUsername = {
  check: value => {
    if ( value.match( VALIDATION_REGEXS['username'] ) ) {
      return true;
    }
    return false;

  },
  errorMessage: 'Must be at least 2 printable characters',
};

export const isValidPassword = {
  check: value => {
    if ( value.match( VALIDATION_REGEXS['password'] ) ) {
      return true;
    }
    return false;

  },
  errorMessage: 'Must be at least 4 printable characters',
};

export const isEmailAddress = {
  check: value => {
    if ( value.match( VALIDATION_REGEXS['email'] ) || value === '' ) {
      return true;
    }
    return false;

  },
  errorMessage: 'Must be a valid email address',
};

export const moreThan1 = {
  check: value => value.length > 1,
  errorMessage: 'Must contain at least 2 items',
};

export const isNotDuplicate = {
  check: ( value, test ) => {
    if ( isNotEmpty( test ) ) {
      return !test.includes( value );
    }
    return true;


  },
  errorMessage: 'Must be unique',
};

export const isInteger = {
  check: value => Number.isInteger( value ),
  errorMessage: 'Must be an integer from 1 - 10',
};
