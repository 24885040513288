/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  decodeURLHash,
  defaultTagColor,
  encodeURLHash,
  formatNumber,
  isEmpty,
  isNotEmpty,
  itemIsArray,
  recordTypeDisplayName,
  tagColors,
} from '../../../shared/Utilities';
import { CurrentUserContext } from '../../../Contexts/CurrentUser';
import Form from '../../../shared/Form';
import { HelpTrigger } from '../../HelpDocumentation/ContextualHelp';
import { getFieldValues } from '../../../shared/Form/Shared';
import AppliedFilter from '../../../shared/FilterForm/AppliedFilter';
import InlineSVG from '../../../shared/InlineSVG';

import './TagEditorV3.scss';
import IndeterminantPagination, { getRowNums, pageIterator } from '../../../shared/Pagination/IndeterminantPagination';
import { makeRequest } from '../../../../legacy/io';
import { FlashMessageQueueContext } from '../../../Contexts/FlashMessageQueue';
import EmptyState from '../../../shared/EmptyState';
import Loading from '../../../shared/Loading';
import DataTable from '../../../shared/DataTable';
import ScanningStatusIndicator from '../../RecordDetails/ScanningStatusIndicator';
import { tagFilterAttributes } from '../../../shared/FilterForm/shared';
import { canConfigure } from '../../App/AccessControl';

const TagEditorV3 = ( {
  selectedRecord,
  // showModal,
  setShowModal,
  onSave,
  osLabels,
  users,
} ) => {

  // current user from the context
  const [ currentUser, , licenseInfo ] = React.useContext( CurrentUserContext );
  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  const EMPTY_TAG_REQUIRED_FIELDS = {
    name: {
      fields: [
        {
          type: 'text',
          label: 'Name',
          attribute: 'label',
          required: true,
          defaultValue: '',
          help: <HelpTrigger helpKey="name_tag" />,
        },
        {
          type: 'colorSelect',
          label: 'Color',
          attribute: 'color',
          defaultValue: defaultTagColor,
          required: true,
          allowBlank: false,
          options: {
            one: [ ...tagColors.one ],
            three: [ ...tagColors.three ],
          },
          help: <HelpTrigger helpKey="color" />,
        },
      ],
    },
    owner: {
      fields: [
        {
          type: 'select',
          label: 'Owner',
          attribute: 'remediation_manager',
          defaultValue: 'null',
          allowBlank: true,
          blankDisplayValue: 'null',
          help: <HelpTrigger helpKey="owner_tag" />,
        },
      ],
    },
  };
  const EMPTY_TAG_DEFINITION_FIELDS = {
    // eslint-disable-next-line camelcase
    to_include: {
      fields: [
        {
          type: 'textarea',
          attribute: 'included_ranges',
          placeholder: 'No IP ranges included',
          label: 'Included IP Range(s)',
          defaultValue: [],
          needsSplit: true,
          elementClass: 'includeFilter',
          help: <HelpTrigger helpKey="ip_ranges" />,
        },
        {
          type: 'textarea',
          attribute: 'included_host_patterns',
          placeholder: 'No wildcards included',
          label: 'Included Host Wildcard(s)',
          defaultValue: [],
          needsSplit: true,
          elementClass: 'includeFilter',
          help: <HelpTrigger helpKey="host_wildcards" />,
        },
        {
          type: 'searchList',
          attribute: 'included_host_ids',
          placeholder: 'Search...',
          label: 'Included Specific Host(s)',
          recordType: 'host',
          defaultValue: [],
          elementClass: 'includeFilter',
          inlineVersion: true,
          help: <HelpTrigger helpKey="specific_hosts" />,
        },
        {
          type: 'select',
          label: 'Included OS Version(s)',
          attribute: 'included_product_names',
          asMultiple: true,
          optionsAbove: true,
          elementClass: 'osTagFilter',
          value: [],
          allowBlank: true,
          helpItem: <HelpTrigger helpKey="os_versions" />,
        },
      ],
    },
    // eslint-disable-next-line camelcase
    to_exclude: {
      fields: [
        {
          type: 'textarea',
          attribute: 'excluded_ranges',
          placeholder: 'No IP ranges excluded',
          label: 'Excluded IP Range(s)',
          defaultValue: [],
          needsSplit: true,
          elementClass: 'excludeFilter',
          help: <HelpTrigger helpKey="excluded_ip_ranges" />,
        },
        {
          type: 'textarea',
          attribute: 'excluded_host_patterns',
          placeholder: 'No wildcards excluded',
          label: 'Excluded Host Wildcard(s)',
          defaultValue: [],
          needsSplit: true,
          elementClass: 'excludeFilter',
          help: <HelpTrigger helpKey="excluded_host_wildcards" />,
        },
        {
          type: 'searchList',
          attribute: 'excluded_host_ids',
          placeholder: 'Search...',
          label: 'Excluded Specific Host(s)',
          recordType: 'host',
          defaultValue: [],
          elementClass: 'excludeFilter',
          inlineVersion: true,
          help: <HelpTrigger helpKey="excluded_specific_hosts" />,
        },
        {
          type: 'select',
          label: 'Excluded OS Version(s)',
          attribute: 'excluded_product_names',
          asMultiple: true,
          optionsAbove: true,
          elementClass: 'osTagFilter',
          value: [],
          allowBlank: true,
          helpItem: <HelpTrigger helpKey="os_versions" />,
        },
      ],
    },
  };

  const [ sanitizedRecord, setSanitizedRecord ] = React.useState( {} );

  const [ requiredFields, setRequiredFields ] = React.useState( null );
  const [ definitionFields, setDefinitionFields ] = React.useState( null );
  const [ requiredValid, setRequiredValid ] = React.useState( false );
  const [ definitionValid, setDefinitionValid ] = React.useState( false );
  const [ updatedRequiredForm, setUpdatedRequiredForm ] = React.useState( null );
  const [ updatedDefinitionForm, setUpdatedDefinitionForm ] = React.useState( null );
  const [ loading, setLoading ] = React.useState( false );
  const [ currentPageNumber, setCurrentPageNumber ] = React.useState( 1 );
  const [ currentPageResults, setCurrentPageResults ] = React.useState( [] );
  const [ nextPageResults, setNextPageResults ] = React.useState( [] );
  const [ showDefinitionModal, setShowDefinitionModal ] = React.useState( false );
  const [ appliedFilterValues, setAppliedFilterValues ] = React.useState( null );

  // for some reason the records have null values instead of empty arrays and it is messing up the fields,
  // this will sanitize those situations so that the form does not get messed up
  React.useEffect( () => {
    if ( isNotEmpty( selectedRecord ) ) {
      const _sanitizedRecord = { ...selectedRecord };

      Object.entries( selectedRecord ).map( ( [ key, value ] ) => {
        if ( tagFilterAttributes.includes( key ) ) {
          if ( isEmpty( value ) || !itemIsArray( value ) ) {
            _sanitizedRecord[key] = [];
          }
        }
      } );

      setSanitizedRecord( _sanitizedRecord );
    } else {
      setSanitizedRecord( {} );
    }
    setCurrentPageNumber( 1 );
    setCurrentPageResults( null );
    setNextPageResults( null );
  }, [ selectedRecord ] );

  // set the appropriate options for the os version fields
  // set the appropriate options for the owner field
  // sets up the fields needed for the forms
  React.useEffect( () => {

    const _requiredFields = { ...EMPTY_TAG_REQUIRED_FIELDS };
    const _definitionFields = { ...EMPTY_TAG_DEFINITION_FIELDS };
    // eslint-disable-next-line max-len
    const includeField = _definitionFields?.to_include?.fields?.find( f => f.attribute === 'included_product_names' );
    // eslint-disable-next-line max-len
    const excludeField = _definitionFields?.to_exclude?.fields?.find( f => f.attribute === 'excluded_product_names' );
    const ownerField = _requiredFields?.owner?.fields?.find( f => f.attribute === 'remediation_manager' );

    if (
      isNotEmpty( osLabels )
      && isNotEmpty( includeField )
      && isNotEmpty( excludeField )
      && isNotEmpty( ownerField )
      && isNotEmpty( currentUser )
    ) {
      includeField.options = osLabels;
      excludeField.options = osLabels;
      ownerField.defaultValue = currentUser.id;
      ownerField.options = users;

      setRequiredFields( _requiredFields );
      setDefinitionFields( _definitionFields );
    }
  }, [ osLabels, users, currentUser ] );

  // whenever the filters get updated, update the applied filter buttons in the ui
  React.useEffect( () => {
    if ( isNotEmpty( updatedDefinitionForm ) && isNotEmpty( updatedDefinitionForm.fieldStates ) ) {
      const values = getFieldValues( updatedDefinitionForm.fieldStates, 'tag' );

      if ( isNotEmpty( values ) ) {

        const _appliedFilterValues = {};
        Object.entries( values ).map( ( [ attribute, value ] ) => {
          if ( isNotEmpty( value ) ) {
            _appliedFilterValues[attribute] = value;
          }
        } );

        setAppliedFilterValues( _appliedFilterValues );
      }
    }
  }, [ updatedDefinitionForm ] );

  const filterAttibuteLabelMap = {
    label: 'Name',
    color: 'Color',
    // eslint-disable-next-line camelcase
    remediation_manager: 'Owner',
    // eslint-disable-next-line camelcase
    included_ranges: 'IP',
    // eslint-disable-next-line camelcase
    included_host_patterns: 'Wildcard',
    // eslint-disable-next-line camelcase
    included_host_ids: 'Host',
    // eslint-disable-next-line camelcase
    included_product_names: 'OS',
    // eslint-disable-next-line camelcase
    excluded_ranges: 'IP',
    // eslint-disable-next-line camelcase
    excluded_host_patterns: 'Wildcard',
    // eslint-disable-next-line camelcase
    excluded_host_ids: 'Host',
    // eslint-disable-next-line camelcase
    excluded_product_names: 'OS',
  };

  // passed into the applied filter component to handle removal (instead of the default url params method)
  const handleAppliedFilterRemove = ( attribute, value ) => {
    if (
      isNotEmpty( updatedDefinitionForm )
      && isNotEmpty( updatedDefinitionForm.fieldStates )
      && isNotEmpty( attribute )
      && isNotEmpty( value )
    ) {

      let _value = value;

      if ( attribute === 'included_host_ids' || attribute === 'excluded_host_ids' ) {
        _value = value.id;
      }
      const _updatedDefinitionForm = { ...updatedDefinitionForm };

      const field = _updatedDefinitionForm.fieldStates[attribute];

      if ( isNotEmpty( field ) ) {
        let _updatedValue = [];

        if ( isNotEmpty( field.updatedValue ) ) {
          _updatedValue = [ ...field.updatedValue ];
        }

        if ( isNotEmpty( _updatedValue ) && itemIsArray( _updatedValue ) ) {
          _updatedValue = _updatedValue.filter( v => v !== _value );
        }
        field.modifiedExternally = true;
        field.updatedValue = _updatedValue;

        setUpdatedDefinitionForm( _updatedDefinitionForm );
      }
    }
  };

  const clearAllAppliedFilters = () => {
    if ( isNotEmpty( updatedDefinitionForm ) && isNotEmpty( updatedDefinitionForm.fieldStates ) ) {
      const _updatedDefinitionForm = { ...updatedDefinitionForm };

      Object.keys( _updatedDefinitionForm.fieldStates ).map( attr => {
        _updatedDefinitionForm.fieldStates[attr].updatedValue = [];
        _updatedDefinitionForm.fieldStates[attr].modifiedExternally = true;
        _updatedDefinitionForm.errors[attr] = [];
      } );

      setUpdatedDefinitionForm( _updatedDefinitionForm );
    }
  };

  const selectHost = hostID => {
    // eslint-disable-next-line max-len
    window.location.href = `#.=risk_insight&report=hosts&item_count=100&order_by=filtered_risk&order_direction=DESC&current_page=1&item=${hostID}`;
  };

  const goToPage = page => {
    // eslint-disable-next-line camelcase
    encodeURLHash( { current_page: parseInt( page ), item_count: 100 } );
    testTagDefinition( parseInt( page ) );
  };

  const testTagDefinition = async ( page=1 ) => {
    setLoading( true );
    setCurrentPageNumber( page );
    setCurrentPageResults( [] );
    setNextPageResults( [] );

    // eslint-disable-next-line camelcase
    const filterValues = { current_page: page, item_count: 100 };

    const _values = getFieldValues( updatedDefinitionForm?.fieldStates, 'tag' );
    const params =  {
      // eslint-disable-next-line camelcase
      order_by: [ [ 'label', 'ASC' ] ],
      rownums: getRowNums( filterValues, 100 ),
    };

    Object.keys( _values ).map( attr => {
      params[attr] = _values[attr];
    } );

    const missingRequired = () => {
      if (
        isNotEmpty( _values.included_ranges )
        || isNotEmpty( _values.included_host_patterns )
        || isNotEmpty( _values.included_host_ids )
        || isNotEmpty( _values.included_product_names )
      ) {
        return false;
      }
      return true;
    };

    if ( missingRequired() ) {
      addFlashMessage( {
        header: 'Missing Information',
        // eslint-disable-next-line max-len
        body: 'Must have at least 1 Include Filter applied (IP Ranges, Specific Hosts, Host Wildcards, Operating Systems)',
        type: 'alert',
      } );
      setLoading( false );
    } else {
      // eslint-disable-next-line camelcase
      encodeURLHash( { current_page: page } );

      const testFiltersResponse = await makeRequest( 'COMPUTE', '/project/default/asset_tag', params );

      setLoading( false );

      if (
        isNotEmpty( testFiltersResponse )
        && isNotEmpty( testFiltersResponse.results )
        && isNotEmpty( testFiltersResponse.results.target_hosts )
      ) {

        const hostData = [];

        testFiltersResponse.results.target_hosts.map( host => {

          const _host = { ...host, type: 'host' };

          hostData.push(
            {
              name: <a
                // eslint-disable-next-line max-len
                href={ `#.=risk_insight&report=hosts&item_count=100&order_by=filtered_risk&order_direction=DESC&current_page=1&item=${host.id}` }
              >
                { recordTypeDisplayName( _host, 'host' ) }
              </a>,
              addresses: <span
                className="ipAddressesWrapper"
              >
                { isNotEmpty( _host.ip_addresses ) ? _host.ip_addresses.join( ', ' ) : '' }
              </span>,
              // eslint-disable-next-line camelcase
              operating_system: host.product_name,
              'DeepSurface Scanning Status': <ScanningStatusIndicator hideText timestamp={ _host.last_scanned } />,
              actions: <React.Fragment>
                {
                  isNotEmpty( _host.id ) &&
                  <React.Fragment>
                    <button
                      title="View Full Host Details"
                      className="roundGlyphButton light selectRecordButton"
                      onClick={ () => selectHost( _host.id ) }
                    >
                      <InlineSVG type="carretRight" />
                    </button>
                  </React.Fragment>
                }
              </React.Fragment>,
              id: _host.id,
              originalRecord: _host,
            },
          );
        } );
        const pageResults = pageIterator( hostData, filterValues );

        setCurrentPageResults( pageResults.firstPage );
        setNextPageResults( pageResults.secondPage );

        addFlashMessage( {
          type: 'success',
          // eslint-disable-next-line max-len
          body: `Applied filters returned ${ formatNumber( testFiltersResponse.results?.target_hosts?.length ) } host(s)`,
        } );
      } else {
        addFlashMessage( {
          type: 'alert',
          body: 'Applied filters returned no hosts',
        } );
      }
    }

  };

  const tableHeader = () => {
    if ( isEmpty( currentPageResults ) ) {
      return 'Matching Hosts (0)';
    }

    const hash = decodeURLHash();

    const pageNumber = hash.current_page || 1;
    const itemsPerPage = hash.item_count || 100;
    const recordCount = currentPageResults?.length || 0;

    const beginning = itemsPerPage * ( pageNumber - 1 );
    const end = beginning + recordCount;

    return `Matching Hosts (${beginning + 1} - ${end})`;
  };

  return (
    <React.Fragment>
      { loading && <Loading /> }
      {
        ( isNotEmpty( requiredFields ) ) &&
        <React.Fragment>
          <label className="nameAndColorLabel">Name and Color</label>
          <Form
            fields={requiredFields}
            trackUpdates={false}
            recordType="tag"
            onChangeCallback={ setUpdatedRequiredForm }
            existingRecord={ sanitizedRecord }
            setIsValid={ setRequiredValid }
            elementClass="requiredFieldsFormWrapper"
          />
        </React.Fragment>

      }
      <div className="tagDefinitionWrapper">
        <h2>Tag Definition</h2>
        <button
          className="toggleDefinitionModalButton"
          onClick={ () => setShowDefinitionModal( !showDefinitionModal ) }
        >
          Add/Edit Filters
        </button>
        <div className="appliedFilters">
          {
            isNotEmpty( appliedFilterValues ) &&
            Object.entries( appliedFilterValues ).map( ( [ attribute, value ], index ) => {
              return <AppliedFilter
                key={index}
                label={ filterAttibuteLabelMap[attribute] }
                value={value}
                attribute={attribute}
                onRefresh={ () => {} }
                reportType="tag"
                onRemoveCallback={ handleAppliedFilterRemove }
              />;
            } )
          }
          {
            isNotEmpty( appliedFilterValues ) &&
            <button
              onClick={ clearAllAppliedFilters }
              className="roundGlyphButton light clearFiltersButton"
            >
              <InlineSVG type="delete" />
            </button>
          }
        </div>
        <button
          disabled={ isEmpty( appliedFilterValues ) }
          className={ `testDefinitionButton ${isEmpty( appliedFilterValues ) ? 'disabled' : ''}`}
          onClick={ () => testTagDefinition( 1 ) }
        >
          Test tag definition
        </button>
        {
          ( isNotEmpty( definitionFields ) ) &&
          <React.Fragment>
            <div
              className={ `definitionFormModalShade ${showDefinitionModal ? 'visible' : ''}` }
              onClick={ () => setShowDefinitionModal( false ) }
            />
            <div className={ `definitionFormModal ${showDefinitionModal ? 'visible' : ''}` }>
              <div className="definitionFormHeader">
                <h2>Tag Definition</h2>
                <button className="roundGlyphButton light" onClick={ () => setShowDefinitionModal( false ) }>
                  <InlineSVG type="close" />
                </button>
              </div>
              <Form
                fields={ definitionFields }
                trackUpdates={ false }
                recordType="tag"
                setExternalFormState={setUpdatedDefinitionForm}
                externalFormState={ updatedDefinitionForm }
                onChangeCallback={ setUpdatedDefinitionForm }
                existingRecord={ sanitizedRecord }
                setIsValid={ setDefinitionValid }
              />
              <div className="definitionFormFooter">
                <button className="submit" onClick={ () => setShowDefinitionModal( false ) }>
                  Okay
                </button>
              </div>
            </div>
          </React.Fragment>
        }
      </div>
      {
        isNotEmpty( currentPageResults )
          ? <React.Fragment>
            <div className="tagTestTableDescription">
              The Above filters matched the following host(s)
            </div>
            <div className="tagTestTableHeader">
              <h2>{ tableHeader() }</h2>
              <IndeterminantPagination
                currentPageNumber={currentPageNumber}
                nextPageResults={nextPageResults}
                goToPage={goToPage}
                elementClass="tagTestPagination"
              />
            </div>
            <div className="tagTestTableWrapper">
              <DataTable
                data={currentPageResults}
                elementClass="policyTestDataTable"
              />
            </div>
          </React.Fragment>
          : <EmptyState message="Add filters to create a tag definition"/>
      }
      <div className="modalActions">
        <button
          disabled={ ( !requiredValid && !definitionValid ) || !canConfigure( licenseInfo ) }
          // eslint-disable-next-line max-len
          className={`${ ( requiredValid && definitionValid ) ? '' : 'disabled'} ${ !canConfigure( licenseInfo ) ? 'disabled' : ''} submitButton`}
          onClick={ () => onSave(
            selectedRecord,
            ( requiredValid && definitionValid ),
            {
              ...getFieldValues( updatedRequiredForm?.fieldStates, 'tag' ),
              ...getFieldValues( updatedDefinitionForm?.fieldStates, 'tag' ),
            },
            () => setShowModal( false ),
            setLoading,
          ) }
        >
          Save Changes
        </button>
        <button
          className="cancelButton"
          onClick={ () => setShowModal( false ) }
        >
          Cancel
        </button>
      </div>
    </React.Fragment>
  );
};

export default TagEditorV3;