/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import InsightTable from '../../Table';

import {
  isNotEmpty,
  decodeURLHash,
  encodeURLHash,
  removeFromURLHash,
} from '../../../../shared/Utilities';

import './style.scss';
import { FlashMessageQueueContext } from '../../../../Contexts/FlashMessageQueue.js';
import { getRecordDetails, getRecords, getRecordsCount } from '../../../../shared/RecordCache';
import RecordDetails from '../../../RecordDetails/index.js';
import PageHeader from '../../../../shared/PageHeader/index.js';
import ReportHeader from '../../ReportHeader/index.js';
import { defaultRemdiationItems } from '../../Table/shared.js';
import { CurrentUserContext } from '../../../../Contexts/CurrentUser.js';

const Hosts = ( ) => {

  const [ selectedRecord, setSelectedRecord ] = React.useState( null );

  const [ records, setRecords ] = React.useState( [] );
  const [ recordCount, setRecordCount ] = React.useState( 0 );

  // controls the top overview panel collapsed state, storing here to pass down to multiple components
  const [ visualCollapsed, setVisualCollapsed ] = React.useState( false );
  const [ loading, setLoading ] = React.useState( false );
  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );
  const [ currentUser ] = React.useContext( CurrentUserContext );
  const [ remediationItems, setRemediationItems ] = React.useState( defaultRemdiationItems );

  const isMounted = true;

  const fetchExistingRecord = async( recordID ) => {
    const tagIDs = decodeURLHash()['asset_tag_ids'];

    const additionalParams = {
      filters: {},
    };

    if ( isNotEmpty( tagIDs ) ) {
      // eslint-disable-next-line camelcase
      additionalParams.filters.asset_tag_ids = tagIDs;
    }

    const _record = (
      await getRecordDetails(
        'host',
        recordID,
        additionalParams,
      )
    );
    return _record;
  };

  const selectRecord = async ( recordID ) => {
    if ( isNotEmpty( recordID ) ) {

      const fetchedRecord = await fetchExistingRecord( recordID );

      if ( isNotEmpty( fetchedRecord ) ) {
        setSelectedRecord( fetchedRecord );
        encodeURLHash( { item: recordID } );
      } else {
        addFlashMessage( {
          type: 'alert',
          body: 'The record you are looking for is no longer part of the risk model',
        } );
        setSelectedRecord( {} );
        removeFromURLHash( 'item' );
        onRefresh();
      }
    } else {
      setSelectedRecord( {} );
      removeFromURLHash( 'item' );
      onRefresh();
    }
  };

  const onRefresh = async () => {
    const hash = decodeURLHash();
    const onCorrectPage = hash.report === 'hosts';

    setLoading( true );

    if ( isMounted && onCorrectPage ) {
      if ( isNotEmpty( hash.item ) ) {
        selectRecord( hash.item );
      } else {
        const _count = await getRecordsCount( 'host' );
        const _records = await getRecords( 'host', null, true );
        setRecords( _records );
        setRecordCount( _count );
      }
    }
    setLoading( false );
  };

  return (
    <React.Fragment>
      {
        ( isNotEmpty( selectedRecord ) && isNotEmpty( currentUser ) )
          ? <RecordDetails
            record={selectedRecord}
            recordType="host"
            riskType="filtered_risk"
            selectRecord={selectRecord}
            options={ {
              isCollapsible: true,
              renderContext: 'risk_insight',
              // eslint-disable-next-line camelcase
              include_scanning_status: true,
              // eslint-disable-next-line camelcase
              include_vulnerability_instances: true,
              // eslint-disable-next-line camelcase
              include_details: true,
              // eslint-disable-next-line camelcase
              include_applications_and_services: true,
              // eslint-disable-next-line camelcase
              include_statistics: true,
              // eslint-disable-next-line camelcase
              include_cvss_breakdown: true,
              // eslint-disable-next-line camelcase
              include_exploit_status_breakdown: true,
            } }
          />
          : <React.Fragment>
            <PageHeader>
              <ReportHeader
                reportType="host"
                onRefresh={onRefresh}
                visualCollapsed={visualCollapsed}
                setVisualCollapsed={setVisualCollapsed}
                records={records}
                selectRecord={selectRecord}
                loading={loading}
                remediationItems={remediationItems}
                setRemediationItems={setRemediationItems}
              />
            </PageHeader>
            <InsightTable
              records={records}
              recordCount={recordCount}
              reportType="host"
              onRefresh={onRefresh}
              refreshTable={onRefresh}
              visualCollapsed={visualCollapsed}
              selectedRecord={selectedRecord}
              selectRecord={selectRecord}
              loading={loading}
              remediationItems={remediationItems}
              setRemediationItems={setRemediationItems}
            />
          </React.Fragment>
      }
    </React.Fragment>
  );
};

export default Hosts;