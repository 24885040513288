/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { TasksProvider } from '../../../Contexts/Tasks';

import TaskList from './TaskList';
import './style.scss';
import ConfigurationAlert, { getConfigurationAlertsFor } from '../../../shared/ConfigurationAlert';
import { isNotEmpty } from '../../../shared/Utilities';
import { hasFeatureAccess } from '../../App/AccessControl';
import { CurrentUserContext } from '../../../Contexts/CurrentUser';

const Tasks = ( ) => {
  const [ hasDiskAlert, setHasDiskAlert ] = React.useState( false );
  const [ currentUser, licenseInfo ] = React.useContext( CurrentUserContext );

  React.useEffect( () => {
    if ( isNotEmpty( currentUser ) && hasFeatureAccess( currentUser, licenseInfo, 'f_configuration_alerts' ) ) {
      getConfigurationAlertsFor( 'health-check' ).then( response => {
        if ( isNotEmpty( response ) ) {
          setHasDiskAlert( true );
        }
      } );
    }
  }, [ currentUser, licenseInfo ] );

  return (
    <div className="setupPageContainer tasksPage">
      <TasksProvider>
        {
          hasDiskAlert &&
          <ConfigurationAlert
            sourceType="health-check"
          />
        }
        <TaskList />
      </TasksProvider>
    </div>
  );
};

export default Tasks;