/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import SetupPage from '../../../shared/SetupComponents/SetupPage';

import {
  isEmpty,
  isFormNull,
  isNotEmpty,
} from '../../../shared/Utilities';

import {
  recordData,
} from './data';
import { OnboardingWizardContext } from '../../../Contexts/OnboardingWizard';
import { normalizedAttribute } from '../../../shared/Form/Shared';

import './style.scss';
import { FlashMessageQueueContext } from '../../../Contexts/FlashMessageQueue';
import { makeRequest } from '../../../../legacy/io';
import { CurrentUserContext } from '../../../Contexts/CurrentUser';
import { canConfigure, hasFeatureAccess } from '../../App/AccessControl';

const ScanCredentials = () => {

  const CRED_OPTIONS = {
    'smb-wmi': 'SMB/WMI',
    'winrm-psrp': 'WinRM w/ PSRP',
    'ssh-password': 'SSH Password',
    'ssh-key': 'SSH Private Key',
    'smb': 'SMB (deprecated)',
  };

  const [ credentials, setCredentials ] = React.useState( [] );

  // ContextualHelp getters and setters
  const [ , , refreshWizard, , , , , , , ] = React.useContext( OnboardingWizardContext );
  const [ currentUser, , licenseInfo ] = React.useContext( CurrentUserContext );
  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );
  const [ maxEvalOrder, setMaxEvalOrder ] = React.useState( 0 );

  // need to get all the credentials, filter out the unecessary ones,
  // match up associated scan groups
  const onRefresh = () => {
    makeRequest( 'SEARCH', '/project/default/credential', {
      // eslint-disable-next-line camelcase
      extra_columns: [
        'protocol',
        'domain',
        'username',
        'eval_order',
        'options',
        'label',
        'pam_settings',
        'pam_type',
        'pam_client_cert',
        'pam_client_key',
      ],
      // eslint-disable-next-line camelcase
      order_by: [ [ 'eval_order', 'ASC' ] ],
    } ).then( credResponse => {
      if ( credResponse ) {
        if ( credResponse['results'] && credResponse['results'].length ) {

          const _evalOrders = [];

          credResponse['results'].map( key => {
            _evalOrders.push( key?.eval_order );
          } );

          setMaxEvalOrder( Math.max( ..._evalOrders ) );

          const scanGroupCredentialsMap = {};

          // get and map associated scan groups for display
          makeRequest( 'FIND', '/project/default/scan_group', {} ).then( sgResponse => {
            const validCreds = [];
            const validProtocols = Object.keys( CRED_OPTIONS );


            if ( sgResponse && isNotEmpty( sgResponse['results'] ) ) {
              sgResponse['results'].map( group => {
                group.credentials.map( cred => {
                  if ( scanGroupCredentialsMap[cred] ) {
                    scanGroupCredentialsMap[cred].push( group.label );
                  } else {
                    scanGroupCredentialsMap[cred] = [ group.label ];
                  }
                } );
              } );

              credResponse['results'].map( cred => {
                if ( isNotEmpty( cred.protocol ) && validProtocols.includes( cred.protocol ) ) {

                  cred.scanGroupLabels = isNotEmpty( scanGroupCredentialsMap[cred.id] )
                    ? scanGroupCredentialsMap[cred.id]
                    : [];
                  if ( cred.options && cred.options.elevate_method === null ) {
                    // eslint-disable-next-line camelcase
                    cred.options.elevate_method = 'null';
                  }

                  validCreds.push( cred );
                }
              } );

              validCreds.map( ( cred, index ) => {
                if ( isEmpty( cred.eval_order ) ) {
                  // eslint-disable-next-line camelcase
                  cred.eval_order = index + 1;
                }
              } );
              setCredentials( validCreds );

            } else {
              credResponse['results'].map( cred => {
                if ( isNotEmpty( cred.protocol ) && validProtocols.includes( cred.protocol ) ) {

                  cred.scanGroupLabels = [];

                  if ( cred.options && cred.options.elevate_method === null ) {
                    // eslint-disable-next-line camelcase
                    cred.options.elevate_method = 'null';
                  }

                  validCreds.push( cred );
                }
              } );

              validCreds.map( ( cred, index ) => {
                // eslint-disable-next-line camelcase
                cred.eval_order = index + 1;
              } );

              setCredentials( validCreds );
            }
          } );
        } else {
          setCredentials( [] );
        }
      }
    } );
  };

  const onReorder = reordered => {
    const updatedCredOrder = [];

    // optimistically reordering in the UI
    setCredentials( reordered );

    reordered.map( ( cred, index ) => {
      // eslint-disable-next-line camelcase
      updatedCredOrder.push( { id: cred.id, eval_order: index + 1 } );
    } );
    makeRequest( 'UPSERT', '/project/default/credential', { 'records': updatedCredOrder } ).then( response => {
      onRefresh();
      if ( response['errors'] ) {
        addFlashMessage( {
          body: response['errors'],
          type: 'alert',
        } );
      } else if (
        isNotEmpty( currentUser )
        && isNotEmpty( licenseInfo )
        && hasFeatureAccess( currentUser, licenseInfo, 'f_onboarding' )
      ) {
        refreshWizard();
      }
    } );
  };

  const onSave = (
    credWithScanGroups,
    isValid,
    fieldStates,
    successCallback,
  ) => {

    const elevateAttrs = [
      'elevate_method',
      'elevate_source',
    ];

    const pamAttrs = [
      'local_user',
      'url',
      'tofu',
      'target_domain',
      'target_username',
      'secret_name',
      'safe_name',
      'require_ip_match',
    ];

    if ( isValid && isNotEmpty( fieldStates ) && canConfigure( licenseInfo ) ) {

      const selectedProtocol = fieldStates.protocol.updatedValue;

      const includedValues = {};
      const credential = {};

      Object.entries( fieldStates ).map( ( [ attribute, state ] ) => {
        if ( state.included ) {
          includedValues[ normalizedAttribute( { attribute }, 'scan_credential' ) ] = state.updatedValue;
        }
      } );

      Object.keys( includedValues ).map( attr => {
        if ( elevateAttrs.includes( attr ) ) {
          if ( isNotEmpty( credential.options ) ) {
            if ( attr === 'elevate_method' && isFormNull( includedValues[attr] ) ) {
              credential.options[attr] = null;
            } else {
              credential.options[attr] = includedValues[attr];
            }

          } else if ( attr === 'elevate_method' && isFormNull( includedValues[attr] ) ) {
            credential.options = { [attr]: null};
          } else {
            credential.options = { [attr]: includedValues[attr] };
          }
        } else if ( pamAttrs.includes( attr ) ) {
          if ( credential['pam_settings'] ) {
            credential['pam_settings'][attr] = includedValues[attr];
          } else {
            credential['pam_settings'] = { [attr]: includedValues[attr] };
          }
        } else if ( attr === 'ports' ) {
          const numberArray = includedValues[attr].map( str => Number( str ) );
          credential.options = { [attr]: numberArray };
        } else {
          credential[attr] = includedValues[attr];
        }
      } );

      // add the protocol
      credential.protocol = selectedProtocol;

      // only increment on new record
      if ( isEmpty( credWithScanGroups ) ) {
        // eslint-disable-next-line camelcase
        credential.eval_order = maxEvalOrder + 1;
      }

      if ( credential?.pam_client_key === '' ) {
        delete credential.pam_client_key;
      }

      // if secret is not being modified, don't replace it with an empty string
      if ( isNotEmpty( credWithScanGroups ) ) {
        if ( credential.secret === '' || credential.secret === 'null' ) {
          delete credential.secret;
        }
        if ( credential.elevate_secret === '' || credential.elevate_secret === 'null' ) {
          delete credential.elevate_secret;
        }

        // we are updating, add the id
        credential.id = credWithScanGroups.id;
      }

      makeRequest( 'UPSERT', '/project/default/credential', { 'records': [ credential ] } ).then( response => {
        // either an error or success
        if ( response?.errors || response?.results ) {
          if ( response.errors ) {
            response.errors.map( e => {
              addFlashMessage( {
                type: 'alert',
                body: e,
              } );
            } );
          // went through without issue
          } else {
            onRefresh();
            successCallback();
            addFlashMessage( {
              body: `Credential Successfully ${credWithScanGroups.id ? 'updated' : 'created'}`,
              type: 'success',
            } );
            if (
              isNotEmpty( currentUser )
              && isNotEmpty( licenseInfo )
              && hasFeatureAccess( currentUser, licenseInfo, 'f_onboarding' )
            ) {
              refreshWizard();
            }
          }
        // 500
        } else {
          addFlashMessage( {
            body: 'There was an error saving the credential, please check the form for any misconfigurations',
            type: 'alert',
          } );
        }
      } );
    }
  };

  return (
    <React.Fragment>
      <SetupPage
        onRefresh={onRefresh}
        onSave={onSave}
        recordData={recordData}
        records={credentials}
        setRecords={setCredentials}
        onReorder={onReorder}
        recordType="scan_credential"
        isDraggable={ canConfigure( licenseInfo ) }
        alternateItemLayout
        modalClass="twoColumn"
        allowEnterSubmit={false}
      />
    </React.Fragment>

  );
};

export default ScanCredentials;
